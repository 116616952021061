import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

// ionic
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-suggest-keyword',
    templateUrl: './suggest-keyword.component.html',
    styleUrls: ['./suggest-keyword.component.scss'],
})
export class SuggestKeywordComponent implements OnInit {

    @ViewChild('inputElem',
        {
            read: ElementRef
        }) inputElem;

    container;

    keyword: string;

    constructor(
        private popoverController: PopoverController
    ) { }

    ngOnInit() {
        setTimeout(() => {
            if (this.inputElem?.nativeElement) {
                this.inputElem.nativeElement.setFocus();
            }
        }, 200);
    }

    close() {
        this.popoverController.dismiss();
    }

    saveKeyword(valid) {
        if (valid) {
            //
            this.popoverController.dismiss({
                keyword: this.keyword,
                container: this.container
            });
        }
    }

}
