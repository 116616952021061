import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Events } from '../../helpers/events';

// ionic
import { ModalController } from '@ionic/angular';

// other libraries
import { TranslateService } from "@ngx-translate/core";

// rxjs
import { Subject } from "rxjs";

// providers
import { MarketplaceService } from "../../services/marketplace.service";
import { OverlayService } from "../../services/overlay.service";
import { PlatformService } from "../../services/platform.service";

// models
import { Marketplace } from "../../models/marketplace";
import { MarketplacePost } from "../../models/marketplace-post";
import { MarketplacePostOrder } from "../../models/marketplace-post-order";
import { User } from "../../models/user";

@Component({
    selector: 'app-marketplace-detail-orders',
    templateUrl: './marketplace-detail-orders.component.html',
    styleUrls: ['./marketplace-detail-orders.component.scss'],
})
export class MarketplaceDetailOrdersComponent implements OnInit, OnDestroy {

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * current user
     *
     * @type {User}
     */
    @Input() user: User;

    /**
     * show form in modal
     *
     * @type boolean
     */
    @Input() modal: boolean = false;

    /**
     * marketplacePost
     *
     * @type {MarketplacePost}
     */
    @Input() marketplacePost: MarketplacePost = new MarketplacePost;

    /**
     * marketplace
     *
     * @type {Marketplace}
     */
    @Input() marketplace: Marketplace;

    // handlers for events
    eventChangeHandler;

    /**
     * default locale
     *
     * @type string
     */
    @Input() defaultLocale: string = 'de';

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    public orders: MarketplacePostOrder[] = [];

    /**
     * constructor
     *
     * @param TranslateService
     * @param Events
     * @param PlatformService
     * @param OverlayService
     * @param MarketplaceService
     * @param ModalController
     */
    constructor(
        public translate: TranslateService,
        public appEvents: Events,
        public plt: PlatformService,
        public overlayService: OverlayService,
        public marketplaceService: MarketplaceService,
        public modalController: ModalController
    ) {
        // get user lang
        this.userLang = this.translate.currentLang;
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() {

        this.eventChangeHandler = () => {
            // NOTE[jg] - cancel?
        }

        // attach hooks on event change
        this.appEvents.subscribe('event:changed', this.eventChangeHandler);

        this.marketplaceService.orders(this.marketplacePost).subscribe(
            (marketplacePostOrders: MarketplacePostOrder[]) => {
                this.orders = marketplacePostOrders;
            });
    }

    /**
     * close modal window
     *
     * @param boolean modal
     *
     */
    public backClicked(modal = true) {
        if (modal) {
            this.modalController.dismiss({ action: 'close' });
        }
    }

    /**
     * close modal window
     *
     */
    public closeSaved() {
        this.modalController.dismiss({ action: 'close' });
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        if (this.eventChangeHandler) {
            this.appEvents.unsubscribe('event:changed', this.eventChangeHandler);
        }
        this.overlayService.closeModals();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}

