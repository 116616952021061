import { Participant } from "./participant";
import { ParticipantSetting } from "./participant-setting";
import { Feed } from "./feed";
import { Blacklist } from "./blacklist";

import * as moment from "moment";

export class User {
    id: number;
    email: string;
    salutation: string;
    password: string;
    password_confirmation: string;
    firstname: string;
    lastname: string;
    profession: string;
    company: string;
    additional_company: string;
    street: string;
    streetnr: string;
    zip: string;
    city: string;
    country: string;
    description: string;
    picture: string;
    picture_url: string;
    token: string;
    selected_participant_id: number;
    selected_participant: Participant;
    template: Participant;

    settings: ParticipantSetting[] = [];
    old_password: string;
    new_password: string;
    new_password_confirmation: string;
    agb_accepted: boolean;
    data_processing_accepted: boolean;

    group_id: number;

    updated_at: string;
    date_updated_at: Date = new Date();
    toc_needed: boolean;
    toc_refresh: boolean;
    toc_checked: boolean;
    header_notifications: Feed[];

    validationErrors = {};

    linkedin_profile: string;
    event_key: string;
    blacklist: Blacklist = new Blacklist();

    email_blacklisted: boolean = false;

    templateAttributes = [
        'public_email',
        'title',
        'profession',
        'company',
        'additional_company',
        'street',
        'streetnr',
        'zip',
        'city',
        'country',
        'phone',
        'xing_profile',
        'linkedin_profile',
        'description',
        'picture',
        'picture_url',
    ];

    enable_newsfeed_video: boolean = false;

    external_secret: string;
    external_secret_verification: string;
    event_id:number;

    is_organizer: boolean = false;

    /**
     * constructor
     *
     * @param {User} init
     */
    public constructor(init?: User) {
        if (!!init) {
            Object.assign(this, init);
            this.date_updated_at = moment.utc(this.updated_at).toDate();
            if (this.selected_participant_id) {
                this.selected_participant = new Participant(this.selected_participant, this);
            }
            if (this.template) {
                this.template = new Participant(this.template, this);
            }

            if (init.blacklist) {
                this.blacklist = new Blacklist(init.blacklist);
            } else {
                this.blacklist = new Blacklist();
            }
        }
    }

    public isValid() {
        if (this.new_password && this.new_password.length && this.new_password != this.new_password_confirmation) {
            this.validationErrors['new_password_confirmation'] = 'VALIDATION_PASSWORD_MISMATCH';
            return false;
        }

        return true;
    }

    /**
     * copy data form participant
     */
    public copyDataFromParticipant(participant) {
        this.firstname = this.firstname || participant.firstname;
        this.lastname = this.lastname || participant.lastname;
        this.email = this.email || participant.email;
        // this.salutation = this.salutation || participant.salutation;
        this.external_secret = this.external_secret || participant.external_secret;
    }

    /**
     * check if i can see profile details
     */
    public canProfileDetails(participant: Participant) {
        if (!participant || !this.selected_participant) return false;

        // user can see his own details...
        if (participant.id == this.selected_participant_id) return true;

        // avoid showing profile from diff event
        if (this.selected_participant.event.id != participant.event_id) return false;

        // NOTE[jg] - don't allow to see profile which is in invisible mode
        // this cover case when backend will deliver profile by mistake :-)
        if (participant.invisible) return false;

        return (
            participant.registered_at &&
            this.selected_participant &&
            this.selected_participant.event.checkGroupPermition(participant.group_id, 'visible') &&
            this.selected_participant.event.checkGroupPermition(participant.group_id, 'profile_details')
        );
    }

    /**
     * check if i can make video call
     */
    public canVideoCall(participant: Participant) {
        if (!participant || !this.selected_participant) return false;

        // user cant make video call with him self...
        if (participant.id == this.selected_participant_id) return false;

        // avoid showing profile from diff event
        if (this.selected_participant.event.id != participant.event_id) return false;

        // NOTE[jg] - don't allow to send messages when invisible mode
        if (this.selected_participant.invisible) return false;

        return (
            participant.registered_at &&
            this.selected_participant &&
            this.canMessage(participant) &&
            this.selected_participant.event.checkGroupPermition(participant.group_id, 'video_call') &&
            this.selected_participant.event.can(this.selected_participant.group_id, 'allow_call')
        );
    }

    /**
     * check if i can make video call
     */
    public canGroupVideoCall() {
        // NOTE[jg] - don't allow to send messages when invisible mode
        if (this.selected_participant?.invisible) return false;

        return (
            this.selected_participant?.event.can(this.selected_participant.group_id, 'allow_call_chat_group')
        );
    }

    /**
     * check if i can see participants picture
     */
    public canPicture(participant: Participant) {
        if (!participant || !this.selected_participant) return false;

        // user can see his won details...
        if (participant.id == this.selected_participant_id) return true;

        return this.selected_participant && this.selected_participant.event.checkGroupPermition(participant.group_id, 'profile_picture');
    }

    /**
     * check if i can see participants matching
     */
    public canMatching(participant: Participant) {
        if (!participant || !this.selected_participant) return false;

        if (!this.selected_participant.event.matching_status) return false;

        // user can not see his own matching...
        if (participant.id == this.selected_participant_id) return false;

        return this.selected_participant && this.selected_participant.event.checkGroupPermition(participant.group_id, 'matching');
    }

    /**
     * check if i can see participants matching
     */
    public canNewsfeed(participant: Participant) {
        if (!participant || !this.selected_participant) return false;

        return this.selected_participant && this.selected_participant.event.checkGroupPermition(participant.group_id, 'newsfeed');
    }

    public canSee(participant) {
        if (!participant || !this.selected_participant) return false;

        // user can see his won details...
        if (participant.id == this.selected_participant_id) return true;

        return this.selected_participant &&
            this.selected_participant.event.checkGroupPermition(participant.group_id, 'visible');
    }

    /**
     * check if i can send message to participant
     */
    public canMessage(participant, onlyAppointment: boolean = false) {
        if (!participant || !this.selected_participant) return false;
        // NOTE[jg] - don't allow to send messages when invisible mode
        if (this.selected_participant.invisible) return false;

        return (this.selected_participant && this.selected_participant.id != participant.id) &&
            !participant.not_contactable &&
            (!participant.not_contactable_msg || onlyAppointment) &&
            !this.selected_participant.not_contactable &&
            this.selected_participant.event_id == participant.event_id &&
            this.canSee(participant) &&
            this.selected_participant.event.checkGroupPermition(participant.group_id, 'message');
    }
}
