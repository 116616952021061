<ion-header class="ion-no-shadow ion-no-border">
    <ion-toolbar class="ion-no-border">
        <ion-title>
            <div class="info">
                <!-- <h3>{{ videoLink }}</h3> -->
            </div>
        </ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="backClicked()">
                <ion-icon slot="icon-only"
                          name="close">
                </ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<iframe id="video"
        [src]="videoLink | embedVideo:true | safe:'resourceUrl'"
        [height]="(plt.appHeight - 56) + 'px'"
        sandbox="allow-scripts allow-same-origin allow-presentation"
        allowfullscreen
        allow="autoplay;fullscreen"
        frameborder="0"
        width="100%">
</iframe>

<div *ngIf="loadingError"
     class="error">
    <p>
        {{'IFRAME_VIDEO_LOADING_ERROR'}}
    </p>
    <a (click)="openLink()">{{videoLink}}</a>
</div>