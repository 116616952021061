import { Component, OnInit, OnDestroy, Input } from '@angular/core';

// ionic
import { PopoverController } from '@ionic/angular';

// rxjs
import { Subject } from "rxjs";
import { debounceTime, take, takeUntil } from "rxjs/operators";

// models
import { Participant } from "../../models/participant";
import { User } from "../../models/user";

// providers
import { PlatformService } from "../../services/platform.service";
import { ParticipantService } from "../../services/participant.service";
import { OverlayService } from "../../services/overlay.service";
import { UserService } from "../../services/user.service";

@Component({
    selector: 'participant-action-menu',
    templateUrl: './participant-action-menu.component.html',
    styleUrls: ['./participant-action-menu.component.scss']
})
export class ParticipantActionMenu implements OnInit, OnDestroy {

    /**
     * selected participant
     *
     * @type string
     */
    @Input() participant: Participant;

    /**
     * current event
     *
     * @type {User}
     */
    public user: User;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    public newsfeed_muted: boolean = false;
    public marketplace_muted: boolean = false;

    /**
     * constructor
     *
     */
    constructor(
        public plt: PlatformService,
        public overlayService: OverlayService,
        public popoverController: PopoverController,
        public participantService: ParticipantService,
        public userService: UserService
    ) { }

    ngOnInit() {
        // subscribe to current user
        this.userService.getCurrentUser().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(0)
        ).subscribe(
            (user) => {
                if (user.id) {
                    this.user = user;

                    if (this.user.selected_participant.blacklist) {
                        if (this.user.selected_participant.blacklist.newsfeed_participants.find(item => item == this.participant.id)) {
                            this.newsfeed_muted = true;
                        }
                    }

                    if (this.user.blacklist) {
                        if (this.user.blacklist.marketplace_participants.find(item => item == this.participant.id)) {
                            this.marketplace_muted = true;
                        }
                    }

                } else {
                    this.user = null;
                }
            });
    }

    async unmute(type: string) {
        await this.popoverController.dismiss({
            item: this.participant,
            action: 'unmute',
            type: type
        });
    }

    async mute(type: string) {
        await this.popoverController.dismiss({
            item: this.participant,
            action: 'mute',
            type: type
        });
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
