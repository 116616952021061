import { Pipe, PipeTransform } from '@angular/core';

/*
 * Converts strings into snake_case format
 */
@Pipe({ name: 'embedVideo' })
export class EmbedVideoPipe implements PipeTransform {
    transform(value: string, autoplay: boolean = false): string {
        if (value) {
            /*
                this pattern matches:
                - http://www.youtube.com/v/0zM3nApSv11?fs=1&amp;hl=en_US&amp;rel=0
                - http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
                - http://www.youtube.com/embed/0zM3nApSvMg?rel=0
                - http://www.youtube.com/watch?v=0zM3nApSvMg
                - http://youtu.be/0zM3nApSvMg
                - https://vimeo.com/265045525
            */
            let pattern = /(?:https?:\/\/)?(?:www\.)?((?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|(vimeo\.com\/([0-9]{6,}))|(vimeo\.com\/event\/(\w+))|(congreet\.stream\/(\w*)))(?:\S+)?/gim;

            let matches = value.match(pattern);
            let video_embed_link;

            // if we found something
            if (matches) {
                let link = matches[matches.length - 1];

                let videoId;

                let linkSplitted = link.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

                // youtube
                if (linkSplitted[2] !== undefined) {
                    videoId = linkSplitted[2].split(/[^0-9a-z_\-]/i);
                    videoId = videoId[0];
                    video_embed_link = 'https://www.youtube.com/embed/' + videoId + '?rel=0&enablejsapi=1';

                    if (autoplay) {
                        video_embed_link += '&autoplay=1';
                    }
                }
                // vimeo
                else {
                    // live stream support

                    /*
                        - https://vimeo.com/event/3649783/embed
                    */
                    linkSplitted = link.replace(/(>|<)/gi, '').split(/(vimeo.com\/event\/)/);
                    if (linkSplitted[2] !== undefined) {
                        videoId = linkSplitted[2];
                        video_embed_link = 'https://vimeo.com/event/' + videoId + '/embed';
                    } else {
                        // regular viemo video link
                        linkSplitted = link.replace(/(>|<)/gi, '').split(/(vimeo.com\/)/);
                        if (linkSplitted[2] !== undefined) {
                            videoId = linkSplitted[2];
                            video_embed_link = 'https://player.vimeo.com/video/' + videoId;
                        } else {
                            // support for contreet stream
                            linkSplitted = link.replace(/(>|<)/gi, '').split(/(congreet.stream\/)/);
                            if (linkSplitted[2] !== undefined) {
                                videoId = linkSplitted[2];
                                video_embed_link = 'https://congreet.stream/' + videoId;
                            }
                        }
                    }
                }

            } else {
                video_embed_link = '';
            }

            return video_embed_link;
        }
    }
}