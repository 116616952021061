// group-name.pipe.ts
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

/**
 *
 */
@Pipe({ name: 'checkTranslate' })
export class CheckTranslatePipe implements PipeTransform {

    constructor(
        public translate: TranslateService,
    ) { }

    /**
     *
     * @param value
     * @returns {string}
     */
    transform(constant: string): string {

        let translation = this.translate.instant(constant);

        if (translation == constant) {
            return '';
        } else {
            return translation;
        }
    }
}
