export const timeFormatDefault = {
    default: 'HH:mm',
    en: 'hh:mm a',
    de: 'HH:mm',
    fr: 'HH:mm',
    es: 'HH:mm',
}

export const timeFormatJustNumbers = {
    default: 'HH:mm',
    en: 'hh:mm',
    de: 'HH:mm',
    fr: 'HH:mm',
    es: 'HH:mm'
}

export const timeFormatDefaultEnd = {
    default: 'HH:mm',
    en: 'hh:mm a',
    de: 'HH:mm U[h]r',
    fr: 'HH:mm',
    es: 'HH:mm'
}

export const dateFormatDefault = {
    default: 'DD.MM.YYYY',
    en: 'DD.MM.YYYY',
    de: 'DD.MM.YYYY',
    fr: 'DD/MM/YYYY',
    es: 'DD/MM/YYYY'
}

export const dateFormatShort = {
    default: 'DD.MM.',
    en: 'DD.MM.',
    de: 'DD.MM.',
    fr: 'DD/MM',
    es: 'DD/MM'
}

export const dateFormatDayName = {
    default: 'dddd, DD.MM.YYYY',
    en: 'dddd, DD.MM.YYYY',
    de: 'dddd, DD.MM.YYYY',
    fr: 'dddd, DD/MM/YYYY',
    es: 'dddd, DD/MM/YYYY'
}

export const dateTimeFormatDefault = {
    default: 'DD.MM.YYYY - HH:mm',
    en: 'DD.MM.YYYY - hh:mm a',
    de: 'DD.MM.YYYY - HH:mm U[h]r',
    fr: 'DD/MM/YYYY - HH:mm',
    es: 'DD/MM/YYYY - HH:mm'
}

export const dateLongFormatDefault = {
    default: 'DD. MMMM YYYY',
    en: 'D[th] [of] MMMM YYYY',
    de: 'DD. MMMM YYYY',
    fr: 'DD. MMMM YYYY',
    es: 'DD de MMMM de YYYY',
}

export const dateFormats = {
    'dateTimeFormatDefault': dateTimeFormatDefault,
    'timeFormatDefault': timeFormatDefault,
    'timeFormatJustNumbers': timeFormatJustNumbers,
    'timeFormatDefaultEnd': timeFormatDefaultEnd,
    'dateFormatDefault': dateFormatDefault,
    'dateFormatDayName': dateFormatDayName,
    'dateFormatShort': dateFormatShort,
    'dateLongFormatDefault': dateLongFormatDefault
}
