<!-- <ion-header class="ion-no-shadow ion-no-border">
  <ion-toolbar>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header> -->

<ion-content class="ion-padding">
    <div class="langSwitch">
        <ng-container *ngFor="let availableLang of plt.availableLangs; let i = index">
            <span (click)="switchLanguage(availableLang)"
                  [ngClass]="{'active': userLang == availableLang}">
                {{ availableLang | uppercase | trns }}
            </span>
            <ng-container *ngIf="i != (plt.availableLangs.length - 1)">
                /
            </ng-container>
        </ng-container>
    </div>
    <div class="ion-hide-xl-up mobile-header">
        <img alt="congreet"
             src="/assets/icons/congreet-logo-text.svg">
        <!-- <p class="welcome-text"
           [innerHtml]="'LEFT_MENU_WELCOME_TEXT' | trns"></p> -->
    </div>
    <div class="content-centered-column">
        <div class="content-centered-row">
            <form *ngIf="plt.isConnected"
                  (ngSubmit)="login()"
                  #f="ngForm"
                  novalidate>
                <ion-list class="form">
                    <ion-item class="ion-no-lines ion-hide-xl-down">
                        <h1>{{ 'LOGIN_PARTICIPANT' | trns}}</h1>
                    </ion-item>
                    <ion-item class="ion-no-lines">
                        <ion-label tabindex="-1"
                                   position="stacked">{{ 'EMAIL' | trns }}</ion-label>
                        <ion-input type="email"
                                   placeholder="{{ 'EMAIL_PLACEHOLDER' | translate }}"
                                   name="email"
                                   inputmode="email"
                                   (keyup.enter)="login()"
                                   spellcheck="false"
                                   autocapitalize="off"
                                   [autocomplete]="!!user.email ? 'off' : plt.is('ios') ? 'username' : 'on'"
                                   #email="ngModel"
                                   #emailInput
                                   id="email-input"
                                   [(ngModel)]="user.email"
                                   autofocus="true"
                                   pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                                   required>
                        </ion-input>
                    </ion-item>
                    <span *ngIf="email.touched && !email.valid"
                          class="error-message">
                        {{ 'VALIDATION_EMAIL' | trns }}
                    </span>

                    <ion-item class="ion-no-lines">
                        <ion-label tabindex="-1"
                                   position="stacked">{{ 'PASSWORD' | trns }}</ion-label>
                        <ion-input type="password"
                                   placeholder="{{ 'PASSWORD_PLACEHOLDER' | translate }}"
                                   name="password"
                                   (keyup.enter)="login()"
                                   [autocomplete]="'current-password'"
                                   #password="ngModel"
                                   #passwordInput
                                   id="password-input"
                                   [(ngModel)]="user.password"
                                   required>
                        </ion-input>
                    </ion-item>
                    <span *ngIf="password.touched && !password.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ion-item *ngIf="!plt.is('cordova')"
                              class="checkbox ion-no-padding">
                        <ion-checkbox slot="start"
                                      name="keepLoggedInInput"
                                      #keepLoggedInInput="ngModel"
                                      [(ngModel)]="keepLoggedIn">
                        </ion-checkbox>
                        <ion-label [innerHtml]="'LOGIN_KEEP' | trns">
                        </ion-label>
                    </ion-item>

                    <ion-item class="ion-no-lines event-key-input"
                              [ngClass]="{'open':showEventKey}">
                        <a class="back pointer"
                           (click)="showEventKey=false">
                            <ion-icon name="arrow-back"></ion-icon>
                            {{ 'LOGIN_EVENT_KEY_BACK' | trns }}
                        </a>
                        <ion-label tabindex="-1"
                                   position="stacked">
                            {{ 'REGISTRATION_LABEL_EVENT_KEY' | trns }}
                        </ion-label>
                        <ion-input type="text"
                                   name="event_key"
                                   placeholder=""
                                   [autocomplete]="!!eventKey ? 'off' : 'on'"
                                   #event_key="ngModel"
                                   #eventInput
                                   id="event-input"
                                   [(ngModel)]="eventKey">
                        </ion-input>
                        <span *ngIf="plt.is('ios') || plt.is('android')"
                              class="qr"
                              [ngClass]="{'ios': plt.is('ios')}"
                              (click)="showQRCodeReader()">
                            <svg-icon [applyClass]="true"
                                      src="/assets/icons/qr-code.svg"></svg-icon>
                        </span>
                    </ion-item>

                    <span *ngIf="event_key.touched && !event_key.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ion-button expand="block"
                                color="primary"
                                type="submit"
                                id="signin-button"
                                [disabled]="!f.form.valid">
                        {{ 'LOGIN_BUTTON_LOGIN' | trns }}
                    </ion-button>
                    <ion-item class="lost-password ion-no-lines ion-no-padding">
                        <div>
                            {{ 'LOGIN_LOST_PASSWORD_TEXT' | trns }}&nbsp;&nbsp;
                            <a class="link"
                               (click)="lostPassword()">
                                {{ 'LOGIN_PASSWORD_RESET' | trns }}
                            </a>
                        </div>
                    </ion-item>

                    <div class="show-event-key"
                         [ngClass]="{'open': !showEventKey}">
                        <hr>
                        <div class="or-divider">{{ 'LOGIN_OR' | trns }}</div>

                        <p>
                            {{ 'LOGIN_ARE_YOU_USING_EVENT_KEY' | trns }}&nbsp;&nbsp;
                            <!-- <a class="link"
                           (click)="showEventKey=true">
                            {{ 'LOGIN_ENTER_EVENT_KEY' | trns }}
                        </a> -->
                        </p>

                        <ion-row class="ion-no-padding">
                            <ion-col size="6"
                                     class="small-padding-right ion-no-padding">
                                <ion-button expand="block"
                                            color="primary"
                                            (click)="showEventKey=true"
                                            [innerHtml]="'LOGIN_ENTER_EVENT_KEY' | trns">
                                </ion-button>
                            </ion-col>
                            <ion-col size="6"
                                     class="small-padding-left ion-no-padding">
                                <ion-button expand="block"
                                            color="secondary"
                                            (click)="registerEventKey()"
                                            [innerHtml]="'LOGIN_REGISTER_EVENT_KEY' | trns">
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </div>

                    <hr>
                    <div class="or-divider">{{ 'LOGIN_OR' | trns }}</div>

                    <!-- Sign with Apple is available from iOS > 13 -->
                    <ion-button *ngIf="plt.is('ios') && plt.is('cordova') && plt.systemVersion >= 13"
                                expand="block"
                                class="apple"
                                type="button"
                                (click)="appleLogin()">
                        <!-- <i class="fa fa-linkedin"></i> -->
                        <ion-icon slot="start"
                                  name="logo-apple"></ion-icon>
                        {{ 'LOGIN_APPLE' | trns }}
                    </ion-button>

                    <ion-button expand="block"
                                class="linkedin"
                                type="button"
                                [ngClass]="{'disabled': linkedInSubmit}"
                                (click)="socialLoginWeb('linkedin'); linkedInSubmit = true">
                        <img slot="start"
                             src="/assets/icons/linkedin-logo.svg"
                             [alt]="'LOGIN_LINKEDIN' | trns" />
                        {{ 'LOGIN_LINKEDIN' | trns }}
                    </ion-button>

                    <!-- <ion-item class="register ion-no-lines ion-text-center" *ngIf="enableRegistration">
                        <ion-button margin-top block
                                type="button"
                                (click)="register()">
                                {{ 'BUTTON_REGISTER_FOR_FREE' | trns }}
                        </ion-button>
                    </ion-item> -->
                    <ion-button *ngIf="enableReconfirmation"
                                color="secondary"
                                class="social"
                                type="button"
                                expand="block"
                                (click)="resendConfirmation()"
                                [ngClass]="{ disabled: reconfirmation }">
                        {{ 'BUTTON_RECONFIRM' | trns }}
                    </ion-button>
                </ion-list>
            </form>
            <div *ngIf="!plt.isConnected">
                {{ 'LOGIN_APP_IS_OFFLINE' | trns }}
            </div>
        </div>
    </div>

    <div *ngIf="!plt.sizeXl && plt.isConnected"
         class="footer-links">
        <ng-container *ngIf="userLang=='en'">
            <a href="https://www.congreet.com/en/imprint/"
               target="_blank">{{ 'CUSTOM_IMPRESSUM' | trns }}</a> |
            <a href="https://www.congreet.com/en/privacy/"
               target="_blank">{{ 'CUSTOM_DATENSCHUTZ' | trns }}</a>
        </ng-container>
        <ng-container *ngIf="userLang=='de'">
            <a href="https://www.congreet.com/de/impressum/"
               target="_blank">{{ 'CUSTOM_IMPRESSUM' | trns }}</a> |
            <a href="https://www.congreet.com/de/datenschutz/"
               target="_blank">{{ 'CUSTOM_DATENSCHUTZ' | trns }}</a>
        </ng-container>
    </div>
</ion-content>