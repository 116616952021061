import { Participant } from "./participant";
import { Appointment } from "./appointment";
import { ConversationMessage } from "./conversation-message";
import * as moment from 'moment/moment';

export class Conversation
{
    id: number;
    participants: Participant[];
    messages: ConversationMessage[] = [];
    latest_message: ConversationMessage = new ConversationMessage;
    mark_read = 0;

    /**
     * constructor
     *
     * @param {Conversation} init
     */
    public constructor(init?: Conversation)
    {
        if (!!init)
        {
            Object.assign(this, init);

            this.messages = this.messages.map((message, i) => {
                if (message.system_message != 'LINKED_ITEM') {
                    return new ConversationMessage(message);
                } else {
                    // add this additional info to next message (there is reverse order from backend)
                    if (this.messages[i + 1]) {
                        this.messages[i + 1].linkedItem = JSON.parse(message.message);
                    }
                    return new ConversationMessage(message);
                }
            });
            this.latest_message.date_created_at = moment.utc(this.latest_message.created_at).toDate();
        }
    }
}
