import { Injectable } from "@angular/core";

// rxjs
import { ReplaySubject } from "rxjs";
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

// other services
import { HttpClient } from '@angular/common/http';

// models
import { Participant } from "../models/participant";
import { Conversation } from "../models/conversation";
import { ConversationMessage } from "../models/conversation-message";
import { Appointment } from "../models/appointment";

// configuration
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ConversationService {

    /**
     * current user
     *
     * @type {ReplaySubject}
     */
    private unreadCount: any = new ReplaySubject(1);

    public directMessageDrafts = [];
    public groupMessageDrafts = [];

    /**
     * constructor
     *
     * @param jwtHttp
     */
    constructor(
        private http: HttpClient
    ) { }

    /**
     * get all users
     *
     * @return {Observable<any>}
     */
    public getAll(): Observable<any> {
        return this.http.get(environment.api + '/conversation');
    }

    /**
     * get user by id
     *
     * @return {Observable<any>}
     */
    public getAllByParticipantId(partipantId: number): Observable<any> {
        return this.http.get(environment.api + '/conversation/' + partipantId);
    }

    /**
     * get user by id
     *
     * @return {Observable<any>}
     */
    public getById(id: number): Observable<any> {
        return this.http.get(environment.api + '/conversation/show/' + id);
    }

    /**
     * get user by id
     *
     * @return {Observable<any>}
     */
    public getByParticipantId(id: number, offset: number = 0, limit: number = 50, searchMessage: string = ''): Observable<any> {
        return this.http.post(environment.api + '/conversation/participant/' + id, {
            offset: offset,
            limit: limit,
            search: searchMessage
        });
    }

    /**
     * create user
     *
     * @param conversation
     *
     * @return {Observable<any>}
     */
    public create(conversation: Conversation): Observable<any> {
        return this.http.post(environment.api + '/conversation', conversation);
    }

    /**
     * update user
     *
     * @param conversation
     *
     * @return {Observable<any>}
     */
    public update(conversation: Conversation): Observable<any> {
        return this.http.put(environment.api + '/conversation/' + conversation.id, conversation);
    }

    /**
     * destroy user by id
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public delete(id: number): Observable<any> {
        return this.http.delete(environment.api + '/conversation/' + id);
    }

    /**
     * send message
     *
     * @param id
     * @param conversationMessage
     *
     * @return {Observable<any>}
     */
    public sendMessage(id: number, conversationMessage: ConversationMessage, filename?: string): Observable<any> {
        return this.http.post(environment.api + '/conversation/message/' + id, { ...conversationMessage, filename: filename });
    }

    /**
     * destroy message by id
     *
     * @param {number} id ID of message
     *
     * @return {Observable<any>}
     */
    public deleteMessage(id: number): Observable<any> {
        return this.http.delete(environment.api + '/conversation/delete-message/' + id);
    }

    /**
     * send appointment
     *
     * @param conversation
     * @param appointment
     *
     * @return {Observable<any>}
     */
    public sendAppointment(conversationId: number, appointment: Appointment): Observable<any> {
        return this.http.post(environment.api + '/conversation/appointment/' + conversationId, appointment);
    }

    /**
     * send appointment
     *
     * @param conversation
     * @param appointment
     *
     * @return {Observable<any>}
     */
    public answerAppointment(conversation_id: number, appointment: Appointment): Observable<any> {
        return this.http.post(environment.api + '/conversation/answerAppointment/' + conversation_id, appointment);
    }

    /**
     * mark conversations as read
     *
     * @param ids
     * @param participantId
     *
     * @return {Observable<any>}
     */
    public markAsRead(participantId: number): Observable<any> {
        return this.http.post(environment.api + '/conversation/markAsRead/' + participantId, {});
    }

    /**
     * set unread count
     *
     * @param count
     */
    public setUnreadCount(count: number) {
        this.unreadCount.next(count);
    }

    /**
     * get unread count
     *
     * @return {Observable<number>}
     */
    public getUnreadCount() {
        return this.unreadCount.asObservable();
    }

    /**
     * reload unread count
     *
     * @param participantId
     */
    public reloadUnreadCount(participantId: number) {
        this.http.get(environment.api + '/conversation/unreadCount/' + participantId)
            .pipe(
                take(1)
            ).subscribe((count: number) => this.setUnreadCount(count));
    }
}
