import { environment } from "../../environments/environment";

/**
 * isExternalUrl
 *
 * helper to return boolean if requested URL is out of internal REST API
 *
 * @param {string|Request} url
 *
 * @return boolean
 */
export function isExternalUrl(url: string | Request): boolean {
    if (typeof url === "string") {
        if (url.indexOf(environment.api) == -1) {
            return true;
        }
    } else {
        if (url.url.indexOf(environment.api) == -1) {
            return true;
        }
    }

    return false;
}


/**
 * isExternalLink
 *
 * helper to return boolean if requested URL is out of PWA
 *
 * @param {string} url
 *
 * @return boolean
 */
export function isExternalLink(url: string): boolean {

    let isExternal = true;

    environment.customDomains.forEach(
        (domain) => {
            if (url.indexOf(domain) !== -1) {
                isExternal = false
            }
        });

    return isExternal;
}
