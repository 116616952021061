import { Appointment } from "./appointment";
import { UploadedFile } from "./uploaded-file";
import * as moment from 'moment/moment';

export class ConversationMessage {
    id: number;
    participant_id: number;
    conversation_id: number;
    message: string;
    system_message: string;
    unread: number;
    created_at: string;
    date_created_at: Date;
    updated_at: string;
    date_updated_at: Date;
    deleted_at: string;
    date_deleted_at: Date;
    appointment: Appointment;
    firstNew: boolean = false;
    edit: boolean = false;

    day: string = moment().format('YYYYMMDD');

    file: any;

    linkedItem: {
        id: number,
        text: string,
        type: string,
        clickable?: boolean
    };

    /**
     * constructor
     *
     * @param {ConversationMessage} init
     */
    public constructor(init?: ConversationMessage)
    {
        if (!!init)
        {
            Object.assign(this, init);

            this.date_created_at = moment.utc(this.created_at).toDate();
            this.day = moment.utc(this.created_at).format('YYYYMMDD');
            this.date_updated_at = moment.utc(this.updated_at).toDate();
            this.date_deleted_at = moment.utc(this.deleted_at).toDate();

            if (this.appointment) {
                this.appointment = new Appointment(this.appointment);
            }

            if (this.file) {
                this.file = new UploadedFile(this.file);
            }
        }
    }

    edited() {
        this.date_updated_at = new Date();
        this.updated_at = moment(this.date_updated_at).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    public hasValue() {
        return this.message;
    }
}
