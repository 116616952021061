import { Component, OnDestroy, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

import { Events } from '../../helpers/events';

// ionic
import { ModalController, PopoverController } from '@ionic/angular';
// import { PopoverController, Platform } from '@ionic/angular';

// other libraries
// import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { TranslateService } from "@ngx-translate/core";

// rxjs
import { Subject } from "rxjs";
import { take, takeUntil, debounceTime } from "rxjs/operators";

// components
import { AttendeeDetailComponent } from "../../components/attendee-detail/attendee-detail.component";

// providers
import { OverlayService } from "../../services/overlay.service";
import { PlatformService } from "../../services/platform.service";
import { UserService } from "../../services/user.service";

// models
import { Participant } from "../../models/participant";
import { User } from "../../models/user";
import { Appointment } from "../../models/appointment";

@Component({
    selector: 'appointment-attendees',
    templateUrl: './appointment-attendees.component.html',
    styleUrls: ['./appointment-attendees.component.scss']
})
export class AppointmentAttendeesComponent implements OnInit, OnDestroy {

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * user
     *
     * @type {User}
     */
    public user: User = new User;

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    /**
     * initial loading state
     *
     * @type {boolean}
     */
    public loading: boolean = false;

    /**
     * appointment
     *
     * @type string
     */
    @Input() appointment: Appointment;

    /**
     * constructor
     *
     * @param appEvents
     */
    constructor(
        public translate: TranslateService,
        public appEvents: Events,
        public plt: PlatformService,
        public overlayService: OverlayService,
        public modalController: ModalController,
        public popoverController: PopoverController,
        public userService: UserService
    ) {
        this.userLang = this.translate.currentLang;
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() {
        // subscribe to current user
        this.userService.getCurrentUser().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(0)
        ).subscribe((user) => {
            this.user = user;
        });
    }

    /**
     * function to display attendee detail
     */
    public async showAttendeeDetail(participant: Participant) {
        const modal = await this.modalController.create({
            component: AttendeeDetailComponent,
            cssClass: 'attendee-modal',
            componentProps: {
                attendee: participant
            }
        });
        return await modal.present();
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
