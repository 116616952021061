export class Blacklist
{
    id: number;
    participant_id: number;
    user_id: number;
    newsfeed_participants: number[] = [];
    marketplace_participants: number[] = [];

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: Blacklist) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
