// models
import { Group } from "./group";
import { Event } from "./event";

export class AttendeesTab
{
    id: number;
    event_id: number;
    name: string;
    groups: Group[] = [];
    event: Event;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: AttendeesTab) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
