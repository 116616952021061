import { Injectable } from '@angular/core';

// ionic
import { Events } from "../helpers/events";

// rxjs
import { Observable } from 'rxjs';
import { ReplaySubject } from "rxjs";

// other services
import { HttpClient } from '@angular/common/http';

// models
import { Event } from "../models/event";
import { User } from "../models/user";
import { ApiResponse } from "../models/api-response";

// configuration
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EventService {
    /**
       * current event
       *
       * @type {ReplaySubject}
       */
    private currentEvent: any = new ReplaySubject(1);

    /**
     * constructor
     *
     * @param jwtHttp
     * @param events
     */
    constructor(
        private http: HttpClient,
        public events: Events,
    ) {
        this.events.subscribe('event:current', ((id: number, user?: User) => this.loadCurrentEvent(id)));
        this.events.subscribe('loggedout', (() => this.clearCurrentEvent()));
    }

    /**
     * get current event
     *
     * @return {Observable<Event>}
     */
    public getCurrentEvent() {
        return this.currentEvent.asObservable();
    }

    /**
     * set current event
     *
     * @param event
     */
    public setCurrentEvent(event: Event) {
        this.currentEvent.next(event);
    }

    /**
     * clear current event
     *
     * @return void
     */
    public clearCurrentEvent() {
        this.currentEvent = new ReplaySubject(1);
    }

    /**
     * reload current event
     *
     * @param {number} id ID of event
     *
     * @return {Observable<any>}
     */
    public loadCurrentEvent(id: number) {
        this.http.get(environment.api + '/event/' + id)
            .subscribe((event: Event) => this.setCurrentEvent(new Event(event)));
    }

    /**
     * get default event timeslot setting
     *
     * @param {number} id ID of event
     *
     * @return {Observable<any>}
     */
    public getEventTimeSetting(id): Observable<any> {
        return this.http.get(environment.api + '/event/' + id + '/time-slots');
    }

    /**
     * get all available events the user participates in
     *
     * @param {number} userId ID of user
     *
     * @return {Observable<any>}
     */
    public getAvailableForUser(userId: number): Observable<any> {
        return this.http.get(environment.api + '/event/availableForUser/' + userId);
    }

    /**
     * get by id
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public getById(id: number): Observable<any> {
        return this.http.get(environment.api + '/event/' + id);
    }

    /**
     * destroy container by id
     *
     * @param {number} id ID of container
     *
     * @return {Observable<any>}
     */
    public deleteContainer(id: number): Observable<any> {
        return this.http.delete(environment.api + '/event/deleteContainer/' + id);
    }

    /**
     * destroy keyword by id
     *
     * @param {number} id ID of keyword
     *
     * @return {Observable<any>}
     */
    public deleteKeyword(id: number): Observable<any> {
        return this.http.delete(environment.api + '/event/deleteKeyword/' + id);
    }

    /**
     * get event based setting
     *
     * @return {Observable<any>}
     */
    public getSetting(id: number): Observable<any> {
        return this.http.get(environment.api + '/event-settings/event/' + id);
    }
}
