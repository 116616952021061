import { MarketplaceTranslation } from "./marketplace-translation";
import { MarketplacePost } from "./marketplace-post";
import { MarketplaceContainer } from "./marketplace-container";
import { MarketplaceCategory } from "./marketplace-category";
import * as moment from "moment";
import { Translatable } from "./translatable.interface";

export class Marketplace implements Translatable {
    id: number;

    translations: MarketplaceTranslation[] = [];
    containers: MarketplaceContainer[] = [];
    categories: MarketplaceCategory[] = [];
    posts: MarketplacePost[] = [];
    hasKeywords: boolean = false;

    pivot: any;

    keyword_suggest_allowed: number = 0;
    allow_comment: boolean = true;
    allow_coins: boolean = false;
    post_visibility: string;
    keywords_limit: number;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: Marketplace) {
        if (!!init) {
            Object.assign(this, init);
            // initiate containers
            this.containers = this.containers.map(container => new MarketplaceContainer(container));

            this.containers.forEach((container) => {
                if (container.keywords.length > 0) {
                    this.hasKeywords = true;
                }
            });

            // initiate categories
            this.categories = this.categories.map(category => new MarketplaceCategory(category));
            this.reindexCategories();
        }
    }

    public reindexCategories() {
        let index = 1;
        this.categories.forEach((category) => {
            if (!category.removed) {
                category.index = index;
                index++;
            }
        });
    }

    /**
     * get translated attributes
     *
     * @param locale
     *
     * @return {MarketplaceTranslation}
     */
    public translateOrNew(locale: string) {
        let currentTranslation = this.translations.filter(translation => translation.locale === locale)[0];

        if (!currentTranslation) {
            currentTranslation = new MarketplaceTranslation;
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return new MarketplaceTranslation(currentTranslation);
    }

    /**
     * translate
     *
     * @param locale
     * @param defaultLocale
     * @return {MarketplaceTranslation}
     */
    public translate(locale: string, defaultLocale?: string) {
        return new MarketplaceTranslation(
            this.translations.filter(translation => { return translation.locale === locale; })[0] ||
            this.translations.filter(translation => { return translation.locale === defaultLocale; })[0] ||
            this.translations.filter(translation => { return translation.locale === 'de'; })[0]);
    }
}
