import { Injectable } from '@angular/core';
import { AlertController, IonicSafeString, LoadingController, ToastController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class OverlayService {

    /**
       * loading state
       *
       * @type {Promise<HTMLIonLoadingElement>}
       */
    public loading: HTMLIonLoadingElement;

    /**
     * toast
     *
     * @type {Promise<HTMLIonToastElement>}
     */
    public toast: HTMLIonToastElement = null;

    public toastIsHidden = true;

    public isEdge = false;

    /**
     * id of live chime meeting call
     *
     * @type {number}
     */
    public liveCallId;

    /**
     * list of participants IDs with waiting video call
     *
     * @type {Any[]}
     */
    public waitingCalls = [];

    /**
     * list of system notifications, key is participant ID
     *
     * @type {Object}
     */
    public notifications = {};

    /**
     * constructor
     *
     * @param loadingCtrl
     * @param translate
     * @param toastCtrl
     * @param alertCtrl
     */
    constructor(
        protected loadingCtrl: LoadingController,
        protected modalController: ModalController,
        protected translate: TranslateService,
        public toastCtrl: ToastController,
        public alertCtrl: AlertController
    ) {
        this.isEdge = window.navigator.userAgent.indexOf("Edge") > -1;
    }

    /**
     * Show loading
     *
     * @param {string} content
     */
    public async showLoading(content?: string) {
        this.loading = await this.loadingCtrl.create({
            message: this.translate.instant(!!content ? content : 'LOADING'),
            // dismissOnPageChange: false TODO
        });

        await this.loading.present();
    }

    /**
     * Hide loading
     */
    public hideLoading() {
        setTimeout(() => {
            if (!!this.loading) {
                this.loading.dismiss();
                this.loading = null;
            }
        }, 300);
    }

    /**
     * present toast
     * @param text
     * @param color
     * @param showCancel
     */
    public async presentToast(text, color, showCancel = false, duration = 3000, buttons = [], cssClass = '', id = '') {

        if(!text) {
            text = this.translate.instant('ERROR_SERVER_ERROR');
            showCancel = true;
        }

        // show close button
        if (showCancel) {
            duration = 0;

            buttons.push({
                text: ' ',
                icon: 'close',
                role: 'cancel',
                handler: () => { }
            });
        }

        text = text.replace('{{supportEmail}}', this.translate.instant('SUPPORT_EMAIL'));

        this.notifications[id] = await this.toastCtrl.create({
            message: text,
            animated: !this.isEdge,
            duration: duration,
            buttons: buttons,
            color: color,
            cssClass: 'custom-toast ' + (showCancel ? ' with-button' : cssClass),
            position: 'top'
        });

        this.hideLoading();

        return await this.notifications[id].present();
    }

    /**
     * show success
     *
     * @param text
     * @param showCancel
     */
    public async showSuccess(text, showCancel?) {
        return this.presentToast(text, 'primary', showCancel);
    }

    /**
     * show error
     *
     * @param text
     * @param showCancel
     */
    public async showError(text = '', showCancel = false, cssClass = '') {

        if(!text) {
            text = this.translate.instant('ERROR_SERVER_ERROR');
            showCancel = true;
        }

        return this.presentToast(text, 'danger', showCancel, 3000, [], cssClass);
    }

    /**
     * show error
     *
     * @param text
     */
    public async showWarning(text, cssClass = '') {
        this.hideLoading();

        let alert = await this.alertCtrl.create({
            header: this.translate.instant('MESSAGE_WARNING'),
            message: text,
            cssClass: cssClass,
            buttons: [this.translate.instant('OK')]
        });

        await alert.present();
    }

    /**
     * show info
     *
     * @param text
     */
    public async showInfo(text, duration = 3000, buttons = []) {
        this.hideLoading();

        this.toast = await this.toastCtrl.create({
            message: text,
            animated: !this.isEdge,
            duration: duration,
            buttons: buttons,
            position: 'top'
        });
        this.toastIsHidden = false;

        await this.toast.present();
    }

    /**
     * Hide info
     */
    public hideInfo() {
        if (!this.toastIsHidden && !!this.toast) {
            this.toastIsHidden = true;
            this.toast.dismiss();
        }
    }

    /**
     * Show confirm
     *
     * @param title
     * @param message
     * @param callback
     */
    public async showConfirm(title: string, message: string | IonicSafeString, callback, callback2?, ok?, cancel?) {

        let confirm = await this.alertCtrl.create({
            header: title,
            message: message,
            cssClass: 'confirm',
            buttons: [
                {
                    text: cancel ? cancel : this.translate.instant('BUTTON_CANCEL'),
                    cssClass: 'secondary',
                    handler: () => {
                        if (callback2) {
                            callback2();
                        }
                    }
                },
                {
                    text: ok ? ok : this.translate.instant('BUTTON_OK'),
                    handler: () => {
                        callback();
                    }
                }
            ]
        });

        await confirm.present();
    }

    /**
     * show success / failure message
     *
     * @param title
     * @param text
     *
     * @return void
     */
    public async showConfirmInfo(title: string, text: string | IonicSafeString, callback?) {

        let confirm = await this.alertCtrl.create({
            backdropDismiss: false,
            header: title,
            message: text,
            cssClass: 'selectable',
            buttons: [{
                text: this.translate.instant('BUTTON_OK'),
                handler: () => {
                    if (callback) {
                        callback();
                    }
                }
            }]
        });

        await confirm.present();
    }

    /**
     * Show warning about connection problems
     */
    public showConnectionProblems(error) {
        console.log('connection problems', error);
        this.showWarning(this.translate.instant('CONNECTION_PROBLEMS_TRY_AGAIN'));
    }

    public closeModals(back = false) {
        this.modalController.getTop().then(
            (v) => {
                if (v) {
                    this.modalController.dismiss();
                } else {
                    if (back) {
                        window.history.back();
                    }
                }
            }
        );
    }

    endCall(chimeMeetingId) {
        // remove info about waiting call
        this.liveCallId = null;
        const index = this.waitingCalls.indexOf(chimeMeetingId);
        if (index > -1) {
            this.waitingCalls.splice(index, 1);
        }
    }
}
