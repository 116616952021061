import { Component, OnInit, Input } from '@angular/core';

// ionic
import { ModalController } from '@ionic/angular';

// rxjs
import { Subject } from "rxjs";

// providers
import { OverlayService } from "../../services/overlay.service";
import { TrnsService } from '../../services/trns.service';

// models
import { Translation } from '../../models/translation';
import { TranslationHistory } from '../../models/translation-history';

@Component({
    selector: 'app-translation-admin-history',
    templateUrl: './translation-admin-history.component.html',
    styleUrls: ['./translation-admin-history.component.scss'],
})
export class TranslationAdminHistoryComponent implements OnInit {

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    /**
     * user language
     *
     * @type { string}
     */
    @Input() public userLang: string;

    /**
     * translation
     *
     * @type {Translation}
     */
    @Input() public translation: Translation;

    records: TranslationHistory[] = [];

    constructor(
        public overlayService: OverlayService,
        public modalController: ModalController,
        public trnsService: TrnsService
    ) { }

    ngOnInit() {
        this.loadData()
    }


    loadData() {
        this.records = [];
        this.trnsService.history(this.translation)
            .subscribe((records: TranslationHistory[]) => {
                records.forEach(
                    (record) => {
                        this.records.push(new TranslationHistory(record));
                    });
            });
    }

    /**
     * close modal window
     */
    public close() {
        // otherwise close whoe modal
        this.modalController.dismiss({ action: 'close' });
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.overlayService.closeModals();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
