export class KeywordTranslation {
    id: number;
    locale: string;
    name: string;

    /**
* constructor
*
* @param {KeywordTranslation} init
*/
    public constructor(init?: KeywordTranslation) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
