import { Event } from "./event";
import {CustomLabelTranslation} from "./custom-label-translation";
import {Translatable} from "./translatable.interface";
import { DatePipe } from "@angular/common";

import * as moment from "moment";

export class CustomLabel implements Translatable
{
    id: number;
    event_id: number;
    event: Event;
    key: string;
    icon: string;
    created_at: string;
    date_created_at: Date = new Date();
    checked: boolean = false;
    translations: CustomLabelTranslation[] = [];
    loadedIframe: boolean = false;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: CustomLabel)
    {
        if (!!init)
        {
            Object.assign(this, init);
            this.date_created_at = moment.utc(this.created_at).toDate();
        }
        else
        {
            const datePipe = new DatePipe('de-DE');
            this.created_at = datePipe.transform(this.date_created_at, 'YYYY-MM-DD HH:mm:ss');
        }
    }

    /**
     * get translated attributes
     *
     * @param locale
     *
     * @return {KeywordTranslation}
     */
    public translateOrNew(locale: string) {
        let currentTranslation = this.translations.filter(translation => translation.locale === locale)[0];

        if (!currentTranslation) {
            currentTranslation = new CustomLabelTranslation;
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return currentTranslation;
    }

    /**
     * translate
     *
     * @param locale
     * @param defaultLocale
     * @param any
     * @return {KeywordTranslation}
     */
    public translate(locale: string, defaultLocale?: string, any?: boolean) {
        let tl = this.translations.filter(translation => {
                return translation.locale === locale;
            })[0] ||
            this.translations.filter(translation => {
                return translation.locale === defaultLocale;
            })[0];

        if (!tl) {
            if (any) {
                tl = this.translations.filter(translation => {
                    return !!translation.name;
                })[0];
            } else {
                tl = new CustomLabelTranslation();
            }
        }

        return tl;

        // return this.translations.filter(translation => { return translation.locale === locale; })[0] ||
        //     this.translations.filter(translation => { return translation.locale === defaultLocale; })[0] ||
        //     new KeywordTranslation();
    }

    /**
     * Removes a translation (to get not submitted if deactivated)
     *
     * @param locale
     */
    public removeTranslation(locale: string) {
        this.translations.forEach((translation, index) => {
            if (translation.locale === locale) {
                this.translations.splice(index, 1);
            }
        });
    }

    /**
     * Looks, if all given locales are already translated
     *
     * @param locales
     * @returns {boolean}
     */
    public hasTranslationsInLocales(locales) {
        for (let i = 0; i < locales.length; i++) {
            let localeOk = false;
            // if any of the translations is missing, return false
            for (let t = 0; t < this.translations.length; t++) {
                if (this.translations[t].locale === locales[i] && this.translations[t].name && this.translations[t].name.trim() !== '') {
                    localeOk = true;
                }
            }
            if (!localeOk) {
                return false;
            }
        }
        return true;
    }
}

export const CustomLabelIcons = [{
    value: 'agreement.svg',
    ico: 'agreement.svg',
    name: 'Agreement'
},
{
    value: 'organizer_1.svg',
    ico: 'organizer_1.svg',
    name: 'Organizer'
},
{
    value: 'map-for-orientation.svg',
    ico: 'map-for-orientation.svg',
    name: 'Map for orientation'
},
{
    value: 'paperclip.svg',
    ico: 'paperclip.svg',
    name: 'Paperclip'
},
{
    value: 'photo.svg',
    ico: 'photo.svg',
    name: 'Photo'
},
{
    value: 'play-button.svg',
    ico: 'play-button.svg',
    name: 'Play button'
},
{
    value: 'restaurant-cutlery.svg',
    ico: 'restaurant-cutlery.svg',
    name: 'Restaurant cutlery'
},
{
    value: 'share.svg',
    ico: 'share.svg',
    name: 'Share'
},
{
    value: 'speaker.svg',
    ico: 'speaker.svg',
    name: 'Speaker'
},
{
    value: 'speech-bubbles.svg',
    ico: 'speech-bubbles.svg',
    name: 'Speech bubbles'
},
{
    value: 'VIP.svg',
    ico: 'VIP.svg',
    name: 'VIP'
},
{
    value: 'wifi-logo.svg',
    ico: 'wifi-logo.svg',
    name: 'Wifi logo'
}];
