// custom-translate.pipe.ts
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

/**
 *
 */
@Pipe({ name: 'customTranslate' })
export class CustomTranslatePipe implements PipeTransform {

    constructor(
        public translate: TranslateService,
    ) { }

    /**
     *
     * @param value
     * @returns {string}
     */
    transform(value: string): string {

        if (value) {
            let translations = value.split('|');

            if (translations.length > 1) {

                let langs = this.translate.getLangs();
                
                // for now make it fixes to specific order and not by tempalet
                langs = ['en', 'de', 'es', 'fr'];

                // check if actual lang index is presented
                if (translations[langs.indexOf(this.translate.currentLang)]) {
                    return translations[langs.indexOf(this.translate.currentLang)];
                } else {
                    // otherwise return first one
                    return translations[0];
                }


            } else {
                // no separator used...
                return value;
            }
        }

        return '';
    }
}
