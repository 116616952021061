import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';

// ionic
import { ModalController } from '@ionic/angular';
import { Events } from '../../helpers/events';

// rxjs
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

// other services
import { TranslateService } from "@ngx-translate/core";

// services
import { EventService } from '../../services/event.service';
import { UserService } from '../../services/user.service';
import { PlatformService } from '../../services/platform.service';
import { ParticipantService } from "../../services/participant.service";
import { MarketplaceService } from "../../services/marketplace.service";
import { OverlayService } from "../../services/overlay.service";

// models
import { User } from '../../models/user';
import { Event } from '../../models/event';
import { Participant } from '../../models/participant';
import { Marketplace } from '../../models/marketplace';
import { MarketplaceKeyword } from '../../models/marketplace-keyword';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit, OnDestroy {

    /**
     * actual wizard step
     *
     * @type {Subjecnumber}
     */
    @Input() public step: number = 0;

    /**
     * last finished step
     *
     * @type {number}
     */
    public stepFinished: number = 0;

    /**
     * count of wizard steps
     *
     * @type {number}
     */
    public wizardSteps: number = 3;

    /**
     * Wizard slider
     *
     * @type {IonSlider}
     */
    @ViewChild('slider', { static: true }) slider;

    /**
     * wizards components
     *
     */
    @ViewChild('profileEdit') profileEdit;
    @ViewChild('keywordSettings') keywordSettings;
    @ViewChild('attendeeDetail') attendeeDetail;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * subscription for event changes subject
     *
     * @type {Subscription}
     */
    private subscriptionEvent: Subscription;

    /**
     * the current user
     *
     * @type {User}
     */
    public user: User = new User;

    /**
     * the current user
     *
     * @type {Event}
     */
    public event: Event = new Event;

    /**
     * Slider options
     *
     * @type {SliderOptions}
     */
    slideOpts = {
        initialSlide: this.step - 1,
    };

    /**
     * is anything required in wizard
     *
     * @type {boolean}
     */
    public nothingRequired: boolean = false;

    /**
     * user
     *
     * @type {Participant}
     */
    public participant: Participant = new Participant;

    /**
     * user marketplace preferenced keywords, array with marketplace ID a s key
     *
     * @type {any[]}
     */
    public marketplaceKeywords = [];

    /**
     * list of marketplace which are enabled for wizard and also with some keywords
     *
     * @type {Marketplace[]}
     */
    public marketplaces = [];

    constructor(
        private modalController: ModalController,
        private overlayService: OverlayService,
        protected translate: TranslateService,
        public appEvents: Events,
        public plt: PlatformService,
        public eventService: EventService,
        public userService: UserService,
        public participantService: ParticipantService,
        public marketplaceService: MarketplaceService
    ) {
    }

    ngOnInit() {

        // if we open wizard, just disable backend auto update
        this.plt.disableBackgroundRefresh = true;

        if (this.step != 1) {
            // nothing is required, only show wizard...
            this.nothingRequired = true;
        }
        if (this.step == 0 || this.step == 2) {
            this.step = 1;
        }

        this.userService.getCurrentUser()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                debounceTime(0)
            ).subscribe(
                (user) => {
                    if (user.id) {
                        this.user = user;
                        // restore last step in wizard
                        if (this.nothingRequired && user.selected_participant.wizard_status && user.selected_participant.wizard_status < this.wizardSteps) {
                            this.step = user.selected_participant.wizard_status;
                        }

                        this.participant = this.user.selected_participant;

                        // cancel previous subscription
                        if (this.subscriptionEvent) {
                            this.subscriptionEvent.unsubscribe();
                        }

                        this.subscriptionEvent = this.eventService.getCurrentEvent()
                            .pipe(
                                takeUntil(this.ngUnsubscribe),
                                debounceTime(0)
                            ).subscribe((event) => {
                                this.event = event;

                                // check if keywords are enabled to decide
                                // if app will show step with keywords selection
                                if (this.event.matching_status) {
                                    this.wizardSteps = 3;
                                } else {
                                    this.wizardSteps = 2;
                                }

                                // load data for marketplaces
                                this.marketplaces = [];
                                this.event.marketplaces.forEach((marketplace) => {
                                    // add marketplaces which are allowed to wizard
                                    if (marketplace.pivot.wizard_allowed) {
                                        this.marketplaces.push(marketplace);
                                        this.restoreKeywords(marketplace);
                                    }
                                });
                            });
                    } else {
                        // cancel previous subscription
                        if (this.subscriptionEvent) {
                            this.subscriptionEvent.unsubscribe();
                        }
                    }
                });

        // go to proper slide step
        // slider is from zero... wizard step is from 1
        this.slider.slideTo(this.step - 1);
        // set actual step as finished
        this.stepFinished = this.step;
        // don't allow to go to next slide
        this.slider.lockSwipeToNext(true);

        // close wizard model when user leave wizard
        this.appEvents.subscribe('close:wizard', () => {
            this.close();
        });
    }

    /**
      * action to close actual step
      *
      * @return void
      */
    finishStep(participant?) {
        // update participant/user if comming from subcomponent
        if (participant) {
            this.participant = participant;
            // NOTE[jg] - this is causing modal to close after first save, why??
            // this.user.selected_participant = participant;
        }

        this.slider.isEnd().then(
            (finished) => {
                // if not last step of form
                if (!finished) {
                    this.slider.lockSwipeToNext(false);
                    this.slider.slideNext();
                    this.slider.lockSwipeToNext(true);
                    // // update current user
                    // this.userService.setCurrentUser(this.user);
                } else {
                    // close wizard modal after finishing last step
                    this.close();
                }
            });
    }

    /**
      * action to close actual step
      *
      * @param {number} step
      *
      * @return void
      */
    goToStep(step: number) {
        if (step <= this.stepFinished) {
            this.step = step;
            // slider is from zero... wizard step is from 1
            this.slider.slideTo(this.step - 1);
        }
    }

    /**
      * sync step attribute with slider
      *
      * @return void
      */
    setStep() {
        this.slider.getActiveIndex().then((index) => {
            // slider is from zero... wizard step is from 1
            this.step = index + 1;
            if (this.stepFinished < this.step) {
                this.stepFinished = this.step;
                // store finished step

                // set finished step for wizard for keywords step
                if (this.participant.wizard_status != this.wizardSteps && this.step == this.wizardSteps) {

                    // be sure to have proper user data
                    this.userService.getCurrentUser()
                        .pipe(
                            take(1),
                            debounceTime(0)
                        ).subscribe(
                            (user) => {
                                this.user = user;

                                this.participant = this.user.selected_participant;

                                this.participant.wizard_status = this.wizardSteps;
                                // don't send and change participant details
                                this.participant.groupedDetails = null;
                                this.participant.groupedDetailsDeleted = null;
                                this.participant.details = null;

                                this.participantService.update(this.participant).subscribe(
                                    () => {
                                        // we need to call action for update local user
                                        this.appEvents.publish('user:current:participant');
                                    },
                                    (error) => {
                                        const data = error.error;
                                        this.overlayService.showError(data.message);
                                    });
                            });
                }
            }
            if (this.step < this.stepFinished) {
                // allow to get to next swipes
                this.slider.lockSwipeToNext(false);
            } else {
                // lock to goint to next slide
                this.slider.lockSwipeToNext(true);
            }
        });
    }

    validateKeywords() {
        if (this.user.selected_participant.keywordsRequired && this.user.selected_participant.keywords.length == 0) {
            // show error message
            this.overlayService.showError(this.translate.instant('VALIDATION_PLEASE_SELECT_AT_LEAST_ONE_KEYWORD'));
        } else {
            this.finishStep();
        }
    }

    /**
      * load marketplace keywords preference
      *
      * @return void
      */
    restoreKeywords(marketplace: Marketplace) {
        this.marketplaceKeywords[marketplace.id] = [];
        this.marketplaceService.getUserKeywords(marketplace.id).subscribe(
            (success) => {
                success.forEach(keyword => {
                    keyword.keyword = new MarketplaceKeyword(keyword.keyword);
                    this.marketplaceKeywords[marketplace.id].push(keyword);
                });
            },
            (error) => {
                console.error(error);
            }
        )
    }

    /**
      * update local array with marketplace keywords preference
      *
      * @return void
      */
    updateKeywords(marketplace, keywords) {
        this.marketplaceKeywords[marketplace.id] = keywords;
    }

    /**
      * save marketplace keywords preference
      *
      * @return void
      */
    applyKeywords(marketplace) {
        this.marketplaceService.storeUserKeywords(marketplace.id, this.marketplaceKeywords[marketplace.id]).subscribe(
            (success) => {
                // go to next step, or finish modal
                this.finishStep()
            },
            (error) => {
                console.error(error);
            })
    }

    /**
      * close wizard modal
      *
      * @return void
      */
    close() {
        this.plt.disableBackgroundRefresh = false;
        // close only top modal with wizard
        this.modalController.getTop().then((modal) => {
            modal.dismiss({ action: 'close' });
        });
        // this.modalController.dismiss({ action: 'close' });
    }

    /**
     * on destroy
     */
    ngOnDestroy() {
        this.plt.disableBackgroundRefresh = false;
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
