import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, NgZone } from '@angular/core';
// ionic
import { ModalController } from '@ionic/angular';

// other libraries
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { DropzoneComponent, DropzoneConfigInterface } from "ngx-dropzone-wrapper";

// components
import { TranslationAdminAddComponent } from "../translation-admin-add/translation-admin-add.component";
import { TranslationAdminHistoryComponent } from "../translation-admin-history/translation-admin-history.component";
import { TranslationAdminVersionsComponent } from "../translation-admin-versions/translation-admin-versions.component";

// services
import { OverlayService } from "../../services/overlay.service";
import { PlatformService } from '../../services/platform.service';
import { TrnsService } from '../../services/trns.service';
import { DropzoneService } from "../../services/dropzone.service";

// models
import { Translation } from '../../models/translation';

import { environment } from "../../../environments/environment";
import { dropZoneSettings } from "../../settings/dropzone.settings";

@Component({
    selector: 'translation-admin',
    templateUrl: './translation-admin.component.html',
    styleUrls: ['./translation-admin.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslationAdminComponent implements OnInit {


    search: string;

    constantSearch: string;

    translations: Translation[] = [];

    loading: boolean = false;

    uploading: boolean = false;

    filterLoading: boolean = false;

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    /**
     * selected locale
     *
     * @type string
     */
    public locale: string;

    /**
     * selected source
     *
     * @type string
     */
    public source: { source: string, name: string };

    /**
     * dropzone config
     *
     * @type {DropzoneConfigInterface}
     */
    public dropZoneConfig: DropzoneConfigInterface = dropZoneSettings;

    public bigView: boolean = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        public overlayService: OverlayService,
        public modalController: ModalController,
        public trnsService: TrnsService,
        public translate: TranslateService,
        public plt: PlatformService,
        public dropzoneService: DropzoneService
    ) {
        // deactivate the change detection for this component and its children
        this.cdr.detach();

        // user language
        this.userLang = this.translate.currentLang;
        this.locale = this.userLang;

        setInterval(() => {
            this.cdr.detectChanges();
        }, 2000);

        // dropzone config
        this.dropZoneConfig = { ...dropzoneService.dropzoneConfig };
        this.dropZoneConfig.maxFilesize = 4;
        this.dropZoneConfig.acceptedFiles = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        this.dropZoneConfig.autoReset = 1000;
        this.dropZoneConfig.dictDefaultMessage = '';

        this.dropZoneConfig.url = environment.api + '/translations/import?ngsw-bypass=true&token=' + localStorage.getItem('admin-token');
    }

    ngOnInit() {
        this.cdr.detectChanges();
        this.loadData();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.userLang = this.translate.currentLang;
            this.locale = this.userLang;
            this.cdr.detectChanges();
            this.loadData();
        });
        this.trnsService.onChange().subscribe(
            (action) => {
                switch (action.type) {
                    case 'search':
                        // setTimeout(() => {
                        this.zone.run(() => {
                            this.source = this.trnsService.sources[0];
                            this.search = action.query;
                            this.applyFilter(true);
                        });
                        // }, 250);
                        break;

                    case 'hover':
                        // TODO: put some highlight efect...
                        break;
                }
            });
    }

    public loadData(fileRefresh: boolean = false) {
        if (!this.loading) {
            this.loading = true;
            this.cdr.detectChanges();

            this.translations = [];
            // TODO[jg] - maybe remove
            if (!this.bigView && false) {
                this.trnsService.all(this.userLang)
                    .subscribe((translations) => {
                        this.translations = translations;
                        this.loading = false;
                    });
            } else {
                // load also other languages
                this.trnsService.all(this.userLang, true, fileRefresh)
                    .subscribe(
                        (translations) => {
                            let index;
                            translations.forEach((translation, i) => {
                                if (translation.locale == this.userLang) {
                                    this.translations.push(new Translation(translation));
                                    // save index of this main translation
                                    index = this.translations.length - 1;
                                    // prepare records for new
                                    this.plt.availableLangs.forEach((lang) => {
                                        if (lang != this.userLang) {
                                            this.translations[index].other[lang] = new Translation({
                                                id: null,
                                                key: translation.key,
                                                source: translation.source,
                                                user_id: translation.user_id,
                                                text: '',
                                                temp: '',
                                                locale: lang,
                                                edit: false,
                                                match: true,
                                                loading: false,
                                                other: {}
                                            });
                                        }
                                    });
                                } else {
                                    // insert other lang, backend should be always return translations sorted by key, main lang, locale...
                                    if (this.translations[index]
                                        && this.translations[index].key == translation.key
                                        && this.translations[index].source == translation.source
                                        && translation.locale != this.userLang
                                    ) {
                                        this.translations[index].other[translation.locale] = new Translation(translation);
                                    }
                                }
                            });
                            this.loading = false;
                            this.cdr.detectChanges();

                        }, (error) => {
                            this.loading = false;
                            // remove invalid token
                            if (error.status == 401) {
                                localStorage.removeItem('admin-token');
                                this.trnsService.adminToken = null;
                                this.overlayService.showError('Token is invalid, please use again link for translation admin.');
                            } else {
                                this.overlayService.showError('error');
                            }
                            this.cdr.detectChanges();
                        });
            }
        }
    }

    trackByFn(index, item) {
        return item.id;
    }

    public changeLang() {
        this.translate.use(this.locale);
    }

    public autoTranslate(translation: Translation) {
        translation.edit = true
        translation.loading = true;
        this.cdr.detectChanges();
        // backup old text
        translation.temp = translation.text;
        this.trnsService.translate(translation)
            .subscribe(
                (result) => {
                    if (result.text) {
                        translation.text = result.text;
                        translation.loading = false;
                        this.cdr.detectChanges();
                    } else {
                        this.overlayService.showError('error');
                        translation.loading = false;
                        translation.text = translation.temp;
                        this.cdr.detectChanges();
                    }
                },
                (error) => {
                    this.overlayService.showError('error');
                    translation.loading = false;
                    translation.text = translation.temp;
                    this.cdr.detectChanges();
                });
    }

    public async showHistory($event, translation: Translation) {
        $event.preventDefault(); // Cancel the native event
        $event.stopImmediatePropagation();// Don't bubble/capture the event any further
        const modal = await this.modalController.create({
            component: TranslationAdminHistoryComponent,
            cssClass: 'community-filter-modal',
            componentProps: {
                userLang: this.userLang,
                translation: translation
            }
        });

        modal.onWillDismiss().then((data) => {
            if (data.data) {
            }
        });

        return await modal.present();
    }

    public changeSource() {
        this.applyFilter();
    }

    public toggleView() {
        this.bigView = !this.bigView;
        // this.loadData();

        let styles = ':root ion-app {';
        if (this.bigView) {
            styles += '--translation-panel-width: calc(100vw) !important;';
            // TODO[jg] - finish mobile preview, not not possible because of media queries...
            // styles += '--app-width: 375px !important;';
            // styles += '--ion-grid-breakpoints-sm: 3000px !important;';
            // styles += '--ion-grid-breakpoints-md: 3000px !important;';
            // styles += '--ion-grid-breakpoints-lg: 3000px !important;';
            // styles += '--ion-grid-breakpoints-xl: 3000px !important;';
            // styles += '--ion-grid-breakpoints-sm: 3000px !important;';
            // this.plt.setupScreenSize(false, 375);
        } else {
            // this.plt.setupScreenSize(false);
        }
        styles += '}';
        document.getElementById('translation-style').innerHTML = styles;
        this.cdr.detectChanges();
    }

    public toggleClickable() {
        this.trnsService.clickable = !this.trnsService.clickable;
        this.cdr.detectChanges();
    }

    public applyFilter(strict: boolean = false) {
        let regex = new RegExp(this.search, 'ig');
        let regexConstant = new RegExp(this.constantSearch, 'ig');
        this.filterLoading = true;
        this.translations.forEach(
            (translation: Translation) => {
                if (!this.source || this.source.source == translation.source) {
                    if ((this.constantSearch && translation.key.match(regexConstant))
                        || (this.search && (translation.key.match(regex) || translation.text.match(regex))
                            || (!this.constantSearch && !this.search))
                    ) {
                        if (strict) {
                            if (translation.key == this.search) {
                                translation.match = true;
                                translation.edit = true;
                            } else {
                                translation.match = false;
                            }
                        } else {
                            translation.match = true;
                        }
                    } else {
                        translation.match = false;
                    }
                } else {
                    translation.match = false;
                }
            });
        this.filterLoading = false;
        this.cdr.detectChanges();
    }

    public edit(translation: Translation) {
        translation.edit = true;
        translation.temp = translation.text;
        this.cdr.detectChanges();
    }

    public cancelEdit($event, translation: Translation) {
        $event.preventDefault(); // Cancel the native event
        $event.stopImmediatePropagation();// Don't bubble/capture the event any further
        translation.edit = false;
        translation.text = translation.temp;

        this.cdr.detectChanges();
    }

    public async add() {
        const modal = await this.modalController.create({
            component: TranslationAdminAddComponent,
            cssClass: 'community-filter-modal',
            componentProps: {
                userLang: this.userLang
            }
        });

        modal.onWillDismiss().then((data) => {
            if (data.data && data.data.translation) {
                this.plt.availableLangs.forEach((lang) => {
                    if (lang != this.userLang) {
                        data.data.translation.other[lang] = new Translation({
                            id: null,
                            key: data.data.translation.key,
                            source: data.data.translation.source,
                            user_id: data.data.translation.user_id,
                            text: '',
                            temp: '',
                            locale: lang,
                            edit: false,
                            match: true,
                            loading: false,
                            other: {}
                        });
                    }
                });
                this.translations.push(data.data.translation);
            }
        });

        return await modal.present();
    }

    public update(translation: Translation) {

        translation.edit = false;
        this.cdr.detectChanges();
        // store data in database
        if (translation.text) {
            if (translation.id) {
                this.trnsService.update(translation)
                    .subscribe(
                        (result) => {
                            this.translate.set(translation.key, translation.text, translation.locale);
                            translation.temp = translation.text;
                            if (result.change) {
                                this.overlayService.showSuccess('saved');
                            }
                        }, (error) => {
                            translation.text = translation.temp;
                            this.overlayService.showError('error');
                        });
            } else {
                this.trnsService.create(translation)
                    .subscribe(
                        (result) => {
                            translation.id = result.id;
                            this.translate.set(translation.key, translation.text, translation.locale);
                            this.overlayService.showSuccess('saved');
                        }, (error) => {
                            translation.text = translation.temp;
                            this.overlayService.showError('error');
                        });
            }
        }
    }

    public async showVersions() {
        const modal = await this.modalController.create({
            component: TranslationAdminVersionsComponent,
            cssClass: 'community-filter-modal',
            componentProps: {
                userLang: this.userLang
            }
        });

        modal.onWillDismiss().then((data) => {
            if (data.data && data.data.action == 'refresh') {
                document.location.reload();
            }
        });

        return await modal.present();
    }

    public saveVersion() {
        this.overlayService.showLoading();
        this.trnsService.createVersion()
            .subscribe(
                (result) => {
                    // show info
                    this.overlayService.hideLoading();
                    this.overlayService.showSuccess('saved');
                }, (error) => {
                    this.overlayService.showError('error');
                });
    }

    download() {
        window.open(this.trnsService.downloadLink(this.source ? this.source.source : null));
    }

    /**
     * on upload finished
     *
     * @param args
     */
    public onUploadSuccess(args: any, detail?) {
        this.uploading = false;
        this.overlayService.showSuccess('saved');
        this.loadData(true);
    }

    /**
     * on error during csv upload
     *
     * @param args
     */
    public onUploadError(args: any) {

        this.uploading = false;
        const message = args[1];
        if (message.indexOf('too large') !== -1 || message.indexOf('est trop grande') !== -1 || message.indexOf('ist zu gro') !== -1) {

            this.overlayService.showError(this.translate.instant('VALIDATION_IMAGE_TOO_LARGE', {
                maxSize: this.dropZoneConfig.maxFilesize
            }));

        } else if (message.toLowerCase().indexOf('upload files of this type') !== -1) {

            this.overlayService.showError(this.translate.instant('VALIDATION_WRONG_TYPE', {
                fileTypes: this.dropZoneConfig.acceptedFiles.split(',').join(', ')
            }));

        } else {
            this.overlayService.showError(this.translate.instant('VALIDATION_IMAGE_OTHER'));
        }
    }

    /**
     * on file upload
     *
     * @param args
     */
    public onSending(args: any) {
        this.uploading = true;

        const xhrRequest = args[1];

        // timeout event
        xhrRequest.ontimeout = ((event) => {
            this.overlayService.showError(this.translate.instant('REQUEST_TIMEOUT'));
        });

    }

    public close() {
        localStorage.removeItem('admin-token');
        document.location.reload();
    }

}
