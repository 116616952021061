import { ConversationMessage } from "./conversation-message";
import { ChatGroupMessage } from "./chat-group-message";
import * as moment from 'moment/moment';

export class UploadedFile {
    id: number;
    participant_id: number;
    message_id: number;
    chat_group_message_id: number;
    file: any;
    file_url: string;
    preview: string;
    name: string;
    size: number;
    sizeString: string;

    created_at: string;
    date_created_at: Date;
    updated_at: string;
    date_updated_at: Date;

    // if file si with full path
    custom: string = '';

    /**
     * constructor
     *
     * @param {UploadedFile} init
     */
    public constructor(init?: UploadedFile)
    {
        if (!!init)
        {
            Object.assign(this, init);

            this.date_created_at = moment.utc(this.created_at).toDate();
            this.date_updated_at = moment.utc(this.updated_at).toDate();

        }
    }
}
