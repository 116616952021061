import { Component, EventEmitter, OnInit, Input, Output, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';

// ionic
import { AlertController, ModalController, IonItemSliding } from '@ionic/angular';

// rxjs
import { Subject, Subscription } from "rxjs";
import { take, takeUntil, debounceTime } from "rxjs/operators";

// other libraries
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

// providers
import { EventService } from "../../services/event.service";
import { OverlayService } from "../../services/overlay.service";
import { PlatformService } from "../../services/platform.service";
import { ParticipantService } from "../../services/participant.service";
import { UserService } from "../../services/user.service";

// models
import { Event } from "../../models/event";
import { User } from "../../models/user";
import { Participant } from "../../models/participant";
import { Group } from "../../models/group";

import { log } from '../../helpers/log';

@Component({
    selector: 'attendee-list-item',
    templateUrl: './attendee-list-item.component.html',
    styleUrls: ['./attendee-list-item.component.scss'],
})
export class AttendeeListItemComponent implements OnInit {

    /**
     * slide items
     *
     * @type QueryList
     */
    @ViewChildren('slidingItems') public slidingItems: QueryList<IonItemSliding>;

    /**
     * attendee object
     *
     * @type Participant
     */
    @Input() attendee: Participant;

    /**
     * parent object
     *
     * @type {}
     */
    @Input() parentObject: any;

    /**
     * @type boolean
     */
    @Input() isPreview: boolean = false;

    /**
     * @type boolean
     */
    @Input() tutorial: boolean = false;

    /**
     * display type
     *
     * @type string
     */
    @Input() type: string;

    /**
     * viev type (grid, lines)
     *
     * @type string
     */
    @Input() viewType: string = 'grid';

    /**
     * should be matching visible
     *
     * @type string
     */
    @Input() public hideMatching: boolean = false;


    @Output() onAttendeeDetail: EventEmitter<Participant> = new EventEmitter();
    @Output() onRemoveMyContact: EventEmitter<Participant> = new EventEmitter();

    /**
     * user
     *
     * @type {User}
     */
    public user: User;

    /**
     * event
     *
     * @type {Event}
     */
    public event: Event;


    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * subscription for event changes subject
     *
     * @type {Subscription}
     */
    private subscriptionEvent: Subscription;

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    /**
     * initial loading state
     *
     * @type {boolean}
     */
    public loading: boolean = true;

    public group: Group;

    constructor(
        public router: Router,
        public modalController: ModalController,
        public translate: TranslateService,
        public plt: PlatformService,
        public overlayService: OverlayService,
        public eventService: EventService,
        public userService: UserService,
        public participantService: ParticipantService
    ) {
        this.userLang = this.translate.currentLang;
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.userLang = event.lang;
        });

        // subscribe to current user
        this.userService.getCurrentUser().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(0)
        ).subscribe((user) => {
            if (user.id) {
                this.user = user;

                // cancel previous subscription
                if (this.subscriptionEvent) {
                    this.subscriptionEvent.unsubscribe();
                }

                this.subscriptionEvent = this.eventService.getCurrentEvent().pipe(
                    takeUntil(this.ngUnsubscribe),
                    debounceTime(0)
                ).subscribe((event) => {

                    // get attendees group from event
                    if (event.use_groups && event.groups.length) {
                        this.group = event.groups.find(item => item.id == this.attendee.group_id);
                    }

                    // if event is changed, we don't wont to allow load data
                    if (!this.event || event.id == this.event.id) {
                        this.event = event;
                    }

                    if (this.tutorial) {
                        this.tutorialSlide();
                    }

                });
            }
        });
    }

    /**
     * Toggle bookmark
     *
     * @param participant
     */
    public toggleBookmark($event, participant: Participant) {
        $event.stopPropagation();

        if (this.user.selected_participant?.id == participant.id) {
            return false;
        }

        if (!participant.bookmarkLoading && !this.isPreview) {
            participant.bookmarkLoading = true;
            this.participantService.toggleBookmark(this.user.selected_participant.id, participant.id).subscribe(
                (success) => {
                    participant.bookmarkLoading = false;
                    participant.bookmarked = !participant.bookmarked;
                    this.overlayService.showSuccess(success.message);
                },
                (error) => {
                    participant.bookmarkLoading = false;
                    this.overlayService.showError(error.error.message);
                });
        }
    }

    /**
     * Toggle like
     *
     * @param participant
     */
    public toggleLike($event, participant: Participant) {
        $event.stopPropagation();

        if (this.user.selected_participant?.id == participant.id) {
            return false;
        }

        if (!participant.likeLoading && !this.isPreview) {
            participant.likeLoading = true;
            this.participantService.toggleLike(this.user.selected_participant.id, participant.id).subscribe(
                (success) => {
                    participant.likeLoading = false;
                    participant.liked = !participant.liked;
                    this.overlayService.showSuccess(success.message);
                },
                (error) => {
                    participant.likeLoading = false;
                    this.overlayService.showError(error.error.message);
                });
        }
    }

    /**
     * rediret to chat with selected attendee
     *
     */
    public goToMessages($event, attendee) {
        $event.stopPropagation();

        if (!this.isPreview) {
            // check if participant can start conversation
            this.participantService.canStartConversation(attendee.id).subscribe(
                (result) => {
                    // participant can join conversation
                    if (result) {
                        this.dismissModals();
                        this.router.navigate([this.plt.defaultLink + '/messages/direct', attendee.id]);
                    }
                    // participant can not join conversation
                    else {
                        this.overlayService.showError(this.translate.instant('CONVERSATION_LIMIT_REACHED'));
                    }
                }
            );
        }
    }

    /**
     * open video call
     *
     */
    public openCall($event, attendee) {
        $event.stopPropagation();

        if (!this.isPreview) {
            this.dismissModals();
            this.router.navigate([this.plt.defaultLink + '/messages/direct/' + attendee.id], {
                replaceUrl: true,
                queryParams: {
                    call: true
                }
            });
        }
    }

    public showAttendeeDetail(participant: Participant) {
        if (participant.event_id == this.user?.selected_participant?.event_id && participant.registered_at) {
            this.onAttendeeDetail.emit(participant);
        }
    }

    public marketplacePostRemoveMyContact(participant: Participant) {
        this.onRemoveMyContact.emit(participant);
    }

    /**
     * open / close first sliding item on first view as tutorial
     *
     * @return void
     */
    private tutorialSlide(): void {
        // Check if the user has already seen the tutorial swipe
        if (this.plt.sizeSm && !localStorage.getItem('hasSeenTutorialSwipe')) {

            setTimeout(() => {
                this.slidingItems.first.open('start');
            }, 1000);

            setTimeout(() => {
                this.slidingItems.first.closeOpened();
            }, 2000);

            setTimeout(() => {
                this.slidingItems.first.open('end');
            }, 3000);

            setTimeout(() => {
                this.slidingItems.first.closeOpened();
            }, 4000);


            localStorage.setItem('hasSeenTutorialSwipe', 'true');

        }
    }

    // function to close all modals
    public dismissModals() {
        this.modalController.getTop().then((modal) => {
            if (modal) {
                this.modalController.dismiss().then(() => {
                    this.dismissModals();
                });
            }
        });
    }
}
