import { Participant } from "./participant";
import { EventPlace } from "./event-place";
import { ChatGroup } from "./chat-group";
import { RoundDatePipe } from "../pipes/datex/round-date";
import * as moment from "moment";

export class Appointment {
    id: number;
    starts_at: string = new Date().toISOString();
    ends_at: string;
    date_starts_at: Date = new Date;
    date_ends_at: Date;
    description: string;
    location: string;
    place: EventPlace;
    status: number;
    send_email_confirmation: any;
    send_acceptation_copy: any;
    participants: Participant[];
    invitation_message: any;
    reason: string;
    online: boolean = false;
    chat_group: ChatGroup;

    // status used for logged participant
    participantStatus: number;

    // imploded names of participants
    participantNamesString: string;

    past = false;
    min_date: string;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: Appointment) {
        if (!!init) {
            Object.assign(this, init);

            this.date_starts_at = moment.utc(this.starts_at).toDate();
            if (this.ends_at) {
                this.date_ends_at = moment.utc(this.ends_at).toDate();
            }

            const roundDatePipe = new RoundDatePipe();
            this.date_starts_at = roundDatePipe.transform(this.starts_at, {
                interval: 5,
                format: 'date'
            });

            this.send_acceptation_copy = true;

            // set default value for appointment acceptation email copy
            if (this.status == 0) {
                this.send_acceptation_copy = true;
            }

            if (!!this.date_ends_at && this.date_ends_at.getTime() < Date.now()) {
                this.past = true;
            }

            if (!this.date_ends_at && this.date_starts_at.getTime() < Date.now()) {
                this.past = true;
            }

            // participants
            if (init.participants) {
                this.participants = init.participants.map(participant => new Participant(participant));

                this.participantNamesString = this.participants.map(function (participant) {
                    return participant.firstname + ' ' + participant.lastname;
                }).join(', ');
            }
        }
        else {

            if (this.date_starts_at) {
                // Round date up to nearest 5 minutes
                let coeff = 1000 * 60 * 5;
                this.date_starts_at = new Date(this.date_starts_at.getTime() + coeff - (this.date_starts_at.getTime() % coeff));

                this.starts_at = moment(this.date_starts_at).utc().format('YYYY-MM-DD HH:mm:ss');
            }

            const roundDatePipe = new RoundDatePipe();
            this.date_starts_at = roundDatePipe.transform(this.starts_at, {
                interval: 5,
                format: 'date'
            });

            // store value in UTC for next processing
            this.starts_at = moment(this.date_starts_at).utc().format('YYYY-MM-DD HH:mm:ss');
        }
    }

    /**
     * this return actual status of praticipants appointments
     * this will be counted different for appointment organizer and appointment member
     * @param participantId
     * @return int
     */
    public setParticipantStatus(participantId) {
        let ownParticipant = this.participants.find((participant) => {
            return participant.id == participantId;
        });

        this.participantStatus = this.status;

        // check if we can use pivot status and set it to appointment (pending, confirmed, refused, canceled)
        if (ownParticipant?.pivot?.status != undefined && [0, 1, 2].includes(this.status)) {
            this.participantStatus = ownParticipant.pivot.status;
        }
    }
}
