<ion-header *ngIf="modal"
            class="ion-no-shadow ion-no-border">
    <ion-toolbar>
        <ion-title class="full"
                   [innerHTML]="'MARKETPLACE_POST_COIN_PRICE_BUY_TITLE' | translate"></ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="backClicked()">
                <ion-icon slot="icon-only"
                          color="dark"
                          name="close">
                </ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<form method="post"
      #f="ngForm"
      role="form"
      class="form-horizontal">
    <ion-grid>

        <ion-row class="row-message"
                 [ngClass]="{'has-error': validate && validationErrors['message']}">

            <ion-col size="12"
                     size-md="3"
                     class="label"
                     class="label">
                <ion-label>
                    {{ 'AMOUNT' | translate }}*
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['amount'] }">
                <ion-input type="number"
                           name="amount"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="text"
                           spellcheck="false"
                           autocapitalize="off"
                           #amountInput="ngModel"
                           [(ngModel)]="amount"
                           required>
                </ion-input>
                <span *ngIf="validate && validationErrors['amount']"
                      class="error-message">
                    {{ validationErrors['amount'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label"
                     class="label">
                <ion-label>
                    {{ 'FIRSTNAME' | translate }}*
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['firstname'] }">
                <ion-input type="text"
                           name="firstname"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="text"
                           spellcheck="false"
                           autocapitalize="off"
                           #street="ngModel"
                           [(ngModel)]="address.firstname"
                           required>
                </ion-input>
                <span *ngIf="validate && validationErrors['firstname']"
                      class="error-message">
                    {{ validationErrors['firstname'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label"
                     class="label">
                <ion-label>
                    {{ 'LASTNAME' | translate }}*
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['lastname'] }">
                <ion-input type="text"
                           name="lastname"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="text"
                           spellcheck="false"
                           autocapitalize="off"
                           #street="ngModel"
                           [(ngModel)]="address.lastname"
                           required>
                </ion-input>
                <span *ngIf="validate && validationErrors['lastname']"
                      class="error-message">
                    {{ validationErrors['lastname'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label"
                     class="label">
                <ion-label>
                    {{ 'COMPANY' | translate }}
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['company'] }">
                <ion-input type="text"
                           name="company"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="text"
                           spellcheck="false"
                           autocapitalize="off"
                           #street="ngModel"
                           [(ngModel)]="address.company">
                </ion-input>
                <span *ngIf="validate && validationErrors['company']"
                      class="error-message">
                    {{ validationErrors['company'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label"
                     class="label">
                <ion-label>
                    {{ 'DEPARTMENT' | translate }}
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['department'] }">
                <ion-input type="text"
                           name="department"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="text"
                           spellcheck="false"
                           autocapitalize="off"
                           #street="ngModel"
                           [(ngModel)]="address.department">
                </ion-input>
                <span *ngIf="validate && validationErrors['department']"
                      class="error-message">
                    {{ validationErrors['department'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label"
                     class="label">
                <ion-label>
                    {{ 'STREET' | translate }}*
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['street'] }">
                <ion-input type="text"
                           name="street"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="text"
                           spellcheck="false"
                           autocapitalize="off"
                           #street="ngModel"
                           [(ngModel)]="address.street"
                           required>
                </ion-input>
                <span *ngIf="validate && validationErrors['street']"
                      class="error-message">
                    {{ validationErrors['street'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label"
                     class="label">
                <ion-label>
                    {{ 'STREETNR' | translate }}*
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['streetnr'] }">
                <ion-input type="text"
                           name="streetnr"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="text"
                           spellcheck="false"
                           autocapitalize="off"
                           #streetnr="ngModel"
                           [(ngModel)]="address.streetnr"
                           required>
                </ion-input>
                <span *ngIf="validate && validationErrors['streetnr']"
                      class="error-message">
                    {{ validationErrors['streetnr'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label">
                <ion-label>
                    {{ 'POSTCODE' | translate }}*
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['zip'] }">
                <ion-input type="text"
                           name="zip"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="email"
                           spellcheck="false"
                           autocapitalize="off"
                           #zip="ngModel"
                           [(ngModel)]="address.zip"
                           required>
                </ion-input>
                <span *ngIf="validate && validationErrors['zip']"
                      class="error-message">
                    {{ validationErrors['zip'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label">
                <ion-label>
                    {{ 'CITY' | translate }}*
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['city'] }">
                <ion-input type="text"
                           name="city"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="email"
                           spellcheck="false"
                           autocapitalize="off"
                           #city="ngModel"
                           [(ngModel)]="address.city"
                           required>
                </ion-input>
                <span *ngIf="validate && validationErrors['city']"
                      class="error-message">
                    {{ validationErrors['city'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label">
                <ion-label>
                    {{ 'COUNTRY' | translate }}*
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['country'] }">
                <ion-input type="text"
                           name="country"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="email"
                           spellcheck="false"
                           autocapitalize="off"
                           #country="ngModel"
                           [(ngModel)]="address.country"
                           required>
                </ion-input>
                <span *ngIf="validate && validationErrors['country']"
                      class="error-message">
                    {{ validationErrors['country'] }}
                </span>
            </ion-col>

        </ion-row>

        <ion-row *ngIf="!modal"
                 class="form-button ion-no-padding">

            <ion-col class="ion-no-padding ion-text-right">
                <ion-button class="btn-close"
                            (click)="backClicked(false)">
                    {{ 'BUTTON_CANCEL' | translate }}
                </ion-button>
                <ion-button color="secondary"
                            id="post-marketplace-button"
                            [disabled]="!f.form.valid || submit"
                            (click)="marketplacePostSave(f.form.valid)">
                    {{ 'BUY' | translate }}
                </ion-button>
            </ion-col>

        </ion-row>

    </ion-grid>

</form>

<ion-footer *ngIf="modal">
    <ion-row class="action-buttons two ion-no-padding">
        <ion-col size="6"
                 class="ion-no-padding">
            <ion-button class="btn-close"
                        (click)="backClicked()">
                {{ 'BUTTON_CANCEL' | translate }}
            </ion-button>
        </ion-col>
        <ion-col size="6"
                 class="ion-no-padding">
            <ion-button color="primary"
                        [disabled]="!f.form.valid || submit"
                        (click)="marketplacePostSave(f.form.valid)">
                {{ 'BUY' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>
