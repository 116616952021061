<div class="modal-header ion-hide-sm-up">
    <ion-grid class="ion-no-padding">
        <ion-row class="ion-no-padding">
            <ion-col size="10"
                     class="ion-no-padding">
                {{ 'DASHBOARD_MATCHING_LIST_SUGGEST_KEYWORD' | translate }}
            </ion-col>
            <ion-col size="2"
                     class="ion-no-padding ion-text-right">
                <a (click)="close()">
                    <svg-icon [applyClass]="true"
                              src="/assets/icons/ico-close.svg"
                              class="icon fill-dark">
                    </svg-icon>
                </a>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>

<form method="get"
      #f="ngForm"
      role="form"
      novalidate
      class="ion-padding">
    <ion-row class="ion-no-padding">
        <ion-col size="12"
                 size-sm="6"
                 class="ion-no-padding">
            <ion-input 
                       type="text"
                       name="keyword"
                       inputmode="text"
                       spellcheck="false"
                       autocapitalize="off"
                       [autofocus]="true"
                       #inputElem="ngModel"
                       [(ngModel)]="keyword"
                       (keyup.enter)="saveKeyword(f.form.valid)"
                       required>
            </ion-input>
        </ion-col>
        <ion-col class="ion-no-padding ion-text-right">
            <ion-button color="primary"
                        [disabled]="!keyword"
                        (click)="saveKeyword(f.form.valid)">
                {{ 'BUTTON_ADD' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
</form>