import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

// providers
import { TranslateService } from "@ngx-translate/core";

// import date default format setting
import { dateFormats } from '../../settings/date.settings';

@Pipe({
    name: 'datex'
})

export class DatexPipe implements PipeTransform {

    constructor(
        public translate?: TranslateService,
    ) { }

    transform(value: any, format: string = "", lang: string = "", applyTimezone: boolean = false): string {
        // Try and parse the passed value.
        let momentDate = moment(value);

        // If moment didn't understand the value, return it unformatted.
        if (!momentDate.isValid()) {
            return value;
        }

        // apply timezone
        if (applyTimezone) {
            let offset = new Date(momentDate.format("YYYY-MM-DD")).getTimezoneOffset();
            if (!!offset) {
                momentDate.subtract(offset, "minutes");
            }
        }

        if (format) {
            if (dateFormats[format]) {
                if (dateFormats[format][this.translate.currentLang]) {
                    format = dateFormats[format][this.translate.currentLang];
                } else {
                    // use default date format
                    format = dateFormats[format]['default'];
                }
            }
        } else {
            format = dateFormats['dateFormatDefault'][this.translate.currentLang];
        }

        // Otherwise, return the date formatted as requested.
        if (this.translate) {
            momentDate.locale(this.translate.currentLang);
        }
        return momentDate.format(format);
    }
}
