<div class="header"
     [ngClass]="{'opened': opened}"
     (click)="toggle()">
    <svg-icon *ngIf="icon"
              [applyClass]="true"
              class="icon"
              [src]="'/assets/icons/ico-' + icon + '.svg'">
    </svg-icon>
    <span>
        {{ title }}
        <ng-container *ngIf="count">
            ({{count}})
        </ng-container>
    </span>
    <svg-icon [applyClass]="true"
              class="opener"
              src="/assets/icons/ico-arrow.svg">
    </svg-icon>
</div>
<div class="content"
     [ngClass]="{'opened': opened}">
    <ng-container [ngTemplateOutlet]="templateRef">
    </ng-container>
</div>