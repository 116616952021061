import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";

/*
 * Rounds a date
 */
@Pipe({ name: 'roundDate' })
export class RoundDatePipe implements PipeTransform {
    transform(value: string, args: any): any {

        args.format = args.format || 'string';
        args.interval = args.interval || 1;
        args.unit = args.unit || 'minutes';

        const momentDate = moment.utc(value);
        const amount = args.interval - momentDate.minute() % args.interval;
        const result = moment(momentDate).add((amount !== args.interval ? amount : 0), args.unit);

        if (args.format === 'date') {
            return result.toDate();
        }

        return result.format('YYYY-MM-DD HH:mm:ss');
    }
}
