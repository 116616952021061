import * as moment from "moment";
import { DatePipe } from "@angular/common";

import { Marketplace } from "./marketplace";

export class Feed {
    id: number;
    user_id: number;
    event_id: number;
    participant_id: number;
    user_type: string;
    key: string;
    data: any;
    action: string;
    color: string;
    icon: string;
    visited_at: string;
    date_visited_at: Date = new Date();

    eventName: string;
    marketplace: Marketplace = new Marketplace;

    marketplaceTypes: string[] = [
        'marketplace-post-confirm',
        'marketplace-post-reject',
        'marketplace-post-created',
        'marketplace-post-waiting',
        'marketplace-post-deleted',
        'marketplace-post-category-deleted',
        'marketplace-post-category-deleted',
        // 'marketplace-post-contact-removed',
        'marketplace-post-contact-added'
    ];

    isMarketplaceType: boolean = false;

    // causing bug with circular dependency
    // source_particpant: Participant;
    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: Feed, locale?: string) {
        if (!!init) {
            Object.assign(this, init);

            this.date_visited_at = moment.utc(this.visited_at).toDate();

            if (this.data.eventNameTranslations) {
                if (this.data.eventNameTranslations[locale]) {
                    this.eventName = this.data.eventNameTranslations[locale];
                } else {
                    this.eventName = this.data.eventNameTranslations[this.data.defaultLocale];
                }
            }

            if (this.marketplace) {
                this.marketplace = new Marketplace(this.marketplace);

                this.isMarketplaceType = (this.marketplaceTypes.indexOf(this.data.type) != -1);
            }

            // causing bug with circular dependency
            // if (init.source_particpant) {
            //     this.source_particpant = new Participant(init.source_particpant);
            // }
        }
        else {
            const datePipe = new DatePipe('de-DE');

            this.visited_at = datePipe.transform(this.date_visited_at, 'YYYY-MM-DD HH:mm:ss');
        }
    }
}
