import { Translatable } from "./translatable.interface";

import { DatexPipe } from "../pipes/datex/datex";
import * as moment from "moment/moment";

// models
import { Container } from "./container";
import { CustomLabel } from "./custom-label";
import { Keyword } from "./keyword";
import { Participant } from "./participant";
import { Group } from "./group";
import { availableRights } from "./group";
import { User } from "./user";
import { EventSetting } from "./event-setting";
import { Feed } from "./feed";
import { Appointment } from "./appointment";
import { EventTranslation } from "./event-translation";
import { EventRecommendation } from "./event-recommendation";
import { AttendeesTab } from "./attendees-tab";

import { Marketplace } from "./marketplace";

export class Event implements Translatable {
    id: number;
    address: string;
    zip: string;
    city: string;
    lat: any;
    lng: any;
    starts_at: string;
    date_starts_at: Date = new Date();
    ends_at: string;
    date_ends_at: Date = new Date();
    updated_at: string;
    created_at: string;
    status = false;
    matching_status = false;
    matching_started_at: string;
    keyword_suggestion_status = false;
    hide_unregistered_status = false;
    ask_the_speaker = false;
    date_matching_started_at: Date = new Date();
    available_locales = ['de', 'en'];
    default_locale = 'de';
    header_image: string;
    header_image_url: string;
    logo: string;
    logo_url: string;
    translations: EventTranslation[] = [];
    containers: Container[] = [];
    current_user_participant: Participant[] = [];
    stats = {};
    no_address = false;
    menu_visible = false;
    participants;

    use_groups: boolean = false;
    use_groups_icons: boolean = false;
    groups: Group[] = [];
    // group rights for participant
    selectedGroup: Group;

    group_rights = [];
    groupRights: {} = {};
    groupSettings = [];

    use_timeslots: boolean = false;

    custom_labels: CustomLabel[] = [];

    dashboardFeeds: Feed[] = [];

    appointments: Appointment[] = [];

    setting: EventSetting;
    is_community: boolean = false;

    public_email: string;
    public_phone: string;

    homepage_content: string = 'matching';

    recommendation: EventRecommendation;

    disable_appointments: boolean = false;
    allow_newsfeed_post: boolean = false;
    allow_newsfeed_comment: boolean = true;
    allow_chat_group_creation: boolean = false;
    allow_call_chat_group_mute: boolean = false;
    allow_call: boolean = false;

    enable_static_live_voting: boolean = false;
    enable_newsfeed_video: boolean = false;
    enable_invisible_profile: boolean = false;
    enable_credential_wallet: boolean = false;

    counties: any[] = [];
    zip_code_search: boolean = false;

    show_wizard: boolean = false;
    allow_skip_wizard: boolean = false;

    allow_coins: boolean = false;
    coin_name: string = 'coins';

    // marketplace setting
    marketplaces: Marketplace[] = [];

    newsfeed_post_visibility: string;
    newsfeed_keywords_limit: number;

    attendees_tabs: AttendeesTab[] = [];
    selectedAttendeesTab: AttendeesTab = null;

    is_homepage_content_matching: boolean = true;
    is_homepage_content_newsfeed: boolean = false;
    is_homepage_content_stream: boolean = false;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: Event, user?: User) {
        if (!!init) {
            Object.assign(this, init);

            this.date_starts_at = moment.utc(this.starts_at).toDate();
            this.date_ends_at = moment.utc(this.ends_at).toDate();
            this.date_matching_started_at = this.matching_status ? moment.utc(this.matching_started_at).toDate() : null;
            this.lat = parseFloat(this.lat);
            this.lng = parseFloat(this.lng);

            // set default tab which will be first enabled
            if (this.use_groups && this.attendees_tabs.length) {
                this.attendees_tabs.map(tab => new AttendeesTab(tab));
                if (!this.selectedAttendeesTab && !init.selectedAttendeesTab) {
                    this.selectedAttendeesTab = this.attendees_tabs[0];
                }
            }

            // appointments
            if (init.appointments) {
                this.appointments = init.appointments.map(appointment => new Appointment(appointment));
            }

            // set default group which will be first enabled
            if (this.groups.length) {
                this.groups.map(group => new Group(group));
                // enable all permissions for admin
                this.groups.forEach((group) => {
                    if (!this.groupRights.hasOwnProperty(group.id)) {
                        this.groupRights[group.id] = {};
                    }
                    availableRights.forEach((right) => {
                        this.groupRights[group.id][right] = true;
                    });

                    // copy group setting for group, later we are filtering groups by visibility
                    this.groupSettings.push({
                        id: group.id,
                        show_wizard: group.show_wizard,
                        allow_skip_wizard: group.allow_skip_wizard,
                        allow_newsfeed_post: group.allow_newsfeed_post,
                        allow_chat_group_creation: group.allow_chat_group_creation,
                        allow_call: group.allow_call,
                        allow_private_chat_group_creation: group.allow_private_chat_group_creation,
                        allow_call_chat_group: group.allow_call_chat_group,
                        allow_secured_chat_group_creation: group.allow_secured_chat_group_creation,
                        allow_newsfeed_comment: group.allow_newsfeed_comment
                    })
                });
                // filter disabled functions if groups are enabled and it is participant
                if (user && user.selected_participant && this.use_groups && this.group_rights.length) {
                    this.group_rights.forEach((groupRight) => {
                        // create matrix for current users group_id
                        if (user.selected_participant.group_id == groupRight.source_group_id) {
                            this.groupRights[groupRight.target_group_id][groupRight.right] = false;
                        }
                    });
                }
                this.groups = this.groups.filter(group => this.groupRights[group.id]['visible']);
                //console.info('Rights', this.groupRights);
                // set default group
                if (!this.selectedGroup) {
                    this.selectedGroup = this.groups[0];
                }

                //console.info(this.groupRights);
            }

            // set dashboard feeds
            if (init.dashboardFeeds) {
                this.dashboardFeeds = init.dashboardFeeds.map(feed => new Feed(feed));
            }

            // custom labels
            this.custom_labels = this.custom_labels.map(customLabel => new CustomLabel(customLabel));
            // containers
            this.containers = this.containers.map(container => new Container(container));

            this.recommendation = new EventRecommendation(this.recommendation);

            this.setting = new EventSetting(this.setting);

            // marketplaces
            this.marketplaces = this.marketplaces.map(marketplace => new Marketplace(marketplace));

            // set proper homepage item
            switch (this.homepage_content) {
                case 'newsfeed':
                    this.is_homepage_content_matching = false;
                    this.is_homepage_content_stream = false;
                    this.is_homepage_content_newsfeed = true;
                    break;
                case 'stream':
                    this.is_homepage_content_matching = false;
                    this.is_homepage_content_stream = true;
                    this.is_homepage_content_newsfeed = false;
                    break;
            }
        }
        else {
            const datePipe = new DatexPipe();

            this.starts_at = datePipe.transform(this.date_starts_at, 'yyyy-MM-dd HH:mm:ss');
            this.ends_at = datePipe.transform(this.date_starts_at, 'yyyy-MM-dd HH:mm:ss');
            this.matching_started_at = this.matching_status ? datePipe.transform(this.date_starts_at, 'yyyy-MM-dd HH:mm:ss') : null;
        }
    }

    /**
     * get translated attributes or create new if not exists
     *
     * @param locale
     *
     * @return {EventTranslation}
     */
    public translateOrNew(locale: string): EventTranslation {
        let currentTranslation = this.translations.filter(translation => { return translation.locale === locale; })[0];

        if (!currentTranslation) {
            currentTranslation = new EventTranslation();
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return new EventTranslation(currentTranslation);
    }

    /**
     * translate with fallback
     *
     * @param locale
     *
     * @return {EventTranslation}
     */
    public translate(locale: string): EventTranslation {
        return new EventTranslation(
            this.translations.filter(translation => { return translation.locale === locale; })[0] ||
            this.translations.filter(translation => { return translation.locale === this.default_locale; })[0]
        );
    }

    /**
     * Removes a translation (to get not submitted if deactivated)
     *
     * @param locale
     */
    public removeTranslation(locale: string) {
        this.translations.forEach((translation, index) => {
            if (translation.locale === locale) {
                this.translations.splice(index, 1);
            }
        });

        this.containers.forEach((container) => {
            container.removeTranslation(locale);
        });
    }

    /**
     * order keywords ny name
     *
     * @param {string} lang
     */
    orderKeywordsByName(lang: string) {
        this.containers.forEach((container, index) => {

            container = Object.assign(new Container(), container);

            container.keywords.forEach((keyword, index) => {
                container.keywords[index] = new Keyword(keyword);
            });

            // order keywords
            container.orderKeywordsByName(lang, this.default_locale);

            this.containers[index] = container;
        });
    }

    /**
       * chceck group permission
       * @param group_id
       * @param action
       * @return boolean
       */
    public checkGroupPermition(group_id: number, action: string) {
        // console.log('checkGroupPermition: (group_id: '+group_id+', action: '+action+')');

        // if event doesnt use groups we dont need to chceck permission
        if (!this.use_groups || !this.groupRights[group_id]) {
            return true;
        }

        // TODO: check for existing group_id and action ??
        return this.groupRights[group_id][action];
    }

    /**
       * chceck group permission
       * @param group_id
       * @param action
       * @return boolean
       */
    public can(group_id: number, action: string) {

        if (this.use_groups) {
            let group = this.groupSettings.filter(group => group.id == group_id);
            if (group[0] && group[0][action]) {
                return true;
            } else {
                return false;
            }
        }
        // if event doesnt use groups we dont need to chceck permission
        if (this[action]) {
            return true;
        }

        return false;
    }
}
