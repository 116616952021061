import { NgModule } from '@angular/core';
import { Location } from '@angular/common';

// modules
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { CalendarModule } from 'primeng/calendar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// components
import { EventDetailComponent } from "../components/event-detail/event-detail.component";
import { EventImprintComponent } from "../components/event-imprint/event-imprint.component";
import { InvitationsComponent } from "../components/invitations/invitations.component";
import { NotificationsComponent } from "../components/notifications/notifications.component";
import { HeaderNotificationsComponent } from "../components/header-notifications/header-notifications.component";
import { SuggestKeywordComponent } from "../components/suggest-keyword/suggest-keyword.component";
import { PercentageCircleComponent } from "../components/percentage-circle/percentage-circle.component";
import { AttendeeFilterComponent } from "../components/attendee-filter/attendee-filter.component";
import { CountyFilterComponent } from "../components/county-filter/county-filter.component";
import { CommunityFilterComponent } from "../components/community-filter/community-filter.component";
import { AttendeeDetailComponent } from "../components/attendee-detail/attendee-detail.component";
import { AppointmentRequestComponent } from "../components/appointment-request/appointment-request.component";
import { AppointmentAddParticipantComponent } from "../components/appointment-add-participant/appointment-add-participant.component";
import { AddNoteComponent } from "../components/add-note/add-note.component";
import { PasswordModalComponent } from "../components/password-modal/password-modal.component";
import { LiveVotingComponent } from "../components/live-voting/live-voting.component";
import { NewQuestionComponent } from "../components/new-question/new-question.component";
import { QrCodeReaderComponent } from "../components/qr-code-reader/qr-code-reader.component";
import { KeywordSettingsComponent } from "../components/keyword-settings/keyword-settings.component";
import { NewsfeedListComponent } from "../components/newsfeed-list/newsfeed-list.component";
import { NewsfeedDetailComponent } from "../components/newsfeed-detail/newsfeed-detail.component";
import { NewsfeedReportComponent } from "../components/newsfeed-report/newsfeed-report.component";
import { KeywordSelectComponent } from "../components/keyword-select/keyword-select.component";
import { AddNewsfeedComponent } from "../components/add-newsfeed/add-newsfeed.component";
import { PopoverSelectBoxComponent } from "../components/popover-select-box/popover-select-box.component";
import { WizardComponent } from "../components/wizard/wizard.component";
import { ProfileEditComponent } from "../components/profile-edit/profile-edit.component";
import { EditMessageComponent } from "../components/edit-message/edit-message.component";
import { ChatGroupsListComponent } from "../components/chat-groups-list/chat-groups-list.component";
import { AddChatGroupComponent } from "../components/add-chat-group/add-chat-group.component";
import { CollapsibleComponent } from "../components/collapsible/collapsible.component";
import { ChatGroupAttendeesComponent } from "../components/chat-group-attendees/chat-group-attendees.component";
import { ChatGroupSettingsComponent } from "../components/chat-group-settings/chat-group-settings.component";
import { MessageFileFormComponent } from "../components/message-file-form/message-file-form.component";
import { FileDetailComponent } from "../components/file-detail/file-detail.component";
import { FilePreviewComponent } from "../components/file-preview/file-preview.component";
import { ChagGroupsFormComponent } from "../components/chat-groups-form/chat-groups-form.component";
import { AutoCompleteComponent } from "../components/auto-complete/auto-complete.component";
import { AttendeeListItemComponent } from "../components/attendee-list-item/attendee-list-item.component";
import { MarketplaceListComponent } from "../components/marketplace-list/marketplace-list.component";
import { MarketplaceDetailComponent } from "../components/marketplace-detail/marketplace-detail.component";
import { MarketplaceDetailOrdersComponent } from "../components/marketplace-detail-orders/marketplace-detail-orders.component";
import { MarketplacePurchaseComponent } from "../components/marketplace-purchase/marketplace-purchase.component";
import { MarketplaceAddComponent } from "../components/marketplace-add/marketplace-add.component";
import { MarketplaceAdDetailComponent } from "../components/marketplace-ad-detail/marketplace-ad-detail.component";
import { MarketplaceKeywordsComponent } from "../components/marketplace-keywords/marketplace-keywords.component";
import { MarketplaceInfoComponent } from "../components/marketplace-info/marketplace-info.component";
import { ChatGroupAttendeesActionMenu } from "../components/chat-group-attendees-action-menu/chat-group-attendees-action-menu.component";
import { NewsfeedActionMenu } from "../components/newsfeed-action-menu/newsfeed-action-menu.component";
import { MarketplaceActionMenu } from "../components/marketplace-action-menu/marketplace-action-menu.component";
import { ParticipantActionMenu } from "../components/participant-action-menu/participant-action-menu.component";
import { SelectGroupForJoinComponent } from "../components/select-group-for-join/select-group-for-join.component";
import { ChimeComponent } from "../components/chime/chime.component";
import { AppOutLinkComponent } from "../components/app-out-link/app-out-link.component";
import { TranslationAdminComponent } from "../components/translation-admin/translation-admin.component";
import { TranslationAdminAddComponent } from "../components/translation-admin-add/translation-admin-add.component";
import { TranslationAdminHistoryComponent } from "../components/translation-admin-history/translation-admin-history.component";
import { TranslationAdminVersionsComponent } from "../components/translation-admin-versions/translation-admin-versions.component";
import { AppointmentAttendeesComponent } from "../components/appointment-attendees/appointment-attendees.component";
import { StreamListComponent } from "../components/stream-list/stream-list.component";
import { StreamDetailComponent } from "../components/stream-detail/stream-detail.component";
import { VideoCallAttendeesComponent } from "../components/video-call-attendees/video-call-attendees.component";
import { VideoDetailComponent } from "../components/video-detail/video-detail.component";

// directives
import { DefaultAvatarImageDirective } from "../directives/images/default-avatar";
import { DefaultHeaderImageDirective } from "../directives/images/default-header";
import { DefaultLogoImageDirective } from "../directives/images/default-logo";
import { IeTextareaFixDirective } from '../directives/ie-textarea-fix/ie-textarea-fix.directive';
import { ClickStopPropagation } from '../directives/click-stop-propagation';

// pipes
import { DatexPipe } from '../pipes/datex/datex';
import { RoundDatePipe } from '../pipes/datex/round-date';
import { SnakeCasePipe } from '../pipes/string/snake-case';
import { GroupNamePipe } from '../pipes/group/group';
import { CheckTranslatePipe } from '../pipes/string/check-translate'
import { CustomTranslatePipe } from '../pipes/string/custom-translate'
import { KeysPipe } from '../pipes/keys/keys';
import { KeywordEnabledPipe } from '../pipes/keywords/keyword-enabled';
import { KeywordValidPipe } from '../pipes/keywords/keyword-valid';
import { RoundPipe } from '../pipes/math/round';
import { NewlinePipe } from '../pipes/newline/newline';
import { OpenWindowPipe } from '../pipes/string/open-window';
import { SafePipe } from '../pipes/string/safe';
import { CutePipe } from '../pipes/string/cut';
import { DateAgoPipe } from '../pipes/date-ago/date-ago';
import { NlBrPipe } from '../pipes/string/nl2br';
import { StripHtmlPipe } from '../pipes/string/strip-html';
import { StripMailTagslPipe } from '../pipes/string/strip-mail-tags';
import { LinkifyPipe } from '../pipes/string/linkify';
import { NlAfterPPipe } from '../pipes/string/nl-after-p';
import { SecuredImagesPipe } from '../pipes/string/secured-images';
import { TrnsPipe } from '../pipes/string/trns';
import { EmbedVideoPipe } from '../pipes/string/embed-video';

// dropzone
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { dropZoneSettings } from "../settings/dropzone.settings";

// config
import { environment } from "../../environments/environment";

@NgModule({
    declarations: [
        // directives
        DefaultAvatarImageDirective,
        DefaultHeaderImageDirective,
        DefaultLogoImageDirective,
        IeTextareaFixDirective,
        ClickStopPropagation,
        // pipes
        DatexPipe,
        RoundDatePipe,
        SnakeCasePipe,
        GroupNamePipe,
        CheckTranslatePipe,
        CustomTranslatePipe,
        KeysPipe,
        KeywordEnabledPipe,
        KeywordValidPipe,
        RoundPipe,
        NewlinePipe,
        OpenWindowPipe,
        SafePipe,
        CutePipe,
        DateAgoPipe,
        NlBrPipe,
        StripHtmlPipe,
        StripMailTagslPipe,
        // components
        AddNoteComponent,
        NewQuestionComponent,
        SuggestKeywordComponent,
        PercentageCircleComponent,
        AttendeeFilterComponent,
        CountyFilterComponent,
        CommunityFilterComponent,
        AttendeeDetailComponent,
        EventDetailComponent,
        EventImprintComponent,
        AppointmentRequestComponent,
        AppointmentAddParticipantComponent,
        LiveVotingComponent,
        HeaderNotificationsComponent,
        NotificationsComponent,
        InvitationsComponent,
        QrCodeReaderComponent,
        KeywordSettingsComponent,
        NewsfeedListComponent,
        NewsfeedDetailComponent,
        NewsfeedReportComponent,
        KeywordSelectComponent,
        AddNewsfeedComponent,
        PopoverSelectBoxComponent,
        WizardComponent,
        ProfileEditComponent,
        EditMessageComponent,
        ChatGroupAttendeesComponent,
        ChatGroupSettingsComponent,
        ChatGroupsListComponent,
        AddChatGroupComponent,
        CollapsibleComponent,
        PasswordModalComponent,
        MessageFileFormComponent,
        FileDetailComponent,
        FilePreviewComponent,
        ChagGroupsFormComponent,
        AutoCompleteComponent,
        LinkifyPipe,
        NlAfterPPipe,
        SecuredImagesPipe,
        TrnsPipe,
        EmbedVideoPipe,
        AttendeeListItemComponent,
        MarketplaceListComponent,
        MarketplaceDetailComponent,
        MarketplaceDetailOrdersComponent,
        MarketplacePurchaseComponent,
        MarketplaceAddComponent,
        MarketplaceAdDetailComponent,
        MarketplaceKeywordsComponent,
        MarketplaceInfoComponent,
        ChatGroupAttendeesActionMenu,
        NewsfeedActionMenu,
        MarketplaceActionMenu,
        ParticipantActionMenu,
        SelectGroupForJoinComponent,
        ChimeComponent,
        AppOutLinkComponent,
        TranslationAdminComponent,
        TranslationAdminAddComponent,
        TranslationAdminHistoryComponent,
        TranslationAdminVersionsComponent,
        AppointmentAttendeesComponent,
        StreamListComponent,
        StreamDetailComponent,
        VideoCallAttendeesComponent,
        VideoDetailComponent
    ],
    imports: [
        CommonModule,
        AngularSvgIconModule,
        IonicModule,
        FormsModule,
        TranslateModule,
        CalendarModule,
        InfiniteScrollModule,
        DropzoneModule
    ],
    exports: [
        // directives
        DefaultAvatarImageDirective,
        DefaultHeaderImageDirective,
        DefaultLogoImageDirective,
        IeTextareaFixDirective,
        ClickStopPropagation,
        // pipes
        DatexPipe,
        RoundDatePipe,
        SnakeCasePipe,
        GroupNamePipe,
        CheckTranslatePipe,
        CustomTranslatePipe,
        KeysPipe,
        KeywordEnabledPipe,
        KeywordValidPipe,
        RoundPipe,
        NewlinePipe,
        OpenWindowPipe,
        SafePipe,
        CutePipe,
        NlBrPipe,
        StripHtmlPipe,
        StripMailTagslPipe,
        // components
        AddNoteComponent,
        NewQuestionComponent,
        SuggestKeywordComponent,
        PercentageCircleComponent,
        AttendeeFilterComponent,
        CountyFilterComponent,
        CommunityFilterComponent,
        AttendeeDetailComponent,
        EventDetailComponent,
        EventImprintComponent,
        AppointmentRequestComponent,
        AppointmentAddParticipantComponent,
        LiveVotingComponent,
        HeaderNotificationsComponent,
        NotificationsComponent,
        InvitationsComponent,
        QrCodeReaderComponent,
        KeywordSettingsComponent,
        NewsfeedListComponent,
        NewsfeedDetailComponent,
        NewsfeedReportComponent,
        KeywordSelectComponent,
        AddNewsfeedComponent,
        PopoverSelectBoxComponent,
        WizardComponent,
        ProfileEditComponent,
        EditMessageComponent,
        ChatGroupsListComponent,
        AddChatGroupComponent,
        CollapsibleComponent,
        PasswordModalComponent,
        ChatGroupAttendeesComponent,
        MessageFileFormComponent,
        FileDetailComponent,
        FilePreviewComponent,
        ChagGroupsFormComponent,
        AutoCompleteComponent,
        MarketplaceListComponent,
        MarketplaceDetailComponent,
        MarketplaceDetailOrdersComponent,
        MarketplacePurchaseComponent,
        MarketplaceAddComponent,
        MarketplaceAdDetailComponent,
        MarketplaceKeywordsComponent,
        MarketplaceInfoComponent,
        // modules
        AngularSvgIconModule,
        //FormsModule,
        //TranslateModule,
        CalendarModule,
        PasswordModalComponent,
        ChatGroupAttendeesComponent,
        ChatGroupSettingsComponent,
        MessageFileFormComponent,
        FileDetailComponent,
        FilePreviewComponent,
        AttendeeListItemComponent,
        LinkifyPipe,
        NlAfterPPipe,
        SecuredImagesPipe,
        TrnsPipe,
        EmbedVideoPipe,
        ChatGroupAttendeesActionMenu,
        NewsfeedActionMenu,
        MarketplaceActionMenu,
        ParticipantActionMenu,
        SelectGroupForJoinComponent,
        ChimeComponent,
        AppOutLinkComponent,
        TranslationAdminComponent,
        TranslationAdminAddComponent,
        TranslationAdminHistoryComponent,
        TranslationAdminVersionsComponent,
        AppointmentAttendeesComponent,
        StreamListComponent,
        StreamDetailComponent,
        VideoCallAttendeesComponent,
        VideoDetailComponent
    ],
    providers: [
        Location,
        {
            provide: DROPZONE_CONFIG,
            useValue: dropZoneSettings
        }
    ]
})
export
    class SharedModule { }
