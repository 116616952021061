<ion-modal [keepContentsMounted]="true"
           class="datetime-picker">
    <ng-template>
        <ion-datetime id="datetime"
                      [locale]="userLang"
                      [cancelText]="'BUTTON_CANCEL' |  translate"
                      [doneText]="'BUTTON_DONE' | translate"
                      color="dark"
                      [showDefaultButtons]="true"
                      presentation="date-time"
                      minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
                      [min]="yearMin"
                      [max]="yearMax"
                      [value]="starts_at"
                      (ionChange)="ionDatetimeChange($event)"
                      [preferWheel]="true">
        </ion-datetime>
    </ng-template>
</ion-modal>
<form *ngIf="!loading"
      #f="ngForm"
      novalidate
      class="{{class}}"
      (ngSubmit)="sendAppointment()"
      [ngClass]="{'no-timeslot': !user.selected_participant.event.use_timeslots, 'small-preview': smallPreview}">

    <h3 *ngIf="!plt.sizeSm">{{ 'APPOINTMENT_REQUEST' | trns }}</h3>

    <ion-grid class="ion-no-padding">
        <ng-container *ngIf="!user.selected_participant.event.use_timeslots">
            <ion-item [ngClass]="{ 'has-error': validate && validationErrors.starts_at }">
                <ion-label *ngIf="plt.sizeMd || smallPreview"
                           position="stacked">
                    {{ 'APPOINTMENT_REQUEST_WHEN' | translate }}
                </ion-label>
                <ion-label *ngIf="!plt.sizeMd && !smallPreview"
                           position="fixed">
                    {{ 'APPOINTMENT_REQUEST_WHEN' | translate }}
                </ion-label>

                <ng-container *ngIf="plt.tabMenu">
                    <!-- <ion-datetime #dateTimePicker
                                  mode="ios"
                                  [preferWheel]="true"
                                  presentation="date-time"
                                  name="date_starts_at_m"
                                  display-format="DD.MM.YYYY HH:mm"
                                  picker-format="DD.MMMM.YYYY HH:mm"
                                  [(ngModel)]="appointment.starts_at_date"
                                  [cancelText]="'BUTTON_CANCEL' |  translate"
                                  [doneText]="'BUTTON_DONE' | translate"
                                  [monthNames]="'MONTH_NAMES' | translate "
                                  minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
                                  [pickerOptions]="customPickerOptions"
                                  (ionChange)="ionDatetimeChange()"
                                  [min]="yearMin"
                                  [max]="yearMax">
                    </ion-datetime> -->

                    <ion-datetime-button datetime="datetime">
                    </ion-datetime-button>
                </ng-container>

                <ng-container *ngIf="!plt.tabMenu">
                    <p-calendar name="date_starts_at"
                                [(ngModel)]="appointment.date_starts_at"
                                #date_starts_at="ngModel"
                                [inputStyleClass]="'form-control appointment-date'"
                                dateFormat="dd.mm.yy"
                                [showTime]="true"
                                [hourFormat]="24"
                                [stepMinute]="5"
                                readonlyInput="readonlyInput"
                                monthNavigator="true"
                                yearNavigator="true"
                                [yearRange]="yearRange"
                                [minDate]="now"
                                (onSelect)="setAppointmentDate()"
                                [locale]="datepickerLangs[userLang]"
                                required
                                appendTo="body"
                                [showIcon]="true">
                    </p-calendar>
                </ng-container>
            </ion-item>
            <span *ngIf="validate && validationErrors.starts_at"
                  class="error-message">
                {{ validationErrors.starts_at }}
            </span>
        </ng-container>
        <ng-container *ngIf="user.selected_participant.event.use_timeslots && participantTimeslot">
            <ion-item [ngClass]="{ 'has-error': validate && validationErrors.starts_at }">
                <ion-label *ngIf="plt.sizeMd || smallPreview"
                           position="stacked">
                    {{ 'APPOINTMENT_REQUEST_WHEN_DURATIONS' | translate }}
                </ion-label>
                <ion-label *ngIf="!plt.sizeMd && !smallPreview"
                           position="fixed">
                    {{ 'APPOINTMENT_REQUEST_WHEN_DURATIONS' | translate }}
                </ion-label>
                <ion-select [interface]="selectType"
                            [interfaceOptions]="{cssClass: participantTimeslot.durations.length < 6 ?'not-overflow': ''}"
                            [cancelText]="'BUTTON_CANCEL' |  translate"
                            name="timeslot_duration"
                            [(ngModel)]="timeslotDuration"
                            (ngModelChange)="selectDuration($event)">
                    <ion-select-option *ngFor="let option of participantTimeslot.durations"
                                       [value]="option">{{option}} min
                    </ion-select-option>
                </ion-select>
            </ion-item>

            <ion-item [ngClass]="{ 'has-error': validate && validationErrors.starts_at }">
                <ion-label *ngIf="plt.sizeMd || smallPreview"
                           position="stacked">
                    {{ 'APPOINTMENT_REQUEST_WHEN_DAY' | translate }}
                </ion-label>
                <ion-label *ngIf="!plt.sizeMd && !smallPreview"
                           position="fixed">
                    {{ 'APPOINTMENT_REQUEST_WHEN_DAY' | translate }}
                </ion-label>
                <ion-select [interface]="selectType"
                            [interfaceOptions]="{cssClass: days.length < 6 ?'not-overflow': ''}"
                            [cancelText]="'BUTTON_CANCEL' |  translate"
                            name="timeslot_day"
                            [(ngModel)]="selectedDay"
                            (ngModelChange)="selectDay($event)">
                    <ion-select-option>
                    </ion-select-option>
                    <ion-select-option *ngFor="let option of days"
                                       [value]="option">
                        {{ participantTimeslot.timeslots['items'][option.getTime()][0].start | datex:'dateFormatDayName':null:true }}
                    </ion-select-option>
                </ion-select>
            </ion-item>


            <ion-item [ngClass]="{ 'has-error': validate && validationErrors.starts_at }">
                <ion-label *ngIf="plt.sizeMd || smallPreview"
                           position="stacked">
                    {{ 'APPOINTMENT_REQUEST_WHEN_TIME' | translate }}
                </ion-label>
                <ion-label *ngIf="!plt.sizeMd && !smallPreview"
                           position="fixed">
                    {{ 'APPOINTMENT_REQUEST_WHEN_TIME' | translate }}
                </ion-label>
                <ion-select [interface]="selectType"
                            [interfaceOptions]="{cssClass: timeslots.length < 6 ?'not-overflow': ''}"
                            [cancelText]="'BUTTON_CANCEL' |  translate"
                            name="timeslot_time"
                            [(ngModel)]="selectedTime"
                            (ngModelChange)="selectTime($event)">
                    <ng-container *ngIf="selectedDay">
                        <ion-select-option></ion-select-option>
                        <ng-container *ngFor="let option of timeslots">
                            <ion-select-option [value]="option">
                                {{option.start | datex: 'timeFormatDefault':null:true}} - {{option.end | datex: 'timeFormatDefaultEnd':null:true}}
                                <!-- <span *ngIf="option.status == 'free' && !option.appointment">({{ 'TIMESLOTS_DEFAULT_FREE' | translate }})</span>
                                                                            <span *ngIf="option.status != 'free' || option.appointment">({{ 'TIMESLOTS_DEFAULT_BLOCKED' | translate }})</span> -->
                            </ion-select-option>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!selectedDay">
                        <ion-select-option disabled="true">{{ 'APPOINTMENT_REQUEST_PLEASE_SELECT_DAY_FIRST' | translate }}</ion-select-option>
                    </ng-container>
                </ion-select>
            </ion-item>
            <span *ngIf="validate && validationErrors.starts_at"
                  class="error-message">
                {{ validationErrors.starts_at }}
            </span>
            <span *ngIf="selectedDay && !someFree"
                  class="info">
                <div *ngIf="!someFree && !diffDayFree && !smallerDurationFree">{{ 'APPOINTMENT_REQUEST_NO_SLOT_IS_AVAILABLE' | translate }}</div>
                <div *ngIf="smallerDurationFree && !diffDayFree">{{ 'APPOINTMENT_REQUEST_SMALLER_SLOT_IS_AVAILABLE' | translate:{ min: smallerDurationFree} }}</div>
                <div *ngIf="diffDayFree && !smallerDurationFree"><span
                          [innerHtml]="'APPOINTMENT_REQUEST_SMALLER_SLOT_IS_AVAILABLE_FOR_DATE_ONLY' | translate:{ min: smallerDurationFreeDiffDay, day: diffDayFree | datex }"></span></div>
                <div *ngIf="diffDayFree && smallerDurationFree"><span [innerHtml]="'APPOINTMENT_REQUEST_SMALLER_SLOT_IS_AVAILABLE_FOR_DATE_ALSO' | translate:{ min: smallerDurationFree}"></span></div>
            </span>
        </ng-container>

        <ion-item *ngIf="user?.canVideoCall(attendee)"
                  class="confirmation-checkbox">
            <ion-checkbox name="online"
                          [(ngModel)]="appointment.online">
            </ion-checkbox>
            <ion-label>{{ 'ATTENDEE_DETAIL_CALL' | translate }}</ion-label>
        </ion-item>

        <ng-container *ngIf="!appointment.online">
            <ion-item [ngClass]="{ 'has-error': validate && validationErrors.location }">
                <ion-label *ngIf="plt.sizeMd || smallPreview"
                           position="stacked">
                    {{ 'APPOINTMENT_REQUEST_WHERE' | translate }}
                </ion-label>
                <ion-label *ngIf="!plt.sizeMd && !smallPreview"
                           position="fixed">
                    {{ 'APPOINTMENT_REQUEST_WHERE' | translate }}
                </ion-label>
                <ng-container *ngIf="user.selected_participant.event.use_places && editableLocation">
                    <ion-select [interface]="selectType"
                                [cancelText]="'BUTTON_CANCEL' |  translate"
                                name="place"
                                [(ngModel)]="appointment.place">
                        <ion-select-option *ngFor="let place of user.selected_participant.event.places"
                                           [value]="place">
                            {{place.title}}
                        </ion-select-option>
                    </ion-select>
                </ng-container>
                <ng-container *ngIf="!user.selected_participant.event.use_places || !editableLocation">
                    <ion-input type="text"
                               name="location"
                               [disabled]="!editableLocation"
                               (ionFocus)="inputFocus()"
                               (ionBlur)="plt.checkResize(false)"
                               [(ngModel)]="appointment.location"
                               #location="ngModel"
                               required>
                    </ion-input>
                </ng-container>
            </ion-item>
            <span *ngIf="validate && validationErrors.location"
                  class="error-message">
                {{ validationErrors.location }}
            </span>
        </ng-container>

        <ion-item [ngClass]="{ 'has-error': validate && validationErrors.description }">
            <ion-label *ngIf="plt.sizeMd || smallPreview"
                       position="stacked">
                {{ 'APPOINTMENT_REQUEST_WHY' | translate }}
            </ion-label>
            <ion-label *ngIf="!plt.sizeMd && !smallPreview"
                       position="fixed">
                {{ 'APPOINTMENT_REQUEST_WHY' | translate }}
            </ion-label>
            <ion-input type="text"
                       placeholder="{{ 'APPOINTMENT_REQUEST_WHY_PLACEHOLDER' | translate }}"
                       name="description"
                       (ionFocus)="inputFocus()"
                       (ionBlur)="plt.checkResize(false)"
                       inputmode="text"
                       spellcheck="false"
                       autocapitalize="off"
                       #title="ngModel"
                       [(ngModel)]="appointment.description">
            </ion-input>
        </ion-item>
        <span *ngIf="validate && validationErrors.description"
              class="error-message">
            {{ validationErrors.description }}
        </span>

        <ng-container>
            <ion-row class="ion-no-padding">
                <ion-col class="ion-no-padding">
                    <h4>{{ 'APPOINTMENT_MEMBERS' | translate }}</h4>
                </ion-col>
            </ion-row>

            <ion-row class="ion-no-padding">
                <ion-col class="ion-no-padding">
                    <div class="invited-participant">
                        {{ attendee.firstname }} {{ attendee.lastname }}
                    </div>
                    <div class="invited-participant">
                        {{ user.selected_participant.firstname }} {{ user.selected_participant.lastname }}
                    </div>
                    <ng-container *ngFor="let participant of appointment.participants">
                        <div class="invited-participant">
                            {{participant.firstname}} {{participant.lastname}}
                            <svg-icon (click)="removeParticipant(participant)"
                                      [applyClass]="true"
                                      src="/assets/icons/ico-close.svg"
                                      class="icon fill-dark">
                            </svg-icon>
                        </div>
                    </ng-container>
                </ion-col>
            </ion-row>
        </ng-container>

        <ion-row class="ion-no-padding">
            <ion-col class="ion-no-padding">
                <ion-item id="invitation-box"
                          class="invitations"
                          [ngClass]="{ 'has-error': validate && validationErrors.participants }">
                    <ng-template #emptyTemplate
                                 let-attrs="attrs"
                                 class="ion-text-center">
                        {{ 'APPOINTMENT_MEMBERS_NO_ITEMS' | translate }}
                    </ng-template>
                    <ng-template #participantTemplate
                                 let-attrs="attrs">
                        {{attrs.data.firstname}} {{attrs.data.lastname}}
                    </ng-template>
                    <ion-auto-complete *ngIf="appointment.participants.length <= 14"
                                       #autocomplete
                                       [maxResults]="6"
                                       [dataProvider]="participantService"
                                       [dataProviderAttribute]="'participants'"
                                       [exclude]="[attendee].concat(appointment.participants)"
                                       [template]="participantTemplate"
                                       [selectionTemplate]="participantTemplate"
                                       [emptyTemplate]="emptyTemplate"
                                       [options]="{mode: 'ios', placeholder: 'APPOINTMENT_ATTENDEE_LIST_SEARCH_PLACEHOLDER' | translate }"
                                       (ionAutoInput)="autocompleteFocus()"
                                       (itemsShown)="autocompleteFocus()"
                                       (itemsHidden)="autocompleteBlur()"
                                       (autoBlur)="autocompleteBlur()"
                                       (itemSelected)="addParticipant($event)">
                    </ion-auto-complete>
                </ion-item>
            </ion-col>
        </ion-row>

        <!-- <ion-item class="confirmation-checkbox">
        <ion-checkbox name="send_email_confirmation"
                      [(ngModel)]="appointment.send_email_confirmation"></ion-checkbox>
        <ion-label>{{ 'APPOINTMENT_SEND_EMAIL_CONFIRMATION' | translate }}</ion-label>
    </ion-item> -->

        <ion-row *ngIf="!plt.sizeSm && !smallPreview"
                 class="action-buttons">
            <ion-col class="ion-text-right">
                <ion-button class="btn-close"
                            (click)="onAppointmentRequest.emit()">
                    {{ 'BUTTON_CANCEL' | translate }}
                </ion-button>

                <ion-button (click)="sendAppointment()">
                    {{ 'BUTTON_SEND' | translate }}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</form>

<ion-footer *ngIf="(plt.sizeSm || smallPreview) && !loading">
    <ion-row class="ion-no-padding two"
             [ngClass]="{'action-buttons': plt.sizeSm}">
        <ion-col size="6"
                 class="ion-no-padding">
            <ion-button class="btn-close"
                        (click)="onAppointmentRequest.emit()">
                {{ 'BUTTON_CANCEL' | translate }}
            </ion-button>
        </ion-col>
        <ion-col size="6"
                 class="ion-no-padding">
            <ion-button (click)="sendAppointment()">
                {{ 'BUTTON_SEND' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>

<ng-container *ngIf="loading">
    <div class="content-centered-column">
        <div class="content-centered-row">
            <ion-spinner></ion-spinner>
        </div>
    </div>
</ng-container>