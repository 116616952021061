<ion-content class="ion-padding">
    <div class="content-centered-column">
        <div class="content-centered-row">
            <form (ngSubmit)="set()"
                  #f="ngForm"
                  novalidate>
                <ion-list class="form">
                    <ion-item class="ion-no-lines ion-no-padding title">
                        <h1>{{ 'SET_PASSWORD_PASSWORD_SET' | trns}}</h1>
                    </ion-item>
                    <ion-item class="hint">
                        <p>{{ 'SET_PASSWORD_PASSWORD_PLEASE_SET' | trns }}</p>
                    </ion-item>
                    <ion-item class="ion-no-lines">
                        <ion-label position="stacked">{{ 'PASSWORD' | trns }}</ion-label>
                        <ion-input type="password"
                                   placeholder="{{ 'PASSWORD_PLACEHOLDER' | translate }}"
                                   name="password"
                                   (keyup.enter)="set()"
                                   #password="ngModel"
                                   [(ngModel)]="model.password"
                                   required>
                        </ion-input>
                    </ion-item>
                    <ion-item class="ion-no-lines">
                        <ion-label position="stacked">{{ 'PASSWORD_CONFIRMATION' | trns }}</ion-label>
                        <ion-input type="password"
                                   placeholder="{{ 'PASSWORD_PLACEHOLDER' | translate }}"
                                   name="password_confirmation"
                                   (keyup.enter)="set()"
                                   #password_confirmation="ngModel"
                                   [(ngModel)]="model.password_confirmation"
                                   required>
                        </ion-input>
                    </ion-item>

                    <ion-button expand="block"
                                color="primary"
                                type="submit"
                                [disabled]="!f.form.valid">
                        {{ 'SET_PASSWORD_BUTTON_SET_PASSWORD' | trns }}
                    </ion-button>
                    <ion-item class="ion-no-lines ion-no-padding">
                        <a class="link"
                           [routerLink]="'/'">
                            {{ 'SET_PASSWORD_GOTO_LOGIN' | trns }}
                        </a>
                    </ion-item>

                </ion-list>
            </form>
        </div>
    </div>
</ion-content>