import { Participant } from "./participant";

export class MarketplaceHeadline {
    id: number;
    marketplace_post_id: number;
    title: string;

    participants: Participant[] = [];

    /**
     * constructor
     *
     * @param {MarketplaceHeadline} init
     */
    public constructor(init?: MarketplaceHeadline)
    {
        if (!!init)
        {
            Object.assign(this, init);

            // participants
            if (init.participants) {
                this.participants = init.participants.map(participant => new Participant(participant));
            }
        }
    }

}
