export const theme = {
    name: 'bfp',
    customRoutes: [{
        path: 'login',
        loadChildren: () => import('../app/pages/custom/bfp/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'login/event-key/:event-key',
        loadChildren: () => import('../app/pages/custom/bfp/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'login/access-key/:event-key',
        loadChildren: () => import('../app/pages/custom/bfp/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'register',
        loadChildren: () => import('../app/pages/custom/bfp/registration/registration.module').then(m => m.RegistrationPageModule),
    },
    {
        path: 'register/event-key/:event-key',
        loadChildren: () => import('../app/pages/custom/bfp/registration/registration.module').then(m => m.RegistrationPageModule),
    },
    {
        path: 'register/access-key/:event-key',
        loadChildren: () => import('../app/pages/custom/bfp/registration/registration.module').then(m => m.RegistrationPageModule),
    },
    {
        path: 'lost-password',
        loadChildren: () => import('../app/pages/custom/bfp/lost-password/lost-password.module').then(m => m.LostPasswordPageModule)
    },
    {
        path: 'set-password',
        loadChildren: () => import('../app/pages/custom/bfp/set-password/set-password.module').then(m => m.SetPasswordPageModule)
    },
    {
        path: 'toc',
        loadChildren: () => import('../app/pages/custom/bfp/toc/toc.module').then(m => m.TocPageModule)
    }]
}
