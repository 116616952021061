import { Injectable } from '@angular/core';

// other services
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { Voting } from "../models/voting";

// configuration
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class VotingService {


    /**
     * constructor
     *
     * @param jwtHttp
     */
    constructor(private http: HttpClient) { }

    /**
     * get voting by event
     *
     * @return {Observable<any>}
     */
    public getListByEvent(eventId: number): Observable<any> {
        return this.http.get(environment.api + '/event/' + eventId + '/voting');
    }

    /**
     * get current live voting
     *
     * @return {Observable<any>}
     */
    public getLiveVoting(participantId: number, chatGroupId?: number): Observable<any> {
        return this.http.get(environment.api + '/participant/' + participantId + '/voting' + (chatGroupId ? '?chat_group=' + chatGroupId : ''));
    }

    /**
     * get current live voting
     *
     * @return {Observable<any>}
     */
    public getLiveVotingIds(participantId: number, chatGroupId?: number): Observable<any> {
        return this.http.get(environment.api + '/participant/' + participantId + '/voting-ids' + (chatGroupId ? '?chat_group=' + chatGroupId : ''));
    }

    /**
     * get current live votings
     *
     * @return {Observable<any>}
     */
    public getLiveVotings(participantId: number, chatGroupId?: number): Observable<any> {
        return this.http.get(environment.api + '/participant/' + participantId + '/votings' + (chatGroupId ? '?chat_group=' + chatGroupId : ''));
    }

    /**
     * store particpant voting
     *
     * @return {Observable<any>}
     */
    public vote(participantId: number, answers, votingId?: number): Observable<any> {
        return this.http.post(environment.api + '/participant/' + participantId + '/voting' + (votingId ? '/' + votingId : '' ), answers);
    }

    /**
     * get by id
     *
     * @return {Observable<any>}
     */
    public getById(id: number, eventId: number): Observable<any> {
        return this.http.get(environment.api + '/event/' + eventId + '/voting/check/' + id);
    }

    /**
     * create
     *
     * @param group
     *
     * @return {Observable<any>}
     */
    public create(voting: Voting): Observable<any> {
        return this.http.post(environment.api + '/event/' + voting.event_id + '/voting', voting);
    }

    /**
     * start
     *
     * @param voting
     *
     * @return {Observable<any>}
     */
    public start(voting: Voting) {
        return this.http.post(environment.api + '/event/' + voting.event_id + '/voting/' + voting.id + '/start', {});
    }

    /**
     * pause
     *
     * @param voting
     *
     * @return {Observable<any>}
     */
    public pause(voting: Voting): Observable<any> {
        return this.http.post(environment.api + '/event/' + voting.event_id + '/voting/' + voting.id + '/pause', {});
    }

    /**
     * close
     *
     * @param voting
     *
     * @return {Observable<any>}
     */
    public close(voting: Voting): Observable<any> {
        return this.http.post(environment.api + '/event/' + voting.event_id + '/voting/' + voting.id + '/close', {});
    }

    /**
     * update
     *
     * @param voting
     *
     * @return {Observable<any>}
     */
    public update(voting: Voting): Observable<any> {
        return this.http.put(environment.api + '/event/' + voting.event_id + '/voting/' + voting.id, voting);
    }

    /**
     * destroy by id
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public delete(id: number, eventId: number): Observable<any> {
        return this.http.delete(environment.api + '/event/' + eventId + '/voting/' + id);
    }

}
