import { Component, Input, OnInit } from '@angular/core';

// models
import { Participant } from '../../models/participant';

@Component({
    selector: 'percentage-circle',
    templateUrl: './percentage-circle.component.html',
    styleUrls: ['./percentage-circle.component.scss']
})

export class PercentageCircleComponent implements OnInit {

    @Input() attendee: Participant;
    @Input() matching: boolean = false;
    @Input() picture: boolean = false;
    @Input() order: number;

    @Input() radius = 25;

    circumference = 2 * Math.PI * this.radius;
    dashoffset: number;

    imageLoading: boolean = true;

    constructor(
    ) {
    }

    ngOnInit() {
        if (this.attendee) {
            if (this.order == undefined) {
                this.order = this.attendee.id;
            }
            // https://coryrylan.com/blog/angular-progress-component-with-svg
            this.circumference = 2 * Math.PI * this.radius;
            this.dashoffset = this.circumference * (1 - (this.attendee.matching_factor / 100));
        } else {
            if (this.order == undefined) {
                this.order = 1;
            }
        }
    }

    loaded() {
        if (this.imageLoading) {
            setTimeout(() => {
                this.imageLoading = false;
            }, 200);
        }
    }
}
