import { Component, OnInit, Input } from '@angular/core';

// ionic
import { ModalController } from '@ionic/angular';

// rxjs
import { Subject } from "rxjs";

// providers
import { OverlayService } from "../../services/overlay.service";
import { TrnsService } from '../../services/trns.service';

// models
import { TranslationHistory } from '../../models/translation-history';

@Component({
    selector: 'app-translation-admin-versions',
    templateUrl: './translation-admin-versions.component.html',
    styleUrls: ['./translation-admin-versions.component.scss'],
})
export class TranslationAdminVersionsComponent implements OnInit {

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    /**
     * user language
     *
     * @type { string}
     */
    @Input() public userLang: string;

    records = [];

    constructor(
        public overlayService: OverlayService,
        public modalController: ModalController,
        public trnsService: TrnsService,
    ) { }

    ngOnInit() {
        this.loadData()
    }


    loadData() {
        this.records = [];
        this.trnsService.versions()
            .subscribe((records) => {
                records.forEach(
                    (record) => {
                        this.records.push(new TranslationHistory(record))
                    });
            });
    }

    /**
     * close modal window
     */
    public close() {
        // otherwise close whoe modal
        this.modalController.dismiss({ action: 'close' });
    }

    restoreVersion(v) {
        this.overlayService.showLoading();
        this.trnsService.restoreVersion(v)
            .subscribe((records) => {
                this.overlayService.hideLoading();
                this.overlayService.showSuccess('saved');
                this.modalController.dismiss({ action: 'refresh' });
            });
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.overlayService.closeModals();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
