<ul class="action-menu">
    <ng-container *ngIf="user?.selected_participant?.event?.is_homepage_content_newsfeed && user?.canNewsfeed(participant)">
        <li>
            <a *ngIf="newsfeed_muted"
               (click)="unmute('newsfeed')">
                {{ 'NEWSFEED_UNMUTE_PARTICIPANT' | translate }}
            </a>
        </li>
        <li>
            <a *ngIf="!newsfeed_muted"
               (click)="mute('newsfeed')">
                {{ 'NEWSFEED_MUTE_PARTICIPANT' | translate }}
            </a>
        </li>
    </ng-container>

    <li>
        <a *ngIf="user?.selected_participant?.event?.marketplaces.length > 0 && marketplace_muted"
           (click)="unmute('marketplace')">
            {{ 'MARKETPLACE_UNMUTE_PARTICIPANT' | translate }}
        </a>
    </li>
    <li>
        <a *ngIf="user?.selected_participant?.event?.marketplaces.length > 0 && !marketplace_muted"
           (click)="mute('marketplace')">
            {{ 'MARKETPLACE_MUTE_PARTICIPANT' | translate }}
        </a>
    </li>
</ul>