<ion-header class="ion-no-shadow ion-no-border">
    <ion-toolbar>
        <ion-title>Add new translation</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon slot="icon-only"
                          color="dark"
                          name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<form method="post"
      #f="ngForm"
      role="form"
      class="form-horizontal">

    <ion-item class="form-content">
        <ion-grid class="ion-no-padding">

            <ion-row class="ion-no-padding">
                <ion-col class="ion-no-padding">
                    <ion-item [ngClass]="{ 'has-error': validate && validationErrors['key'] }">
                        <ion-input type="text"
                                   placeholder="key"
                                   name="key"
                                   inputmode="text"
                                   spellcheck="false"
                                   autocapitalize="off"
                                   #key="ngModel"
                                   [(ngModel)]="translation.key"
                                   required>
                        </ion-input>
                    </ion-item>
                    <span *ngIf="validate && validationErrors['key']"
                          class="error-message">
                        {{ validationErrors['key'] }}
                    </span>
                </ion-col>
            </ion-row>

            <ion-row class="row-message ion-no-padding"
                     [ngClass]="{'has-error': validate && validationErrors['text']}">
                <ion-col class="ion-no-padding">
                    <ion-textarea #messageTextarea
                                  ieTextareaFix
                                  placeholder="text"
                                  name="message"
                                  inputmode="text"
                                  spellcheck="false"
                                  autocapitalize="off"
                                  id="messageTextarea"
                                  maxLength="1500"
                                  autoGrow="true"
                                  (ionFocus)="plt.checkResize()"
                                  (ionBlur)="plt.checkResize(false)"
                                  [(ngModel)]="translation.text"
                                  required>
                    </ion-textarea>
                    <span *ngIf="validate && validationErrors['text']"
                          class="error-message">
                        {{ validationErrors['text'] }}
                    </span>
                </ion-col>
            </ion-row>

            <ion-row class="ion-no-padding">
                <ion-col class="ion-no-padding">
                    <ion-item [ngClass]="{ 'has-error': validate && validationErrors['locale'] }">
                        <ion-select interface="popover"
                                    [interfaceOptions]="{cssClass:'not-overflow'}"
                                    name="locale"
                                    id="lang-select"
                                    [(ngModel)]="translation.locale"
                                    required>
                            <ion-select-option *ngFor="let lang of plt.availableLangs"
                                               [value]="lang">
                                {{ lang | uppercase | translate }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                    <span *ngIf="validate && validationErrors['locale']"
                          class="error-message">
                        {{ validationErrors['locale'] }}
                    </span>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-item [ngClass]="{ 'has-error': validate && validationErrors['source'] }">
                        <ion-select interface="popover"
                                    [interfaceOptions]="{cssClass:'not-overflow'}"
                                    name="source"
                                    id="lang-source"
                                    [(ngModel)]="translation.source">
                            <ion-select-option *ngFor="let source of trnsService.sources"
                                               [value]="source.source">
                                {{ source.name }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                    <span *ngIf="validate && validationErrors['source']"
                          class="error-message">
                        {{ validationErrors['source'] }}
                    </span>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>
</form>

<ion-footer>
    <ion-row class="action-buttons two ion-no-padding">
        <ion-col size="6"
                 class="ion-no-padding">
            <ion-button class="btn-close"
                        (click)="close()">
                {{ 'BUTTON_CANCEL' | translate }}
            </ion-button>
        </ion-col>
        <ion-col size="6"
                 class="ion-no-padding">
            <ion-button color="primary"
                        [disabled]="!f.form.valid || submit"
                        (click)="save(f.form.valid)">
                {{ 'BUTTON_SAVE' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>