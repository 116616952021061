import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';
import { ReplaySubject } from "rxjs";

// helpers
import { Events } from "../helpers/events";

import { Translation } from "../models/translation";

// configuration
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TrnsService {

    /**
       * current event
       *
       * @type {ReplaySubject}
       */
    private changes: any = new ReplaySubject(1);


    public adminToken: string = localStorage.getItem('admin-token');

    public clickable: boolean = true;

    // ['auth', 'content', 'messages', 'notifications', 'pagination', 'passwords', 'validation']
    public sources = [
        {
            source: '',
            name: 'PWA'
        },
        {
            source: 'auth',
            name: 'API - auth'
        },
        {
            source: 'content',
            name: 'API - content'
        },
        {
            source: 'messages',
            name: 'API - messages'
        },
        {
            source: 'notifications',
            name: 'API - notifications'
        },
        {
            source: 'pagination',
            name: 'API - pagination'
        },
        {
            source: 'passwords',
            name: 'API - passwords'
        },
        {
            source: 'validation',
            name: 'API - validation'
        }
    ];

    /**
     * constructor
     *
     */
    constructor(
        private http: HttpClient,
        public events: Events
    ) { }

    /**
     * get preview data
     *
     * @return {Observable<any>}
     */
    public all(locale: string, all: boolean = false, fileRefresh: boolean = false): Observable<any> {
        return this.http.get(environment.api + '/translation?locale=' + locale + (all ? '&all=1': '') + (all ? '&fileRefresh=1': '') + '&token=' + localStorage.getItem('admin-token'));
    }

    /**
     * get history data
     *
     * @return {Observable<any>}
     */
    public history(translation: Translation): Observable<any> {
        return this.http.get(environment.api + '/translation/' + translation.id + '/history?&token=' + localStorage.getItem('admin-token'));
    }

    /**
     * get translation versions
     *
     * @return {Observable<any>}
     */
    public versions(): Observable<any> {
        return this.http.get(environment.api + '/translationVersion?&token=' + localStorage.getItem('admin-token'));
    }

    /**
     * get history data
     *
     * @return {Observable<any>}
     */
    public restoreVersion(version: number): Observable<any> {
        return this.http.put(environment.api + '/translationVersion/' + version + '?&token=' + localStorage.getItem('admin-token'), {});
    }

    /**
     * create translation
     *
     * @param participant
     *
     * @return {Observable<any>}
     */
    public create(translation: Translation): Observable<any> {
        return this.http.post(environment.api + '/translation?token=' + localStorage.getItem('admin-token'), translation);
    }

    /**
     * create version
     *
     * @param participant
     *
     * @return {Observable<any>}
     */
    public createVersion(): Observable<any> {
        return this.http.post(environment.api + '/translationVersion?token=' + localStorage.getItem('admin-token'), {});
    }

    /**
     * get history data
     *
     * @return {Observable<any>}
     */
    public translate(translation: Translation): Observable<any> {
        return this.http.post(environment.api + '/translations/translate?&token=' + localStorage.getItem('admin-token'), translation);
    }

    /**
     * update translation
     *
     * @param participant
     *
     * @return {Observable<any>}
     */
    public update(translation: Translation): Observable<any> {
        return this.http.put(environment.api + '/translation/' + translation.id + '?token=' + localStorage.getItem('admin-token'), translation);
    }

    /**
     * destroy translation by id
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public delete(id: number): Observable<any> {
        return this.http.delete(environment.api + '/translation/' + id + '?token=' + localStorage.getItem('admin-token'));
    }

    /**
     * get current change replay subject
     *
     * @return {Observable<Event>}
     */
    public onChange() {
        return this.changes.asObservable();
    }

    /**
     * trigger search event
     *
     * @return {Observable<Event>}
     */
    public publish(action: { type: string, query: string }) {
        // publish action about search
        this.changes.next(action);
    }

    /**
     * trigger search event
     *
     * @return {Observable<Event>}
     */
    public search(query: string) {
        // publish action about search
        this.changes.next({
            type: 'search',
            query: query
        });
    }

    public downloadLink(source = null) {
        return environment.api + '/translations/export?token=' + localStorage.getItem('admin-token') + (source != null ? '&source=' + (source ? source : 'pwa') : '')
    }

}
