import { Component, OnInit, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { PlatformService } from "../../services/platform.service";

@Component({
    selector: 'app-select-group-for-join',
    templateUrl: './select-group-for-join.component.html',
    styleUrls: ['./select-group-for-join.component.scss'],
})
export class SelectGroupForJoinComponent implements OnInit {

    @Input() groups;

    selectedGroup;

    constructor(
        public plt: PlatformService,
        public modalController: ModalController
    ) { }


    selectGroup(group) {

        this.selectedGroup = group;

        if (group.event_key) {
            this.modalController.dismiss({
                action: 'close',
                selectedGroup: this.selectedGroup
            });
        }
    }

    login() {
        this.modalController.dismiss({
            action: 'close',
            selectedGroup: this.selectedGroup
        });
    }

    ngOnInit() { }

    /**
     * close modal window
     *
     */
    public backClicked() {
        this.modalController.dismiss({
            action: 'close'
        });
    }

}
