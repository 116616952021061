import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private _storage: Storage | null = null;

    constructor(
        private storage: Storage
    ) {
        this.init();
    }

    async init() {
        await this.storage.defineDriver(CordovaSQLiteDriver);

        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const storage = await this.storage.create();
        this._storage = storage;
    }

    // Create and expose methods that users of this service can
    public set(key: string, value: any) {
        this._storage?.set(key, value);
    }

    public async get(key: string) {
        return this._storage?.get(key);
    }

    public async remove(key: string) {
        return this._storage?.remove(key);
    }

    public driver() {
        return this._storage?.driver;
    }
}