import { Directive, ElementRef, Input, HostListener, Renderer2 } from "@angular/core";

import { PlatformService } from "../../services/platform.service";

@Directive({
    selector: 'img[src][img-avatar]',
    host: {
        '[src]': 'checkPath(src)',
        '(error)': 'onError()'
    }
})
export class DefaultAvatarImageDirective {
    /**
     * input src
     *
     * @type Input
     */
    @Input() src: string;
    @Input() random: number = 0;

    constructor(
        private plt: PlatformService,
        private el: ElementRef,
        private renderer: Renderer2
    ) {
        // disable skeleton animation after 2s
        setTimeout(() => {
            this.hideSkeleton();
        }, 5000);
    }

    /**
     * default image
     *
     * @type {string}
     */
    public defaultImg: string[] = [
        'assets/icons/ico-avatar.svg',
        'assets/icons/ico-avatar-blue.svg',
        'assets/icons/ico-avatar-green.svg'
    ];

    /**
     * fallback on error
     *
     * @return {string}
     */
    public onError() {
        this.hideSkeleton();
        if (this.plt.isConnected) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.defaultImg[this.random % this.defaultImg.length]);
        }
    }

    /**
     * disable animated skeleton if used
     *
     */
    @HostListener('load', ['$event'])
    public onLoad() {
        this.hideSkeleton();
    }

    /**
     * fallback on image is null
     *
     * @param src
     * @return {string}
     */
    public checkPath(src): string {
        return src ? this.plt.createSignedLink(src) : this.defaultImg[this.random % this.defaultImg.length];
    }

    private hideSkeleton() {
        if (this.el.nativeElement && this.el.nativeElement.parentNode) {
            let skeleton = this.el.nativeElement.parentNode.querySelector('ion-skeleton-text');
            if (skeleton) {
                skeleton.remove();
            }
        }
    }
}
