<ion-app [ngClass]="{'keyboard': keyboardVisible, 'translation-mode': plt.translationMode}">

    <ng-container *ngIf="plt.forceUpdate && !isPreview">
        <div class="force-update-page">
            <div class="content-centered-column">
                <div class="content-centered-row">
                    <div class="inner">
                        <img alt="congreet"
                             src="/assets/icons/congreet-logo-text.svg">
                        <p>
                            {{ 'VERSION_CHANGED_FORCE' | trns }}
                        </p>
                        <ion-button *ngIf="plt.is('cordova')"
                                    color="primary"
                                    (click)="updateApp()">
                            {{ 'VERSION_CHANGED_BUTTON' | trns }}
                        </ion-button>
                        <ion-button *ngIf="!plt.is('cordova')"
                                    color="primary"
                                    (click)="updatePwa()">
                            {{ 'VERSION_CHANGED_BUTTON' | trns }}
                        </ion-button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!plt.forceUpdate || isPreview">
        <ion-split-pane content-id="main-content"
                        class="ion-no-border"
                        [ngClass]="{'disabled': plt.hideApp && !isPreview}"
                        when="(min-width: 1366px)">
            <ion-menu id="authenticated"
                      (ionWillOpen)="menuOpened()"
                      (ionWillClose)="menuClosed()"
                      content-id="main-content"
                      [side]="plt.tabMenu ? 'end' : 'start'"
                      [ngClass]="{'hidden': plt.hideSidebar, 'logged-out': !loggedIn}"
                      type="push"
                      class="ion-no-border">

                <ng-container *ngIf="!isPreview else templatePreview">
                    <ion-header class="ion-no-shadow ion-no-border"
                                [ngClass]="{'not-logged': true || !loggedIn}">
                        <ion-toolbar>
                            <ion-title>
                                <a *ngIf="loggedIn && user?.selected_participant?.event.setting.logo_small_link"
                                   [href]="user?.selected_participant?.event.setting.logo_small_link"
                                   onclick="window.open(this.href, '_system');return false">
                                    &nbsp;
                                </a>
                                <!-- <img alt="congreet"
                                     src="/assets/icons/congreet-logo-text.svg"> -->
                                <!-- <img *ngIf="loggedIn"
                                     alt="congreet"
                                     src="/assets/icons/congreet-logo.svg"> -->
                            </ion-title>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <ion-list *ngIf="loggedIn && !publicPage">

                            <div *ngIf="loggedIn && plt.isConnected && user?.selected_participant"
                                 (click)="viewEventList=!viewEventList"
                                 class="event-switch">
                                <span *ngIf="user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_COMMUNITY' | trns }} ({{events.length}})</span>
                                <span *ngIf="!user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_EVENTS' | trns }} ({{events.length}})</span>
                                <span class="fill-menu-contrast"
                                      *ngIf="!viewEventList">
                                    <ng-container *ngIf="user.selected_participant?.event?.is_community">{{'LEFT_MENU_MORE_COMMUNITY' | trns}}</ng-container>
                                    <ng-container *ngIf="!user.selected_participant?.event?.is_community">{{'LEFT_MENU_MORE_EVENTS' | trns}}</ng-container>
                                    <svg-icon [applyClass]="true"
                                              src="/assets/icons/ico-arrow.svg"
                                              class="collapsible">
                                    </svg-icon>
                                    <ion-badge *ngIf="plt.unreadOtherCount"
                                               class="notification">
                                        {{ plt.unreadOtherCount }}
                                    </ion-badge>
                                </span>
                                <span color="secondary"
                                      *ngIf="viewEventList">
                                    <ng-container *ngIf="user.selected_participant?.event?.is_community">{{'LEFT_MENU_LESS_COMMUNITY' | trns }}</ng-container>
                                    <ng-container *ngIf="!user.selected_participant?.event?.is_community">{{'LEFT_MENU_LESS_EVENTS' | trns }}</ng-container>
                                    <svg-icon [applyClass]="true"
                                              src="/assets/icons/ico-arrow.svg"
                                              class="collapsible opened">
                                    </svg-icon>
                                </span>
                            </div>

                            <ion-menu-toggle [menu]="plt.tabMenu ? 'end' : 'start'"
                                             auto-hide="false"
                                             *ngIf="viewEventList">
                                <ion-item [routerDirection]="'root'"
                                          [routerLink]="[plt.defaultLink + '/access-key']"
                                          routerLinkActive="active">
                                    <ion-button color="primary">{{ 'LEFT_MENU_EVENT_KEY' | trns }}</ion-button>
                                </ion-item>
                            </ion-menu-toggle>

                            <ng-container *ngIf="events.length > 0 && viewEventList">
                                <ng-container *ngFor="let event of events">
                                    <ion-menu-toggle *ngIf="event.id != user?.selected_participant?.event_id"
                                                     [menu]="plt.tabMenu ? 'end' : 'start'"
                                                     auto-hide="false"
                                                     (click)="selectEvent(event.id)">
                                        <ion-item>
                                            <img class="logo"
                                                 width="32"
                                                 height="32"
                                                 img-logo
                                                 [src]="event.logo_url">
                                            <ion-label>
                                                <strong class="event-name">{{ event.translate(userLang).name }}</strong><br />
                                                <ng-container *ngIf="!event.is_community">
                                                    <ng-container *ngIf="event.date_starts_at.toDateString() != event.date_ends_at.toDateString()">
                                                        {{ event.date_starts_at | datex:'dateFormatShort' }} -
                                                    </ng-container>
                                                    {{ event.date_ends_at | datex }}
                                                </ng-container>
                                            </ion-label>
                                            <ion-badge *ngIf="plt.unreadOther && plt.unreadOther[event.id]"
                                                       class="notification">
                                                {{ plt.unreadOther[event.id] }}
                                            </ion-badge>
                                        </ion-item>
                                    </ion-menu-toggle>
                                </ng-container>

                                <app-chat-groups-list *ngIf="!plt.tabMenu"
                                                      [silent]="viewType != 'messages'"
                                                      [ngClass]="{'hidden': true}">
                                </app-chat-groups-list>
                            </ng-container>

                            <ng-container *ngIf="!loading && !viewEventList && user?.selected_participant">
                                <ion-menu-toggle [menu]="plt.tabMenu ? 'end' : 'start'"
                                                 auto-hide="false">
                                    <ion-item *ngIf="plt.isConnected"
                                              [routerDirection]="'root'"
                                              [routerLink]="[plt.defaultLink + '/home/info/matching']">
                                        <img class="logo"
                                             width="32"
                                             height="32"
                                             img-logo
                                             [src]="user.selected_participant.event.logo_url">
                                        <ion-label>
                                            <strong class="event-name">{{ user.selected_participant.event.name }}</strong><br />
                                            <ng-container *ngIf="!user.selected_participant?.event?.is_community">
                                                <ng-container *ngIf="user.selected_participant.event.date_starts_at.toDateString() != user.selected_participant.event.date_ends_at.toDateString()">
                                                    {{ user.selected_participant.event.date_starts_at | datex:'dateFormatShort' }} -
                                                </ng-container>
                                                {{ user.selected_participant.event.date_ends_at | datex }}
                                            </ng-container>
                                        </ion-label>
                                    </ion-item>

                                    <ion-item *ngIf="!plt.isConnected"
                                              [routerDirection]="'root'"
                                              [routerLink]="[plt.defaultLink + '/home/detail/event']">

                                        <img class="logo"
                                             width="32"
                                             height="32"
                                             img-logo
                                             [src]="user.selected_participant.event.logo_url">
                                        <ion-label>
                                            <strong class="event-name">{{ user.selected_participant.event.name }}</strong><br />
                                            <ng-container *ngIf="!user.selected_participant?.event?.is_community">
                                                <ng-container *ngIf="user.selected_participant.event.date_starts_at.toDateString() != user.selected_participant.event.date_ends_at.toDateString()">
                                                    {{ user.selected_participant.event.date_starts_at | datex:'dateFormatShort' }} -
                                                </ng-container>
                                                {{ user.selected_participant.event.date_ends_at | datex }}
                                            </ng-container>
                                        </ion-label>
                                    </ion-item>
                                </ion-menu-toggle>
                                <ng-container *ngIf="plt.isConnected">
                                    <hr class="full" />

                                    <div *ngIf="!plt.tabMenu"
                                         class="menu-switch">
                                        <div (click)="switchMenu('event')"
                                             [ngClass]="{'active': viewType == 'event'}">
                                            <ng-container *ngIf="!user.selected_participant?.event?.is_community">
                                                {{ 'LEFT_MENU_EVENT' | trns }}
                                            </ng-container>
                                            <ng-container *ngIf="user.selected_participant?.event?.is_community">
                                                {{ 'LEFT_MENU_COMMUNITY' | trns }}
                                            </ng-container>
                                        </div>
                                        <div (click)="switchMenu('messages')"
                                             [ngClass]="{'active': viewType == 'messages', 'unread': plt.unreadMessages || plt.unreadChatGroups}">
                                            {{ 'LEFT_MENU_MESSAGES' | trns }}
                                            <ion-badge *ngIf="plt.unreadMessages || plt.unreadChatGroups"
                                                       class="notification">
                                                {{ plt.unreadMessages + plt.unreadChatGroups }}
                                            </ion-badge>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="plt.tabMenu || viewType == 'event'">
                                        <ion-menu-toggle [menu]="plt.tabMenu ? 'end' : 'start'"
                                                         auto-hide="false">
                                            <ion-item id="menu-edit-profile-button"
                                                      [routerDirection]="'root'"
                                                      [routerLink]="[plt.defaultLink + '/edit-profile']"
                                                      routerLinkActive="active"
                                                      class="profile">
                                                <ion-avatar slot="start"
                                                            *ngIf="user.selected_participant">
                                                    <img img-avatar
                                                         [src]="user.selected_participant.picture_url">
                                                </ion-avatar>
                                                <ion-label>
                                                    <strong>
                                                        {{ user.selected_participant.firstname }} {{ user.selected_participant.lastname }}
                                                    </strong>
                                                    <br />{{ 'LEFT_MENU_PROFILE_MODIFY' | trns }}
                                                </ion-label>
                                            </ion-item>
                                        </ion-menu-toggle>

                                        <ion-item *ngIf="user.selected_participant?.invisible"
                                                  class="invisible">
                                            <svg-icon [applyClass]="true"
                                                      src="/assets/icons/warning.svg"
                                                      class="fill-menu-contrast">
                                            </svg-icon>
                                            <ion-label>
                                                {{ 'LEFT_MENU_INVISIBLE_PROFILE' | trns }}
                                                <br /><span (click)="hideProfile(false)">{{ 'LEFT_MENU_PROFILE_UNHIDE' | trns }}</span>
                                            </ion-label>
                                        </ion-item>

                                        <ng-container *ngFor="let detail of user.selected_participant.details">
                                            <ng-container *ngIf="detail.options.menu == true || (detail.type == 'personalized-link' || detail.type == 'modal-window')">
                                                <ng-container [ngSwitch]="true">

                                                    <ng-container *ngSwitchCase="detail.type == 'personalized-link'">
                                                        <ion-item class="personal-links pointer"
                                                                  [ngClass]="{'nats': !user.selected_participant?.event.ask_the_speaker}">
                                                            <a [href]="detail.value"
                                                               onclick="window.open(this.href, '_system');return false">
                                                                <ng-container *ngIf="detail.options.name">{{ detail.options.name }}</ng-container>
                                                                <ng-container *ngIf="!detail.options.name">{{ detail.value }}</ng-container>
                                                                <ion-icon color="light"
                                                                          class="pointer"
                                                                          name="arrow-forward">
                                                                </ion-icon>
                                                            </a>
                                                        </ion-item>
                                                    </ng-container>

                                                    <ng-container *ngSwitchCase="detail.type == 'modal-window'">
                                                        <ion-item class="personal-links pointer"
                                                                  [ngClass]="{'nats': !user.selected_participant?.event.ask_the_speaker}"
                                                                  (click)="showAttendeeDetail(detail)">
                                                            <ion-label>
                                                                {{ detail.options.name }}
                                                                <ion-icon color="light"
                                                                          class="pointer"
                                                                          name="arrow-forward">
                                                                </ion-icon>
                                                            </ion-label>
                                                        </ion-item>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>

                                        <ion-menu-toggle *ngIf="!plt.tabMenu && false"
                                                         auto-hide="false">
                                            <ion-item [routerDirection]="'root'"
                                                      [routerLink]="[plt.defaultLink + '/messages']"
                                                      routerLinkActive="active">
                                                <svg-icon [applyClass]="true"
                                                          src="/assets/icons/ico-messages.svg"
                                                          class="fill-menu-contrast"></svg-icon>
                                                <ion-label>{{ 'LEFT_MENU_MESSAGES' | trns }}</ion-label>
                                                <ion-badge *ngIf="plt.unreadMessages || plt.unreadChatGroups"
                                                           class="notification">
                                                    {{ plt.unreadMessages + plt.unreadChatGroups }}
                                                </ion-badge>
                                            </ion-item>
                                        </ion-menu-toggle>

                                        <ion-menu-toggle *ngIf="!plt.tabMenu && !user.selected_participant.event.disable_appointments"
                                                         auto-hide="false">
                                            <ion-item [routerDirection]="'root'"
                                                      [routerLink]="[plt.defaultLink + '/appointments']"
                                                      routerLinkActive="active">
                                                <svg-icon [applyClass]="true"
                                                          src="/assets/icons/ico-appointments.svg"
                                                          class="fill-menu-contrast"></svg-icon>
                                                <ion-label>{{ 'LEFT_MENU_APPOINTMENTS' | trns }}</ion-label>
                                                <ion-badge *ngIf="plt.unreadAppointments"
                                                           class="notification">
                                                    {{ plt.unreadAppointments }}
                                                </ion-badge>
                                            </ion-item>
                                        </ion-menu-toggle>
                                        <ion-menu-toggle [menu]="plt.tabMenu ? 'end' : 'start'"
                                                         auto-hide="false"
                                                         *ngIf="user.selected_participant?.event.ask_the_speaker">
                                            <ion-item [routerDirection]="'root'"
                                                      [routerLink]="[plt.defaultLink + '/ask-the-speaker']"
                                                      routerLinkActive="active">
                                                <svg-icon [applyClass]="true"
                                                          src="/assets/icons/ico-attendee.svg"
                                                          class="fill-menu-contrast"></svg-icon>
                                                <ion-label>{{ 'LEFT_MENU_ASK_THE_SPEAKER' | trns }}</ion-label>
                                            </ion-item>
                                        </ion-menu-toggle>
                                        <ion-menu-toggle [menu]="plt.tabMenu ? 'end' : 'start'"
                                                         auto-hide="false"
                                                         *ngIf="user.selected_participant?.event.enable_static_live_voting">
                                            <ion-item [routerDirection]="'root'"
                                                      [routerLink]="[plt.defaultLink + '/live-votings']"
                                                      routerLinkActive="active">
                                                <svg-icon [applyClass]="true"
                                                          src="/assets/icons/ico-attendee.svg"
                                                          class="fill-menu-contrast"></svg-icon>
                                                <ion-label>{{ 'LEFT_MENU_STATIC_LIVE_VOTING' | trns }}</ion-label>
                                            </ion-item>
                                        </ion-menu-toggle>
                                        <hr />
                                        <ion-menu-toggle *ngIf="!plt.tabMenu"
                                                         auto-hide="false">
                                            <ion-item [routerDirection]="'root'"
                                                      [ngClass]="{'active': manageKeywordsActive.isActive || matchingActive.isActive}"
                                                      [routerLink]="[plt.defaultLink + '/home/info/matching']"
                                                      #matchingActive="routerLinkActive"
                                                      routerLinkActive="active">
                                                <svg-icon [applyClass]="true"
                                                          src="/assets/icons/ico-dashboard.svg"
                                                          class="fill-menu-contrast"></svg-icon>
                                                <ion-label *ngIf="user.selected_participant?.event?.is_homepage_content_stream">{{ 'LEFT_MENU_STREAM' | trns }}</ion-label>
                                                <ion-label *ngIf="!user.selected_participant?.event?.is_homepage_content_stream">{{ 'LEFT_MENU_DASHBOARD' | trns }}</ion-label>
                                                <a [routerLink]="[plt.defaultLink + '/home/info/manage-keywords']"
                                                   routerLinkActive
                                                   #manageKeywordsActive="routerLinkActive"
                                                   style="display: none"></a>
                                            </ion-item>
                                        </ion-menu-toggle>

                                        <!-- Marketplace link -->
                                        <ion-menu-toggle *ngIf="!plt.tabMenu && user.selected_participant?.event?.marketplaces.length > 0
                                                                    && user.selected_participant"
                                                         auto-hide="false">
                                            <ion-item [routerDirection]="'root'"
                                                      [routerLink]="[plt.defaultLink + '/home/marketplace/list']"
                                                      routerLinkActive="active">
                                                <svg-icon [applyClass]="true"
                                                          src="/assets/icons/pricetags-outline.svg"
                                                          class="fill-menu-contrast marketplace"></svg-icon>
                                                <ion-label>
                                                    <ng-container *ngIf="user.selected_participant.event.translate(userLang, user.selected_participant.event.default_locale).tab_marketplace">
                                                        {{ user.selected_participant.event.translate(userLang, user.selected_participant.event.default_locale).tab_marketplace }}
                                                    </ng-container>
                                                    <ng-container *ngIf="!user.selected_participant.event.translate(userLang, user.selected_participant.event.default_locale).tab_marketplace">
                                                        {{ 'DASHBOARD_SUBMENU_MARKETPLACE' | trns }}
                                                    </ng-container>
                                                </ion-label>
                                            </ion-item>
                                        </ion-menu-toggle>

                                        <ion-menu-toggle *ngIf="!user.selected_participant?.event.is_homepage_content_matching && user.selected_participant?.event.matching_status"
                                                         auto-hide="false">
                                            <ion-item [routerDirection]="'root'"
                                                      [routerLink]="[plt.defaultLink + '/keywords/matching']"
                                                      routerLinkActive="active">
                                                <svg-icon [applyClass]="true"
                                                          src="/assets/icons/ico-lines.svg"
                                                          class="fill-menu-contrast"></svg-icon>
                                                <ion-label>{{ 'LEFT_MENU_KEYWORDS' | trns }}</ion-label>
                                            </ion-item>
                                        </ion-menu-toggle>
                                        <ion-menu-toggle *ngIf="!plt.tabMenu"
                                                         auto-hide="false">
                                            <ion-item [routerDirection]="'root'"
                                                      [routerLink]="[plt.defaultLink + '/home/attendees/list']"
                                                      routerLinkActive="active">
                                                <svg-icon [applyClass]="true"
                                                          src="/assets/icons/ico-attendees.svg"
                                                          class="fill-menu-contrast"></svg-icon>
                                                <ion-label *ngIf="!user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_ATTENDEES' | trns }}</ion-label>
                                                <ion-label *ngIf="user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_ATTENDEES_COMMUNITY' | trns }}</ion-label>
                                            </ion-item>
                                        </ion-menu-toggle>
                                        <ion-menu-toggle [menu]="plt.tabMenu ? 'end' : 'start'"
                                                         auto-hide="false">
                                            <ion-item [routerDirection]="'root'"
                                                      [routerLink]="[plt.defaultLink + '/home/detail/event']"
                                                      routerLinkActive="active">
                                                <svg-icon [applyClass]="true"
                                                          src="/assets/icons/ico-eventinfo.svg"
                                                          class="fill-menu-contrast"></svg-icon>

                                                <ion-label *ngIf="!user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_EVENT_INFO'  + themeConfig.themeSuffix | trns }}</ion-label>
                                                <ion-label *ngIf="user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_YOUR_NETWORK'  + themeConfig.themeSuffix | trns }}</ion-label>
                                            </ion-item>
                                        </ion-menu-toggle>

                                        <ion-menu-toggle *ngIf="env.showCredentialWallet && user.selected_participant?.event?.enable_credential_wallet"
                                                         [menu]="plt.tabMenu ? 'end' : 'start'"
                                                         auto-hide="false">
                                            <ion-item [routerDirection]="'root'"
                                                      [routerLink]="[plt.defaultLink + '/credentials-wallet']"
                                                      routerLinkActive="active">
                                                <svg-icon [applyClass]="true"
                                                          src="/assets/icons/ico-credentials-wallet.svg"
                                                          class="fill-menu-contrast">
                                                </svg-icon>

                                                <ion-label>{{ 'LEFT_MENU_CREDENTIALS_WALLET' | trns }}</ion-label>
                                            </ion-item>
                                        </ion-menu-toggle>

                                    </ng-container>

                                    <app-chat-groups-list *ngIf="!plt.tabMenu"
                                                          [silent]="viewType != 'messages'"
                                                          [ngClass]="{'hidden': viewType != 'messages'}">
                                    </app-chat-groups-list>

                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="loading || !user">
                                <!-- <ion-list-header>
                          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
                        </ion-list-header> -->
                                <ion-item>
                                    <ion-avatar slot="start"
                                                class="small-radius">
                                        <ion-skeleton-text animated></ion-skeleton-text>
                                    </ion-avatar>
                                    <ion-label>
                                        <h3>
                                            <ion-skeleton-text animated
                                                               style="width: 80%">
                                            </ion-skeleton-text>
                                        </h3>
                                        <p>
                                            <ion-skeleton-text animated
                                                               style="width: 50%">
                                            </ion-skeleton-text>
                                        </p>
                                    </ion-label>
                                </ion-item>
                                <hr class="full" />
                                <ion-item class="profile">
                                    <ion-avatar slot="start">
                                        <ion-skeleton-text animated></ion-skeleton-text>
                                    </ion-avatar>
                                    <ion-label>
                                        <h3>
                                            <ion-skeleton-text animated
                                                               style="width: 80%">
                                            </ion-skeleton-text>
                                        </h3>
                                        <p>
                                            <ion-skeleton-text animated
                                                               style="width: 50%">
                                            </ion-skeleton-text>
                                        </p>
                                    </ion-label>
                                </ion-item>
                                <ion-item>
                                    <ion-skeleton-text animated
                                                       style="width: 27px; height: 27px"
                                                       slot="start"></ion-skeleton-text>
                                    <ion-label>
                                        <h3>
                                            <ion-skeleton-text animated
                                                               style="width: 40%">
                                            </ion-skeleton-text>
                                        </h3>
                                    </ion-label>
                                </ion-item>
                                <ion-item>
                                    <ion-skeleton-text animated
                                                       style="width: 27px; height: 27px"
                                                       slot="start">
                                    </ion-skeleton-text>
                                    <ion-label>
                                        <h3>
                                            <ion-skeleton-text animated
                                                               style="width: 40%">
                                            </ion-skeleton-text>
                                        </h3>
                                    </ion-label>
                                </ion-item>
                                <ion-item *ngIf="user?.selected_participant?.event.ask_the_speaker">
                                    <ion-skeleton-text animated
                                                       style="width: 27px; height: 27px"
                                                       slot="start">
                                    </ion-skeleton-text>
                                    <ion-label>
                                        <h3>
                                            <ion-skeleton-text animated
                                                               style="width: 40%">
                                            </ion-skeleton-text>
                                        </h3>
                                    </ion-label>
                                </ion-item>
                                <hr>
                                <ion-item>
                                    <ion-skeleton-text animated
                                                       style="width: 27px; height: 27px"
                                                       slot="start">
                                    </ion-skeleton-text>
                                    <ion-label>
                                        <h3>
                                            <ion-skeleton-text animated
                                                               style="width: 40%">
                                            </ion-skeleton-text>
                                        </h3>
                                    </ion-label>
                                </ion-item>
                                <ion-item>
                                    <ion-skeleton-text animated
                                                       style="width: 27px; height: 27px"
                                                       slot="start">
                                    </ion-skeleton-text>
                                    <ion-label>
                                        <h3>
                                            <ion-skeleton-text animated
                                                               style="width: 40%">
                                            </ion-skeleton-text>
                                        </h3>
                                    </ion-label>
                                </ion-item>
                                <ion-item>
                                    <ion-skeleton-text animated
                                                       style="width: 27px; height: 27px"
                                                       slot="start">
                                    </ion-skeleton-text>
                                    <ion-label>
                                        <h3>
                                            <ion-skeleton-text animated
                                                               style="width: 40%">
                                            </ion-skeleton-text>
                                        </h3>
                                    </ion-label>
                                </ion-item>
                            </ng-container>
                        </ion-list>
                        <p *ngIf="!loggedIn || publicPage"
                           class="welcome-text"
                           [innerHtml]="'LEFT_MENU_WELCOME_TEXT' | trns">
                        </p>
                    </ion-content>
                    <ion-footer>
                        <ion-toolbar *ngIf="loggedIn && plt.isConnected && !publicPage">
                            <ion-button *ngIf="user?.selected_participant"
                                        id="menu-setting-button"
                                        (click)="closeMenu()"
                                        [routerDirection]="'root'"
                                        [routerLink]="[plt.defaultLink + '/setting']"
                                        routerLinkActive="active">
                                <svg-icon [applyClass]="true"
                                          src="/assets/icons/ico-settings.svg"
                                          class="fill-menu-contrast"></svg-icon>
                                <span>&nbsp;&nbsp;</span>
                                {{ 'LEFT_MENU_SETTING' | trns }}
                            </ion-button>
                            <ion-button *ngIf="!env.hideLogout"
                                        (click)="logout()"
                                        id="logout-button"
                                        class="logout">
                                {{ 'BUTTON_LOGOUT' | trns }}
                                <!-- <svg-icon [applyClass]="true"
                                          src="/assets/icons/ico-loggout.svg"
                                          class="fill-menu-contrast"></svg-icon> -->
                            </ion-button>
                        </ion-toolbar>
                        <ion-toolbar *ngIf="loggedIn && !plt.isConnected">
                            <div class="offline">
                                {{ 'LEFT_MENU_APP_IS_OFFLINE' | trns}}
                            </div>
                        </ion-toolbar>
                        <ion-toolbar *ngIf="!loggedIn || publicPage">
                            <div class="footer-links">
                                <ng-container *ngIf="userLang=='en'">
                                    <a href="https://www.congreet.com/en/imprint/"
                                       onclick="window.open(this.href, '_system');return false">{{ 'CUSTOM_IMPRESSUM' | trns }}</a> |
                                    <a href="https://www.congreet.com/en/privacy/"
                                       onclick="window.open(this.href, '_system');return false">{{ 'CUSTOM_DATENSCHUTZ' | trns }}</a>
                                </ng-container>
                                <ng-container *ngIf="userLang=='de'">
                                    <a href="https://www.congreet.com/de/impressum/"
                                       onclick="window.open(this.href, '_system');return false">{{ 'CUSTOM_IMPRESSUM' | trns }}</a> |
                                    <a href="https://www.congreet.com/de/datenschutz/"
                                       onclick="window.open(this.href, '_system');return false">{{ 'CUSTOM_DATENSCHUTZ' | trns }}</a>
                                </ng-container>
                            </div>
                        </ion-toolbar>
                    </ion-footer>
                </ng-container>

                <ng-template #templatePreview>
                    <ion-header class="ion-no-shadow ion-no-border"
                                [ngClass]="{'not-logged': false}">
                        <ion-toolbar>
                            <ion-title>
                                <!-- <img alt="congreet"
                                         src="/assets/icons/congreet-logo-text.svg"> -->
                                <!-- <img *ngIf="loggedIn"
                                         alt="congreet"
                                         src="/assets/icons/congreet-logo.svg"> -->
                            </ion-title>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <ion-list>

                            <div *ngIf="plt.isConnected && !plt.tabMenu"
                                 class="event-switch">
                                <span *ngIf="user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_COMMUNITY' | trns }} ({{events.length}})</span>
                                <span *ngIf="!user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_EVENTS' | trns }} ({{events.length}})</span>
                                <span class="fill-menu-contrast"
                                      *ngIf="!viewEventList">
                                    <ng-container *ngIf="user.selected_participant?.event?.is_community">{{'LEFT_MENU_MORE_COMMUNITY' | trns}}</ng-container>
                                    <ng-container *ngIf="!user.selected_participant?.event?.is_community">{{'LEFT_MENU_MORE_EVENTS' | trns}}</ng-container>
                                    <svg-icon [applyClass]="true"
                                              src="/assets/icons/ico-arrow.svg"
                                              class="collapsible">
                                    </svg-icon>
                                    <ion-badge *ngIf="plt.unreadOtherCount"
                                               class="notification">
                                        {{ plt.unreadOtherCount }}
                                    </ion-badge>
                                </span>
                                <span color="secondary"
                                      *ngIf="viewEventList">
                                    <ng-container *ngIf="user.selected_participant?.event?.is_community">{{'LEFT_MENU_LESS_COMMUNITY' | trns }}</ng-container>
                                    <ng-container *ngIf="!user.selected_participant?.event?.is_community">{{'LEFT_MENU_LESS_EVENTS' | trns }}</ng-container>
                                    <svg-icon [applyClass]="true"
                                              src="/assets/icons/ico-arrow.svg"
                                              class="collapsible opened">
                                    </svg-icon>
                                </span>
                            </div>

                            <ng-container>
                                <ion-menu-toggle [menu]="plt.tabMenu ? 'end' : 'start'"
                                                 auto-hide="false">
                                    <ion-item *ngIf="plt.isConnected">
                                        <img class="logo"
                                             width="32"
                                             height="32"
                                             img-logo
                                             [src]="user.selected_participant?.event?.logo_url">
                                        <ion-label>
                                            <strong class="event-name">{{ user.selected_participant?.event?.translate(userLang).name }}</strong><br />
                                            <ng-container *ngIf="!user.selected_participant?.event?.is_community">
                                                {{ user.selected_participant?.event?.date_starts_at | datex:'dateFormatShort' }}
                                                - {{ user.selected_participant?.event?.date_ends_at | datex }}
                                            </ng-container>
                                        </ion-label>
                                    </ion-item>

                                    <ion-item *ngIf="!plt.isConnected">
                                        <img class="logo"
                                             width="32"
                                             height="32"
                                             img-logo
                                             [src]="user.selected_participant?.event?.logo_url">
                                        <ion-label>
                                            <strong class="event-name">{{ user.selected_participant?.event?.translate(userLang).name }}</strong><br />
                                            <ng-container *ngIf="!user.selected_participant?.event?.is_community">
                                                {{ user.selected_participant?.event?.date_starts_at | datex:'dateFormatShort' }}
                                                - {{ user.selected_participant?.event?.date_ends_at | datex }}
                                            </ng-container>
                                        </ion-label>
                                    </ion-item>
                                </ion-menu-toggle>
                                <ng-container *ngIf="plt.isConnected">
                                    <hr class="full" />

                                    <div *ngIf="!plt.tabMenu"
                                         class="menu-switch">
                                        <div (click)="switchMenu('event')"
                                             [ngClass]="{'active': viewType == 'event'}">
                                            <ng-container *ngIf="!user.selected_participant?.event?.is_community">
                                                {{ 'LEFT_MENU_EVENT' | trns }}
                                            </ng-container>
                                            <ng-container *ngIf="user.selected_participant?.event?.is_community">
                                                {{ 'LEFT_MENU_COMMUNITY' | trns }}
                                            </ng-container>
                                        </div>
                                        <div (click)="switchMenu('messages')"
                                             [ngClass]="{'active': viewType == 'messages', 'unread': plt.unreadMessages || plt.unreadChatGroups}">
                                            {{ 'LEFT_MENU_MESSAGES' | trns }}
                                            <ion-badge *ngIf="plt.unreadMessages || plt.unreadChatGroups"
                                                       class="notification">
                                                {{ plt.unreadMessages + plt.unreadChatGroups }}
                                            </ion-badge>
                                        </div>
                                    </div>

                                    <ion-menu-toggle [menu]="plt.tabMenu ? 'end' : 'start'"
                                                     auto-hide="false">
                                        <ion-item class="profile">
                                            <ion-avatar slot="start">
                                                <img img-avatar
                                                     [src]="">
                                            </ion-avatar>
                                            <ion-label>
                                                <strong>
                                                    {{ user.selected_participant?.firstname }} {{ user.selected_participant?.lastname }}
                                                </strong>
                                                <br />{{ 'LEFT_MENU_PROFILE_MODIFY' | trns }}
                                            </ion-label>
                                        </ion-item>
                                    </ion-menu-toggle>

                                    <ion-menu-toggle *ngIf="!plt.tabMenu && false"
                                                     auto-hide="false">
                                        <ion-item>
                                            <svg-icon [applyClass]="true"
                                                      src="/assets/icons/ico-messages.svg"
                                                      class="fill-menu-contrast"></svg-icon>
                                            <ion-label>{{ 'LEFT_MENU_MESSAGES' | trns }}a</ion-label>
                                            <ion-badge class="notification">
                                                3
                                            </ion-badge>
                                        </ion-item>
                                    </ion-menu-toggle>
                                    <ion-menu-toggle *ngIf="!plt.tabMenu"
                                                     auto-hide="false">
                                        <ion-item>
                                            <svg-icon [applyClass]="true"
                                                      src="/assets/icons/ico-appointments.svg"
                                                      class="fill-menu-contrast"></svg-icon>
                                            <ion-label>{{ 'LEFT_MENU_APPOINTMENTS' | trns }}</ion-label>
                                            <ion-badge class="notification">
                                                1
                                            </ion-badge>
                                        </ion-item>
                                    </ion-menu-toggle>
                                    <ion-menu-toggle [menu]="plt.tabMenu ? 'end' : 'start'"
                                                     auto-hide="false">
                                        <ion-item>
                                            <svg-icon [applyClass]="true"
                                                      src="/assets/icons/ico-attendee.svg"
                                                      class="fill-menu-contrast"></svg-icon>
                                            <ion-label>{{ 'LEFT_MENU_ASK_THE_SPEAKER' | trns }}</ion-label>
                                        </ion-item>
                                    </ion-menu-toggle>
                                    <hr />
                                    <ion-menu-toggle *ngIf="!plt.tabMenu"
                                                     auto-hide="false">
                                        <ion-item [ngClass]="{'active': (activePage === 'previewinfomatching')}">
                                            <svg-icon [applyClass]="true"
                                                      src="/assets/icons/ico-dashboard.svg"
                                                      class="fill-menu-contrast"></svg-icon>
                                            <ion-label *ngIf="user.selected_participant?.event?.is_homepage_content_stream">{{ 'LEFT_MENU_STREAM' | trns }}</ion-label>
                                            <ion-label *ngIf="!user.selected_participant?.event?.is_homepage_content_stream">{{ 'LEFT_MENU_DASHBOARD' | trns }}</ion-label>
                                        </ion-item>
                                    </ion-menu-toggle>
                                    <ion-menu-toggle *ngIf="!user.selected_participant?.event.is_homepage_content_matching"
                                                     auto-hide="false">
                                        <ion-item>
                                            <svg-icon [applyClass]="true"
                                                      src="/assets/icons/ico-lines.svg"
                                                      class="fill-menu-contrast"></svg-icon>
                                            <ion-label>{{ 'LEFT_MENU_KEYWORDS' | trns }}</ion-label>
                                        </ion-item>
                                    </ion-menu-toggle>
                                    <ion-menu-toggle *ngIf="!plt.tabMenu"
                                                     auto-hide="false">
                                        <ion-item [ngClass]="{'active': (activePage === 'previewattendeelist')}">
                                            <svg-icon [applyClass]="true"
                                                      src="/assets/icons/ico-attendees.svg"
                                                      class="fill-menu-contrast"></svg-icon>
                                            <ion-label *ngIf="!user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_ATTENDEES' | trns }}</ion-label>
                                            <ion-label *ngIf="user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_ATTENDEES_COMMUNITY' | trns }}</ion-label>
                                        </ion-item>
                                    </ion-menu-toggle>
                                    <ion-menu-toggle [menu]="plt.tabMenu ? 'end' : 'start'"
                                                     auto-hide="false">
                                        <ion-item [ngClass]="{'active': (activePage === 'previewdetailevent')}">
                                            <svg-icon [applyClass]="true"
                                                      src="/assets/icons/ico-eventinfo.svg"
                                                      class="fill-menu-contrast"></svg-icon>
                                            <ion-label *ngIf="!user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_EVENT_INFO' + themeConfig.themeSuffix | trns }}</ion-label>
                                            <ion-label *ngIf="user.selected_participant?.event?.is_community">{{ 'LEFT_MENU_YOUR_NETWORK' + themeConfig.themeSuffix | trns }}</ion-label>
                                        </ion-item>
                                    </ion-menu-toggle>

                                    <ion-menu-toggle auto-hide="false">
                                        <ion-item>
                                            <svg-icon [applyClass]="true"
                                                      src="/assets/icons/ico-key.svg"
                                                      class="fill-menu-contrast"></svg-icon>
                                            <ion-label>{{ 'LEFT_MENU_EVENT_KEY' | trns }}</ion-label>
                                        </ion-item>
                                    </ion-menu-toggle>

                                </ng-container>
                            </ng-container>

                        </ion-list>
                    </ion-content>
                    <ion-footer>
                        <ion-toolbar *ngIf="plt.isConnected">
                            <ion-button>
                                <svg-icon [applyClass]="true"
                                          src="/assets/icons/ico-settings.svg"
                                          class="fill-menu-contrast">
                                </svg-icon>
                                <span>&nbsp;&nbsp;</span>
                                {{ 'LEFT_MENU_SETTING' | trns }}
                            </ion-button>
                            <ion-button *ngIf="!env.hideLogout"
                                        class="logout">
                                {{ 'BUTTON_LOGOUT' | translate }}
                                <!-- <svg-icon [applyClass]="true"
                                              src="/assets/icons/ico-loggout.svg"
                                              class="fill-menu-contrast"></svg-icon> -->
                            </ion-button>
                        </ion-toolbar>
                        <ion-toolbar *ngIf="!plt.isConnected">
                            <div class="offline">
                                {{ 'LEFT_MENU_APP_IS_OFFLINE' | trns}}
                            </div>
                        </ion-toolbar>
                    </ion-footer>
                </ng-template>

            </ion-menu>

            <ion-router-outlet id="main-content"
                               [swipeGesture]="false"
                               [animated]="animateRoute"
                               [ngClass]="{'not-logged': !isPreview && (!loggedIn || publicPage)}">
            </ion-router-outlet>
        </ion-split-pane>

        <translation-admin *ngIf="plt.translationMode && !isPreview"></translation-admin>
        <!-- NOTE[jg] we have disabled left menu -->
        <!-- <ion-menu *ngIf="plt.tabMenu && loggedIn"
                  side="start"
                  type="push"
                  menuId="second">
            <ion-header class="ion-no-shadow ion-no-border"
                        [ngClass]="{'not-logged': true || !loggedIn}">
                <ion-toolbar>
                    <ion-title>
                    </ion-title>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-list *ngIf="loggedIn">
                    <div *ngIf="loggedIn && plt.isConnected"
                         (click)="viewEventList=!viewEventList"
                         class="event-switch">
                        {{ 'LEFT_MENU_EVENTS' | trns }} ({{events.length}})
                    </div>
                    <ion-menu-toggle menu="start"
                                     *ngFor="let event of events">
                        <ion-item (click)="selectEvent(event)">
                            <img class="logo"
                                 width="32"
                                 height="32"
                                 img-logo
                                 [src]="event.logo_url">
                            <ion-label>
                                <strong>{{ event.translate(userLang).name }}</strong><br />
                                {{ event.date_starts_at | datex:'dateFormatShort' }}
                                - {{ event.date_ends_at | datex }}
                            </ion-label>
                            <ion-badge *ngIf="plt.unreadOther && plt.unreadOther[event.id]"
                                       class="notification">
                                {{ plt.unreadOther[event.id] }}
                            </ion-badge>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>
            </ion-content>
        </ion-menu> -->
    </ng-container>

    <ng-container *ngIf="showiOSInstallMessage">
        <div class="ios-pwa-install"
             (click)="showiOSInstallMessage = false">
            <p [innerHTML]="'PWA_IOS_INSTALL_PROMPT' | trns"></p>
            <div class="arrow"></div>
        </div>
    </ng-container>
</ion-app>