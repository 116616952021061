import { Component, ElementRef, EventEmitter, OnInit, Input, Output, ViewChild, APP_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';

// ionic
import { IonicSafeString, ModalController, PopoverController } from '@ionic/angular';
import { Events } from '../../helpers/events';

// rxjs
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

// components
import { MarketplaceAddComponent } from "../marketplace-add/marketplace-add.component";
import { MarketplaceActionMenu } from "../marketplace-action-menu/marketplace-action-menu.component";
import { MarketplacePurchaseComponent } from "../marketplace-purchase/marketplace-purchase.component";
import { MarketplaceDetailOrdersComponent } from "../marketplace-detail-orders/marketplace-detail-orders.component";

// other libraries
// import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { TranslateService } from "@ngx-translate/core";

// components
import { MarketplaceAdDetailComponent } from "../marketplace-ad-detail/marketplace-ad-detail.component";
// pipes
import { LinkifyPipe } from "../../pipes/string/linkify";

// services
import { MarketplaceService } from "../../services/marketplace.service";
import { OverlayService } from "../../services/overlay.service";
import { PlatformService } from "../../services/platform.service";
import { UserService } from "../../services/user.service";
import { ParticipantService } from "../../services/participant.service";
import { RouterExtService } from "../../services/router-ext.service";

// models
import { Event } from "../../models/event";
import { Marketplace } from "../../models/marketplace";
import { MarketplacePost } from "../../models/marketplace-post";
import { MarketplaceComment } from "../../models/marketplace-comment";
import { MarketplaceLike } from "../../models/marketplace-like";
import { User } from "../../models/user";
import { isExternalLink } from 'src/app/helpers/url';
import { isVideoLink } from 'src/app/services/helper';

@Component({
    selector: 'marketplace-detail',
    templateUrl: './marketplace-detail.component.html',
    styleUrls: ['./marketplace-detail.component.scss'],
})
export class MarketplaceDetailComponent implements OnInit {

    /**
     * selected marketplace post
     *
     * @type MarketplacePost
     */
    @Input() marketplacePost: MarketplacePost;

    /**
     * selected newsfeed ID
     *
     * @type {number}
     */
    @Input() marketplacePostId: number;

    /**
     * selected marketplace
     *
     * @type {Marketplace}
     */
    @Input() marketplace: Marketplace;

    /**
     * show form in modal
     *
     * @type boolean
     */
    @Input() modal: boolean = false;

    /**
     * default locale
     *
     * @type string
     */
    @Input() defaultLocale: string = 'de';

    @Output() onDelete: EventEmitter<Number> = new EventEmitter();

    @Input() event: Event;

    /**
     * marketplacePostBox
     *
     * @type ViewChild
     */
    @ViewChild('marketplacePostBox') marketplacePostBox: ElementRef;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * current user
     *
     * @type {User}
     */
    @Input() user: User;

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    /**
     * initial loading state, we should have injected marketplacePost model by default
     *
     * @type {boolean}
     */
    public loading: boolean = false;

    /**
     * validate state
     *
     * @type {boolean}
     */
    public validate: boolean = false;

    /**
     * Validation errors
     *
     * @type {object}
     */
    public validationErrors: object = {};

    /**
     * validation errors state
     *
     * @type {boolean}
     */
    public hasValidationErrors: boolean = false;

    /**
     * show edit mode
     *
     * @type {boolean}
     */
    public edit: boolean = false;

    /**
     * category name for detail label
     *
     * @type {string}
     */
    public categoryName: string = '';

    postMaxLength = 350;

    public submit: boolean = false;
    public submitLike: boolean = false;

    constructor(
        @Inject(APP_ID) private appId: string,
        private router: Router,
        public appEvents: Events,
        public userService: UserService,
        public translate: TranslateService,
        public marketplaceService: MarketplaceService,
        public plt: PlatformService,
        public overlayService: OverlayService,
        public modalController: ModalController,
        public popoverController: PopoverController,
        public routerExtService: RouterExtService,
        public participantService: ParticipantService
    ) {
        this.userLang = this.translate.currentLang;
    }

    ngOnInit() {

        // get translations for category label
        this.translateCategoryName();

        // if marketplace post is not injected
        if (!this.marketplacePost && this.marketplacePostId) {
            // load it from service
            this.loading = true
            this.marketplaceService.getPost(+this.marketplace.id, +this.marketplacePostId).subscribe(
                (marketplacePost: MarketplacePost) => {
                    this.loading = false;
                    this.marketplacePost = new MarketplacePost(marketplacePost);
                    // open comments
                    this.marketplacePost.commentsVisible = true;

                    if (!this.marketplace.categories && this.marketplacePost.marketplace) {
                        this.marketplace = new Marketplace(this.marketplacePost.marketplace);
                    }

                    // get category label
                    this.translateCategoryName();
                });
        }
    }

    public deleteMarketplace(id: number) {
        this.loading = true;
        this.marketplaceService.deletePost(+this.marketplace.id, id).subscribe(
            (success) => {
                this.overlayService.showSuccess(success.message);
                this.loading = false;

                // close modal if used
                if (this.modal) {
                    this.modalController.dismiss({
                        deleteId: id,
                        action: 'close'
                    });
                } else {
                    this.onDelete.emit(id);
                }
            },
            (error) => {
                this.overlayService.showConnectionProblems(error);
                this.loading = false;
            }
        );
    }

    /**
     * delete post confirmation
     *
     * @param id
     *
     * @return boolean
     */
    public async deleteMarketplaceConfirmation(id: number) {
        this.overlayService.showConfirm(
            this.translate.instant('MARKETPLACE_POST_DELETE_CONFIRMATION'),
            this.translate.instant('MARKETPLACE_POST_DELETE_CONFIRMATION_HINT'),
            () => { this.deleteMarketplace(id) },
            () => { },
            this.translate.instant('MARKETPLACE_POST_DELETE_CONFIRMATION_BTN')
        );

        return false;
    }

    /**
     * edit uncorfimed marketplace post
     *
     * @param Marketplace
     *
     * @return boolean
     */
    public async editUnconfirmedModal(marketplacePost: MarketplacePost) {
        this.overlayService.showConfirm(
            this.translate.instant('MARKETPLACE_POST_EDIT_UNCONFIRMED_MODAL'),
            marketplacePost.marketplace.post_visibility == 'keywords-limit' ?
                this.translate.instant('MARKETPLACE_POST_EDIT_UNCONFIRMED_LIMITED_MODAL_HINT', { count: marketplacePost.marketplace.keywords_limit }) :
                this.translate.instant('MARKETPLACE_POST_EDIT_UNCONFIRMED_MODAL_HINT'),
            () => { this.editMarketplacePost(marketplacePost) },
            () => { },
            this.translate.instant('MARKETPLACE_POST_EDIT_UNCONFIRMED_MODAL_EDIT_BTN'),
            this.translate.instant('MARKETPLACE_POST_EDIT_UNCONFIRMED_MODAL_CANCEL_BTN')
        );

        return false;
    }

    /**
     * edit marketplacePost
     *
     * @param MarketplacePost
     *
     * @return void
     */
    public editMarketplacePost(marketplacePost: MarketplacePost) {
        this.editMarketplacePostModal(marketplacePost)
        // NOTE[jg] for now we use only modal solution...
        // if (this.plt.sizeSm) {
        //     this.editMarketplacePostModal(marketplacePost);
        // } else {
        //     // use output to emit edit
        //     this.edit = true
        // }
    }

    /**
     * open modal with marketplacePost add form
     * @return void
     */
    public async editMarketplacePostModal(marketplacePost: MarketplacePost = null) {

        this.routerExtService.softNavigate();

        const modal = await this.modalController.create({
            component: MarketplaceAddComponent,
            cssClass: 'newsfeed-form',
            componentProps: {
                modal: true,
                event: this.event,
                displayBigTextarea: true,
                marketplace: this.marketplace,
                marketplacePost: new MarketplacePost(marketplacePost)
            }
        });

        modal.onWillDismiss().then((data) => {

            if (data.data?.marketplacePost) {
                this.marketplacePost = data.data.marketplacePost;
                this.translateCategoryName();
            }

            if (data.data?.action == 'close' || data.role == 'backdrop') {
                this.routerExtService.softBack();
            }

        });

        return await modal.present();
    }

    /**
     * update current marketplace on edit
     *
     * @return void
     */
    public marketplacePostSended(event?: MarketplacePost) {
        this.marketplacePost = event;
        this.edit = false;
        this.translateCategoryName();
    }

    /**
     * show marketplacePost category name
     *
     * @return void
     */
    public translateCategoryName() {
        // get translations for category label
        if (this.marketplace && this.marketplace.categories) {
            this.marketplace.categories.forEach((category) => {
                if (this.marketplacePost && category.id == this.marketplacePost.marketplace_category_id) {
                    this.categoryName = category.translate(this.userLang, this.defaultLocale).name;
                }
            });
        }
    }

    /**
     * show marketplacePost comments
     *
     * @return void
     */
    public showComments() {
        // dont display comment where there is no comment and posting comments is disabled
        if (!this.marketplacePost.comments.length && !this.marketplace.allow_comment) {
            return false;
        }

        // on mobile we will use modal to show all details about comments
        if (!this.modal && this.plt.sizeSm) {
            this.showModalDetail();
        } else {
            this.marketplacePost.commentsVisible = !this.marketplacePost.commentsVisible
        }
    }

    /**
     * cancel comments edit
     *
     * @return void
     */
    public cancelCommentEdit() {
        this.marketplacePost.editComment = new MarketplaceComment;
    }

    /**
     * save comments for marketplacePost
     *
     * @return void
     */
    public postComment(marketplace) {
        this.validationErrors = {};
        this.validate = true;
        this.submit = true;

        this.marketplaceService.postComment(marketplace).subscribe(
            (success) => {
                this.overlayService.showSuccess(success.message);
                marketplace.comments = success.comments.map(comment => new MarketplaceComment(comment));

                this.validate = false;

                // show last comment if new one
                if (this.modal && !marketplace.editComment.id) {
                    this.scrollPageToBottom();
                }
                //this.scrollPageToBottom();
                marketplace.editComment = new MarketplaceComment;
                this.submit = false;
            },
            (error) => {
                const data = error.error;

                if (data.fields) {
                    for (const field in data.fields) {
                        if (data.fields.hasOwnProperty(field)) {
                            this.hasValidationErrors = true;
                            this.validationErrors[field] = data.fields[field].join(' ');
                        }
                    }
                }

                this.overlayService.showError(data.message);
                this.submit = false;
            }
        );
    }

    /**
     * toggle like for marketplace
     *
     * @param Marketplace
     *
     * @return void
     */
    public toggleLike(marketplacePost) {
        if (this.submitLike) {
            return;
        }

        this.validationErrors = {};
        this.validate = true;
        this.submitLike = true;

        this.marketplaceService.togglePostLike(marketplacePost).subscribe(
            (success) => {
                this.overlayService.showSuccess(success.message);
                this.marketplacePost.likes = success.likes.map(like => new MarketplaceLike(like));
                this.marketplacePost.number_of_likes = success.number_of_likes;
                this.validate = false;
                this.submitLike = false;
            },
            (error) => {
                this.overlayService.showError(error.message);
                this.submitLike = false;
            }
        );
    }

    /**
     * check if marketplace post is liked
     *
     * @param MarketplacePost
     *
     * @return number
     */
    public isLiked(marketplacePost) {
        return marketplacePost.likes.filter((item) => {
            return this.user && item.participant && item.participant.user_id == this.user.id;
        }).length;
    }

    /**
     * open edit for comment
     *
     * @param Marketplace
     * @param MarketplaceComment
     *
     * @return void
     */
    public editComment(marketplace, marketplacePostComment) {
        this.validationErrors = [];
        this.hasValidationErrors = false;
        marketplace.editComment = new MarketplaceComment(marketplacePostComment);
    }

    /**
     * delete comment
     *
     * @param Marketplace
     * @param id
     *
     * @return void
     */
    public deleteComment(marketplacePost, id: number) {
        this.loading = true;
        this.marketplaceService.deleteComment(marketplacePost, id).subscribe(
            (success) => {
                // cancel editing comment when it is deleted
                if (this.marketplacePost.editComment && this.marketplacePost.editComment.id == id) {
                    this.cancelCommentEdit();
                }

                this.overlayService.showSuccess(success.message);
                marketplacePost.comments = marketplacePost.comments.filter((item) => { return item.id != id });
                this.loading = false;
            },
            (error) => {
                this.overlayService.showConnectionProblems(error);
                this.loading = false;
            }
        );
    }

    /**
     * confirm for deleting comment
     *
     * @return void
     */
    public async deleteMarketplacePostCommentConfirmation(marketplace, id: number) {
        this.overlayService.showConfirm(
            this.translate.instant('MARKETPLACE_POST_DELETE_COMMENT_CONFIRMATION'),
            this.translate.instant('MARKETPLACE_POST_DELETE_COMMENT_CONFIRMATION_HINT'),
            () => { this.deleteComment(marketplace, id) },
            () => { },
            this.translate.instant('MARKETPLACE_POST_DELETE_COMMENT_CONFIRMATION_BTN')
        );
    }

    /**
     * open marketplacePost detail with comments in new modal
     *
     * @return modal
     */
    public async showModalDetail() {

        // open modal only if we see this marketplace in list
        if (!this.modal && this.plt.sizeSm) {

            this.routerExtService.softNavigate();

            // open comments
            this.marketplacePost.commentsVisible = true;

            const detail = await this.modalController.create({
                component: MarketplaceDetailComponent,
                cssClass: 'newsfeed-detail',
                componentProps: {
                    event: this.event,
                    user: this.user,
                    marketplacePost: new MarketplacePost({ ...this.marketplacePost }),
                    marketplace: this.marketplace,
                    modal: true
                }
            });

            detail.onWillDismiss().then((data) => {
                if (data.data) {
                    if (data.data.keywords) {
                        // TODO[jg] refresh details about comments and so...
                    }
                    if (data.data.action && data.data.action == 'close') {
                        let link = this.plt.defaultLink + this.routerExtService.currentUrl.split("?")[0].replace('/tabs', '');
                        if (this.plt.tabMenu && !this.routerExtService.currentUrl.includes('/tabs')) {
                            this.router.navigateByUrl(link);
                        } else {
                            this.routerExtService.softNavigate(link);
                        }
                    }

                    // delete event from opened modal
                    if (data.data.deleteId) {
                        this.onDelete.emit(data.data.deleteId);
                    }
                }
            });

            return await detail.present();
        }
    }

    /**
     * go to participants detail page by marketplacePost
     *
     * @param marketplacePost
     *
     * @return void
     */
    public async showAttendeeDetail(marketplacePost: MarketplacePost) {
        if (marketplacePost.getRealParticipant()) {
            if (this.modal) {
                this.modalController.dismiss();
            }
            this.appEvents.publish('attendee:show', marketplacePost.getRealParticipant().id);
        }
    }

    /**
     * go to participants detail page by marketplacePost comment
     *
     * @param comment
     *
     * @return void
     */
    public async showAttendeeDetailByComment(comment: MarketplaceComment) {
        if (comment.getRealParticipant()) {
            if (this.modal) {
                this.modalController.dismiss();
            }
            this.appEvents.publish('attendee:show', comment.getRealParticipant().id);
        }
    }

    /**
     * close modal window
     *
     * @return void
     */
    public backClicked() {
        this.modalController.dismiss({ action: 'close' });
    }

    /**
     * close modal window
     *
     */
    public marketplacePostEditationCanceled(event?: MarketplacePost) {
        this.marketplacePost = event;
        this.edit = false;
    }

    /**
     * translate subject for email reference
     *
     */
    public addReferenceSubject(string) {
        return this.translate.instant('MARKETPLACE_POST_REFERENCE_SUBJECT_PREFIX') + encodeURI(string);
    }

    /**
     * scroll chat to bottom
     *
     * @return void
     */
    public scrollPageToBottom(): void {
        try {
            setTimeout(() => {
                // scroll whole page to bottom, used for keyboard to focus textarea
                document.querySelector('.modal').scrollTo(0, document.querySelector('.modal').scrollHeight);
                // NOTE[jg] this is now working.. ion-item is not element, but ionic component...
                //this.marketplacePostBox.nativeElement.scrollTo(0, this.marketplacePostBox.nativeElement.scrollHeight);
            }, 150);
        } catch (err) {
            return;
        }
    }

    /**
     * rediret to chat with selected attendee
     *
     */
    public goToMessages(post: MarketplacePost) {
        // check if participant can start conversation
        this.participantService.canStartConversation(post.getRealParticipant().id).subscribe(
            (result) => {
                // participant can join conversation
                if (result) {
                    // store linked items for next messaging
                    this.participantService.linkedItem = {
                        id: post.id,
                        marketplace_id: post.marketplace_id,
                        participant_id: post.getRealParticipant().id,
                        text: post.name,
                        type: 'marketplace-post',
                    };
                    this.router.navigate([this.plt.defaultLink + '/messages/direct', post.getRealParticipant().id], {
                        replaceUrl: true,
                        queryParams: {
                            linkedItem: JSON.stringify({
                                id: post.id,
                                marketplace_id: post.marketplace_id,
                                participant_id: post.getRealParticipant().id,
                                text: post.name,
                                type: 'marketplace-post',
                            })
                        }
                    });
                }
                // participant can not join conversation
                else {
                    this.overlayService.showError(this.translate.instant('CONVERSATION_LIMIT_REACHED'));
                }
            }
        );
    }

    /**
     * open design modal
     *
     * @return void
     */
    public async showDesignModal() {

        // update url
        if (!this.plt.marketplacePostOpened) {
            let link = this.routerExtService.currentUrl + '?post_id=' + this.marketplacePost.id.toString();
            // add event id also
            link += '&marketplace_id=' + this.marketplacePost.marketplace_id.toString();
            link += '&event_id=' + this.event.id.toString();
            this.routerExtService.softNavigate(link);
        } else {
            this.routerExtService.softNavigate();
        }

        const modal = await this.modalController.create({
            component: MarketplaceAdDetailComponent,
            cssClass: 'marketplace-details-modal',
            componentProps: {
                isPreview: true,
                marketplace: this.marketplace,
                marketplacePost: this.marketplacePost,
                categoryName: this.categoryName
            }
        });

        modal.onWillDismiss().then((data) => {

            if (data.data?.marketplacePost) {
                this.marketplacePost = data.data.marketplacePost;
            }

            if (data.data?.action == 'close' || data.role == 'backdrop') {
                let link = this.plt.defaultLink + this.routerExtService.currentUrl.split("?")[0].replace('/tabs', '');
                if (this.plt.tabMenu && !this.routerExtService.currentUrl.includes('/tabs')) {
                    this.router.navigateByUrl(link);
                } else {
                    this.routerExtService.softNavigate(link);
                }
            } else {
                // do nothing for back navigation from browser..
                // just close modal and update history
                this.routerExtService.popHistory();
            }
        });

        this.marketplaceService.visitPost(this.marketplace.id, this.marketplacePost.id).subscribe();

        return await modal.present();
    }

    /**
     * show popover with action menu
     * @return void
     */
    async showActionMenu(ev: any, post: MarketplacePost) {
        const popover = await this.popoverController.create({
            component: MarketplaceActionMenu,
            event: ev,
            componentProps: {
                post: post
            }
        });

        popover.onWillDismiss().then((data) => {
            if (data && data.data) {
                if (data.data.action == 'mute' && data.data.item) {
                    this.loading = true;
                    this.user.blacklist.marketplace_participants.push(data.data.item.participant_id);

                    this.userService.updateBlacklist(this.user.blacklist).subscribe(
                        (success) => {
                            // update newsfeed list
                            this.appEvents.publish('marketplace:refresh');
                            this.loading = false;
                        },
                        (error) => {
                            this.overlayService.showConnectionProblems(error);
                            this.loading = false;
                        }
                    );
                }
                else if (data.data.action == 'unmute' && data.data.item) {
                    this.loading = true;
                    this.user.blacklist.marketplace_participants = this.user.blacklist.marketplace_participants.filter(item => item != data.data.item.participant_id);

                    this.userService.updateBlacklist(this.user.blacklist).subscribe(
                        (success) => {
                            // update newsfeed list
                            this.appEvents.publish('marketplace:refresh');
                            this.loading = false;
                        },
                        (error) => {
                            this.overlayService.showConnectionProblems(error);
                            this.loading = false;
                        }
                    );
                }
            }
        });

        await popover.present();
    }

    public showMore(post: MarketplacePost) {
        post.longTextVisible = true;
        this.marketplaceService.visitPost(post.marketplace_id, post.id).subscribe();
    }

    public purchase() {
        if (this.marketplacePost.digital_purchase_link && this.marketplacePost.coin_price <= this.user.selected_participant.current_coins) {
            // show confirm for purchase
            this.overlayService.showConfirm(
                this.translate.instant('MARKETPLACE_POST_COIN_PRICE_BUY_TITLE'),
                this.translate.instant('MARKETPLACE_POST_COIN_PRICE_BUY_DIGITAL_HINT'),
                () => {
                    // call saving method
                    this.marketplaceService.purchase(this.marketplacePost).subscribe(
                        (success: { current_coins: number }) => {

                            this.user.selected_participant.current_coins = success.current_coins;

                            const linkifyPipe = new LinkifyPipe(this.appId, this.plt);

                            // show modal with link + thank you
                            this.overlayService.showConfirmInfo(
                                this.translate.instant('MARKETPLACE_POST_COIN_PRICE_BUY_THANK_YOU_TITLE'),
                                new IonicSafeString(this.translate.instant('MARKETPLACE_POST_COIN_PRICE_BUY_THANK_YOU_HINT', { digital_purchase_link: linkifyPipe.transform(this.marketplacePost.digital_purchase_link, '_blank') })),
                                () => {

                                });
                        },
                        (error) => {
                            this.overlayService.showError(this.translate.instant('MARKETPLACE_POST_COIN_PRICE_BUY_NO_COINS', { coin_name: this.event.coin_name }));
                        });
                },
                () => {
                    // just close payment
                },
                this.translate.instant('BUY'),
                this.translate.instant('BUTTON_CANCEL')
            );
        } else {
            if (this.marketplacePost.coin_price > this.user.selected_participant.current_coins) {
                this.overlayService.showError(this.translate.instant('MARKETPLACE_POST_COIN_PRICE_BUY_NO_COINS', { coin_name: this.event.coin_name }));
            } else {
                // show modal with quantity selection + delivery address
                this.showPurchaseModal();
            }
        }
    }

    /**
     * open purchase modal
     *
     * @return void
     */
    public async showPurchaseModal() {

        this.routerExtService.softNavigate();

        const modal = await this.modalController.create({
            component: MarketplacePurchaseComponent,
            cssClass: 'marketplace-details-modal',
            componentProps: {
                user: this.user,
                marketplace: this.marketplace,
                marketplacePost: this.marketplacePost,
            }
        });

        modal.onWillDismiss().then((data) => {
            if (data.data.action && data.data.action == 'close') {
                this.routerExtService.softBack();
            }
        });

        return await modal.present();
    }

    /**
     * open orders modal
     *
     * @return void
     */
    public async showOrdersModal() {

        const modal = await this.modalController.create({
            component: MarketplaceDetailOrdersComponent,
            cssClass: 'marketplace-details-modal',
            componentProps: {
                user: this.user,
                marketplace: this.marketplace,
                marketplacePost: this.marketplacePost,
            }
        });

        modal.onWillDismiss().then((data) => {

        });

        return await modal.present();
    }

    openMail(marketplacePost) {
        window.open('mailto:' + marketplacePost.email + '?subject=' + this.addReferenceSubject(marketplacePost.name));
    }

    openLink(url: string, type: string) {

        if (isVideoLink(url)) {
            this.appEvents.publish('video:show', url);
        } else {
            if (!isExternalLink(url)) {
                if (this.modal) {
                    this.backClicked();
                }
                let urlPath = new URL(url);
                this.router.navigateByUrl(this.plt.defaultLink + urlPath.pathname.replace(this.plt.defaultLink, '') + urlPath.search);
            } else {

                if (this.plt.is('cordova')) {
                    // cordova is not working with third options attribute
                    window.open(url, type == '_blank' ? '_system' : '');
                } else {
                    window.open(url, type == '_blank' ? '_system' : '', 'noreferrer');
                }
            }
        }
    }
}
