export class Translation {
    id: number;
    key: string;
    text: string;
    temp: string;
    locale: string;
    source: string;
    user_id: number;

    edit: boolean = false;
    match: boolean = true;
    loading: boolean = false;
    // translation in other languages
    other: {} = {};

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: Translation) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
