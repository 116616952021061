// group-name.pipe.ts
import { ElementRef, ChangeDetectorRef, Pipe, NgZone } from "@angular/core";
import { TranslateService, TranslatePipe, LangChangeEvent, TranslationChangeEvent } from "@ngx-translate/core";

import { TrnsService } from '../../services/trns.service';

import { isDefined, equals } from '../../helpers/util';

/**
 *
 */
@Pipe({
    name: 'trns',
    pure: false
})
export class TrnsPipe extends TranslatePipe {

    elm;

    constructor(
        public cd: ChangeDetectorRef,
        private zone: NgZone,
        private trns: TranslateService,
        private ref: ChangeDetectorRef,
        private trnsService: TrnsService,
        public el: ElementRef
    ) {
        super(trns, ref);
    }

    /**
     *
     * @param value
     * @returns {string}
     */
    transform(query: string, ...args: any[]): any {
        if (!query || !query.length) {
            return query;
        }

        // if we ask another time for the same key, return the last value
        if (equals(query, this.lastKey) && equals(args, this.lastParams)) {
            if (this.trnsService.adminToken && this.el.nativeElement.nodeName == '#text') {
                return '';
            } else {
                return this.value;
            }
        }

        let interpolateParams: Object;

        if (isDefined(args[0]) && args.length) {
            if (typeof args[0] === 'string' && args[0].length) {
                // we accept objects written in the template such as {n:1}, {'n':1}, {n:'v'}
                // which is why we might need to change it to real JSON objects such as {"n":1} or {"n":"v"}
                let validArgs: string = args[0]
                    .replace(/(\')?([a-zA-Z0-9_]+)(\')?(\s)?:/g, '"$2":')
                    .replace(/:(\s)?(\')(.*?)(\')/g, ':"$3"');
                try {
                    interpolateParams = JSON.parse(validArgs);
                } catch (e) {
                    throw new SyntaxError(`Wrong parameter in TranslatePipe. Expected a valid Object, received: ${args[0]}`);
                }
            } else if (typeof args[0] === 'object' && !Array.isArray(args[0])) {
                interpolateParams = args[0];
            }
        }

        // store the query, in case it changes
        this.lastKey = query;

        // store the params, in case they change
        this.lastParams = args;

        // set the value
        this.updateValue(query, interpolateParams);

        // if there is a subscription to onLangChange, clean it
        this.dispose();

        // subscribe to onTranslationChange event, in case the translations change
        if (!this.onTranslationChange) {
            this.onTranslationChange = this.trns.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
                if (this.lastKey && event.lang === this.trns.currentLang) {
                    this.lastKey = null;
                    this.value = null;
                    this.updateValue(query, interpolateParams, event.translations);
                    if (localStorage.getItem('admin-token')) {
                        this.addTranslationTool(query);
                    }
                }
            });
        }

        // subscribe to onLangChange event, in case the language changes
        if (!this.onLangChange) {
            this.onLangChange = this.trns.onLangChange.subscribe((event: LangChangeEvent) => {
                if (this.lastKey) {
                    this.lastKey = null; // we want to make sure it doesn't return the same value until it's been updated
                    this.updateValue(query, interpolateParams, event.translations);
                    if (localStorage.getItem('admin-token')) {
                        this.addTranslationTool(query);
                    }
                }
            });
        }

        // subscribe to onDefaultLangChange event, in case the default language changes
        if (!this.onDefaultLangChange) {
            this.onDefaultLangChange = this.trns.onDefaultLangChange.subscribe(() => {
                if (this.lastKey) {
                    this.lastKey = null; // we want to make sure it doesn't return the same value until it's been updated
                    this.updateValue(query, interpolateParams);
                    if (localStorage.getItem('admin-token')) {
                        this.addTranslationTool(query);
                    }
                }
            });
        }

        if (!localStorage.getItem('admin-token')) {
            return this.value;
        } else {
            this.addTranslationTool(query);
            return this.value;
        }
    }

    addTranslationTool(query) {
        if (this.el.nativeElement.nodeName != '#text') {
            setTimeout(() => {
                this.zone.runOutsideAngular(() => {
                    let anchors = document.querySelectorAll('.trns.' + query);
                    anchors.forEach((anchor: HTMLAnchorElement) => {
                        // this.cd.detach();
                        // anchor.removeEventListener('click', () => { this.trnsService.search(query) })
                        anchor.addEventListener('click', ($event) => {
                            // this.cd.reattach();
                            if (!this.trnsService.clickable) {
                                $event.preventDefault(); // Cancel the native event
                                $event.stopImmediatePropagation();// Don't bubble/capture the event any further
                                this.trnsService.search(query);
                            }
                        });
                        anchor.addEventListener('mouseenter', ($event) => {
                            $event.preventDefault(); // Cancel the native event
                            $event.stopImmediatePropagation();// Don't bubble/capture the event any further

                            this.trnsService.publish({
                                type: 'hover',
                                query: query
                            })

                        }, {
                                once: false,
                                passive: false,
                            });
                    });
                });
            }, 200);
            this.value = '<span class="trns ' + query + '" title="' + query + '">' + this.value + '</span>';
        } else {
            // https://netbasal.com/optimizing-angular-change-detection-triggered-by-dom-events-d2a3b2e11d87

            let old = this.el.nativeElement.parentElement.querySelector('i.' + query);
            if (old) {
                old.remove();
            }
            let s = document.createElement('i');
            s.title = query;
            s.className = 'trns ' + query;
            s.style.display = 'inline-block';
            s.style.fontStyle = 'normal';
            s.innerHTML = this.value;
            // run lisener outside angular zone, to provent triggering change tick
            this.zone.runOutsideAngular(() => {
                // apply click events
                s.addEventListener('click', ($event) => {
                    // this.cd.reattach();
                    if (!this.trnsService.clickable) {
                        $event.preventDefault(); // Cancel the native event
                        $event.stopImmediatePropagation();// Don't bubble/capture the event any further
                        this.trnsService.search(query);
                    }
                });
                // apply on enter efect
                s.addEventListener('mouseenter', (e) => {
                    e.preventDefault(); // Cancel the native event
                    e.stopImmediatePropagation();// Don't bubble/capture the event any further

                    this.trnsService.publish({
                        type: 'hover',
                        query: query
                    })

                }, {
                        once: false,
                        passive: false,
                    });
                // this.cd.detach();
                this.elm = s;
                this.el.nativeElement.parentElement.insertBefore(s, this.el.nativeElement);
                // setTimeout(() => {
                //     this.cd.reattach();
                // }, 3500);
            });
            this.value = '';
        }
    }

    /**
     * Clean any existing subscription to change events
     */
    public dispose(): void {
        if (this.elm) {
            this.elm.remove();
        }
        if (typeof this.onTranslationChange !== 'undefined') {
            this.onTranslationChange.unsubscribe();
            this.onTranslationChange = undefined;
        }
        if (typeof this.onLangChange !== 'undefined') {
            this.onLangChange.unsubscribe();
            this.onLangChange = undefined;
        }
        if (typeof this.onDefaultLangChange !== 'undefined') {
            this.onDefaultLangChange.unsubscribe();
            this.onDefaultLangChange = undefined;
        }
    }

    ngOnDestroy(): void {
        this.dispose();
    }
}
