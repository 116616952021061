import {Pipe, PipeTransform} from '@angular/core';

/*
 * Converts strings into snake_case format
 */
@Pipe({ name: 'nl2br' })
export class NlBrPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            return value.replace(/\n/g, '<br/>');
        }      
   }
}
