import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
    selector: 'app-video-detail',
    templateUrl: './video-detail.component.html',
    styleUrls: ['./video-detail.component.scss'],
})
export class VideoDetailComponent implements OnInit {

    @Input() videoLink: string;

    public loadingError: boolean = false;

    constructor(
        @Inject(DOCUMENT) document: Document,
        public plt: PlatformService,
        public modalController: ModalController
    ) {
    }

    ngOnInit() {

        document.getElementById('video').addEventListener('load', (e) => {
            console.info(e);

            var iframe =  <HTMLIFrameElement> document.getElementById('video');
            var innerDoc = (iframe.contentDocument) ? iframe.contentDocument : iframe.contentWindow;
            

            if (!innerDoc) {
                this.loadingError = true;
            }

            // vimeo is informing via this and ready message...
            // youtube not...
            window.addEventListener('message', function (msgEvt) {
                // console.log(msgEvt);
            });
           
        });
    }

    openLink() {
        if (this.plt.is('cordova')) {
            // cordova is not working with third options attribute
            window.open(this.videoLink, '_system');
        } else {
            window.open(this.videoLink, '_system', 'noreferrer');
        }
    }

    /**
     * close modal window
     *
     */
    public backClicked() {
        this.modalController.dismiss({
            action: 'close'
        });
    }
}
