import { Directive, ElementRef, Input, HostListener, Renderer2 } from "@angular/core";

import { PlatformService } from "../../services/platform.service";

@Directive({
    selector: 'img[src][img-logo]',
    host: {
        '[src]': 'checkPath(src)',
        '(error)': 'onError()'
    }
})
export class DefaultLogoImageDirective {
    /**
     * input src
     *
     * @type Input
     */
    @Input() src: string;

    constructor(
        private plt: PlatformService,
        private el: ElementRef,
        private renderer: Renderer2
    ) { }

    /**
     * default image
     *
     * @type {string}
     */
    public defaultImg: string = '/assets/images/logo-dashboard.png';

    /**
     * fallback on error
     *
     * @return {string}
     */
    @HostListener('error', ['$event'])
    public onError() {
        this.hideSkeleton();
        if (this.plt.isConnected) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.defaultImg);
        }
    }

    @HostListener('load', ['$event'])
    public onLoad() {
        this.hideSkeleton();
    }

    /**
     * fallback on image is null
     *
     * @param src
     * @return {string}
     */
    public checkPath(src): string {
        return src ? this.plt.createSignedLink(src) : this.defaultImg;
    }

    private hideSkeleton() {
        if (this.el.nativeElement && this.el.nativeElement.parentNode) {
            let skeleton = this.el.nativeElement.parentNode.querySelector('ion-skeleton-text');
            if (skeleton) {
                skeleton.remove();
            }
        }
    }
}
