<ion-header *ngIf="modal"
            class="ion-no-shadow ion-no-border">
    <ion-toolbar>
        <ion-title *ngIf="!marketplacePost.id"
                   class="full"
                   [innerHTML]="'MARKETPLACE_POST_CREATE' | translate"></ion-title>
        <ion-title *ngIf="marketplacePost.id"
                   class="full"
                   [innerHTML]="'MARKETPLACE_POST_EDIT' | translate"></ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="backClicked()">
                <ion-icon slot="icon-only"
                          color="dark"
                          name="close">
                </ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<form method="post"
      #f="ngForm"
      role="form"
      class="form-horizontal"
      [ngClass]="{'has-value': (marketplacePost.hasValue() || displayBigTextarea) }">
    <ion-grid>

        <ion-row class="row-message"
                 [ngClass]="{'has-error': validate && validationErrors['message']}">

            <ion-col size="12"
                     size-md="3"
                     class="label">
                <ion-label>
                    {{ 'MARKETPLACE_POST_CATEGORY' | translate }}*
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['marketplace_category_id'] }">
                <ion-select [interface]="selectType"
                            [interfaceOptions]="{cssClass:'not-overflow'}"
                            [cancelText]="'BUTTON_CANCEL' |  translate"
                            name="category"
                            id="category"
                            #category_id="ngModel"
                            (ionChange)="categorySelected($event)"
                            [(ngModel)]="marketplacePost.marketplace_category_id">
                    <ion-select-option *ngFor="let category of marketplace.categories"
                                       [value]="category.id">
                        {{ category.translate(userLang, event.default_locale).name }}
                    </ion-select-option>
                </ion-select>
                <span *ngIf="validate && validationErrors['marketplace_category_id']"
                      class="error-message">
                    {{ validationErrors['marketplace_category_id'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label"
                     class="label">
                <ion-label>
                    {{ 'MARKETPLACE_POST_NAME' | translate }}*
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['name'] }">
                <ion-input type="text"
                           name="name"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="text"
                           spellcheck="false"
                           autocapitalize="off"
                           #name="ngModel"
                           [(ngModel)]="marketplacePost.name"
                           required>
                </ion-input>
                <span *ngIf="validate && validationErrors['name']"
                      class="error-message">
                    {{ validationErrors['name'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label">
                <ion-spinner *ngIf="sending"
                             name="dots">
                </ion-spinner>
                <span *ngIf="uploading"
                      class="uploading">
                    <div class="uploading-spinner">
                        <ion-spinner></ion-spinner>
                    </div>
                    <!-- END :: initial -->
                    <div class="progress-container"
                         *ngIf="uploading">
                        <progress class="progress"
                                  max="100"
                                  value="{{ progress }}">
                        </progress>
                    </div>
                </span>
                <div class="action-buttons-wrap"
                     [hidden]="sending"
                     [ngClass]="{'empty': !marketplacePost.image}">
                    <ng-container *ngIf="plt.is('cordova') && !marketplacePost.image">
                        <div class="mobile-image-buttons">
                            <button class="pure-btn"
                                    (click)="openCamera()">
                                <ion-icon name="camera"
                                          color="secondary">
                                </ion-icon>
                            </button>
                            <button class="pure-btn"
                                    (click)="openAlbums()">
                                <ion-icon name="image"
                                          color="secondary">
                                </ion-icon>
                            </button>
                        </div>
                    </ng-container>

                    <div class="dropzone-image"
                         *ngIf="!plt.is('cordova') && !marketplacePost.image">
                        <dropzone [message]="''"
                                  [config]="dropZoneConfig"
                                  (success)="onUploadSuccess($event)"
                                  (error)="onUploadError($event)"
                                  (sending)="onSending($event)">
                        </dropzone>
                    </div>
                </div>

                <span *ngIf="marketplacePost.image"
                      class="image-link">
                    <img [src]="plt.createSignedLink(marketplacePost.image_url)">
                    <div class="remove-image"
                         (click)="deleteImage();">
                    </div>
                </span>
            </ion-col>
            <ion-col *ngIf="plt.sizeSm"
                     size="12"
                     class="label"
                     [ngClass]="{ 'has-error': validate && validationErrors['description'] }">
                <ion-label>
                    {{ 'MARKETPLACE_POST_DESCRIPTION' | translate }}
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     class="col-textarea"
                     [ngClass]="{ 'has-error': validate && validationErrors['description'] }">
                <ion-label *ngIf="!plt.sizeSm"
                           position="stacked">
                    {{ 'MARKETPLACE_POST_DESCRIPTION' | translate }}*
                </ion-label>
                <ion-textarea #descriptionTextarea
                              ieTextareaFix
                              name="description"
                              inputmode="text"
                              spellcheck="false"
                              autocapitalize="off"
                              id="descriptionTextarea"
                              maxLength="1500"
                              autoGrow="false"
                              (click)="displayBigTextarea = true"
                              (ionFocus)="plt.checkResize()"
                              (ionBlur)="plt.checkResize(false)"
                              [(ngModel)]="marketplacePost.description">
                </ion-textarea>
                <span *ngIf="validate && validationErrors['description']"
                      class="error-message">
                    {{ validationErrors['description'] }}
                </span>
            </ion-col>

            <ng-container *ngIf="priceVisible">
                <ion-col size="12"
                         size-md="3"
                         class="label"
                         [ngClass]="{ 'has-error': validate && validationErrors['price'] }">
                    <ion-label>
                        {{ 'MARKETPLACE_POST_PRICE' | translate }}
                    </ion-label>
                </ion-col>
                <ion-col size="12"
                         size-md="9">
                    <ion-input type="text"
                               name="price"
                               (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                               (ionBlur)="plt.checkResize(false)"
                               (keydown.enter)="hideKeyboard($event)"
                               inputmode="text"
                               spellcheck="false"
                               autocapitalize="off"
                               #price="ngModel"
                               [(ngModel)]="marketplacePost.price">
                    </ion-input>
                    <span *ngIf="validate && validationErrors['price']"
                          class="error-message">
                        {{ validationErrors['price'] }}
                    </span>
                </ion-col>
            </ng-container>

            <ng-container *ngIf="marketplace?.allow_coins && event?.allow_coins && priceVisible">
                <ion-col size="12"
                         size-md="3"
                         class="label"
                         [ngClass]="{ 'has-error': validate && validationErrors['coin_price'] }">
                    <ion-label>
                        {{ 'MARKETPLACE_POST_COIN_PRICE' | translate }} ({{ event.coin_name }})
                    </ion-label>
                </ion-col>
                <ion-col size="12"
                         size-md="9">
                    <ion-input type="text"
                               name="coin_price"
                               (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                               (ionBlur)="plt.checkResize(false)"
                               (keydown.enter)="hideKeyboard($event)"
                               inputmode="text"
                               spellcheck="false"
                               autocapitalize="off"
                               #price="ngModel"
                               [(ngModel)]="marketplacePost.coin_price">
                    </ion-input>
                    <span *ngIf="validate && validationErrors['coin_price']"
                          class="error-message">
                        {{ validationErrors['coin_price'] }}
                    </span>
                </ion-col>
                <ion-col size="12"
                         size-md="3"
                         class="label"
                         [ngClass]="{ 'has-error': validate && validationErrors['digital_purchase_link'] }">
                    <ion-label>
                        {{ 'MARKETPLACE_POST_DIGITAL_PURCHASE_LINK' | translate }}
                    </ion-label>
                </ion-col>
                <ion-col size="12"
                         size-md="9">
                    <ion-input type="text"
                               name="digital_purchase_link"
                               (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                               (ionBlur)="plt.checkResize(false)"
                               (keydown.enter)="hideKeyboard($event)"
                               inputmode="text"
                               spellcheck="false"
                               autocapitalize="off"
                               #price="ngModel"
                               [(ngModel)]="marketplacePost.digital_purchase_link">
                    </ion-input>
                    <span *ngIf="validate && validationErrors['digital_purchase_link']"
                          class="error-message">
                        {{ validationErrors['digital_purchase_link'] }}
                    </span>
                </ion-col>
            </ng-container>

            <ion-col size="12"
                     size-md="3"
                     class="label">
                <ion-label>
                    {{ 'MARKETPLACE_POST_EMAIL' | translate }}
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['email'] }">
                <ion-input type="email"
                           name="email"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="email"
                           spellcheck="false"
                           autocapitalize="off"
                           #email="ngModel"
                           [(ngModel)]="marketplacePost.email"
                           pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'>
                </ion-input>
                <span *ngIf="(email.touched || validate) && !email.valid"
                      class="error-message">
                    {{ 'VALIDATION_EMAIL' | translate }}
                </span>
                <span *ngIf="validate && validationErrors['email']"
                      class="error-message">
                    {{ validationErrors['email'] }}
                </span>
            </ion-col>

            <ion-col size="12"
                     size-md="3"
                     class="label">
                <ion-label>
                    {{ 'MARKETPLACE_POST_PHONE' | translate }}
                </ion-label>
            </ion-col>
            <ion-col size="12"
                     size-md="9"
                     [ngClass]="{ 'has-error': validate && validationErrors['phone'] }">
                <ion-input type="text"
                           name="phone"
                           (ionFocus)="plt.checkResize(false, $event, 'nearest') "
                           (ionBlur)="plt.checkResize(false)"
                           (keydown.enter)="hideKeyboard($event)"
                           inputmode="text"
                           spellcheck="false"
                           autocapitalize="off"
                           #phone="ngModel"
                           [(ngModel)]="marketplacePost.phone">
                </ion-input>
                <span *ngIf="validate && validationErrors['phone']"
                      class="error-message">
                    {{ validationErrors['phone'] }}
                </span>
            </ion-col>

        </ion-row>

        <ion-row class="ion-no-padding">
            <ion-col *ngIf="!plt.sizeSm"
                     size="12"
                     size-md="3"
                     class="label">
            </ion-col>
            <ion-col>
                <div class="keyword-list"
                     *ngIf="marketplacePost.keywords.length">
                    <ion-label *ngFor="let selectedKeyword of marketplacePost.keywordsUnique"
                               class="keyword">
                        #{{ selectedKeyword.keyword.translate(userLang, defaultLocale).name | cut: 80 }}
                    </ion-label>
                </div>

                <ion-button *ngIf="marketplace.hasKeywords"
                            (click)="showKeywordsModal()"
                            [expand]="plt.sizeSm ? 'block' : ''"
                            color="secondary">
                    {{ 'MARKETPLACE_POST_SELECT_KEYWORDS' | translate }}
                </ion-button>
                <ng-container *ngIf="!plt.sizeSm">&nbsp;&nbsp;&nbsp;</ng-container>
                <ion-button (click)="showDesignModal()"
                            [expand]="plt.sizeSm ? 'block' : ''"
                            color="primary">
                    {{ 'MARKETPLACE_POST_DESIGN' | translate }}
                </ion-button>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="!modal"
                 class="form-button ion-no-padding">

            <ion-col class="ion-no-padding ion-text-right">
                <ion-button *ngIf="marketplacePost.id"
                            class="btn-close"
                            (click)="backClicked(false)">
                    {{ 'BUTTON_CANCEL' | translate }}
                </ion-button>
                <ion-button color="secondary"
                            id="post-marketplace-button"
                            [disabled]="!f.form.valid || sending || uploading || submit"
                            (click)="marketplacePostSave(f.form.valid)">
                    {{ 'BUTTON_SAVE' | translate }}
                </ion-button>
            </ion-col>

        </ion-row>

    </ion-grid>

</form>

<ion-footer *ngIf="modal">
    <ion-row class="action-buttons two ion-no-padding">
        <ion-col size="6"
                 class="ion-no-padding">
            <ion-button class="btn-close"
                        (click)="backClicked()">
                {{ 'BUTTON_CANCEL' | translate }}
            </ion-button>
        </ion-col>
        <ion-col size="6"
                 class="ion-no-padding">
            <ion-button color="primary"
                        [disabled]="!f.form.valid || sending || uploading || submit"
                        (click)="marketplacePostSave(f.form.valid)">
                {{ 'BUTTON_SAVE' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>