import { Pipe } from '@angular/core';

import { PlatformService } from "../../services/platform.service";

@Pipe({
    name: 'securedImages'
})
export class SecuredImagesPipe {

    constructor(
        public plt: PlatformService
    ) { }

    public transform(value: string): string {
        // add security attributes to image links
        if (value) {

            let matches = value.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
            if (matches) {
                matches = matches.map(x => x.replace(/.*src="([^"]*)".*/, '$1'))

                let replaces = matches.map(x => this.plt.createSignedLink(x));

                matches.forEach((match, i) => {
                    value = value.replace(match, replaces[i]);
                });
            }

            return value;
        } else {
            return '';
        }
    }

}