import { EventSettingTranslation } from "./event-setting-translation";
import { Translatable } from "./translatable.interface";

import * as moment from "moment";

export class EventSetting implements Translatable {
    id: number;
    event_id: number;

    primary_color: string;
    primary_text_color: string;
    secondary_color: string;
    secondary_text_color: string;
    menu_background: string;
    menu_background_url: string;
    header_background: string;
    header_background_url: string;
    mobile_menu_background: string;
    mobile_menu_background_url: string;
    mobile_header_background: string;
    mobile_header_background_url: string;
    overlay: string;
    hide_overlay: boolean = false;
    overlay_text_color: string;
    logo_small: string;
    logo_small_url: string;
    logo_small_link: string;
    logo_big: string;
    logo_big_url: string;
    updated_at: string;
    date_updated_at: Date = new Date();

    profile_setting: Array<{
        type: string,
        edit: boolean,
        visible: boolean,
        required: boolean
    }> = [];

    app_setting
    //  = {
    //     external_button: [
    //         {
    //             button_label: 'Digital Avatar Space (DAS)',
    //             type: 'info',
    //             info_header: 'Vielen Dank für Ihre Teilnahme am BizOps Forum 2021.',
    //             info_text: 'Der Digital Avatar Space ist ab Dienstag, 04. Mai 21, 09:00 Uhr für Sie geöffnet.<br /><br />Wir freuen uns auf Ihren Besuch!',
    //             required: [
    //                 {
    //                     type: 'start',
    //                     value: '2021-03-29T11:37:00+0000'
    //                 },
    //                 {
    //                     type: 'end',
    //                     value: '2021-03-29T11:39:00+0000'
    //                 }
    //             ]
    //         },
    //         {
    //             button_label: 'Digital Avatar Space (DAS)',
    //             type: 'external_link',
    //             link: 'https://theroundrectangle.com/amasol/event/index.html',
    //             required: [
    //                 {
    //                     type: 'filled',
    //                     value: 'external_token'
    //                 },
    //                 {
    //                     type: 'start',
    //                     value: '2021-03-29T11:39:00+0000'
    //                 },
    //                 {
    //                     type: 'end',
    //                     value: '2021-03-29T11:41:00+0000'
    //                 }
    //             ]
    //         },
    //     ]
    // };
    // {"external_button":[{"button_label":"Digital Avatar Space (DAS)","type":"info","info_header":"Vielen Dank für Ihre Teilnahme am BizOps Forum 2021.","info_text":"Der Digital Avatar Space ist ab Dienstag, 04. Mai 21, 09:00 Uhr für Sie geöffnet.<br /><br />Wir freuen uns auf Ihren Besuch!","required":[{"type":"filled","value":"external_token"},{"type":"start","value":"2021-03-29T13:10:00+0000"},{"type":"end","value":"2021-03-29T13:20:00+0000"}]},{"button_label":"Digital Avatar Space (DAS)","type":"external_link","link":"https://theroundrectangle.com/amasol/event/index.html","required":[{"type":"filled","value":"external_token"},{"type":"start","value":"2021-03-29T13:20:00+0000"},{"type":"end","value":"2021-03-29T13:30:00+0000"}]}]}

    custom_imprint: boolean = false;
    translations: EventSettingTranslation[] = [];

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: EventSetting) {
        if (!!init) {
            Object.assign(this, init);
            if (this.updated_at) this.date_updated_at = moment.utc(this.updated_at).toDate();
            if (!this.profile_setting) {
                this.initProfileSetting();
            }
        } else {
            this.initProfileSetting();
        }
    }
    /**
     * get translated attributes
     *
     * @param locale
     *
     * @return {OrganizerSettingTranslation}
     */
    public translateOrNew(locale: string) {
        let currentTranslation = this.translations.filter(translation => translation.locale === locale)[0];

        if (!currentTranslation) {
            currentTranslation = new EventSettingTranslation;
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return currentTranslation;
    }

    /**
     * translate
     *
     * @param locale
     * @return {SubscriptionTranslation}
     */
    public translate(locale: string) {
        return this.translations.filter(translation => { return translation.locale === locale; })[0] || new EventSettingTranslation();
    }

    /**
     * check customized profile rules
     *
     * @param type
     * @param attr
     *
     * @return {boolean}
     */
    public checkProfileRule(type: string, attr: string): boolean {
        // default is to open all..
        let allowed = true;
        this.profile_setting.forEach((rule) => {
            if (rule.type == type && rule.hasOwnProperty(attr)) {
                allowed = !!rule[attr];
                // required is neutralizing edit for item rule
                // required items need to be visible for user
                if (attr != 'required' && !!rule['required']) {
                    allowed = true;
                }
            }
        });
        return allowed;
    }

    private initProfileSetting() {
        this.profile_setting = [];
        [
            "public_email",
            "title",
            "profession",
            "company",
            "street",
            "city",
            "country",
            "phone",
            "paragraph",
            "image",
            "cv",
            "link",
        ].forEach((type) => {
            this.profile_setting.push({
                type: type,
                edit: true,
                visible: true,
                required: false
            })
        });
    }
}
