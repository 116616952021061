// config
import { environment } from "../../environments/environment";

export function log(type, ...msg) {

    let stack = type;
    // try {
    //     stack = new Error().stack;
    // } catch (e) {
    //
    // }
    // print only last line from stack
    //stack = stack.split('at');
    //stack = stack[stack.length - 1];
    switch (type) {
        case 'info':
            if (environment.debug) {
                console.info(stack, ...msg);
            }
            break;
        case 'warn':
            console.warn(stack, ...msg);
            break;
        case 'log':
            if (environment.debug) {
                console.log(stack, ...msg);
            }
            break;
        case 'debug':
            if (environment.debug) {
                console.debug(stack, ...msg);
            }
            break;
        case 'error':
            console.error(stack, ...msg);
            break;
        case 'show':
            console.info(stack, ...msg);
            break;

    }
}
