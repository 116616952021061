<ion-col *ngIf="event && (!event?.matching_status || keywordsEmpty)"
         class="ion-no-padding">
    {{ 'DASHBOARD_MATCHING_LIST_NOT_ENABLED' | translate }}
</ion-col>

<!-- dashboar version for participant managing keywords -->
<ion-col *ngIf="event?.matching_status && !keywordsEmpty && view =='manage-keywords' && user"
         class="ion-no-padding">
    <ion-row *ngIf="!wizard"
             class="ion-no-padding">
        <ion-col size="12"
                 class="ion-no-padding">
            <h2 *ngIf="!event?.is_community">{{ 'DASHBOARD_EVEN_PREFERENCE' | translate }}</h2>
            <h2 *ngIf="event?.is_community">{{ 'DASHBOARD_EVEN_PREFERENCE_COMMUNITY' | translate }}</h2>
            <p class="hint"
               [innerHTML]="'DASHBOARD_EVEN_PREFERENCE_HINT' | translate"></p>
        </ion-col>
        <ion-col size="12"
                 class="ion-no-padding ion-text-left">
            <ion-button color="primary"
                        [disabled]="user?.selected_participant.keywords.length == 0"
                        (click)="showInterests()">
                {{ 'DASHBOARD_BUTTON_SHOW_MATCHING' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
    <hr *ngIf="!wizard"
        class="full" />
    <h3>{{ 'DASHBOARD_MATCHING_LIST' | translate }}:</h3>
    <ng-container *ngFor="let container of event.containers; let c = index">
        <hr class="full"
            *ngIf="c > 0">
        <ng-container *ngIf="container.keywords.length > 0">
            <h4><strong>{{c+1}}.</strong> {{ container.translate(userLang, event.default_locale).name }}</h4>
            <ion-row class="keyword-box ion-no-padding">

                <ng-container *ngFor="let column of (container.columns === 2 ? [1,2] : [1])">
                    <ion-col class="multi-columns ion-no-padding"
                             size="{{ container.columns === 2 ? '6' : '12' }}">
                        <br *ngIf="plt.sizeSm && column == 2">
                        <h5 *ngIf="container.columns === 2">{{ container.translate(userLang, event.default_locale)['column_' + column + '_name'] }}</h5>

                        <!-- Keywords -->
                        <div class="keyword-list">
                            <ng-container *ngFor="let keyword of container.keywords">
                                <ng-container *ngIf="keyword.isValid(event.available_locales, user?.selected_participant_id)">
                                    <ion-chip *ngIf="keyword.approved_at || !keyword.participant_id"
                                              [ngClass]="{'enabled': isEnabled(column, keyword)}"
                                              (click)="toggleKeyword(column, keyword)">
                                        <ion-label>
                                            {{ keyword.translate(userLang, event.default_locale).name | cut: 80 }}
                                            <ion-spinner *ngIf="keyword.loading == column"
                                                         name="dots">
                                            </ion-spinner>
                                        </ion-label>
                                    </ion-chip>
                                    <ion-chip *ngIf="!keyword.approved_at && keyword.participant_id"
                                              class="suggestion"
                                              (click)="openDeleteSuggest(keyword)">
                                        <img img-avatar
                                             [src]="user?.selected_participant.picture_url">
                                        <ion-label>{{ keyword.translate(userLang, event.default_locale).name | cut: 80 }}</ion-label>
                                        <ion-icon name="close"></ion-icon>
                                    </ion-chip>
                                </ng-container>
                            </ng-container>
                            <ion-chip *ngIf="event?.matching_status && event.keyword_suggestion_status"
                                      class="suggest"
                                      (click)="presentSuggestKeyword($event, container)">
                                <ion-label>+ {{ 'DASHBOARD_MATCHING_LIST_SUGGEST_KEYWORD' | translate }}</ion-label>
                            </ion-chip>
                        </div>

                    </ion-col>
                </ng-container>
            </ion-row>
        </ng-container>
    </ng-container>
</ion-col>

<!-- dashboard version for list of attendee keywords and matching attendees -->
<ion-col *ngIf="event?.matching_status && view =='matching' && user"
         class="ion-no-padding">
    <ion-row class="ion-no-padding">
        <ion-col size="12"
                 class="ion-no-padding">
            <h2 class="matching"
                *ngIf="event?.is_homepage_content_matching">{{ 'DASHBOARD_EVENT_MATCHING' | translate }}</h2>
            <h2 class="matching"
                *ngIf="!event?.is_homepage_content_matching">{{ 'NEWSFEED_DASHBOARD_EVENT_MATCHING' | translate }}</h2>
        </ion-col>
        <ion-col size="12"
                 class="ion-no-padding ion-text-left">
            <ion-button *ngIf="plt.isConnected"
                        color="primary"
                        (click)="showManageKeywords()">
                {{ 'DASHBOARD_MANAGE_KEYWORDS' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
    <hr class="full" />
    <!-- <h3>{{ 'DASHBOARD_MATCHING_LIST' | translate }}:</h3> -->
    <ng-container *ngFor="let container of containers; let c = index">
        <ng-container *ngIf="visibleAllKeywords || c == 0">
            <hr class="full"
                *ngIf="c > 0">
            <h4><strong></strong> {{ container.translate(userLang, event.default_locale).name }}</h4>
            <ion-row class="keyword-box ion-no-padding">

                <ng-container *ngFor="let column of (container.columns === 2 ? [1,2] : [1])">
                    <ion-col class="multi-columns ion-no-padding"
                             size="{{ container.columns === 2 ? '6' : '12' }}">
                        <br *ngIf="plt.sizeSm && column == 2">
                        <h5 *ngIf="container.columns === 2">{{ container.translate(userLang, event.default_locale)['column_' + column + '_name'] }}</h5>

                        <!-- Keywords -->
                        <div class="keyword-list">
                            <ng-container *ngFor="let keyword of container.keywords">
                                <ng-container *ngIf="isEnabled(column, keyword)">
                                    <span class="keyword-selected">#{{ keyword.translate(userLang, event.default_locale).name }}</span>
                                </ng-container>
                            </ng-container>
                        </div>

                    </ion-col>
                </ng-container>
            </ion-row>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="containers.length > 1">
        <ng-container *ngIf="!visibleAllKeywords">
            <a (click)="visibleAllKeywords=true"
               class="more">
                {{ 'DASHBOARD_MATCHING_LIST_MORE' | translate:{count:containers.length - 1} }}
                <svg-icon [applyClass]="true"
                          src="/assets/icons/ico-arrow.svg"
                          class="collapsible ">
                </svg-icon>
            </a>
        </ng-container>
        <ng-container *ngIf="visibleAllKeywords">
            <a (click)="visibleAllKeywords=false"
               class="more">
                {{ 'DASHBOARD_MATCHING_LIST_LESS' | translate:{count:containers.length - 1} }}
                <svg-icon [applyClass]="true"
                          src="/assets/icons/ico-arrow.svg"
                          class="collapsible opened">
                </svg-icon>
            </a>
        </ng-container>
    </ng-container>

    <hr class="full">

    <!-- list of matching participants  -->
    <h3 class="flex ion-align-items-center most-matching">
        {{ 'DASHBOARD_MOST_MATCHING' | translate }}
        <ion-spinner *ngIf="this.calculatingCalls"
                     name="dots"></ion-spinner>
    </h3>

    <div class="attendee-list-mobile"
         *ngIf="plt.sizeSm">
        <ng-container *ngFor="let attendee of matchingAttendees; let c = index">
            <attendee-list-item [attendee]="attendee"
                                [viewType]="'lines'"
                                [type]="'most-matching-mobile'"
                                (onAttendeeDetail)="showAttendeeDetail($event)">
            </attendee-list-item>
        </ng-container>
    </div>

    <div *ngIf="!plt.sizeSm"
         class="attendee-list">
        <ng-container *ngFor="let attendee of matchingAttendees; let c = index">
            <attendee-list-item [attendee]="attendee"
                                [type]="'small'"
                                (onAttendeeDetail)="showAttendeeDetail($event)">
            </attendee-list-item>
        </ng-container>
        <!-- fake attendee box to solve bug with last row in flex grid -->
        <div class="attendee-box-empty small"></div>
        <div class="attendee-box-empty small"></div>
        <div class="attendee-box-empty small"></div>
        <div class="attendee-box-empty small"></div>
        <div class="attendee-box-empty small"></div>
    </div>
    <!-- <a *ngIf="matchingAttendees.length > 0"
       class="show-more-matching"
       [routerLink]="[plt.defaultLink + '/' + homeUrlSegment + '/attendees/list']"
       (click)="tabChanged('attendees', 'list', true)">
        {{ 'DASHBOARD_MOST_MATCHING_MORE' | translate }}
    </a> -->
    <a *ngIf="matchingAttendees.length > 0"
       class="show-more-matching"
       (click)="showAttendeeList()">
        {{ 'DASHBOARD_MOST_MATCHING_MORE' | translate }}
    </a>
</ion-col>