import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuController } from '@ionic/angular';

// extends
import { BasePage } from "../base";

// models
import { User } from "../../models/user";

// services
//import { PushPageService } from "../../services/push-page.service"; TODO
import { AuthenticationService } from "../../services/authentication.service";

// config
import { environment } from "../../../environments/environment";

@Component({
    selector: 'app-lost-password',
    templateUrl: './lost-password.page.html',
    styleUrls: ['./lost-password.page.scss'],
})
export class LostPasswordPage extends BasePage implements OnInit {

    /**
     * Form
     *
     * @type ViewChild
     */
    @ViewChild('f') private form;

    /**
     * user model
     *
     * @type User
     */
    public user: User = new User;

    /**
     * loaded state
     *
     * @type {boolean}
     */
    public loaded: boolean = false;

    /**
     * static environment config
     *
     * @type {Object}
     */
    public environment: Object = environment;

    /**
     * submitted
     *
     * @type {boolean}
     */
    public submitted: boolean = false;

    /**
     * validate state
     *
     * @type {boolean}
     */
    public validate: boolean = false;

    /**
     * constructor
     *
     * @param router
     * @param auth
     * @param menuCtrl
     * @param platform
     * @param oAuthBrowser
     */
    constructor(
        public auth: AuthenticationService,
        public menuCtrl: MenuController,
    ) {
        super();
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() { // vs ionViewDidLoad?
        this.plt.hideSidebar = this.hideSidebar;
        this.menuCtrl.enable(false, 'authenticated');

        this.initSetting();
        this.loaded = true;
    }

    public initSetting() { }

    /**
     * lost password
     *
     * @return void
     */
    public reset() {

        this.validate = true;

        if (!this.submitted && this.form.valid) {

            this.submitted = true;
            this.showLoading();

            this.auth.resetPassword(this.user.email, this.plt.is('cordova') ? 'mobile' : 'pwa').subscribe(
                (success) => {
                    this.submitted = false;
                    this.validate = false;
                    this.hideLoading();
                    this.overlayService.showSuccess(success.message);
                    this.router.navigate(['/']);
                },
                (error) => {
                    this.submitted = false;
                    this.hideLoading();
                    this.overlayService.showError(error.error.message, !!error.error.static);
                }
            );
        }
    }
}
