import * as moment from "moment/moment";

import { config } from "src/environments/config";

export class EventTimeSlots {
    event_id: number;
    // default value for block when creating
    timeslots_free: true;
    // default size of block in minutes
    timeslot_length: number = 5;

    // object with actual structure -> create model...
    eventTimeslots: {} = {};

    // array with allowed timeslots duration
    durations = [];
    // array of days for time slots
    days = [];
    // current configuration for slots of this event
    timeslots = [];
    // array which help to secure that there would not be any duplicit date
    daysTime = [];

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: EventTimeSlots, options?) {
        if (!!init) {
            Object.assign(this, init);
        }

        if (!!options) {
            Object.assign(this, options);
        }

        // generate durations, maximum size for appointment request
        let duration = this.timeslot_length;
        while(duration <= config.maxAppointmentBlockSize) {
            this.durations.push(duration);
            duration += this.timeslot_length;
        }

        this.generateTimeslots(this.timeslot_length);
    }

    /**
     * Method which will generate slots by event configuration
     *
     * @param {number} slotLength size of block in minutes
     * @return void
     */
    public generateTimeslots(slotLength = 5) {
        // generate days
        this.eventTimeslots = {};
        this.timeslots.forEach((slot) => {
            let day = moment.utc(slot.start).set({hour:0, minute:0,second:0}).toDate();
            // check if day is alredy in array
            if (this.daysTime.indexOf(day.getTime()) == -1) {
                this.days.push(day);
                this.daysTime.push(day.getTime());
            }

            let start = moment.utc(slot.start).set({second:0,millisecond:0});
            let end = moment.utc(slot.end).set({second:0,millisecond:0});//.subtract(slotLength, 'minutes');
            let actual = start.clone();
            let next = start.clone();

            // prepare empty array for days slots
            if (!this.eventTimeslots[day.getTime()]) {
                this.eventTimeslots[day.getTime()] = [];
            }
            // generate blocked/free slots for selected day by start/end
            while (next.isBefore(end)) {
                this.eventTimeslots[day.getTime()].push({
                    // TODO[jg] we should maybe somehow optimize this structure
                    start: actual.clone(),
                    end: next.add(slotLength, 'minutes').clone(),
                    status: this.timeslots_free ? 'free' : 'blocked'
                });
                actual = next.clone();
            }
        });
    }
}
