<div *ngIf="user"
     #chatList
     class="conversations-list">

    <ng-container
                  *ngIf="visibleChatGroup || broadcastsConversation.length > 0 || (visibleJoinChatGroup || event?.can(user.selected_participant.group_id, 'allow_chat_group_creation') || event?.can(user.selected_participant.group_id, 'allow_private_chat_group_creation') || event?.can(user.selected_participant.group_id, 'allow_secured_chat_group_creation'))">

        <h2>{{ 'CHAT_GROUPS_CHANNELS' | translate }}</h2>

        <ng-container *ngFor="let chatGroup of assignedChatGroups">
            <!-- broadcast messages -->
            <div *ngIf="chatGroup.joined"
                 class="chat-group-item"
                 [ngClass]="{'active': selectedChatGroupId == chatGroup.id}"
                 (click)="loadChatGroup(chatGroup)">

                <div class="conversation-detail">
                    <h3>
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-hashtag.svg"
                                  class="icon fill-light">
                        </svg-icon>{{ chatGroup.name }}
                    </h3>
                    <div class="unread"
                         *ngIf="chatGroup.unread_messages_count">
                        {{ chatGroup.unread_messages_count }}
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- broadcast messages -->
        <ng-container *ngFor="let broadcat of broadcastsConversation">
            <div class="chat-group-item"
                 [ngClass]="{'active': selectedBroadcast}"
                 (click)="loadBroadcast(broadcat)">
                <div class="conversation-detail">
                    <h3>
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/ico-hashtag.svg"
                                  class="icon fill-light">
                        </svg-icon>{{ event.name }}
                    </h3>
                    <div class="unread"
                         *ngIf="broadcat.pivot.unread">
                        {{ broadcat.pivot.unread }}
                    </div>
                </div>
            </div>
        </ng-container>

        <div *ngIf="(visibleJoinChatGroup || event?.can(user.selected_participant.group_id, 'allow_chat_group_creation') || event?.can(user.selected_participant.group_id, 'allow_private_chat_group_creation') || event?.can(user.selected_participant.group_id, 'allow_secured_chat_group_creation')) && !user.selected_participant.not_contactable"
             class="chat-group-item"
             (click)="addChatGroup()">

            <div class="conversation-detail">
                <h3>
                    + {{ 'CHAT_GROUPS_ADD_CHANNEL' | translate }}
                </h3>
            </div>
        </div>

        <hr />

        <h2>{{ 'CHAT_GROUPS_DIRECT_MESSAGES' | translate }}</h2>

    </ng-container>

    <ng-container *ngIf="conversations.length == 0 && plt.sizeSm">
        <div class="empty-data">
            <svg-icon [applyClass]="true"
                      src="/assets/icons/ico-messages.svg"
                      class="icon fill-light"></svg-icon>
            <h3>{{ 'MESSAGES_EMPTY' | translate }}</h3>
            <p (click)="goToAttendees()">
                {{ 'MESSAGES_EMPTY_TEXT_1' | translate }}
                <a class="pointer">{{ 'MESSAGES_EMPTY_TEXT_ANCHOR' | translate }}</a>
                {{ 'MESSAGES_EMPTY_TEXT_2' | translate }}
            </p>
        </div>
    </ng-container>

    <ng-container *ngFor="let conversation of conversations">
        <!-- opened conversations with other attendees -->
        <div class="conversation-item"
             [ngClass]="{'active': selectedParticipantId == conversation.participants[0].id}"
             (click)="setConversationParticipant(conversation.participants[0], conversation, true);">
            <ion-avatar>
                <ion-skeleton-text animated></ion-skeleton-text>
                <img class="conversation-avatar"
                     img-avatar
                     [src]="user.canPicture(conversation.participants[0]) ? conversation.participants[0].picture_url : ''"
                     alt="">
            </ion-avatar>

            <div class="conversation-detail">
                <h3>{{ conversation.participants[0].firstname }} {{ conversation.participants[0].lastname }}</h3>
                <div class="unread"
                     *ngIf="conversation.messages.length">
                    {{ conversation.messages.length }}
                </div>
            </div>
        </div>
    </ng-container>

    <div *ngIf="!loading && deletedConversationsCount > 0"
         class="panel panel-info">
        <div class="panel-body"
             *ngIf="deletedConversationsCount == 1">
            <i class="fa fa-info-circle"></i> {{ 'CONVERSATIONS_ONE_PARTICIPANT_DELETED' | translate:{ value: deletedConversationsCount } }}
        </div>
        <div class="panel-body"
             *ngIf="deletedConversationsCount > 1">
            <i class="fa fa-info-circle"></i> {{ 'CONVERSATIONS_MULTIPLE_PARTICIPANTS_DELETED' | translate:{ value: deletedConversationsCount } }}
        </div>
    </div>

</div>