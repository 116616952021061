export const datepickerLangs = {
    de: {
        "firstDayOfWeek": 1,
        "dayNames": [ "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag" ],
        "dayNamesShort": [ "Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam" ],
        "dayNamesMin": [ "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa" ],
        "monthNames": [ "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ],
        "monthNamesShort": [ "Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez" ]
    },
    en: {
        "firstDayOfWeek": 0,
        "dayNames": [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
        "dayNamesShort": [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ],
        "dayNamesMin": [ "Su", "Mo", "Tu", "We", "Th", "Fr", "Sa" ],
        "monthNames": [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
        "monthNamesShort": [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
    },
    fr: {
        "firstDayOfWeek": 1,
        "dayNames": [ "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi" ],
        "dayNamesShort": [ "Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa" ],
        "dayNamesMin": [ "Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa" ],
        "monthNames": [ "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Julliet", "Août", "Septembre", "Octobre", "Novembre", "Décembre" ],
        "monthNamesShort": [ "Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc" ]
    },
    es: {
        "firstDayOfWeek": 1,
        "dayNames": [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ],
        "dayNamesShort": [ "Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb" ],
        "dayNamesMin": [ "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa" ],
        "monthNames": [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ],
        "monthNamesShort": [ "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic" ]
    }
};
