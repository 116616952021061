import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

// ionic
import { Platform } from "@ionic/angular";

// rxjs
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

// models
import { User } from "../../models/user";

// extends
import { BasePage } from "../base";

@Component({
    selector: 'app-toc',
    templateUrl: './toc.page.html',
    styleUrls: ['./toc.page.scss'],
})
export class TocPage extends BasePage implements OnInit {

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * user model
     *
     * @type User
     */
    public model: any = {
        'agb_accepted': false,
        'data_processing_accepted': false
    };

    /**
     * loading state
     *
     * @type {boolean}
     */
    public submit: boolean = false;

    /**
     * return url
     *
     * @type string
     */
    public returnUrl: string = '';

    /**
     * event key
     *
     * @type {string}
     */
    public eventKey: string;

    /**
     * Validation errors
     *
     * @type {object}
     */
    public validationErrors: object = {};

    /**
     * validation errors state
     *
     * @type {boolean}
     */
    public hasValidationErrors: boolean = false;

    /**
     * do we need only agb refresh
     *
     * @type {boolean}
     */
    public toc_refresh: boolean = false;

    /**
     * active user
     *
     * @type {User}
     */
    public user: User = new User;

    /**
     * constructor
     *
     * @param router
     * @param userService
     * @param route
     *
     * @return void
     */
    constructor(
        private platform: Platform,
        public route: ActivatedRoute,
    ) {
        super();
    }

    /**
     * on init
     *
     * @returns {void}
     */
    ngOnInit() {
        this.hideLoading();
        this.plt.hideSidebar = this.hideSidebar;

        if (!localStorage.getItem('token')) {
            this.router.navigate(['/login'])
        }

        const toc_refresh = JSON.parse(this.route.snapshot.queryParams['toc_refresh']);

        if (!!toc_refresh) {
            this.toc_refresh = true
        }

        this.userService.getCurrentUser()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            ).subscribe(user => {
                if (user.id) {
                    this.user = user;
                }
            });

        if (this.plt.is('cordova') && this.plt.is('android')) {
            this.platform.backButton.pipe(
                takeUntil(this.ngUnsubscribe)
            ).subscribe(() => {
                // go back to login page
                this.logout()
            });
        } else {
            window.onpopstate = () => {
                this.logout()
            };
        }

        this.initSetting();
    }

    public initSetting() {}

    /**
     * register
     *
     * @return void
     */
    public submitTOC() {

        if (!this.submit) {
            this.showLoading();
            this.submit = true;

            if (this.eventKey) {
                this.userService.useEventKey(this.eventKey).subscribe(
                    () => {
                        this.sendToc(true);
                    },
                    (error) => {
                        this.hideLoading();
                        this.submit = false;
                        const data = error.error;

                        if (data.fields) {
                            for (const field in data.fields) {
                                if (data.fields.hasOwnProperty(field)) {
                                    this.hasValidationErrors = true;
                                    this.validationErrors[field] = data.fields[field].join(' ');
                                }
                            }
                        }

                        this.overlayService.showError(data.message);
                    });
            } else {
                this.sendToc(false);
            }
        }

    }

    sendToc(eventKeyUsed: boolean) {
        // set actual language selected on login page... can be different compared to participant's setting
        this.model.forceLocale = this.translate.currentLang;
        this.userService.setTOC(this.model)
            .subscribe(
                () => {
                    // this.showSuccess(success.message);
                    this.userService.getCurrentUser()
                        .pipe(
                            take(1)
                        ).subscribe(
                            (user) => {
                                user.toc_needed = false;
                                user.toc_refresh = false;
                                this.plt.hideSidebar = false;
                                this.hideLoading();
                                if (eventKeyUsed) {
                                    this.appEvents.publish('user:current:participant', {
                                        redirectLink: this.plt.defaultLink + '/home/info/matching'
                                    });
                                } else {
                                    this.userService.setCurrentUser(user);
                                    if (user.selected_participant_id) {
                                        this.router.navigate([this.plt.defaultLink + '/home/info/matching']);
                                    } else {
                                        this.router.navigate(['/no-event']);
                                    }
                                }
                            });


                },
                (error) => {
                    this.hideLoading();
                    this.showError(error.error.message);
                    this.submit = false;
                });
    }

    public async openDestroyAccount() {
        this.showConfirm(
            this.translate.instant('PROFILE_EDIT_DELETE_ACCOUNT'),
            this.translate.instant('PROFILE_EDIT_DELETE_ACCOUNT_PARTICIPANT_HINT') + '<br /><br /><span class="highlight">' + this.translate.instant('PROFILE_EDIT_DELETE_ACCOUNT_PARTICIPANT_WARNING_HINT') + '</span>',
            () => { this.destroyAccount() },
            () => { },
            this.translate.instant('PROFILE_EDIT_DELETE_ACCOUNT_PERMANENTLY')
        );
    }

    /**
     * delete account
     */
    public destroyAccount() {
        this.overlayService.showLoading();

        this.userService.getCurrentUser()
            .pipe(
                take(1)
            ).subscribe(
                (user) => {
                    this.userService.delete(user.id).subscribe(
                        () => {
                            this.overlayService.hideLoading();
                            this.appEvents.publish('logout');
                            //this.alertService.success(success.message, true);
                            // this.overlayService.showSuccess(success.message);
                            // this.authService.logout();
                            // this.router.navigate(['login']);
                            // this.loading = false;
                        },
                        (error) => {
                            this.overlayService.showConnectionProblems(error);
                            this.overlayService.hideLoading();
                        });
                });
    }

    /**
      * logout user, redirect to login, close menu
      *
      * @return void
      */
    logout() {
        // adding delay to have nice animation with closing menu
        setTimeout(() => {
            this.appEvents.publish('logout', 'logout');
        }, 300);
    }
}
