<ion-content class="ion-padding">
    <div class="content-centered-column">
        <div class="content-centered-row">
            <form *ngIf="plt.isConnected"
                  (ngSubmit)="submitTOC()"
                  #f="ngForm"
                  novalidate>
                <ion-list class="form">
                    <ion-item class="ion-no-lines ion-no-padding title">
                        <h1 *ngIf="!toc_refresh">{{ 'TOC_TITLE' | trns}}</h1>
                        <h1 *ngIf="toc_refresh">{{ 'TOC_CONFIRM_TITLE' | trns }}</h1>
                    </ion-item>

                    <p *ngIf="toc_refresh"
                       [innerHtml]="'TOC_CONFIRM_HINT' | trns"></p>

                    <ng-container *ngIf="!toc_refresh && (!user.selected_participant)">
                        <ion-item class="ion-no-lines">
                            <ion-label position="stacked">
                                {{ 'LOGIN_ARE_YOU_USING_EVENT_KEY' | trns }} ({{ 'OPTIONAL' | trns }})
                            </ion-label>
                            <ion-input type="text"
                                       name="event_key"
                                       placeholder="{{ 'REGISTRATION_LABEL_EVENT_KEY' | translate }}"
                                       tabindex="2"
                                       #event_key="ngModel"
                                       [(ngModel)]="eventKey">
                            </ion-input>
                        </ion-item>
                        <span *ngIf="event_key.touched && !event_key.valid"
                              class="error-message">
                            {{ 'VALIDATION_REQUIRED' | trns }}
                        </span>
                    </ng-container>

                    <ion-item class="global ion-no-padding"
                              [ngClass]="{ 'has-error': agb_accepted.touched && !agb_accepted.valid }">
                        <ion-checkbox slot="start"
                                      class="required"
                                      name="agb_accepted"
                                      #agb_accepted="ngModel"
                                      [(ngModel)]="model.agb_accepted"
                                      required></ion-checkbox>
                        <ion-label [innerHtml]="'REGISTRATION_LABEL_AGB' | trns"></ion-label>
                    </ion-item>
                    <span *ngIf="agb_accepted.touched && !agb_accepted.valid"
                          class="error-message">
                        {{ 'VALIDATION_REQUIRED' | trns }}
                    </span>

                    <ng-container *ngIf="!toc_refresh">
                        <ion-item class="global ion-no-padding"
                                  [ngClass]="{ 'has-error': data_processing_accepted.touched && !data_processing_accepted.valid }">
                            <ion-checkbox slot="start"
                                          class="required"
                                          name="data_processing_accepted"
                                          #data_processing_accepted="ngModel"
                                          [(ngModel)]="model.data_processing_accepted"
                                          required></ion-checkbox>
                            <ion-label [innerHtml]="'REGISTRATION_LABEL_DATA_PROCESSING' | trns"></ion-label>
                        </ion-item>
                        <span *ngIf="data_processing_accepted.touched && !data_processing_accepted.valid"
                              class="error-message">
                            {{ 'VALIDATION_REQUIRED' | trns }}
                        </span>
                    </ng-container>

                    <ion-button expand="block"
                                color="primary"
                                type="submit"
                                [disabled]="!f.form.valid">
                        <ng-container *ngIf="!toc_refresh">{{ 'REGISTRATION_BUTTON_REGISTER' | trns }}</ng-container>
                        <ng-container *ngIf="toc_refresh">{{ 'TOC_CONFIRM_BUTTON' | trns }}</ng-container>
                    </ion-button>

                    <ion-item class="ion-no-lines ion-no-padding">
                        {{ 'TOC_YOU_CAN_GO_TO_LOGIN' | trns }}&nbsp;
                        <a class="link pointer"
                           (click)="logout()">
                            {{ 'TOC_GO_TO_LOGIN' | trns }}
                        </a>
                    </ion-item>

                    <ng-container *ngIf="toc_refresh">
                        <hr>
                        <div class="or-divider">{{ 'LOGIN_OR' | trns }}</div>
                        <ion-button color="secondary"
                                    class="social"
                                    type="button"
                                    expand="block"
                                    (click)="openDestroyAccount()">
                            {{ 'PROFILE_EDIT_DELETE_ACCOUNT' | trns }}
                        </ion-button>
                    </ng-container>

                </ion-list>
            </form>
            <div *ngIf="!plt.isConnected">
                {{ 'LOGIN_APP_IS_OFFLINE' | trns }}
            </div>
        </div>
    </div>
</ion-content>