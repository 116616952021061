<ng-container *ngIf="!user">
    <div class="content-centered-column">
        <div class="content-centered-row">
            <ion-spinner></ion-spinner>
        </div>
    </div>
</ng-container>

<ion-grid class="list ion-no-padding">
    <ion-row class="item" *ngFor="let participant of appointment.participants; let i = index">
        <ion-col size="2"
                 class="ion-text-center">
                 <ion-avatar class="ion-align-self-center"
                             (click)="showAttendeeDetail(participant)">
                     <img img-avatar
                          [src]="user?.canPicture(participant) ? participant.picture_url : ''"
                          [title]="participant.getFullName()">
                 </ion-avatar>
        </ion-col>

        <ion-col size="6"
                 class="name">
                <span (click)="showAttendeeDetail(participant)"
                      [ngClass]="{'pointer': user.canProfileDetails(participant)}">
                    {{participant.getFullName() | cut: 24:'...':false}}
                    <ng-container *ngIf="participant.company"><br /><span class="company">{{participant.company}}</span></ng-container>
                </span>
        </ion-col>

        <ion-col size="4"
                 class="status">
                 <span class="pending"
                       *ngIf="participant.pivot?.status === 0"><span class="dot"></span>{{ 'APPOINTMENTS_STATUS_PENDING' | trns }}</span>
                 <span class="confirmed"
                       *ngIf="participant.pivot?.status === 1 || (!participant.pivot?.status && participant.pivot?.status !== 0 && appointment.status != 3) "><span class="dot"></span>{{ 'APPOINTMENTS_STATUS_CONFIRMED' | trns }}</span>
                 <span class="canceled"
                       *ngIf="participant.pivot?.status === 2 || participant.pivot?.status === 3 || (!participant.pivot?.status && participant.pivot?.status !== 0 && appointment.status === 3)"><span class="dot"></span>{{ 'APPOINTMENTS_STATUS_CANCELED' | trns }}</span>
        </ion-col>
    </ion-row>
</ion-grid>
