var QRReader;

QRReader = {
    active: false,
    webcam: null,
    canvas: null,
    ctx: null,
    decoder: null,
    streaming: false,
    isAllowed: false
};


function isiOS() {
    return ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
}

function isMediaStreamAPISupported() {
    return navigator && navigator.mediaDevices && 'enumerateDevices' in navigator.mediaDevices;
}

QRReader.setCanvas = () => {
    QRReader.canvas = document.createElement('canvas');
    QRReader.ctx = QRReader.canvas.getContext('2d');
};

function setPhotoSourceToScan(forSelectedPhotos?) {
    if (!forSelectedPhotos && isMediaStreamAPISupported()) {
        QRReader.webcam = document.querySelector('video');
    } else {
        QRReader.webcam = document.querySelector('img');
    }
}

function getConstraints(device) {
    var constraints;
    if (device.length > 1) {
        constraints = {
            video: {
                mandatory: {
                    sourceId: device[1].deviceId ? device[1].deviceId : null
                }
            },
            audio: false
        };

        if (isiOS()) {
            constraints.video.facingMode = 'environment';
        }
    } else if (device.length) {
        constraints = {
            video: {
                mandatory: {
                    sourceId: device[0].deviceId ? device[0].deviceId : null
                }
            },
            audio: false
        };

        if (isiOS()) {
            constraints.video.facingMode = 'environment';
        }

    } else {
        constraints = { video: true };
    }

    return constraints;
}

QRReader.destroy = () => {
    QRReader.active = false;
    if (isMediaStreamAPISupported()) {
        navigator.mediaDevices
            .enumerateDevices()
            .then(function(devices) {
                var device = devices.filter(function(device) {
                    if (device.kind == 'videoinput') {
                        return device;
                    }
                });

                navigator.mediaDevices
                    .getUserMedia(getConstraints(device))
                    .then(function(stream) {
                        stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    })
                    .catch(function(err) {
                        console.log('Error occurred ', err);
                    });
            })
            .catch(function(err) {
                console.error('Error occurred : ', err);
            });
    }
}

QRReader.init = (callback) => {
    var baseurl = '/assets/js/';
    QRReader.streaming = false;

    // Init Webcam + Canvas
    setPhotoSourceToScan();

    QRReader.setCanvas();
    QRReader.decoder = new Worker(baseurl + 'decoder.js');

    if (isMediaStreamAPISupported()) {
        // Resize webcam according to input
        if (QRReader.webcam) {
            try {
                QRReader.webcam.addEventListener(
                    'play',
                    function(ev) {
                        if (!QRReader.streaming) {
                            setCanvasProperties();
                            QRReader.streaming = true;
                        }
                    },
                    false
                );
            } catch (err) {
                console.log('Error occurred ', err);
                showErrorMsg();
            }
        } else {
            //return false;
        }
    } else {
        setCanvasProperties();
    }

    function setCanvasProperties() {
        QRReader.canvas.width = window['innerWidth'];
        QRReader.canvas.height = window['innerHeight'];
    }

    function startCapture(constraints) {
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function(stream) {
                callback(true);
                QRReader.isAllowed = true;
                QRReader.webcam.srcObject = stream;
                QRReader.webcam.setAttribute('playsinline', true);
                QRReader.webcam.setAttribute('controls', true);
                setTimeout(() => {
                    document.querySelector('video').removeAttribute('controls');
                });
            })
            .catch(function(err) {
                console.log('Error occurred ', err);
                showErrorMsg();
            });
    }

    if (isMediaStreamAPISupported()) {
        navigator.mediaDevices
            .enumerateDevices()
            .then(function(devices) {
                var device = devices.filter(function(device) {
                    // var deviceLabel = device.label.split(',')[1];
                    if (device.kind == 'videoinput') {
                        return device;
                    }
                });

                startCapture(getConstraints(device));
            })
            .catch(function(error) {
                showErrorMsg();
                console.error('Error occurred : ', error);
            });
    }

    function showErrorMsg() {
        QRReader.isAllowed = false;
        callback(false);
        //window.noCameraPermission = true;
        //document.querySelector('.custom-scanner').style.display = 'none';
        //snackbar.show('Unable to access the camera', 10000);
    }
};

/**
 * \brief QRReader Scan Action
 * Call this to start scanning for QR codes.
 *
 * \param A function(scan_result)
 */
QRReader.scan = function(callback, forSelectedPhotos) {
    QRReader.active = true;
    QRReader.setCanvas();

    // Start QR-decoder
    function newDecoderFrame() {
        QRReader.streaming = true;
        if (!QRReader.active) return;
        try {
            QRReader.ctx.drawImage(QRReader.webcam, 0, 0, QRReader.canvas.width, QRReader.canvas.height);
            var imgData = QRReader.ctx.getImageData(0, 0, QRReader.canvas.width, QRReader.canvas.height);
            if (imgData.data) {
                QRReader.decoder.postMessage(imgData);
                //setTimeout(newDecoderFrame, 300);
            }
        } catch (e) {
            // Try-Catch to circumvent Firefox Bug #879717
            console.info('Error', e)
            if (e.name == 'NS_ERROR_NOT_AVAILABLE') setTimeout(newDecoderFrame, 100);
        }
    }

    function onDecoderMessage(event) {
        if (event.data.length > 0) {
            var qrid = event.data[0][2];
            QRReader.active = false;
            callback(qrid);
        } else {
            setTimeout(newDecoderFrame, 100);
        }
    }

    QRReader.decoder.onmessage = onDecoderMessage;

    setTimeout(() => {
        setPhotoSourceToScan(forSelectedPhotos);
    });

    newDecoderFrame();
};

export default QRReader;
