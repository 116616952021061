import { Component, OnInit, Input } from '@angular/core';

// ionic
import { ModalController } from '@ionic/angular';

// rxjs
import { Subject } from "rxjs";

// other libraries
import { TranslateService } from "@ngx-translate/core";

// providers
import { OverlayService } from "../../services/overlay.service";
import { TrnsService } from '../../services/trns.service';
import { PlatformService } from '../../services/platform.service';

// models
import { Translation } from '../../models/translation';

@Component({
    selector: 'app-translation-admin-add',
    templateUrl: './translation-admin-add.component.html',
    styleUrls: ['./translation-admin-add.component.scss'],
})
export class TranslationAdminAddComponent implements OnInit {

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * user language
     *
     * @type {string}
     */
    @Input() public userLang: string;

    /**
     * translation model
     *
     * @type {Translation}
     */
    public translation: Translation = new Translation();

    /**
     * validate state
     *
     * @type {boolean}
     */
    public validate: boolean = false;

    /**
     * Validation errors
     *
     * @type {object}
     */
    public validationErrors: object = {};

    /**
     * validation errors state
     *
     * @type {boolean}
     */
    public hasValidationErrors: boolean = false;

    /**
      * submit state
      *
      * @type {boolean}
      */
    public submit: boolean = false;

    constructor(
        public overlayService: OverlayService,
        public modalController: ModalController,
        public translate: TranslateService,
        public trnsService: TrnsService,
        public plt: PlatformService
    ) { }

    ngOnInit() { }

    public save(valid: boolean = false) {
        if (valid) {
            this.validationErrors = {};
            this.validate = true;
            this.submit = true;

            this.trnsService.create(this.translation)
                .subscribe(
                    (response) => {
                        this.translate.set(this.translation.key, this.translation.text, this.translation.locale)
                        this.overlayService.showSuccess('saved');
                        this.validate = false;
                        this.submit = false;
                        this.translation.id = response.id;
                        this.close();
                    }, (error) => {
                        // show error message..
                        const data = error.error;

                        if (data.fields) {
                            for (const field in data.fields) {
                                if (data.fields.hasOwnProperty(field)) {
                                    this.hasValidationErrors = true;
                                    this.validationErrors[field] = data.fields[field].join(' ');
                                }
                            }
                        }

                        this.submit = false;
                        this.overlayService.showError(data.message);
                    });
        }
    }

    /**
     * close modal window
     */
    public close() {
        // otherwise close whoe modal
        this.modalController.dismiss({ action: 'close', translation: this.translation });
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.overlayService.closeModals();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
