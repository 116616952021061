export class BroadcastDetail
{
    id: number;
    broadcast_id: number;
    type: string;
    error: string;
    value: string;
    position: number;
    group_index: number;
    group_type: string = '';
    deleted: boolean = false;
    options: {
        type?: string,
        name?: string,
        line?: boolean,
        menu?: boolean,
        filename?: string
        orig_name?: string,
        preview?: string,
        size?: number,
        target?: string,
        error?: string
    } = {};

    /**
     * constructor
     *
     * @param {BroadcastDetail} init
     */
    public constructor(init?: BroadcastDetail) {
        if (!!init) {
            Object.assign(this, init);
        }

        if (!this.options) {
            this.options = {};
        }
    }
}
