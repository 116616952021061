import { VotingTranslation } from "./voting-translation";
import { VotingAnswer } from "./voting-answer";
import { VotingVote } from "./voting-vote";
import { Translatable } from "./translatable.interface";
import * as moment from "moment";

export class Voting implements Translatable
{
    id: number;
    event_id: number;
    user_id: number;
    type: string;
    selectable_count: number;
    response_count: number;
    running_time: number;
    started_at: string;
    paused_at: string;
    closed_at: string;
    updated_at: string;
    translations: VotingTranslation[] = [];
    date_started_at: Date = null;
    date_paused_at: Date = null;
    date_closed_at: Date = null;
    date_updated_at: Date = null;
    answers: VotingAnswer[] = [];
    votes: VotingVote[] = [];
    // translated attributes
    title: string;
    question: string;
    description: string

    /**
     * constructor
     *
     * @param {Voting} init
     */
    public constructor(init?: Voting)
    {
        if (!!init)
        {
            Object.assign(this, init);
            if (this.started_at) this.date_started_at = moment.utc(this.started_at).toDate();
            if (this.paused_at) this.date_paused_at = moment.utc(this.paused_at).toDate();
            if (this.closed_at) this.date_closed_at = moment.utc(this.closed_at).toDate();
            if (this.updated_at) this.date_updated_at = moment.utc(this.updated_at).toDate();

            this.running_time = 1000*init.running_time;

            if (init.answers) {
                this.answers = [];
                init.answers.forEach(answer => {
                    this.answers.push(new VotingAnswer(answer));
                });
            }

        } else {
            this.type = 'answers';
        }
    }

    /**
     * get translated attributes
     *
     * @param locale
     *
     * @return {VotingTranslation}
     */
    public translateOrNew(locale: string)
    {
        let currentTranslation = this.translations.filter(translation => translation.locale === locale )[0];

        if (!currentTranslation)
        {
            currentTranslation        = new VotingTranslation;
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return currentTranslation;
    }

    /**
     * translate
     *
     * @param locale
     * @param defaultLocale
     * @return {VotingTranslation}
     */
    public translate(locale: string, defaultLocale?: string)
    {
        return this.translations.filter(translation => { return translation.locale === locale; })[0] ||
            this.translations.filter(translation => { return translation.locale === defaultLocale; })[0] ||
            new VotingTranslation();
    }
}
