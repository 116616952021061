import * as moment from "moment";

export class EventRecommendation {
    id: number;
    event_id: number;
    status: number = 0;
    title: string;
    subject: string;
    link: string;
    text: string;

    setting: Array<{type:string,enabled:boolean}> = [
        { type: 'facebook', enabled: false },
        { type: 'twitter', enabled: false },
        { type: 'linkedin', enabled: false },
        { type: 'google', enabled: false },
        { type: 'whatsapp', enabled: false },
        { type: 'xing', enabled: false },
        { type: 'email', enabled: false },
        { type: 'copy', enabled: false }
    ];

    updated_at: string = null;
    date_updated_at: Date = null;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: EventRecommendation) {
        if (!!init) {
            Object.assign(this, init);
            this.date_updated_at = moment.utc(this.updated_at).toDate();
        } else {

        }
    }
}
