<ion-header class="ion-no-shadow ion-no-border">
    <ion-toolbar>
        <ng-container>
            <ion-title>{{ 'SELECT_EVENT_GROUP' | translate }}</ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="backClicked()">
                    <ion-icon slot="icon-only"
                              color="dark"
                              name="close"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ng-container>
    </ion-toolbar>
</ion-header>

<div class="scroll-box ion-padding">
    <ng-container *ngIf="!selectedGroup">
        <ng-container *ngFor="let group of groups">
            <ion-button (click)="selectGroup(group)">
                {{group | groupName}}
            </ion-button>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedGroup">
        <form (ngSubmit)="login()"
              #f="ngForm"
              novalidate>
            <ion-list class="form">
                <ion-item class="event-key-input ion-no-lines"
                          [ngClass]="{'open':showEventKey}">
                    <a class="back pointer"
                       (click)="selectedGroup=null">
                        <ion-icon name="arrow-back"></ion-icon>
                        {{ 'LOGIN_EVENT_KEY_BACK' | translate }}
                    </a>
                    <ion-label tabindex="-1"
                               position="stacked">
                        {{ 'REGISTRATION_LABEL_EVENT_KEY' | translate }}
                    </ion-label>
                    <ion-input type="text"
                               name="event_key"
                               placeholder=""
                               [autocomplete]="!!eventKey ? 'off' : 'on'"
                               #event_key="ngModel"
                               #eventInput
                               id="event-input"
                               [(ngModel)]="selectedGroup.event_key">
                    </ion-input>
                    <span *ngIf="plt.is('ios') || plt.is('android')"
                          class="qr"
                          [ngClass]="{'ios': plt.is('ios')}"
                          (click)="showQRCodeReader()">
                        <svg-icon [applyClass]="true"
                                  src="/assets/icons/qr-code.svg">
                        </svg-icon>
                    </span>
                </ion-item>
                <span *ngIf="event_key.touched && !event_key.valid"
                      class="error-message">
                    {{ 'VALIDATION_REQUIRED' | translate }}
                </span>

                <ion-button expand="block"
                            color="primary"
                            type="submit"
                            [disabled]="!f.form.valid">
                    {{ 'LOGIN_BUTTON_LOGIN' | translate }}
                </ion-button>
            </ion-list>
        </form>
    </ng-container>
</div>
<div></div>