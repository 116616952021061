import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment/moment";

// providers
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'dateAgo'
})

export class DateAgoPipe implements PipeTransform {

    constructor(
        public translate?: TranslateService,
    ) { }

    transform(valueInUtc: any, lang: string = ""): string {
        if (valueInUtc) {

            let momentDate = moment.utc(valueInUtc);

            const seconds = Math.floor((+new Date() - +momentDate.toDate()) / 1000);

            // less than 30 seconds ago will show as 'Just now'
            if (seconds < 29) {
                return this.translate.instant('DATE_AGO_PIPE_RIGHT_NOW');
            }

            const intervals = {
                'YEAR': 31536000,
                'MONTH': 2592000,
                'WEEK': 604800,
                'DAY': 86400,
                'HOUR': 3600,
                'MINUTE': 60,
                'SECOND': 1
            };

            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0) {
                    if (counter === 1) {
                        return this.translate.instant('DATE_AGO_PIPE_' + i, { count: counter });
                    } else {
                        return this.translate.instant('DATE_AGO_PIPE_' + i + '_PLURAL', { count: counter });
                    }
                }
            }

        }

        return valueInUtc;
    }
}
