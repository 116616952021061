// group-name.pipe.ts
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { Group } from "../../models/group";
/**
 *
 */
@Pipe({ name: 'groupName' })
export class GroupNamePipe implements PipeTransform {

    constructor(
        public translate: TranslateService,
    ) {}

    /**
     *
     * @param value
     * @returns {string}
     */
    transform(group: Group): string {

        if (group.name) {
            return group.name;
        } else {
            return this.translate.instant('EVENT_GROUP_NAME') + ' ' + group.sort;
        }
    }
}
