<ion-header *ngIf="modal"
            class="ion-no-shadow ion-no-border">
    <ion-toolbar>
        <ion-title class="full"
                   [innerHTML]="'MARKETPLACE_POST_ORDERS' | translate"></ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="backClicked()">
                <ion-icon slot="icon-only"
                          color="dark"
                          name="close">
                </ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<div class="orders">
    <div class="main-header">
        <div class="id">ID</div>
        <div class="amount">{{ 'AMOUNT' | translate }}</div>
        <div class="date">{{ 'DATE' | translate }}</div>
        <div class="more"
             *ngIf="!marketplacePost.digital_purchase_link"></div>
    </div>
    <div class="list">
        <div class="order"
             *ngFor="let order of orders">
            <div class="header"
                 [ngClass]="{'pointer': order.city, 'opened': order.showDetails && order.city}"
                 (click)="order.showDetails = !order.showDetails">
                <div class="id">{{ order.id }}</div>
                <div class="amount">{{ order.amount }}</div>
                <div class="date">{{ order.created_at | datex: 'dateTimeFormatDefault' }}</div>
                <div class="more"
                     *ngIf="!marketplacePost.digital_purchase_link">
                    <ion-icon *ngIf="!order.showDetails"
                              name="chevron-down-outline">
                    </ion-icon>
                    <ion-icon *ngIf="order.showDetails"
                              name="chevron-up-outline">
                    </ion-icon>
                </div>
            </div>
            <div *ngIf="order.city"
                 class="details"
                 [ngClass]="{'opened': order.showDetails && order.city}">
                {{ order.firstname ? order.firstname : order.participant.firstname }} {{ order.lastname ? order.lastname : order.participant.lastname }}<br />
                <ng-container *ngIf="order.participant?.user?.email">{{ order.participant?.user?.email }}<br /></ng-container>
                <ng-container *ngIf="order.company || order.department">{{order.company}}<ng-container *ngIf="order.company && order.department">, </ng-container>{{order.department}}<br /></ng-container>
                {{order.street}} {{order.streetnr}}<br />
                {{order.zip}} {{order.city}}<br />
                {{order.country }}<br />
            </div>
        </div>
    </div>
</div>
