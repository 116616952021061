import { Component, OnDestroy, Input, OnInit } from '@angular/core';

// ionic
import { PopoverController } from '@ionic/angular';

// rxjs
import { Subject } from "rxjs";
import { debounceTime, take, takeUntil } from "rxjs/operators";

// models
import { Participant } from "../../models/participant";
import { MarketplacePost } from "../../models/marketplace-post";
import { User } from "../../models/user";

// providers
import { PlatformService } from "../../services/platform.service";
import { ParticipantService } from "../../services/participant.service";
import { OverlayService } from "../../services/overlay.service";
import { UserService } from "../../services/user.service";

@Component({
    selector: 'marketplace-action-menu',
    templateUrl: './marketplace-action-menu.component.html',
    styleUrls: ['./marketplace-action-menu.component.scss']
})
export class MarketplaceActionMenu implements OnDestroy, OnInit {

    /**
     * selected marketplace
     *
     * @type string
     */
    @Input() post: MarketplacePost;

    /**
     * current event
     *
     * @type {User}
     */
    public user: User;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    public newsfeed_muted: boolean = false;
    public marketplace_muted: boolean = false;

    /**
     * constructor
     *
     * @param eventService
     */
    constructor(
        public plt: PlatformService,
        public overlayService: OverlayService,
        public popoverController: PopoverController,
        public participantService: ParticipantService,
        public userService: UserService
    ) {}

    ngOnInit() {
        // subscribe to current user
        this.userService.getCurrentUser().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(0)
        ).subscribe(
            (user) => {
            if (user.id) {
                this.user = user;

                if (this.user.blacklist) {
                    if (this.user.blacklist.marketplace_participants.find(item => item == this.post.participant_id)) {
                        this.marketplace_muted = true;
                    }
                }

            } else {
                this.user = null;
            }
        });
    }

    async mute() {
        await this.popoverController.dismiss({
            item: this.post,
            action: 'mute'
        });
    }

    async unmute() {
        await this.popoverController.dismiss({
            item: this.post,
            action: 'unmute'
        });
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
