import { Injectable } from "@angular/core";

// rxjs
import { Observable } from "rxjs";

// other services
import { HttpClient } from '@angular/common/http';

// models
import { ChatGroup } from "../models/chat-group";
import { ChatGroupMessage } from '../models/chat-group-message';


// configuration
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ChatGroupsService {

    /**
     * constructor
     *
     * @param jwtHttp
     */
    constructor(
        private http: HttpClient
    ) { }

    /**
     * get list by event
     *
     * @return {Observable<any>}
     */
    public getListByEvent(eventId: number, params = {}): Observable<any> {
        return this.http.post(environment.api + '/chat-group/getListByEvent/' + eventId, params);
    }

    /**
     * get participant list
     *
     * @return {Observable<any>}
     */
    public getParticipantList(chatGroupId: number, length: number, start: number, params: any = {}): Observable<any> {
        let postParams = {};
        postParams['limit'] = length;
        postParams['offset'] = start;
        postParams['order'] = {};
        postParams['invited'] = params.invited;

        if (params.search && !!params.search.value) {
            postParams['search'] = params.search.value;
        }

        if (params.hasOwnProperty('order')) {
            params.order.forEach((order) => {
                if (order.column == 0) {
                    postParams['order']['lastname'] = order.dir;
                }
                if (order.column == 1) {
                    postParams['order']['email'] = order.dir;
                }
                if (order.column == 2) {
                    postParams['order']['created_at'] = order.dir;
                }
            });
        }

        return this.http.post(environment.api + '/chat-group/' + chatGroupId + '/participants', postParams);
    }

    /**
     * get by id
     *
     * @return {Observable<any>}
     */
    public getById(id: number): Observable<any> {
        return this.http.get(environment.api + '/chat-group/' + id);
    }

    /**
     * create
     *
     * @param chatGroup
     *
     * @return {Observable<any>}
     */
    public create(chatGroup: ChatGroup): Observable<any> {
        return this.http.post(environment.api + '/chat-group', chatGroup);
    }

    /**
     * update
     *
     * @param chatGroup
     *
     * @return {Observable<any>}
     */
    public update(chatGroup: ChatGroup): Observable<any> {
        return this.http.put(environment.api + '/chat-group/' + chatGroup.id, chatGroup);
    }

    /**
     * invite participans to chat group
     *
     * @param chatGroup
     *
     * @return {Observable<any>}
     */
    public invite(chatGroup: ChatGroup): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + chatGroup.id + '/invite', chatGroup);
    }

    /**
     * invite participans to chat group
     *
     * @param chatGroupId
     * @param participantId
     *
     * @return {Observable<any>}
     */
    public inviteParticipant(chatGroupId: number, participantId: number): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + chatGroupId + '/inviteParticipant', {
            participantId: participantId
        });
    }

    /**
     * cancel participant invitation
     *
     * @param id - participant id
     * @param chatGroupId
     * @returns {Observable<any>}
     */
    public cancelInvitation(id: number, chatGroupId: number): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + chatGroupId + '/cancelInvitation', { participant_id: id });
    }

    /**
     * update
     *
     * @param chatGroup
     *
     * @return {Observable<any>}
     */
    public delete(chatGroup: ChatGroup): Observable<any> {
        return this.http.delete(environment.api + '/chat-group/' + chatGroup.id);
    }

    /**
     * update
     *
     * @param chatGroup
     *
     * @return {Observable<any>}
     */
    public removeParticipant(chatGroupId: number, participantId: number): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + chatGroupId + '/removeParticipant', {
            participant_id: participantId
        });
    }

    /**
     * set admin
     *
     * @param chatGroup
     *
     * @return {Observable<any>}
     */
    public setParticipantAdmin(chatGroupId: number, participantId: number): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + chatGroupId + '/add-admin', {
            participant_id: participantId,
            role: 'owner'
        });
    }

    /**
     * join chat group
     *
     * @param {number} chatGroupId
     *
     * @return {Observable<any>}
     */
    public joinChatGroup(chatGroupId: number, password?): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + chatGroupId + '/join', {
            password: password
        });
    }

    /**
     * leave chat group
     *
     * @param {number} chatGroupId
     *
     * @return {Observable<any>}
     */
    public leaveChatGroup(chatGroupId: number): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + chatGroupId + '/leave', {});
    }

    /**
     * get list of messages for chat group
     *
     * @param chatGroup
     *
     * @return {Observable<any>}
     */
    public getMessagesByChannelId(chatGroupId: number, offset: number = 0, limit: number = 50, searchMessage: string = ''): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + chatGroupId + '/messages', {
            offset: offset,
            limit: limit,
            search: searchMessage
        });
    }

    /**
     * send message
     *
     * @param {number} id
     * @param {ChatGroupMessage} chatGroupMessage
     *
     * @return {Observable<any>}
     */
    public sendMessage(chatGroupId: number, chatGroupMessage: ChatGroupMessage, filename?: string): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + chatGroupId + '/message', { ...chatGroupMessage, filename: filename });
    }

    /**
     * send message
     *
     * @param id
     * @param conversationMessage
     *
     * @return {Observable<any>}
     */
    public updateMessage(id: number, chatGroupMessage: ChatGroupMessage): Observable<any> {
        return this.http.put(environment.api + '/chat-group/' + chatGroupMessage.chat_group_id + '/message/' + id, chatGroupMessage);
    }

    /**
     * destroy message by id
     *
     * @param {number} id ID of message
     *
     * @return {Observable<any>}
     */
    public deleteMessage(id: number, chatGroupMessage: ChatGroupMessage): Observable<any> {
        return this.http.delete(environment.api + '/chat-group/' + chatGroupMessage.chat_group_id + '/message/' + id);
    }

    /**
     * mark chat group as read
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public markAsRead(id: number): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + id + '/messages/mark-as-read', {});
    }

    /**
     * mute notifications
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public muteNotifications(id: number): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + id + '/mute-notifications', {});
    }

    /**
     * unmute notifications
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public unmuteNotifications(id: number): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + id + '/unmute-notifications', {});
    }

    /**
     * add chat group admin
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public addAdmin(id: number, participantId, role = 'moderator'): Observable<any> {
        return this.http.post(environment.api + '/chat-group/' + id + '/add-admin', {
            role: role,
            participant_id: participantId
        });
    }

    /**
     * remove admin
     *
     * @param id - chat group admin id
     *
     * @return {Observable<any>}
     */
    public deleteAdmin(id: number): Observable<any> {
        return this.http.post(environment.api + '/chat-group/delete-admin/' + id, {});
    }
}
