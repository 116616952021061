import * as moment from "moment";

import { Participant } from "./participant";
import { CustomParticipant } from "./custom-participant";

export class MarketplaceComment {
    id: number;
    marketplace_post_id: number;
    participant_id: number;
    custom_participant_id: number;
    message: string;
    updated_at: string;
    date_updated_at: Date = new Date();
    created_at: string;
    date_created_at: Date = new Date();

    hidden: boolean = false;
    participant: Participant;
    custom_participant: CustomParticipant = new CustomParticipant;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: MarketplaceComment) {
        if (!!init) {
            Object.assign(this, init);
            if (this.updated_at) this.date_updated_at = moment.utc(this.updated_at).toDate();
            if (this.created_at) this.date_created_at = moment.utc(this.created_at).toDate();

            if (init.custom_participant) {
                this.custom_participant = new CustomParticipant(init.custom_participant);
            }

            if (init.participant) {
                this.participant = new Participant(init.participant);
            }
        }
    }

    /**
     * return real participant if possible
     * @return Participant || false
     */
    public getRealParticipant() {
        let participant = null;

        // selected participant by organizer
        if (this.custom_participant) {
            if (this.custom_participant.participant_id) {
                participant = this.custom_participant.participant;
            }
        }

        // normal participant
        else if (this.participant_id) {
            participant = this.participant;
        }

        return participant || false;
    }
}
