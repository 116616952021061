<ion-header class="ion-no-shadow ion-no-border">
    <ion-toolbar>
        <ion-title>Translation versions</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon slot="icon-only"
                          color="dark"
                          name="close">
                </ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-grid>
    <ion-row>
        <ion-col class="ion-no-padding">
            Created at
        </ion-col>
        <ion-col class="ion-no-padding">
            Version
        </ion-col>
        <ion-col class="ion-no-padding">
        </ion-col>
    </ion-row>
    <ion-row *ngFor="let record of records">
        <ion-col class="ion-no-padding">
            {{record.date_created_at | datex: 'dateTimeFormatDefault' }}
        </ion-col>
        <ion-col class="ion-no-padding">
            {{record.version}}
        </ion-col>
        <ion-col class="ion-no-padding">
            <ion-button (click)="restoreVersion(record.version)">
                restore
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>