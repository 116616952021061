export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function is_hexadecimal(str) {
    let regexp = /^[0-9a-fA-F]+$/;

    if (regexp.test(str)) {
        return true;
    }
    else {
        return false;
    }
}

export function shadeColor(color, percent: number) {

    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    // black hotfix
    if (R == 0) R = 32;
    if (G == 0) G = 32;
    if (B == 0) B = 32;

    R = parseInt('' + R * (100 + percent) / 100);
    G = parseInt('' + G * (100 + percent) / 100);
    B = parseInt('' + B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    let RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    let GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    let BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));
    if (is_hexadecimal(RR + GG + BB)) {
        return "#" + RR + GG + BB;
    } else {
        return color;
    }
}
