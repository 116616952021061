import { MarketplaceKeywordTranslation } from "./marketplace-keyword-translation";
import { Translatable } from "./translatable.interface";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { DatexPipe } from "../pipes/datex/datex";

export class MarketplaceKeyword implements Translatable {
    id: number;
    marketplace_container_id: number;
    updated_at: string;
    date_updated_at: Date = new Date();
    created_at: string;
    date_created_at: Date = new Date();

    participant_id: number;
    approved_at: string;
    date_approved_at: Date;
    translations: MarketplaceKeywordTranslation[] = [];
    pivot: any;

    containerName: string;
    displayEditModal = false;
    emptyKeyword = false;
    displayRejectSuggestedKeywordModal = false;
    tempId: number = parseInt((Math.random() + 1).toString().replace('.', ''), 0);
    reject_reason: string;

    hash: number;
    modified = false;
    unsaved = false;
    name: string;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: MarketplaceKeyword) {
        if (!!init) {
            Object.assign(this, init);
            if (this.updated_at) this.date_updated_at = moment.utc(this.updated_at).toDate();
            if (this.created_at) this.date_created_at = moment.utc(this.created_at).toDate();
        }

        const datePipe = new DatexPipe();
    }

    /**
     * Creates a hash of the object with all relevant fields and translations included and stores it optionally.
     *
     * @param {boolean} [store]
     * @returns {number}
     */
    public getHash(store?: boolean) {
        let string = this.id + this.marketplace_container_id + this.participant_id + this.approved_at + this.reject_reason + this.tempId;
        // if any of the translations is missing, return false
        for (let t = 0; t < this.translations.length; t++) {
            if (this.translations[t].name && this.translations[t].name.trim() !== '') {
                string += this.translations[t].name.trim();
            }
        }
        if (string) {
            const hash = string.split("").reduce(function(a, b) {
                a = ((a << 5) - a) + b.charCodeAt(0);
                return a & a;
            }, 0);
            if (store) {
                this.hash = hash;
            } else {
                return hash;
            }
        }
    }

    public isModified() {
        return this.unsaved || (this.getHash() !== this.hash);
    }

    /**
     * get translated attributes
     *
     * @param locale
     *
     * @return {MarketplaceKeywordTranslation}
     */
    public translateOrNew(locale: string) {
        let currentTranslation = this.translations.filter(translation => translation.locale === locale)[0];

        if (!currentTranslation) {
            currentTranslation = new MarketplaceKeywordTranslation;
            currentTranslation.locale = locale;

            this.translations.push(currentTranslation);
        }

        return currentTranslation;
    }

    /**
     * translate
     *
     * @param locale
     * @param defaultLocale
     * @param any
     * @return {MarketplaceKeywordTranslation}
     */
    public translate(locale: string, defaultLocale?: string, any: boolean = true) {
        let tl = this.translations.filter(translation => {
            return translation.locale === locale;
        })[0];

        if (!tl) {
            if (any) {
                tl = this.translations.filter(translation => {
                    return !!translation.name;
                })[0];
            } else {
                tl = new MarketplaceKeywordTranslation();
            }
        }

        return tl;

        // return this.translations.filter(translation => { return translation.locale === locale; })[0] ||
        //     this.translations.filter(translation => { return translation.locale === defaultLocale; })[0] ||
        //     new KeywordTranslation();
    }

    /**
     * Removes a translation (to get not submitted if deactivated)
     *
     * @param locale
     */
    public removeTranslation(locale: string) {
        this.translations.forEach((translation, index) => {
            if (translation.locale === locale) {
                this.translations.splice(index, 1);
            }
        });
    }

    /**
     * Looks, if all given locales are already translated
     *
     * @param locales
     * @returns {boolean}
     */
    public hasTranslationsInLocales(locales) {
        let localeOk = false;
        for (let i = 0; i < locales.length; i++) {
            // if any of the translations is missing, return false
            for (let t = 0; t < this.translations.length; t++) {
                if (this.translations[t].locale === locales[i] && this.translations[t].name && this.translations[t].name.trim() !== '') {
                    localeOk = true;
                }
            }
        }
        return localeOk;
    }

    /**
     * Get the id or a temporary id as fallback, if no id is present
     *
     * @param fallback
     */
    public getId(fallback: boolean = false) {
        return this.id || (fallback ? this.tempId : this.id);
    }

    /**
     * Determine, if the keyword can be used for matching
     *
     * @param {string[]} availableLocales
     * @returns {boolean | string}
     */
    public isValid(availableLocales: string[]) {
        return this.hasTranslationsInLocales(availableLocales) && (!this.participant_id || this.approved_at);
    }
}
