import * as moment from 'moment/moment';

export class TranslationHistory {
    id: number;
    key: string;
    text: string;
    locale: string;
    source: string;
    user_id: number;
    translation_id;

    edit: boolean = false;
    match: boolean = true;
    // translation in other languages
    other: {} = {};

    // timestamps
    created_at: string;
    date_created_at: Date;
    updated_at: string;
    date_updated_at: Date;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: TranslationHistory) {
        if (!!init) {
            Object.assign(this, init);

            this.date_created_at = moment.utc(this.created_at).toDate();
            this.date_updated_at = moment.utc(this.updated_at).toDate();
        }
    }
}
