// round.pipe.ts
import { Pipe, PipeTransform } from "@angular/core";
import { Keyword } from "../../models/keyword";

/**
 *
 */
@Pipe({
    name: 'keywordEnabled',
    pure: false
})
export class KeywordEnabledPipe implements PipeTransform {
    /**
     * @param allKeywords
     * @param column
     * @param selectedKeywords
     * @param locales
     * @returns {number}
     */
    transform(allKeywords: Keyword[], column: number, selectedKeywords: Keyword[], locales: any, participantId): any
    {
        return allKeywords.filter(keyword =>
        {
            for (let k = 0; k < selectedKeywords.length; k++)
            {
                const item = selectedKeywords[k];

                if (
                    keyword.id === item.id &&
                    column === item.pivot.column &&
                    keyword.isValid(locales, participantId))
                {
                    return true;
                }
            }

            return false;
        });
    }
}
