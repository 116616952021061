import * as moment from "moment";
import { Participant } from "./participant";

export class MarketplaceLike
{
    id: number;
    marketplace_post_id: number;
    participant_id: number;
    participant: Participant;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: MarketplaceLike) {
        if (!!init) {
            Object.assign(this, init);

            if (this.participant) {
                this.participant = new Participant(this.participant);
            }
        }
    }
}
