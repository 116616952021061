import { Injectable } from '@angular/core';

// rxjs
import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';

// models
import { Participant } from "../models/participant";
import { Keyword } from "../models/keyword";
import { Blacklist } from "../models/blacklist";

// config
import { environment } from "../../environments/environment";

import { AutoCompleteService } from './auto-complete.service';

@Injectable({
    providedIn: 'root'
})
export class ParticipantService implements AutoCompleteService {

    /**
     * current event id
     *
     * @type {number}
     */
    public eventId;

    /**
     * current linked item for conversation
     *
     */
    public linkedItem;

    /**
     * for searching, it defines if we will have result with/without own profile
     * @type {boolean}
     */
    public withOwnProfile = false;

    /**
     * options used for searching with autocomplete - params used in search request
     * @type {Object}
     */
    public autocompleteOptions;

    /**
     * constructor
     *
     * @param jwtHttp
     */
    constructor(
        private http: HttpClient
    ) { }

    /**
     * get participant by id
     *
     * @param participantId
     *
     * @return {Observable<any>}
     */
    public getById(participantId: number, silent: boolean = false, eventId: number = null): Observable<any> {

        let params = new HttpParams();
        params = params.append('silent', (silent ? 'true' : 'false'));
        params = params.append('apiVersion', sessionStorage.getItem('api-version') || 'prod');
        if (eventId) {
            params = params.append('eventId', eventId.toString());
        }

        return this.http.get(environment.api + '/participant/' + participantId, { params: params });
    }

    /**
     * update participant
     *
     * @param participant
     *
     * @return {Observable<any>}
     */
    public update(participant: Participant): Observable<any> {
        return this.http.put(environment.api + '/participant/' + participant.id, participant);
    }

    /**
     * destroy participant by id
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public delete(id: number): Observable<any> {
        return this.http.delete(environment.api + '/participant/' + id);
    }

    /**
     * Send invitations to participants
     *
     * @param participantId
     * @param column
     * @param keywordId
     * @returns {Observable<any>}
     */
    public toggleKeyword(participantId: number, column: number, keywordId: number): Observable<any> {

        let params = new HttpParams();
        params = params.append('apiVersion', sessionStorage.getItem('api-version') || 'prod');

        return this.http.post(environment.api + '/participant/toggleKeyword/' + participantId, {
            column: column,
            keywordId: keywordId,
        }, { params: params });
    }

    /**
     * Suggest keyword
     *
     * @param participantId
     * @param keyword
     * @returns {Observable<any>}
     */
    public suggestKeyword(participantId: number, keyword: Keyword): Observable<any> {
        return this.http.post(environment.api + '/participant/suggestKeyword/' + participantId, {
            keyword: keyword
        });
    }

    /**
     * Get a list of participants for an event
     *
     * @param id
     * @returns {Observable<any>}
     */
    public getListByEvent(id: number): Observable<any> {
        return this.http.get(environment.api + '/participant/getListByEvent/' + id);
    }

    /**
     * Get a list of participants for an event
     *
     * @param id
     * @returns {Observable<any>}
     */
    public canStartConversation(id: number): Observable<any> {
        return this.http.get(environment.api + '/participant/can-start-conversation/' + id);
    }

    /**
     * Get a filtered list of participants for an event
     *
     * @param id
     * @param limit
     * @param offset
     * @param search
     * @param likes
     * @param bookmarks
     *
     * @returns {Observable<any>}
     */
    public getFilteredListByEvent(
        id: number,
        limit?: number,
        offset?: number,
        search?: string,
        filter: {} = {},
        // likes?: boolean,
        // bookmarks?: boolean,
        order?: string,
        // groupId?: number,
        // jobs?: boolean,
        // keywordIds?: any[],
        // counties?: string[],
    ): Observable<any> {
        let postParams = {
            limit: limit || 0,
            offset: offset || 0,
            search: search || '',
            likes: filter['likes'] || false,
            bookmarks: filter['bookmarks'] || false,
            attendeesTabId: filter['attendeesTabId'] || 0,
            jobs: filter['jobs'] || false,
            keywordIds: filter['keywordIds'] || [],
            counties: filter['counties'] || [],
            chatGroupId: filter['chatGroupId'] || null,
            exludeChatGroupId: filter['exludeChatGroupId'] || null,
            onlyContactable: filter['onlyContactable'] || null,
            hideNotVisibleMatching: filter['hideNotVisibleMatching'] || null,
            hideNotVisibleMessage: filter['hideNotVisibleMessage'] || null,
            withOwnProfile: filter['withOwnProfile'] || null,
            dontCheckRightsForOwnProfile: filter['dontCheckRightsForOwnProfile'] || null,
            address: filter['address'] || null,
            distance: filter['distance'] || null,
            apiVersion: sessionStorage.getItem('api-version') || 'prod',
        };

        if (order) {
            postParams['order'] = {};
            switch (order) {
                case 'matching_factor':
                    postParams['order']['matching_factor'] = 'desc';
                    break;
                case 'lastname':
                    postParams['order']['lastname'] = 'asc';
                    break;
            }
        }

        return this.http.post(environment.api + '/participant/getFilteredListByEvent/' + id, postParams);
    }

    /**
     * Get a list of participants for an event by search attribute
     *
     * @param keyword
     * @returns {Observable<any[]>}
     */
    getResults(keyword: string): Observable<any[]> {

        return this.getFilteredListByEvent(
            this.eventId,
            150, // this should be enought for now
            0,
            keyword,
            this.autocompleteOptions || {
                onlyContactable: true
            }
        );
    }

    getItemLabel(item) {
        if (item.hasOwnProperty('participant_id')) {
            return item.participant_id
        }

        return item.id
    }
    /**
     * Get a list of participants for an event with matches, likes and bookmarks included
     *
     * @param id
     * @returns {Observable<any>}
     */
    public getListByEventWithRelations(id: number): Observable<any> {
        return this.http.get(environment.api + '/participant/getListByEventWithRelations/' + id);
    }

    /**
     * Toggle like
     *
     * @param participantId
     * @param likedParticipantId
     * @returns {Observable<any>}
     */
    public toggleLike(participantId: number, likedParticipantId: number): Observable<any> {
        return this.http.post(environment.api + '/participant/toggleLike/' + participantId, {
            likedParticipantId: likedParticipantId
        });
    }

    /**
     * Toggle bookmark
     *
     * @param participantId
     * @param bookmarkedParticipantId
     * @returns {Observable<any>}
     */
    public toggleBookmark(participantId: number, bookmarkedParticipantId: number): Observable<any> {
        return this.http.post(environment.api + '/participant/toggleBookmark/' + participantId, {
            bookmarkedParticipantId: bookmarkedParticipantId
        });
    }

    /**
     * get last visitors, likers and appointments for participant
     *
     * @param participantId
     *
     * @return {Observable<any>}
     */
    public lastVisitorsLikersAndAppointments(participantId: number): Observable<any> {
        return this.http.get(environment.api + '/participant/lastVisitorsLikersAndAppointments/' + participantId);
    }

    /**
     * get participant by invitation token
     *
     * @param invitation_token
     * @return {Observable<any>}
     */
    public getByInvitationToken(invitation_token: string) {
        return this.http.get(environment.api + '/participant/invitation-token/' + invitation_token);
    }

    /**
     * get participant by secret token
     *
     * @param secret
     * @return {Observable<any>}
     */
    public getByInvitationExternalSecret(secret: string) {
        return this.http.get(environment.api + '/participant/invitation-secret/' + secret);
    }

    /**
     * get participant timeslot
     *
     * @param {number} participantId
     *
     * @return {Observable<any>}
     */
    public getTimeslot(participantId): Observable<any> {
        return this.http.get(environment.api + '/participant/' + participantId + '/timeslot');
    }

    /**
     * create participant timeslot
     *
     * @param {number} participantId
     *
     * @return {Observable<any>}
     */
    public createTimeslot(participantId: number, timeslots): Observable<any> {
        return this.http.post(environment.api + '/participant/' + participantId + '/timeslot', timeslots);
    }

    /**
     * update participant timeslot
     *
     * @param participant
     *
     * @return {Observable<any>}
     */
    public updateTimeslot(participantId: number, timeslots): Observable<any> {
        return this.http.put(environment.api + '/participant/' + participantId + '/timeslot', timeslots);
    }

    /**
     * get participant timeslot
     *
     * @param {number} participantId
     *
     * @return {Observable<any>}
     */
    public getFeeds(participantId: number): Observable<any> {
        return this.http.get(environment.api + '/participant/' + participantId + '/feeds');
    }

    /**
     * set participant feeds visited
     *
     * @return {Observable<any>}
     */
    public setFeedsVisited(): Observable<any> {
        return this.http.post(environment.api + '/participant/feeds-visited', {});
    }

    /**
     * update participant blacklist
     *
     * @param {Blacklist} blacklist
     *
     * @return {Observable<any>}
     */
    public updateBlacklist(blacklist: Blacklist): Observable<any> {
        return this.http.post(environment.api + '/participant/blacklist', blacklist);
    }

    /**
     * update participant hidden status
     *
     * @param participant
     *
     * @return {Observable<any>}
     */
    public hide(participantId: number, status: boolean = false): Observable<any> {
        return this.http.put(environment.api + '/participant/' + participantId + '/hide', { hidden: status });
    }
}
