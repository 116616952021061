import * as moment from "moment";
import { DatexPipe } from "../pipes/datex/datex";

import { EventTimeSlots } from "./event-timeslots";

import { config } from "src/environments/config";

export class ParticipantTimeslot {
    id: number;
    participant_id: number;
    created_at: string;
    date_created_at: Date = new Date();
    updated_at: string;
    date_updated_at: Date = new Date();

    // object with actual structure -> create model...
    timeslots: {} = {};
    // array with allowed timeslots duration
    durations = [];
    // default size of block in minutes
    timeslot_length: number = 5;

  /**
   * constructor
   *
   * @return void
   */
  public constructor(init?: ParticipantTimeslot) {
    if (!!init) {
      Object.assign(this, init);
      this.timeslot_length = init.timeslots['timeslot_length'];
      this.date_created_at = moment.utc(this.created_at).toDate();

      // transform slots to momentjs and reset seconds/miliseconds just for sure
      Object.keys(this.timeslots['items']).forEach((key) => {
        this.timeslots['items'][key].forEach((slot, index) => {
          // set properly type of slots to moment date
          this.timeslots['items'][key][index].start = moment.utc(this.timeslots['items'][key][index].start).set({ second: 0, millisecond: 0 });
          this.timeslots['items'][key][index].end = moment.utc(this.timeslots['items'][key][index].end).set({ second: 0, millisecond: 0 });
        });
      });
    }
    else {
      const datePipe = new DatexPipe();
      this.created_at = datePipe.transform(this.date_created_at, 'yyyy-MM-dd HH:mm:ss');
      this.updated_at = datePipe.transform(this.date_updated_at, 'yyyy-MM-dd HH:mm:ss');
    }

        let duration = this.timeslot_length;
        // NOTE[jg] temporary solution for event without timeslot_max_block_size values
        if (this.timeslot_length == 30) {
            this.durations.push(duration);
            // if (init.timeslots['timeslot_max_block_size']) {
            //     // generate durations, maximum size for appointment request
            //     while (duration <= init.timeslots['timeslot_max_block_size']) {
            //         this.durations.push(duration);
            //         duration += this.timeslot_length;
            //     }
        } else {
            // generate durations, maximum size for appointment request
            while (duration <= config.maxAppointmentBlockSize) {
                this.durations.push(duration);
                duration += this.timeslot_length;
            }
        }
    }

  /**
    * Load setting from event timeslots
    *
    * @param {EventTimeSlots} eventTimeslots
    */
  public loadFromEvent(eventTimeslots: EventTimeSlots) {
    this.timeslot_length = eventTimeslots.timeslot_length;
    this.durations = eventTimeslots.durations;
    this.timeslots['items'] = eventTimeslots.eventTimeslots;
    this.timeslots['timeslot_length'] = eventTimeslots.timeslot_length;
  }

  /**
   * Generate array with day timestamps by block keys
   *
   * @return number[]
   */
  public getDays(past = true) {
    if (this.timeslots['items']) {
      if (past) {
        return Object.keys(this.timeslots['items']).map((day) => {
          return moment(new Date(Number(day))).toDate()
        });
      } else {

        return Object.keys(this.timeslots['items'])
          .filter((day) => {
            return moment().set({ hour: 0, minute: 1, second: 0, millisecond: 0 }).isBefore(moment.utc(this.timeslots['items'][day][0].start).set({ hour: 0, minute: 1, second: 0, millisecond: 0 }))
          })
          .map(day => moment(new Date(Number(day))).toDate());
      }
    } else {
      return [];
    }
  }

  /**
    * Generate dayily offset for first hour
    *
    * @return number[]
    */
  public getDayilyOffset() {
    if (this.timeslots['items']) {
      let dailyOffset = [];
      Object.keys(this.timeslots['items']).forEach((day) => {
        // get first time of the day
        if (this.timeslots['items'][day][0]) {
          let firstTime = moment(this.timeslots['items'][day][0].start);
          if (firstTime.minutes()) {
            dailyOffset[day] = firstTime.minutes() / this.timeslot_length;
          } else {
            dailyOffset[day] = 0;
          }
        }
      });
      return dailyOffset;
    } else {
      return [];
    }
  }

  /**
   * Find proper slots for participant appointments and mark them as blocked
   *
   * @param appointments array of participant appointments
   * @return void
   */
  public markAppointment(appointments, status?: string): void {
    if (appointments) {

      appointments.forEach((appointment) => {
        // now only count with confirmed appointments, appointment without status are from API which is returning only confirmed
        if (!appointment.status || appointment.status == 4 || appointment.status == 1) {
          // prepare date and times from appointment
          let starts_at = moment.utc(appointment.starts_at).set({ second: 0, millisecond: 0 });
          let date_ends_at;
          if (!appointment.ends_at) {
            date_ends_at = moment.utc(appointment.starts_at).set({ second: 0, millisecond: 0 }).add(this.timeslots['timeslot_length'], 'minutes').toDate();
          } else {
            date_ends_at = moment.utc(appointment.ends_at).set({ second: 0, millisecond: 0 }).toDate();
          }

                    let date_starts_at = starts_at.toDate();

          // we need to check all days, because grouping is done by first day start time
          Object.keys(this.timeslots['items']).forEach((day) => {
            this.timeslots['items'][day].forEach((slot, index) => {

              // transform times and round them to minutes
              // check if there is a time collision
              if ((slot.start.toDate() >= date_starts_at && slot.end.toDate() <= date_ends_at)
                || (slot.start.toDate() < date_ends_at && slot.start.toDate() >= date_starts_at && slot.end.toDate() > date_ends_at)) {
                // mark proper timeslots as timeslot with appointment
                if (!status) {
                  this.timeslots['items'][day][index].appointment = appointment;
                } else {
                  this.timeslots['items'][day][index].status = status;
                }
              }
            });
          });
        }
      })
    }
  }

  /**
   * Find same slots for participant timeslots and mark them as blocked
   *
   * @param participantTimeslot
   */
  public markSlot(participantTimeslot) {
    if (participantTimeslot) {
      // compare orther slots with users and mark blocked if there is no free slot
      Object.keys(this.timeslots['items']).forEach((key) => {
        this.timeslots['items'][key].forEach((slot, index) => {
          if (participantTimeslot[key] && participantTimeslot[key][index]
            && participantTimeslot[key][index].status != 'free') {
            this.timeslots['items'][key][index].status = 'blocked';
          } else {
            // check past time and block slots if they are in past
            if (moment(this.timeslots['items'][key][index].start).valueOf() < Date.now()) {
              this.timeslots['items'][key][index].status = 'blocked';
            }
          }
        });
      });
    }
  }

  /**
   * Generate array of pripate meetings - where slots are blocked...
   * It will join blocked slots in rows and also separate by free blocks or blocks with notes
   *
   * @return privateAppointments
   */
  generatePrivateAppointment() {
    let privateAppointments = [];
    let privateAppointment;
    Object.keys(this.timeslots['items']).forEach((key) => {
      this.timeslots['items'][key].forEach((slot, index) => {
        if (slot.status != 'free' && !slot.appointment && !slot.note && privateAppointment) {
          // update end time for block
          privateAppointment.ends_at = moment(slot.end).set({ second: 0, millisecond: 0 });
          privateAppointment.date_ends_at = moment(slot.end).set({ second: 0, millisecond: 0 }).toDate();
        } else {
          // end up actual joined block and put it into array
          if (privateAppointment) {
            privateAppointments.push({ ...privateAppointment });
            privateAppointment = null;
          }
          // create new private meeting
          if (slot.status == 'blocked' && !slot.appointment) {
            privateAppointment = {
              starts_at: moment.utc(slot.start).set({ second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm:ss'),
              ends_at: moment.utc(slot.end).set({ second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm:ss'),
              date_starts_at: moment.utc(slot.start).set({ second: 0, millisecond: 0 }).toDate(),
              date_ends_at: moment.utc(slot.end).set({ second: 0, millisecond: 0 }).toDate(),
              status: 4,
              description: slot.note
            }
          }
        }
      });
      if (privateAppointment) {
        privateAppointments.push({ ...privateAppointment });
        privateAppointment = null; // reset slot for every day
      }
    });

        return privateAppointments;
    }
}
