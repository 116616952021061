import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";

export const dropZoneSettings: DropzoneConfigInterface = {
    // only settings are allowed which won't be overridden by any component
    // overriding the values are global
    url: 'https://httpbin.org/post',
    errorReset: 5000,
    maxFiles: 1,
    maxFilesize: 50,
    acceptedFiles: 'image/*',
    timeout: 1800000
};

export const fileTypeBlacklist: string[] = ['text/plain'];

export const imageExtList: string[] = ['png', 'jpg', 'jpeg', 'gif'];
