export function getThemeConfig(communityId: number = null) {

    let theme = '';

    let communityToc = '';
    let communityPp = '';
    let themeSuffix = '';
    let showHeaderMenu = true;
    let registrationLink = 'https://skillsclub.com/become-a-member/';
    let registrationLinkSamePage = false;
    let headerLogo = '';
    let headerLogoLink = '';
    let disableLogoLink = false;

    let toc = {
        en: 'https://skillsclub.com/terms-and-conditions/',
        de: 'https://skillsclub.com/de/bedingungen-und-konditionen/',
        es: 'https://skillsclub.com/es/terminos-y-condiciones/',
        fr: 'https://skillsclub.com/fr/modalites-et-conditions/',
    };

    let pp = {
        en: 'https://skillsclub.com/privacy-policy/',
        de: 'https://skillsclub.com/de/datenschutzbestimmungen/',
        es: 'https://skillsclub.com/es/politica-de-privacidad/',
        fr: 'https://skillsclub.com/fr/politique-de-confidentialite/',
    };

    // get theme name by community ID
    if (communityId) {
        switch (communityId) {
            case 705:
                // case 518:
                theme = 'geostqb';
                break;
        }
    } else {
        // or by subdomain name
        theme = window.location.host.split('.')[0];
        // for main app dont use community theme
        if (theme == 'app' || theme == 'pwa' || theme.includes('localhost')) {
            theme = '';
        }
    }

    // testing purposes
    // theme = 'stb';

    let community = theme.toUpperCase();

    switch (theme) {
        case 'mstqb':
            communityPp = 'https://www.mstqb.mu/privacy-policy.html';
            break;
        case 'ngstqb':
            communityPp = 'https://www.ngstqb.ng/privacy';
            break;
        case 'stb':
            communityToc = 'https://swisstestingboard.org/wp-content/uploads/2023/10/STB_AGB.pdf';
            communityPp = 'https://swisstestingboard.org/wp-content/uploads/2023/10/STB_DSE.pdf';

            toc = {
                en: 'https://skillsclub.com/de/bedingungen-und-konditionen/',
                de: 'https://skillsclub.com/de/bedingungen-und-konditionen/',
                es: 'https://skillsclub.com/de/bedingungen-und-konditionen/',
                fr: 'https://skillsclub.com/de/bedingungen-und-konditionen/',
            };
        
            pp = {
                en: 'https://skillsclub.com/de/datenschutzbestimmungen/',
                de: 'https://skillsclub.com/de/datenschutzbestimmungen/',
                es: 'https://skillsclub.com/de/datenschutzbestimmungen/',
                fr: 'https://skillsclub.com/de/datenschutzbestimmungen/',
            };

            disableLogoLink = true;
            showHeaderMenu = false;
            // headerLogo = '/assets/images/theme/stb/logo.png';
            headerLogoLink = 'https://swisstestingboard.org';
            registrationLink = 'https://stb.skillsclub.com/register/access-key/werde_mitglied';
            registrationLinkSamePage = true;
            break;
        case 'a4q':
            communityPp = 'https://allianceforqualification.com/privacy-policy-2/';
            break;
        case 'womeninit':
            // communityToc = '#TODO';
            community = 'Women in IT';
            break;
        case 'geostqb':
            // communityToc = '#TODO';
            community = 'GeoSTQB';
            themeSuffix = '_GEOSTQB';
            communityPp = 'https://www.geostqb.org/en/quick-navis/footer/privacy-policy.html';
            break;
        case 'iqbba':
            // communityToc = '#TODO';
            themeSuffix = '_IQBBA';
            break;
    }

    return {
        name: theme,
        community: community,
        toc: toc,
        pp: pp,
        communityToc: communityToc,
        communityPp: communityPp,
        themeSuffix: themeSuffix,
        disableLogoLink: disableLogoLink,
        showHeaderMenu: showHeaderMenu,
        registrationLink: registrationLink,
        registrationLinkSamePage: registrationLinkSamePage,
        headerLogo: headerLogo,
        headerLogoLink: headerLogoLink
    }
}