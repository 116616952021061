import { Injectable, NgZone } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, NavigationStart } from '@angular/router';
import { Events } from '../helpers/events';

import { PlatformService } from "./platform.service";

import { log } from '../helpers/log';
import { MenuController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class RouterExtService {

    private previousUrl: string = '';
    public currentUrl: string = '';
    private history: string[] = [];

    public backNavigation: boolean = false;

    constructor(
        private plt: PlatformService,
        private router: Router,
        private menu: MenuController,
        public ngZone: NgZone,
        private appEvents: Events
    ) {

        this.currentUrl = this.router.url;
        router.events.subscribe(event => {

            if (event instanceof NavigationStart) {

                // solution for offline mode to keep user on proper site
                if (this.previousUrl && !this.plt.isConnected) {
                    // redirect to home allways
                    if (event.url != this.plt.defaultLink + '/home/detail/event') {
                        this.router.navigate([this.plt.defaultLink + '/home/detail/event']);
                    }
                    return;
                }

                log('info', "NavigationStart", event);

                // decide if navigation was back or forward, based on history and type of event
                // popstate -> initialized by browser back or forward button
                let navigateBack = event.navigationTrigger === 'popstate'
                    && (this.history.length == 0 || event.url == this.previousUrl || (this.history.length > 0 && this.history[this.history.length - 1].split('#')[0] == event.url.split('#')[0]));

                log('info', "NavigateBack", navigateBack);

                if (!navigateBack) {

                    if (!this.backNavigation
                        && this.currentUrl != event.url
                        && this.currentUrl != "/"
                        && this.currentUrl != this.history[this.history.length - 1]
                    ) {
                        this.history.push(this.currentUrl);
                    }
                    this.previousUrl = this.currentUrl;
                    this.currentUrl = event.url;
                    this.backNavigation = false;

                } else {
                    // on navigation back check if we can go

                    // if menu is opened, just close it
                    if (this.plt.tabMenu && this.plt.tabMenuOpened) {
                        this.menu.close();
                        // keep user on the same page
                        this.router.navigate([this.currentUrl]);
                    } else {
                        this.popHistory();
                    }
                    if (!this.canGoBack() && localStorage.getItem('token')) {
                        this.previousUrl = this.plt.defaultLink + '/home/info/matching';
                        // don't navigate again when on home already
                        if (event.url != this.plt.defaultLink + '/home/info/matching') {
                            this.router.navigate([this.plt.defaultLink + '/home/info/matching']);
                        }
                    }
                }

                log('info', 'NavigationStart history', this.history);
            }
        });
    }

    public softNavigate(url?: string, replace = false) {

        // do nothing when on same url, empty url is used for modal navigation
        if (url == (window.location.pathname + window.location.search)) {
            return;
        }

        if (!url) {
            if (replace) {
                this.popHistory();
            }
            url = (this.currentUrl ? this.currentUrl.split('#')[0] : '') + '#' + this.history.length;
            this.history.push(this.currentUrl);
        }

        // solve navigation for iframes
        let iframe = <HTMLIFrameElement>document.querySelector('iframe#networking');
        if (!(window === window.parent) && iframe) {
            // do nothing
            // if (replace) {
            //     iframe.contentWindow.history.replaceState({}, '', `${url}`);
            // } else {
            //     iframe.contentWindow.history.pushState({}, '', `${url}`);
            // }
        } else {
            if (replace || this.currentUrl == url) {
                window.history.replaceState({}, '', `${url}`);
            } else {
                window.history.pushState({}, '', `${url}`);
            }
        }
        if (!this.backNavigation
            && this.currentUrl != url
            && this.currentUrl != "/"
            && this.currentUrl != this.history[this.history.length - 1]
        ) {
            this.history.push(this.currentUrl);
        }
        log('info', 'Soft navigate', this.history);
        this.previousUrl = this.currentUrl;
        this.currentUrl = url;
        this.appEvents.publish('route:changed');
    }

    public softBack(updateHistory: boolean = true) {
        let iframe = <HTMLIFrameElement>document.querySelector('iframe#networking');
        if (!(window === window.parent) && iframe) {
            // do nothing
            // iframe.contentWindow.history.back();
        } else {
            window.history.back();
        }

        if (updateHistory) {
            this.popHistory();
        }
    }

    public popHistory() {
        this.currentUrl = this.previousUrl;
        return this.previousUrl = this.history.pop();
    }

    public resetHistory() {
        // set first page
        this.previousUrl = undefined;
        this.history = [this.plt.defaultLink + '/home/info/matching'];
    }

    public emptyHistory(): boolean {
        return this.previousUrl == undefined;
    }


    public getPreviousUrl() {
        return this.previousUrl;
    }

    // NOTE[jg] - will not be used, we use pure browser history
    public canGoBack(): boolean {
        if (this.history.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    // NOTE[jg] - changed, we use pure browser history
    public back() {
        if (this.history.length > 0) {
            this.backNavigation = true;
            // this.ngZone.run(() => {
            let url = this.popHistory();
            this.router.navigate([url]);
            // window.history.back();
            log('info', 'History pop', this.history);
            // });
        } else {
            // go home...
            this.router.navigate([this.plt.defaultLink + 'home/info/matching']);
        }
    }
}
