import { Injectable } from "@angular/core";

// other services
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { Participant } from "../models/participant";

// configuration
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {
    /**
     * constructor
     *
     * @param jwtHttp
     */
    constructor(
        private http: HttpClient
    ) { }

    /**
     * get all by participant
     *
     * @param participant
     * @return {Observable<any>}
     */
    public getAllByParticipant(participant: Participant): Observable<any> {
        return this.http.get(environment.api + '/appointment/' + participant.id);
    }

    /**
     * get appointments and timeslots by participant id
     *
     * @return {Observable<any>}
     */
    public getByParticipantId(id: number): Observable<any> {
        return this.http.get(environment.api + '/appointment/participant/' + id);
    }

    /**
     * generate appointment
     *
     * @param appointment
     * @return {Observable<any>}
     */
    public generateAppointment(appointment): Observable<any> {
        return this.http.post(environment.api + '/appointment/generate', appointment);
    }

    /**
     * add participants to appointment
     *
     * @param appointment
     * @return {Observable<any>}
     */
    public addParticipants(appointmentId: number, participants: Array<Participant>, eventId: number): Observable<any> {
        return this.http.post(environment.api + '/event/' + eventId + '/appointment/' + appointmentId + '/participants', {
            participants: participants
        });
    }
}
