import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";

// config
import { dropZoneSettings } from "../settings/dropzone.settings";

@Injectable({
    providedIn: 'root'
})
export class DropzoneService {
    /**
     * dropzone config
     *
     * @type {DropzoneConfigInterface}
     */
    public dropzoneConfig: DropzoneConfigInterface = dropZoneSettings;

    /**
     * constructor
     *
     * @param jwtHttp
     * @param translate
     */
    constructor(
        private http: HttpClient,
        public translate: TranslateService
    ) {
        this.translateConfig();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translateConfig();
        });

    }

    public translateConfig() {
        // The text used before any files are dropped.
        this.dropzoneConfig.dictDefaultMessage = this.translate.instant('DROPZONE_INFO') + ' <span>(max. 5 MB, min. 200x200px)</span>';//this.translate.instant('DROPZONE_DEFAULT_MESSAGE');
        // The text that replaces the default message text it the browser is not supported.
        this.dropzoneConfig.dictFallbackMessage = this.translate.instant('DROPZONE_FALLBACK_MESSAGE');
        // The text that will be added before the fallback form.
        // If you provide a  fallback element yourself, or if this option is `null` this will be ignored.
        this.dropzoneConfig.dictFallbackText = this.translate.instant('DROPZONE_FALLBACK_TEXT');
        // If the filesize is too big.
        // `{{filesize}}` and `{{maxFilesize}}` will be replaced with the respective configuration values.
        this.dropzoneConfig.dictFileTooBig = this.translate.instant('DROPZONE_FILE_TOO_BIG');
        // If the file doesn't match the file type.
        this.dropzoneConfig.dictInvalidFileType = this.translate.instant('DROPZONE_INVALID_FILE_TYPE');
        // If the server response was invalid.
        // `{{statusCode}}` will be replaced with the servers status code.
        this.dropzoneConfig.dictResponseError = this.translate.instant('DROPZONE_RESPONSE_ERROR');
        // If `addRemoveLinks` is true, the text to be used for the cancel upload link.
        this.dropzoneConfig.dictCancelUpload = this.translate.instant('DROPZONE_CANCEL_UPLOAD');
        // If `addRemoveLinks` is true, the text to be used for confirmation when cancelling upload.
        this.dropzoneConfig.dictCancelUploadConfirmation = this.translate.instant('DROPZONE_UPLOAD_CONFIRMATION');
        // If `addRemoveLinks` is true, the text to be used to remove a file.
        this.dropzoneConfig.dictRemoveFile = this.translate.instant('DROPZONE_REMOVE_FILE');
        // If this is not null, then the user will be prompted before removing a file.
        this.dropzoneConfig.dictRemoveFileConfirmation = null; // this.translate.instant('DROPZONE_REMOVE_FILE_CONFIRMATION');
        // Displayed if `maxFiles` is st and exceeded.
        // The string `{{maxFiles}}` will be replaced by the configuration value.
        this.dropzoneConfig.dictMaxFilesExceeded = this.translate.instant('DROPZONE_MAX_FILES_EXCEEDED');
        // Allows you to translate the different units. Starting with `tb` for terabytes and going down to `b` for bytes.
        this.dropzoneConfig.dictFileSizeUnits = {
            tb: this.translate.instant('TERABYTE_SHORT'),
            gb: this.translate.instant('GIGABYTE_SHORT'),
            mb: this.translate.instant('MEGABYTE_SHORT'),
            kb: this.translate.instant('KILOBYTE_SHORT'),
            b: this.translate.instant('BYTE_SHORT')
        };
    }

}
