export class MarketplaceTranslation
{
    id: number;
    locale: string;
    name: string;
    description: string;

    /**
     * constructor
     *
     * @param {MarketplaceTranslation} init
     */
    public constructor(init?: MarketplaceTranslation)
    {
        if (!!init)
        {
            Object.assign(this, init);
        }
    }

}
