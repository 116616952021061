import { Injectable } from '@angular/core';

// other services
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { Broadcast } from "../models/broadcast";

// configuration
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class BroadcastService {
    /**
     * constructor
     *
     * @param jwtHttp
     * @param appEvents
     */
    constructor(
        private http: HttpClient
    ) {
    }

    /**
     * get list by event
     *
     * @return {Observable<any>}
     */
    public getListByEvent(eventIc: number): Observable<any> {
        return this.http.get(environment.api + '/broadcast/getListByEvent/' + eventIc);
    }

    /**
     * get by id
     *
     * @return {Observable<any>}
     */
    public getById(eventId: number, id: number): Observable<any> {
        return this.http.get(environment.api + '/event/' + eventId + '/broadcast/' + id);
    }

    /**
     * create
     *
     * @param broadcast
     *
     * @return {Observable<any>}
     */
    public create(broadcast: Broadcast): Observable<any> {
        return this.http.post(environment.api + '/broadcast', broadcast);
    }

    /**
     * update
     *
     * @param broadcast
     *
     * @return {Observable<any>}
     */
    public update(broadcast: Broadcast): Observable<any> {
        return this.http.put(environment.api + '/broadcast/' + broadcast.id, broadcast);
    }

    /**
     * destroy by id
     *
     * @param id
     *
     * @return {Observable<any>}
     */
    public delete(id: number): Observable<any> {
        return this.http.delete(environment.api + '/broadcast/' + id);
    }

    /**
     * get all by participant id
     *
     * @return {Observable<any>}
     */
    public getAllByParticipantId(partipantId: number, searchMessage: string = ''): Observable<any> {
        return this.http.post(environment.api + '/broadcast/getAllByParticipant/' + partipantId, {
            search: searchMessage
        });
    }

    /**
     * get by id for participant
     *
     * @return {Observable<any>}
     */
    public getForParticipantById(id: number): Observable<any> {
        return this.http.get(environment.api + '/broadcast/getForParticipantById/' + id);
    }

    /**
     * mark broadcast as read
     *
     * @param ids
     * @param participantId
     *
     * @return {Observable<any>}
     */
    public markAsRead(ids: number[], participantId: number): Observable<any> {
        return this.http.post(environment.api + '/broadcast/markAsRead/' + participantId, ids);
    }

}
