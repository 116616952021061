import { Component, EventEmitter, OnInit, OnDestroy, OnChanges, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
// ionic
import { ModalController, PopoverController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Events } from '../../helpers/events';

// other libraries
import { TranslateService } from "@ngx-translate/core";

// rxjs
import { Subject, Subscription } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";

// services
import { EventService } from "../../services/event.service";
import { PlatformService } from "../../services/platform.service";
import { ParticipantService } from "../../services/participant.service";
import { OverlayService } from "../../services/overlay.service";
import { UserService } from "../../services/user.service";
import { ConversationService } from "../../services/conversation.service";

// models
import { Appointment } from "../../models/appointment";
import { Event } from "../../models/event";
import { Conversation } from "../../models/conversation";
import { Container } from "../../models/container";
import { Group } from "../../models/group";
import { Keyword } from "../../models/keyword";
import { Participant } from "../../models/participant";
import { ParticipantDetail } from "../../models/participant-detail";
import { User } from "../../models/user";

// components
import { ParticipantActionMenu } from "../participant-action-menu/participant-action-menu.component";
import { AppointmentAttendeesComponent } from "../appointment-attendees/appointment-attendees.component";

import { environment } from "../../../environments/environment";
import { isVideoLink } from 'src/app/services/helper';
import { isExternalLink } from 'src/app/helpers/url';

@Component({
    selector: 'attendee-detail',
    templateUrl: './attendee-detail.component.html',
    styleUrls: ['./attendee-detail.component.scss'],
})
export class AttendeeDetailComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * subscription for event changes subject
     *
     * @type {Subscription}
     */
    private subscriptionEvent: Subscription;

    /**
     * event
     *
     * @type {Event}
     */
    public event: Event;

    /**
     * user
     *
     * @type {User}
     */
    public user: User = new User;

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    /**
     * show group icon
     *
     * @type {Group}
     */
    public group: Group;

    /**
     * show group icon
     *
     * @type {string}
     */
    public groupIcon: string = '';

    /**
     * show loading
     *
     * @type {boolean}
     */
    public loading: boolean = true;

    /**
     * limit request for sime time period by loading state
     *
     * @type {boolean}
     */
    public allowLoading: boolean = true;

    /**
     * close modal after appointment request
     *
     * @type {boolean}
     */
    public closeAfterAppointmentRequest: boolean = false;

    /**
     * profile of attendee which is presented
     *
     * @type {Participant}
     */
    @Input() attendee: Participant;

    /**
     * attendee ID
     *
     * @type {number}
     */
    @Input() attendeeId: number;

    /**
     * if we want to use actual profile from db or profile from input
     *
     * @type {boolean}
     */
    @Input() isPreview: boolean = false;

    /**
     * if we want to use hide header
     *
     * @type {boolean}
     */
    @Input() hideHeader: boolean = false;

    /**
     * if we want dont want to count profile visit
     *
     * @type {boolean}
     */
    @Input() silent: boolean = false;

    /**
     * decide if we will show appointment request
     *
     * @type {Participant}
     */
    @Input() appointmentRequest: boolean = false;

    /**
     * class
     *
     * @type {string}
     */
    @Input() class: string = '';

    /**
     * original source from which component is used
     *
     * @type {string}
     */
    @Input() source: string = '';

    /**
     * Show specific detail for participant
     *
     * @type {ParticipantDetail}
     */
    @Input() detail: ParticipantDetail;

    /**
     * is this component used in wizard?
     *
     * @type {boolean}
     */
    @Input() wizard: boolean = false;

    /**
     * is this component used in small version
     *
     * @type {boolean}
     */
    @Input() smallPreview: boolean = false;

    /**
     * emit for appointment request
     *
     * @type {EventEmitter<any>}
     */
    @Output() onAppointmentRequest: EventEmitter<any> = new EventEmitter();

    /**
     * like loading status
     *
     * @type {boolean}
     */
    public likeLoading: boolean = false;

    /**
     * bookmark loading status
     *
     * @type {boolean}
     */
    public bookmarkLoading: boolean = false;

    /**
     * decide to show particiapnt image
     *
     * @type {boolean}
     */
    public showImage: boolean = false;

    public appointments: Appointment[] = [];
    public unprocessedAppointments: Appointment[] = [];
    public processedAppointments: Appointment[] = [];

    /**
     * Text with reason which will be sended to other participant
     *
     * @type {string}
     */
    public cancelReason: string = '';

    /**
     * Array of kyewords containers
     *
     * @type {Container[]}
     */
    public containers: Container[] = [];

    /**
     * env configuration
     */
    public env = environment;

    public error: string = '';

    constructor(
        public plt: PlatformService,
        public appEvents: Events,
        public conversationService: ConversationService,
        public eventService: EventService,
        public participantService: ParticipantService,
        public overlayService: OverlayService,
        public translate: TranslateService,
        public userService: UserService,
        public modalController: ModalController,
        public alertCtrl: AlertController,
        public router: Router,
        public popoverController: PopoverController
    ) {

        // user language
        this.userLang = this.translate.currentLang;
    }

    ngOnInit() {
        // if detail was opened with appointment request, use cancel button to close whole modal
        if (this.appointmentRequest) {
            this.closeAfterAppointmentRequest = true;
        }

        this.userService.getCurrentUser().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(0)
        ).subscribe((user) => {
            if (user.id) {
                this.user = user;

                // cancel previous subscription
                if (this.subscriptionEvent) {
                    this.subscriptionEvent.unsubscribe();
                }

                this.subscriptionEvent = this.eventService.getCurrentEvent().pipe(
                    takeUntil(this.ngUnsubscribe),
                    debounceTime(0)
                ).subscribe(
                    (event) => {

                        // if event is changed, we don't wont to allow load data
                        if (!this.event || event.id == this.event.id) {
                            this.event = event;
                            // there is a problem in wizard when choosing to continue later, we start closing modal,
                            // but at the same time we change current event and participant and this component
                            // is trying to load old particpant even with new event selected...
                            if (!this.wizard ||
                                (   // we need to be sure that current event is same as current selected participant event
                                    this.attendee.id == this.user.selected_participant_id
                                    && this.event.id == this.user.selected_participant.event_id
                                )
                            ) {

                                // use preview data
                                if (this.attendee && this.isPreview) {
                                    this.initParticipant();
                                }
                                // load actual data about participant from api
                                else {
                                    // NOTE[jg] - why thi is here???
                                    if (this.allowLoading) {
                                        this.allowLoading = false;

                                        if (!this.attendeeId) {
                                            this.attendeeId = this.attendee.id
                                        }

                                        // load local data for current user
                                        if (this.attendeeId == this.user.selected_participant_id) {
                                            this.attendee = this.user.selected_participant;
                                            this.initParticipant();
                                        } else {
                                            // otherwise load proper attendee details
                                            if (this.attendeeId) {
                                                this.participantService.getById(+ this.attendeeId, this.silent, this.event.id).subscribe(
                                                    (participant) => {
                                                        if (participant.id) {
                                                            this.attendee = new Participant(participant);
                                                            this.initParticipant();
                                                        }
                                                    },
                                                    (error) => {
                                                        // show error message
                                                        this.loading = false;
                                                        if (this.silent) {
                                                            // show error message in card
                                                            this.error = error.error.message;
                                                        } else {
                                                            this.overlayService.showError(error.error.message);
                                                            this.modalController.dismiss({ action: 'close' });
                                                        }
                                                    });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
            } else {
                // cancel previous subscription
                if (this.subscriptionEvent) {
                    this.subscriptionEvent.unsubscribe();
                }
            }
        });
    }

    /**
     * Watch changes on Inputs and refresh component if needed
     *
     * @param participant
     */
    ngOnChanges(changes: SimpleChanges) {

        if (
            this.attendeeId
            && changes.hasOwnProperty('attendeeId')
            && changes.attendeeId.previousValue != undefined
        ) {
            this.loading = true;
            // refresh attendeee
            this.participantService.getById(+ this.attendeeId, this.silent, this.event.id).subscribe(
                (participant) => {
                    this.attendee = new Participant(participant);
                    this.initParticipant();
                },
                (error) => {
                    // show error message
                    this.overlayService.showError(error.error.message);
                    this.modalController.dismiss({ action: 'close' });
                });
        }
    }

    /**
     * Init participant model
     *
     */
    private initParticipant() {
        // display all info from form if it is preview
        if (this.isPreview) {
            this.attendee.groupedDetails.forEach((group) => {
                group.visible = true;
            });
        }

        this.setupContainers();

        if (!this.user.canProfileDetails(this.attendee) && !this.silent) {
            this.overlayService.showError(this.translate.instant('ERROR_NO_ACCESS'));
            this.modalController.dismiss({ action: 'close' });
            //this.router.navigate(['/home/attendees/list']);
        }

        // appointments with selected user
        this.appointments = [];
        if (this.attendee.appointments) {
            this.attendee.appointments.forEach((app) => {
                let appointment = new Appointment(app);
                appointment.setParticipantStatus(this.user.selected_participant_id);

                let attendeeAppointment = appointment.participants.find((participant) => {
                    return participant.id == this.attendee.id;
                });

                if (attendeeAppointment) {
                    this.appointments.push(appointment);

                    if (!appointment.past) {
                        // check for accepted apointment
                        if (appointment.participantStatus == 1) {
                            this.processedAppointments.push(appointment);
                        }

                        // check for pending appointment
                        if (appointment.participantStatus == 0 && appointment.invitation_message && appointment.invitation_message.participant_id != this.user.selected_participant_id) {
                            this.unprocessedAppointments.push(appointment);
                        }
                    }
                }
            });
        }

        // get attendees group from event
        if (this.event.use_groups && this.event.groups.length) {
            this.group = this.event.groups.find(item => item.id == this.attendee.group_id);
        }

        // allow maximally one request per second...
        setTimeout(() => {
            this.allowLoading = true;
        }, 1000);

        this.loading = false;
    }

    /**
     * Show only containers where attendee has some keywords
     *
     */
    private setupContainers() {
        this.containers = [];

        for (let c = 0; c < this.event.containers.length; c++) {
            const container = new Container(this.event.containers[c]);
            this.event.containers[c] = container;
            let keywordIncluded = false;
            // keywords
            if (container.keywords) {
                for (let k = 0; k < container.keywords.length; k++) {
                    const keyword = container.keywords[k];

                    if (this.attendee?.keywords?.length
                        && this.attendee.keywords.find((item) => { return keyword.id == item.id })) {
                        keywordIncluded = true;
                    }
                }
            }

            // order keywords
            container.orderKeywordsByName(this.userLang, this.event.default_locale);

            // check if some keyword is checked, then include it in visible list
            if (keywordIncluded) {
                this.containers.push(container);
            }
        }
    }

    /**
     * Toggle like
     *
     * @param participant
     */
    public toggleLike(attendee: Participant) {
        if (this.user.selected_participant.id == attendee.id) {
            return false;
        }

        if (!this.likeLoading) {
            this.likeLoading = true;
            this.participantService.toggleLike(this.user.selected_participant.id, attendee.id).subscribe(
                (success) => {
                    this.likeLoading = false;
                    attendee.liked = !attendee.liked;
                    this.overlayService.showSuccess(success.message);

                    this.appEvents.publish('attendee:changed', attendee);
                },
                (error) => {
                    this.likeLoading = false;
                    this.overlayService.showError(error.error.message);
                });
        }
    }

    /**
     * Toggle bookmark
     *
     * @param participant
     */
    public toggleBookmark(attendee: Participant) {
        if (this.user.selected_participant.id == attendee.id) {
            return false;
        }

        if (!this.bookmarkLoading) {
            this.bookmarkLoading = true;
            this.participantService.toggleBookmark(this.user.selected_participant.id, attendee.id).subscribe(
                (success) => {
                    this.bookmarkLoading = false;
                    attendee.bookmarked = !attendee.bookmarked;
                    this.overlayService.showSuccess(success.message);

                    this.appEvents.publish('attendee:changed', attendee);
                },
                (error) => {
                    this.bookmarkLoading = false;
                    this.overlayService.showError(error.error.message);
                });
        }
    }

    /**
     * is keyword a match
     *
     * @param {Keyword} keyword
     * @param {Container} container
     * @param {number} column
     *
     * @returns {number}
     */
    public isMatch(keyword: Keyword, container: Container, column: number): boolean {
        // return false if looking on own profile
        if (this.user.selected_participant && this.user.selected_participant.id == this.attendee.id) {
            return false;
        }
        // 1 column, match is, if both have the same keyword selected
        if (container.columns === 1) {
            return !!this.user.selected_participant.keywords.filter(item => item.id === keyword.id).length;
        }

        // 2 column matches if both have the same keyword in different columns
        return !!this.user.selected_participant.keywords.filter((item) => {
            return (item.id === keyword.id && item.pivot.column !== column);
        }).length;
    }

    /**
     * rediret to chat with selected attendee
     *
     */
    public goToMessages() {
        // check if participant can start conversation
        this.participantService.canStartConversation(this.attendee.id).subscribe(
            (result) => {
                // participant can join conversation
                if (result) {
                    this.dismissModals();
                    this.dismissPopovers();

                    if (this.source != 'messages') {
                        this.router.navigate([this.plt.defaultLink + '/messages/direct', this.attendee.id], { replaceUrl: true });
                    }
                }
                // participant can not join conversation
                else {
                    this.overlayService.showError(this.translate.instant('CONVERSATION_LIMIT_REACHED'));
                }
            }
        );
    }

    /**
     * open video call
     *
     */
    public openCall(appointment?) {
        if (!this.smallPreview) {
            // close modal
            this.dismissModals();
            this.dismissPopovers();

            this.appEvents.publish('messages:call', false);
            let link = this.plt.defaultLink + '/messages/direct/' + this.attendee.id;

            if (appointment) {
                let attendee = appointment.participants.filter(p => p.id != this.user.selected_participant_id)[0];
                link = appointment.chat_group ? this.plt.defaultLink + '/messages/channel/' + appointment.chat_group.id : this.plt.defaultLink + '/messages/direct/' + attendee.id;
            }

            this.router.navigate([link], {
                replaceUrl: true,
                queryParams: {
                    call: true
                }
            });
        } else {
            this.appEvents.publish('messages:call', true);
        }
    }

    /**
     * display appointment request
     *
     */
    public setAppointmentRequest() {
        // check if participant can start conversation
        this.participantService.canStartConversation(this.attendee.id).subscribe(
            (result) => {
                // participant can join conversation
                if (result) {
                    this.appointmentRequest = true;
                }
                // participant can not join conversation
                else {
                    this.overlayService.showError(this.translate.instant('CONVERSATION_LIMIT_REACHED'));
                }
            }
        );
    }

    /**
     * close modal window
     *
     */
    public backClicked() {
        this.modalController.dismiss({ action: 'close' });
    }


    /**
     * close appointment request
     *
     * @param {Conversation} conversation
     *
     */
    public closeAppointmentRequest(conversation: Conversation) {
        if (!this.closeAfterAppointmentRequest) {
            this.appointmentRequest = false;
            this.onAppointmentRequest.emit(conversation);
        } else {
            this.modalController.dismiss({ conversation: conversation, action: 'close' });
        }
    }

    /**
     * close modal window and redirect to dashboard with manage keywords
     *
     */
    manageKeywords() {
        // redirect to keywords managing page
        if (!this.event.is_homepage_content_matching) {
            this.router.navigate([this.plt.defaultLink + '/keywords/matching']);
        } else {
            // TODO[jg] - make better links...
            this.router.navigate([this.plt.defaultLink + '/home/info/manage-keywords']);
        }
        // close opened modal
        this.dismissModals();
        this.dismissPopovers();
    }

    public async openAnswerAppointment(appointment: Appointment, status: number) {

        var secondParticipant = appointment.participants.filter((item: Participant) => {
            return item.id != this.user.selected_participant_id;
        })[0];

        var options = {};

        switch (status) {
            case 2: {
                options = {
                    cssClass: 'confirm',
                    header: this.translate.instant('APPOINTMENTS_MODAL_DECLINE_TITLE'),
                    message: this.translate.instant('APPOINTMENTS_MODAL_DECLINE_TEXT'),
                    inputs: [
                        {
                            name: 'reason',
                            type: 'text',
                            placeholder: this.translate.instant('APPOINTMENTS_MODAL_DECLINE_REASON_PLACEHOLDER', {
                                name: secondParticipant.getFullName()
                            })
                        }
                    ],
                    buttons: [{
                        text: this.translate.instant('BUTTON_CANCEL'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => { }
                    }, {
                        text: this.translate.instant('BUTTON_OK'),
                        role: '',
                        cssClass: 'primary',
                        handler: (data) => {
                            this.addCacnelReason(data.reason);
                            this.answerAppointment(appointment, status);
                        }
                    }]
                };

                break;
            }
            default: {
                options = {
                    cssClass: 'confirm',
                    header: this.translate.instant('APPOINTMENTS_MODAL_ACCEPT_TITLE'),
                    message: this.translate.instant('APPOINTMENTS_MODAL_ACCEPT_TEXT'),
                    buttons: [{
                        text: this.translate.instant('BUTTON_CANCEL'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => { }
                    }, {
                        text: this.translate.instant('BUTTON_OK'),
                        role: '',
                        cssClass: 'primary',
                        handler: () => { this.answerAppointment(appointment, status); }
                    }]
                };

                break;
            }
        }

        let alert = await this.alertCtrl.create(options);
        await alert.present();
    }

    public addCacnelReason(reason) {
        this.cancelReason = reason;
    }

    /**
     * answer appointment
     *
     * @param appointment
     * @param status
     *
     * @return void
     */
    public answerAppointment(appointment: Appointment, status: number) {
        this.loading = true;
        this.overlayService.showLoading();

        const oldStatus = appointment.status;
        appointment.status = status;
        appointment.participantStatus = status;

        if (appointment.status === 3 || appointment.status === 2) {
            appointment.reason = this.cancelReason;
        }

        this.conversationService.answerAppointment(appointment.invitation_message.conversation_id, appointment)
            .subscribe((conversation: Conversation) => {
                if (appointment.status == 3) {
                    this.overlayService.showSuccess(this.translate.instant('APPOINTMENTS_CANCELED_TEXT'));
                }
                if (appointment.status == 2) {
                    this.overlayService.showSuccess(this.translate.instant('APPOINTMENTS_DECLINED_TEXT'));
                }
                if (appointment.status == 1) {
                    this.overlayService.showSuccess(this.translate.instant('APPOINTMENTS_ACCEPTED_TEXT'));
                }
                this.cancelReason = '';
                appointment.status = oldStatus;

                // set unprocessedAppointments
                this.unprocessedAppointments = this.unprocessedAppointments.filter((item) => { return item.id != appointment.id });

                this.loading = false;
            }, error => {
                this.overlayService.showError(error.error.message);
                appointment.status = oldStatus;
                appointment.participantStatus = oldStatus;
                this.cancelReason = '';
                this.loading = false;
            });
    }

    /**
     * show popover with action menu
     * @return void
     */
    async showActionMenu(ev: any) {
        const popover = await this.popoverController.create({
            component: ParticipantActionMenu,
            event: ev,
            componentProps: {
                participant: this.attendee
            }
        });

        popover.onWillDismiss().then((data) => {
            if (data && data.data) {
                if (data.data.action == 'unmute' && data.data.item) {
                    this.loading = true;

                    let blacklist;
                    let service;

                    if (data.data.type == 'newsfeed') {
                        this.user.selected_participant.blacklist.newsfeed_participants = this.user.selected_participant.blacklist.newsfeed_participants.filter(item => item != data.data.item.id);
                        blacklist = this.user.selected_participant.blacklist;
                        service = this.participantService;
                    }
                    else if (data.data.type == 'marketplace') {
                        this.user.blacklist.marketplace_participants = this.user.blacklist.marketplace_participants.filter(item => item != data.data.item.id);
                        blacklist = this.user.blacklist;
                        service = this.userService;
                    }

                    service.updateBlacklist(blacklist).subscribe(
                        (success) => {
                            // update newsfeed list
                            this.appEvents.publish(data.data.type + ':refresh');
                            this.loading = false;
                        },
                        (error) => {
                            this.overlayService.showConnectionProblems(error);
                            this.loading = false;
                        }
                    );
                }
                else if (data.data.action == 'mute' && data.data.item) {
                    this.loading = true;

                    let blacklist;
                    let service;

                    if (data.data.type == 'newsfeed') {
                        this.user.selected_participant.blacklist.newsfeed_participants.push(data.data.item.id);
                        blacklist = this.user.selected_participant.blacklist;
                        service = this.participantService;
                    }
                    else if (data.data.type == 'marketplace') {
                        this.user.blacklist.marketplace_participants.push(data.data.item.id);
                        blacklist = this.user.blacklist;
                        service = this.userService;
                    }

                    service.updateBlacklist(blacklist).subscribe(
                        (success) => {
                            // update newsfeed list
                            this.appEvents.publish(data.data.type + ':refresh');
                            this.loading = false;
                        },
                        (error) => {
                            this.overlayService.showConnectionProblems(error);
                            this.loading = false;
                        }
                    );
                }
            }
        });

        await popover.present();
    }

    /**
     * show appointment participant list
     * @return void
     */
    async openAppointmentParticipants(ev: any, appointment: Appointment) {
        const popover = await this.popoverController.create({
            component: AppointmentAttendeesComponent,
            event: ev,
            componentProps: {
                appointment: appointment
            },
            cssClass: 'appointment-participant-list',
            id: 'appointment-participant-list'
        });

        popover.onWillDismiss().then((data) => { });

        await popover.present();
    }

    // function to close all popovers
    public dismissPopovers() {
        this.popoverController.getTop().then((popover) => {
            if (popover) {
                this.popoverController.dismiss().then(() => {
                    this.dismissPopovers();
                });
            }
        });

    }

    // function to close all modals
    public dismissModals() {
        this.modalController.getTop().then((modal) => {
            if (modal) {
                this.modalController.dismiss().then(() => {
                    this.dismissModals();
                });
            }
        });
    }

    openLink(url: string, external: boolean = true) {
        if (isVideoLink(url)) {
            this.appEvents.publish('video:show', url);
        } else {
            if (external) {

                if (this.plt.is('cordova')) {
                    // cordova is not working with third options attribute
                    window.open(url, '_system');
                } else {
                    window.open(url, '_system', 'noreferrer');
                }

            } else {
                // check by domain if external or not...
                if (!isExternalLink(url)) {
                    // app internal links
                    let urlPath = new URL(url);
                    this.router.navigateByUrl(this.plt.defaultLink + urlPath.pathname.replace(this.plt.defaultLink, '') + urlPath.search);
                } else {
                    // redirect to specific page
                    window.location.href = url;
                }
            }
        }
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
