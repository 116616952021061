import * as moment from "moment/moment";

export class MarketplacePostDetail {
    id: number;
    marketplace_post_id: number;
    type: string;
    error: string;
    value: string | Date;
    string_value: string;
    position: number;
    group_index: number;
    group_type: string = '';
    deleted: boolean = false;
    options: MarketplacePostDetailOptions = {};

    /**
     * constructor
     *
     * @param {ParticipantDetail} init
     */
    public constructor(init?: MarketplacePostDetail) {
        if (!!init) {
            Object.assign(this, init);

            if (!this.options) {
                this.options = {};
            }

            if ([
                'from',
                'to'
            ].indexOf(this.type) != -1) {
                if (this.value) {
                    this.value = moment(this.value).toDate();
                    this.string_value = this.value.toISOString();
                }
            }

            if ([
                'link',
            ].indexOf(this.type) != -1) {
                this.options.target = '_blank';
                if (this.value.toString().search(window.location.host) !== -1) {
                    this.options.target = '';
                }
            }
        }
    }
}

export class MarketplacePostDetailOptions {
    type?: string;
    name?: string;
    line?: boolean;
    lang?: string;
    headline?: boolean;
    filename?: string;
    target?: string;
    error?: string;
}