export class Helper {
    /**
     * Creates a unique guid
     * @returns A guid
     */
    public static guid = (): string => {
        return Helper.s4() + Helper.s4() + '-' + Helper.s4() + '-' + Helper.s4() + '-' +
            Helper.s4() + '-' + Helper.s4() + Helper.s4() + Helper.s4();
    };

    /**
     * Generates a random string
     * @returns A random string
     */
    private static s4(): string {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
}

/**
 * deleteCookie
 *
 * helper to delete cookie with give name and value for domain
 *
 * @param name
 * @param value
 * @param days
 * @param domain
 */
export function setCookie(name: string, value: string, days: number, domain: string = null) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/" +
        ((domain) ? ";domain=" + domain : "");
}

/**
 * deleteCookie
 *
 * helper to delete cookie with give name and value for domain
 *
 * @param name
 * @param path
 * @param domain
 */
export function deleteCookie(name: string, path: string = null, domain: string = null) {
    document.cookie = name + "=" +
        ((path) ? ";path=" + path : "") +
        ((domain) ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
}
/**
 * getCookie
 *
 * helper to return cokie value by name
 *
 * @param name
 */
export function getCookie(name: string) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
    else return '';
}

/**
 * getToken
 *
 * helper to return fake token if session storage is empty
 *
 * @param name
 */
export function getToken(name: string) {
    let token: string;
    if (token = localStorage.getItem(name)) {
        return token;
    } else {
        // return fake token to force app to logout user
        return '';//'eyJhbGciOiJub25lIiwidHlwIjoiSldUIn0.eyJpc3MiOiJodHRwczovL2p3dC1pZHAuZXhhbXBsZS5jb20iLCJzdWIiOiJtYWlsdG86bWlrZUBleGFtcGxlLmNvbSIsIm5iZiI6MTUzMzcwOTI1NiwiZXhwIjoxNTMzNzEyODU2LCJpYXQiOjE1MzM3MDkyNTYsImp0aSI6ImlkMTIzNDU2IiwidHlwIjoiaHR0cHM6Ly9leGFtcGxlLmNvbS9yZWdpc3RlciJ9.';
    }
}

export function isVideoLink(url:string) {

    if (!!url) {
        /*
            this pattern matches:
            - http://www.youtube.com/v/0zM3nApSv11?fs=1&amp;hl=en_US&amp;rel=0
            - http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
            - http://www.youtube.com/embed/0zM3nApSvMg?rel=0
            - http://www.youtube.com/watch?v=0zM3nApSvMg
            - http://youtu.be/0zM3nApSvMg
            - https://vimeo.com/265045525
            - https://vimeo.com/event/265045525
        */
        // let pattern = /(?:https?:\/\/)?(?:www\.)?((?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|(vimeo\.com\/(\w*))|(vimeo\.com\/event\/(\w*))|(congreet\.stream\/(\w*)))(?:\S+)?/gim;
        let pattern = /(?:https?:\/\/)?(?:www\.)?((?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|(vimeo\.com\/([0-9]{6,}))|(vimeo\.com\/event\/(\w+))|(congreet\.stream\/(\w*)))(?:\S+)?/gim;

        // if we found something
        if (url.match(pattern)) {
            return true;
        }
    }

    return false
}
