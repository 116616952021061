import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { ChatGroup } from "./chat-group";

export class Stream {
    id: number;
    created_at: string;
    user_id: number;
    event_id: number;
    chat_group: ChatGroup;
    title: string;
    link: string;
    embed_link: string;
    display_chat: boolean;
    unread_messages_count: 0;

    /**
     * constructor
     *
     * @return void
     */
    public constructor(init?: Stream) {
        if (!!init) {
            Object.assign(this, init);

            if (init.chat_group) {
                this.chat_group = new ChatGroup(init.chat_group);
            }

            this.initEmbedLink();
        }
        else {

        }
    }

    /**
     * parse and set the actual embed link
     */
    public initEmbedLink() {

        let text = this.link;

        /*
            this pattern matches:
            - http://www.youtube.com/v/0zM3nApSv11?fs=1&amp;hl=en_US&amp;rel=0
            - http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
            - http://www.youtube.com/embed/0zM3nApSvMg?rel=0
            - http://www.youtube.com/watch?v=0zM3nApSvMg
            - http://youtu.be/0zM3nApSvMg
            - https://vimeo.com/265045525
        */
        let pattern = /(?:https?:\/\/)?(?:www\.)?((?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|(vimeo\.com\/(\w*)))(?:\S+)?/gim;

        let matches = text.match(pattern);

        // if we found something
        if (matches) {
            let link = matches[matches.length - 1];

            let videoId;
            let video_embed_link;

            let linkSplitted = link.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

            // youtube
            if (linkSplitted[2] !== undefined) {
                videoId = linkSplitted[2].split(/[^0-9a-z_\-]/i);
                videoId = videoId[0];
                video_embed_link = 'https://www.youtube.com/embed/' + videoId;
            }
            // vimeo
            else {
                // live stream support

                /*
                    - https://vimeo.com/event/3649783/embed
                */
                linkSplitted = link.replace(/(>|<)/gi, '').split(/(vimeo.com\/event\/)/);
                if (linkSplitted[2] !== undefined) {
                    videoId = linkSplitted[2];
                    video_embed_link = 'https://vimeo.com/event/' + videoId + '/embed';
                } else {
                    // regular viemo video link
                    linkSplitted = link.replace(/(>|<)/gi, '').split(/(vimeo.com\/)/);
                    if (linkSplitted[2] !== undefined && !link.includes('player.vimeo.com')) {
                        videoId = linkSplitted[2];
                        video_embed_link = 'https://player.vimeo.com/video/' + videoId;
                    }
                }
            }

            this.embed_link = video_embed_link;
        } else {
            this.embed_link = this.link;
        }

        return this.embed_link;
    }
}
