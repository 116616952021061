import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';

import { LangChangeEvent } from "@ngx-translate/core";

// extends
import { BasePage } from "../base";


// services
//import { PushPageService } from "../../services/push-page.service"; TODO
import { AuthenticationService } from "../../services/authentication.service";
import { PlatformService } from '../../services/platform.service';
import { StorageService } from "../../services/storage.service";

// config
import { environment } from "../../../environments/environment";

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.page.html',
    styleUrls: ['./set-password.page.scss'],
})
export class SetPasswordPage extends BasePage implements OnInit {

    public tokenIsValid = false;

    public model: any = {
        email: '',
        token: '',
        type: 'participant',
        password: '',
        password_confirmation: '',
        locale: ''
    };

    /**
     * loaded state
     *
     * @type {boolean}
     */
    public loaded: boolean = false;


    /**
     * static environment config
     *
     * @type {Object}
     */
    public environment: Object = environment;

    /**
     * submitted
     *
     * @type {boolean}
     */
    public submitted: boolean = false;

    /**
     * constructor
     *
     * @param route
     * @param auth
     * @param menuCtrl
     * @param storage
     * @param plt
     */
    constructor(
        public route: ActivatedRoute,
        public auth: AuthenticationService,
        public menuCtrl: MenuController,
        public storage: StorageService,
        public plt: PlatformService
    ) {
        super();
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() { // vs ionViewDidLoad?
        this.plt.hideSidebar = this.hideSidebar;
        this.menuCtrl.enable(false, 'authenticated');

        if (this.plt.is('cordova')) {
            this.model.token = sessionStorage.getItem('password_token');
            this.model.email = sessionStorage.getItem('password_email');
        } else {
            this.model.email = this.route.snapshot.queryParams.email;
            this.model.token = this.route.snapshot.queryParams.token;
        }

        // const locale = this.route.snapshot.queryParams['forceLocale'];
        // if (locale) {
        //     setTimeout(() => {
        //         this.translate.use(locale);
        //     }, 250);
        // }

        // NOTE[jg] - this is not used anywhere, so why to call it...
        // this.auth.checkResetToken(this.model).subscribe(
        //     (success) => {
        //         this.tokenIsValid = true;
        //     }
        // );

        // check if there is request for different start url
        if (this.route.snapshot.queryParams['redirect']) {
            this.plt.saveStartUrl(decodeURIComponent(this.route.snapshot.queryParams['redirect']));
        }

        this.model.locale = this.translate.currentLang;
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.model.locale = event.lang;
        });

        this.initSetting();
        this.loaded = true;
    }

    public initSetting() { }

    /**
     * lost password
     *
     * @return void
     */
    public set() {
        if (!this.submitted) {
            this.submitted = true;
            this.showLoading();

            this.auth.setPassword(this.model).subscribe(
                (success) => {

                    this.submitted = false;
                    this.overlayService.showSuccess(success.message);
                    setTimeout(() => {
                        this.hideLoading();
                        this.router.navigate(['/']);
                    }, 1000);
                },
                (error) => {
                    this.submitted = false;
                    this.overlayService.showError(error.error.message);
                    // thiw status is returned only when reset password is done
                    // via external service which is also used for registration
                    // now it is used for skim import tool...
                    setTimeout(() => {
                        this.hideLoading();
                        if (error.status == 404) {
                            // redirect to registration site in case of not existing account
                            this.router.navigate(['/register']);
                        }
                    }, 1000);
                }
            );
        }
    }
}
