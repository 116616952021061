import { APP_ID, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Events } from '../helpers/events';

// other services
import { TranslateService } from "@ngx-translate/core";
import { JwtHelperService } from "@auth0/angular-jwt";

// rxjs
import { Observable, from } from "rxjs";

// services
import { UserService } from "./user.service";
import { StorageService } from "./storage.service";

// models
import { User } from "../models/user";

// helpers
import { deleteCookie, setCookie } from "./helper";

import { log } from '../helpers/log';

// config
import { environment } from "../../environments/environment";
import { theme } from "../../environments/theme";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    // how many days will be signing cookies valid
    cookieValidDays = 14;

    /**
     * String with cloudfront signed params
     *
     * @type {string}
     */
    public signedUrlParams = '';

    /**
     * constructor
     *
     * @param http
     * @param events
     * @param storage
     * @param translate
     * @param jwtHelper
     * @param push
     * @param userService
     */
    constructor(
        @Inject(APP_ID) private appId: string,
        private http: HttpClient,
        public events: Events,
        private storage: StorageService,
        private translate: TranslateService,
        private jwtHelper: JwtHelperService,
        public userService: UserService,
    ) { }

    /**
     * login
     *
     * @param email
     * @param password
     *
     * @return {Observable<any>}
     */
    // TODO[jg] rewrite all attributes to params..
    public login(
        email: string,
        password: string,
        eventKey: string,
        device: string = 'pwa',
        transferAcceptedEventId: number = 0,
        invitation_token: string = null,
        checkEventid: number = 0,
        external_secret: string = null,
        external_secret_verification: string = null,
        event_id: number = 0,
        group_id: number = 0,
        keepLoggedIn: boolean = false,
        src: string = ''

    ): Observable<any> {

        let options = { withCredentials: true };

        const params = {
            email: email,
            password: password,
            device: device,
            app: theme.name,
            invitation_token: invitation_token,
            forceLocale: this.translate.currentLang,
            event_key: eventKey,
            transfer_accepted_event_id: transferAcceptedEventId,
            check_event_id: checkEventid,
            event_id: event_id,
            group_id: group_id,
            external_secret: external_secret,
            external_secret_verification: external_secret_verification,
            timezone_offset: new Date().getTimezoneOffset(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            sameSiteAssets: environment.sameSiteAssets,
            keepLoggedIn: keepLoggedIn,
            src: src
        };

        return this.http.post(environment.api + '/participant/login', JSON.stringify(params), options);
    }

    /**
     * login with external token
     *
     * @param token
     * @param eventId
     *
     * @return {Observable<any>}
     */
    public loginWithExternalToken(token: string, eventId: number, device: string = 'pwa'): Observable<any> {

        let options = { withCredentials: true };

        const params = {
            token: token,
            device: device,
            app: theme.name,
            forceLocale: this.translate.currentLang,
            timezone_offset: new Date().getTimezoneOffset(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            sameSiteAssets: environment.sameSiteAssets
        };

        return this.http.post(environment.api + '/participant/' + eventId + '/external-login', JSON.stringify(params), options);
    }

    /**
     * social login
     *
     * @param payload
     * @param type
     *
     * @return {Observable<any>}
     */
    public socialLogin(payload: any, type: string, invitation_token: string = null): Observable<any> {

        let options = { withCredentials: true };

        payload.redirectUri = environment.redirectUri;
        payload.device = 'mobile';
        payload.invitation_token = invitation_token;
        payload.forceLocale = this.translate.currentLang;
        payload.timezone_offset = new Date().getTimezoneOffset();
        payload.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        payload.sameSiteAssets = environment.sameSiteAssets;
        payload.clientId = environment.linkedIn.clientId;

        return this.http.post(environment.api + '/auth/' + type, JSON.stringify(payload), options);
    }

    /**
     * apple login
     *
     * @param payload
     *
     * @return {Observable<any>}
     */
    public appleLogin(payload: any, invitation_token: string = null): Observable<any> {

        let options = { withCredentials: true };

        payload.redirectUri = environment.redirectUri;
        payload.device = 'mobile';
        payload.invitation_token = invitation_token;
        payload.forceLocale = this.translate.currentLang;
        payload.timezone_offset = new Date().getTimezoneOffset();
        payload.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        payload.sameSiteAssets = environment.sameSiteAssets;
        payload.clientId = environment.mobileAppId;

        return this.http.post(environment.api + '/auth/apple', JSON.stringify(payload), options);
    }

    /**
     * login from token
     *
     * @param token
     */
    public loginFromToken(token: string, type: string = 'token', eventId = null) {

        try {
            // try to decode token
            this.jwtHelper.decodeToken(token)
        } catch (error) {
            this.logout();
            log('info', 'invalid token');
            this.events.publish('logout', 'logout');
        }

        // logout user if token is expired
        if (this.jwtHelper.isTokenExpired(token)) {
            this.logout();
            log('info', 'expired token');
            this.events.publish('logout', 'logout');
        } else {

            this.updateToken(token);

            // load cookies for assets on cloudfronts
            // TODO[jg] - use this also for updating users timezone !!!
            this.userService.setCloudfrontCookies().subscribe(
                (success) => {
                    log('info', 'Cloudfront cookies set');
                    // publish login event after receiving security cookies
                    this.events.publish('loggedin', { 'type': type });
                    // store signing cookies for cloudfront assets
                    this.signedUrlParams = 'Key-Pair-Id=' + encodeURIComponent(success.cookie['CloudFront-Key-Pair-Id'])
                        + '&Policy=' + encodeURIComponent(success.cookie['CloudFront-Policy'])
                        + '&Signature=' + encodeURIComponent(success.cookie['CloudFront-Signature']);
                    // this.storeCurrentUserCookies(response.json().cookie);

                    setTimeout(() => {
                        // download user data
                        this.events.publish('user:current', {
                            eventId: eventId
                        });
                    }, 250);
                },
                (error) => {
                    // do logout
                    this.logout();
                    this.events.publish('logout', 'logout');
                }
            );
        }
    }

    /**
     * create user
     *
     * @param user
     *
     * @return {Observable<any>}
     */
    public register(user: User, invitation_token: string = null, device: string = 'pwa', src = null, srcId = null, details: Array<object> = null): Observable<any> {

        // for case it is not required by custom template
        if (!user.salutation) {
            delete user.salutation;
        }

        let params = {
            user: user,
            invitation_token: invitation_token,
            forceLocale: this.translate.currentLang,
            device: device,
            app: theme.name,
            domain: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''),
            timezone_offset: new Date().getTimezoneOffset(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            src: src,
            srcId: srcId,
            details: details
        };

        if (device == 'mobile') {
            params.domain = environment.pwa
        }

        return this.http.post(environment.api + '/participant/register', JSON.stringify(params));
    }

    /**
     * logout
     *
     * @return void
     */
    public logout() {

        this.events.publish('loggedout');

        // remove user from local storage to log user out
        //this.translate.use(this.translate.defaultLang);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        // NOTE[jg] - not remove token for testing
        this.storage.remove('token');

        // remove cloudfront security cookies
        this.userService.unsetCloudfrontCookies().subscribe(
            () => {
                log('info', 'Cloudfront cookies removed');
            }
        );

        this.removeCookies();
    }

    /**
     * reset password request
     *
     * @param email
     * @return {Observable<any>}
     */
    public resetPassword(email: string, device: string = 'pwa'): Observable<any> {
        // return Observable.fromPromise(this.storage.get('invitation_token')).flatMap(invitation_token => {
        // NEED to be tested, it can remove invitation token for registration...
        this.storage.remove('invitation_token');

        let params = {
            email: email,
            // invitation_token: invitation_token,
            forceLocale: this.translate.currentLang,
            domain: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
        };

        if (device == 'mobile') {
            params.domain = environment.pwa
        }

        return this.http.post(environment.api + '/participant/lost', JSON.stringify(params));
        // });

    }

    /**
     * Confirm a registration
     *
     * @param token
     * @param type
     * @returns {Observable<any>}
     */
    public registerConfirmation(token: string, type: string): Observable<any> {
        return this.http.get(environment.api + '/' + type + '/register/confirm?token=' + token + '&forceLocale=' + this.translate.currentLang);
    }

    /**
     * send reconfirmation request
     *
     * @param email
     * @param type
     * @returns {Observable<any>}
     */
    public resendConfirmation(email: string, type: string, device: string = 'pwa'): Observable<any> {

        let params = {
            email: email,
            forceLocale: this.translate.currentLang,
            domain: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
        };

        if (device == 'mobile') {
            params.domain = environment.pwa
        }

        return this.http.post(environment.api + '/' + type + '/register/reconfirm', JSON.stringify(params));
    }

    /**
     * resset/set password request
     *
     * @param params
     * @return {Observable<any>}
     */
    public setPassword(params): Observable<any> {
        const url = '/password/reset';

        // force server feedback to get proper translations
        params['forceLocale'] = this.translate.currentLang;

        return this.http.post(environment.api + url, JSON.stringify(params));
    }

    /**
    * check password reset token
    * @param params
    * @return {Observable<any>}
    */
    public checkResetToken(params): Observable<any> {
        const url = "/password/check-reset-token";

        // force server feedback to get proper translations
        params['forceLocale'] = this.translate.currentLang;

        return this.http.post(environment.api + url, JSON.stringify(params));
    }

    /**
     * send request for new password reset token
     * @param params
     * @return {Observable<any>}
     */
    public requestPasswordToken(params): Observable<any> {
        const url = "/" + params.type + "/lost";

        // force server feedback to get proper translations
        params['forceLocale'] = this.translate.currentLang;

        return this.http.post(environment.api + url, JSON.stringify(params));
    }

    /**
     * check event key
     *
     * @param eventKey
     *
     * @return {Observable<any>}
     */
    public verifyEventKey(eventKey: string): Observable<any> {
        let params = {
            eventKey: eventKey
        };
        return this.http.post(environment.api + '/event/getTransferByEventKey', JSON.stringify(params));
    }

    /**
      * store current User cookies for cloudfront
      *
      * @return void
      */
    public storeCurrentUserCookies(cookies) {
        for (var cookie in cookies) {
            setCookie(cookie, cookies[cookie], this.cookieValidDays, cookies['domain']);
        }
    }

    /**
     * clear current User cookies for cloudfront
     *
     * @return void
     */
    public removeCookies() {
        console.info('clear cookies for domain ');
        deleteCookie('CloudFront-Signature');
        deleteCookie('CloudFront-Key-Pair-Id');
        deleteCookie('CloudFront-Policy');
        deleteCookie('CloudFront-Expires');
    }

    /**
     * update user token
     *
     * @param {string} token
     *
     */
    public updateToken(token: string, forceAll: boolean = false) {
        localStorage.setItem('token', token);
        localStorage.setItem('refresh_token', token);

        // always store token permanently
        this.storage.set('token', token);
        // NOTE[jg] - store for debugging
        // this.storage.set('old_token', token);
        const channel = new BroadcastChannel('app-data');
        // channel.addEventListener ('message', (event) => {
        //     console.log(event.data);
        //    });

        // let ohter instances know about changes
        let data = this.jwtHelper.decodeToken(token);
        data['broadcastSender'] = forceAll ? '' : this.appId;
        channel.postMessage(data);
    }
}
