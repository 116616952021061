import { Component, Input, OnInit, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// ionic native
import { Camera } from '@ionic-native/camera/ngx';
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { FileTransfer, FileTransferObject } from "@ionic-native/file-transfer/ngx";

// ionic
import { ModalController } from '@ionic/angular';
import { Events } from '../../helpers/events';

// rxjs
import { Subject } from "rxjs";
import { debounceTime, take, takeUntil } from "rxjs/operators";


// components
import { AutoCompleteComponent } from "../../components/auto-complete/auto-complete.component";

// dropzone
import { DropzoneComponent, DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { DropzoneService } from "../../services/dropzone.service";

// other services
import { TranslateService } from "@ngx-translate/core";

// services
import { PlatformService } from "../../services/platform.service";
import { UserService } from "../../services/user.service";
import { MarketplaceService } from "../../services/marketplace.service";
import { OverlayService } from "../../services/overlay.service";
import { ParticipantService } from "../../services/participant.service";
import { Helper, isVideoLink } from "../../services/helper";

// models
import { Marketplace } from "../../models/marketplace";
import { MarketplacePost } from "../../models/marketplace-post";
import { MarketplacePostDetail, MarketplacePostDetailOptions } from "../../models/marketplace-post-detail";
import { UploadImage } from "../../models/upload-image";
import { User } from "../../models/user";
import { Participant } from "../../models/participant";
import { MarketplaceHeadline } from "../../models/marketplace-headline";

// config
import { environment } from "../../../environments/environment";
import { dropZoneSettings } from "../../settings/dropzone.settings";
import { isExternalLink } from 'src/app/helpers/url';

@Component({
    selector: 'app-marketplace-ad-detail',
    templateUrl: './marketplace-ad-detail.component.html',
    styleUrls: ['./marketplace-ad-detail.component.scss'],
})
export class MarketplaceAdDetailComponent implements OnInit {

    @ViewChild('autocomplete') searchbar: AutoCompleteComponent;

    /**
     * dropzone
     *
     * @type ViewChild
     */
    @ViewChild(DropzoneComponent) dropzone: DropzoneComponent;

    /**
     * marketplacePost
     *
     * @type {MarketplacePost}
     */
    @Input() marketplace: Marketplace;

    /**
     * marketplacePost
     *
     * @type {MarketplacePost}
     */
    @Input() marketplacePost: MarketplacePost = new MarketplacePost;

    /**
     * preview mode
     *
     * @type {boolean}
     */
    @Input() isPreview: boolean = false;

    /**
     * edit mode
     *
     * @type {boolean}
     */
    @Input() editable: boolean = false;

    /**
     * category name for detail label
     *
     * @type {string}
     */
    @Input() categoryName: string = '';

    /**
     * dropzone config
     *
     * @type {DropzoneConfigInterface}
     */
    public dropZoneConfigExtended: DropzoneConfigInterface = dropZoneSettings;

    /**
      * upload state
      *
      * @type {boolean}
      */
    public uploading: boolean = false;

    /**
      * loading state
      *
      * @type {boolean}
      */
    public loading: boolean = false;

    /**
     * is image upload active
     *
     * @type {boolean}
     */
    public sending: boolean = false;

    /**
     * validate state
     *
     * @type {boolean}
     */
    public validate: boolean = false;

    /**
      * loaded state
      *
      * @type {boolean}
      */
    public loaded: boolean = false;

    /**
      * The selected images
      *
      * @type UploadImage
      */
    public image: UploadImage = new UploadImage;

    /**
      * The transfer object
      *
      * @type TransferObject
      */
    public fileTransfer: FileTransferObject = this.transfer.create();

    /**
      * Amount of progress made during an image upload
      *
      * @type number
      */
    public progress: number = 0;

    /**
     * current event
     *
     * @type {User}
     */
    public user: User;

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * Validation errors
     *
     * @type {object}
     */
    public validationErrors: object = {};

    /**
     * active headline
     * @type {MarketplaceHeadline}
     */
    public activeHeadline: MarketplaceHeadline;

    public groupedDetailPreview = [];

    /**
     * constructor
     *
     * @param plt
     * @param translate
     * @param camera
     * @param imagePicker
     * @param transfer
     * @param ngZone
     * @param dropzoneService
     * @param overlayService
     * @param marketplaceService
     * @param modalController
     * @param userService
     * @param participantService
     */
    constructor(
        private router: Router,
        public plt: PlatformService,
        public translate: TranslateService,
        public camera: Camera,
        public imagePicker: ImagePicker,
        public transfer: FileTransfer,
        public ngZone: NgZone,
        public dropzoneService: DropzoneService,
        public marketplaceService: MarketplaceService,
        public overlayService: OverlayService,
        public modalController: ModalController,
        public userService: UserService,
        public participantService: ParticipantService,
        public appEvents: Events
    ) {
        // dropzone extended config
        this.dropZoneConfigExtended = { ...dropzoneService.dropzoneConfig };
        this.dropZoneConfigExtended.maxFilesize = 4;
        if (this.plt.is('cordova')) {
            this.dropZoneConfigExtended.acceptedFiles = 'application/pdf';
        } else {
            this.dropZoneConfigExtended.acceptedFiles = 'image/jpg,image/jpeg,image/png,application/pdf,image/gif';
        }
        this.dropZoneConfigExtended.autoReset = 1000;

        this.userLang = this.translate.currentLang;
    }

    ngOnInit() {

        this.dropZoneConfigExtended.url = environment.api + '/marketplace/' + this.marketplace.id + '/posts/upload?ngsw-bypass=true';

        // subscribe to current user
        this.userService.getCurrentUser().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(0)
        ).subscribe((user) => {
            // if (this.marketplacePost?.marketplace_id) {
            //     this.marketplaceService.marketplaceId = this.marketplacePost.marketplace_id;
            // }

            if (user.id) {
                this.user = user;

                if (this.user.selected_participant?.event_id) {
                    this.participantService.eventId = this.user.selected_participant.event_id;
                    this.participantService.autocompleteOptions = {
                        onlyContactable: true,
                        withOwnProfile: true,
                        dontCheckRightsForOwnProfile: true
                    };
                }

            } else {
                this.user = null;
            }
        });

        setTimeout(() => {
            this.loaded = true;
        }, 250);

        // prepare preview based only by lang
        if (this.isPreview) {
            this.groupedDetailPreview = this.marketplacePost.groupedDetails.filter((group) => {
                return !group.lang || group.lang == this.userLang;
            });
            console.info(this.groupedDetailPreview);
        }
    }

    /**
     * add new participant detail group
     *
     * @returns {void}
     */
    public addDetail(detailType, options: MarketplacePostDetailOptions = {}, headline: boolean = false): void {
        let group = {
            type: detailType.name,
            line: true,
            headline: options ? options.headline : false,
            deleted: false,
            details: []
        };
        let nextIndex = this.marketplacePost.groupedDetails.length;
        detailType.group.forEach((type, index) => {
            group.details.push(new MarketplacePostDetail({
                id: null,
                marketplace_post_id: this.marketplacePost.id,
                type: type,
                error: '',
                value: '',
                position: index,
                group_index: nextIndex,
                group_type: detailType.name,
                deleted: false,
                options: options,
                string_value: ''
            }));
        });

        if (options?.headline) {
            this.marketplacePost.groupedHeadlineDetails.push(group);
        } else {
            this.marketplacePost.groupedDetails.push(group);
        }
    }

    /**
     * delete participant detail group
     *
     * @returns {void}
     */
    public deleteGroup(index, group, headline: boolean = false): void {
        this.marketplacePost.groupedDetailsDeleted.push(group);
        // remove from actual groups
        if (headline) {
            this.marketplacePost.groupedHeadlineDetails.splice(index, 1);
        } else {
            this.marketplacePost.groupedDetails.splice(index, 1);
        }
    }

    /**
     * delete detail group
     *
     * @returns {void}
     */
    public deleteDetail(index, detail, groupIndex, group): void {
        if (detail.id) {
            this.marketplacePost.groupedDetailsDeleted.push({
                details: [{
                    id: detail.id
                }]
            });
        }
        // remove from actual groups
        group.details.splice(index, 1);

        // check if group is havein any detail..
        if (group.details.length == 0) {
            this.marketplacePost.groupedDetails.splice(groupIndex, 1);
        }
    }

    /**
     * add image detail group
     *
     * @returns {void}
     */
    public addGroupDetail(group, type): void {
        group.details.unshift(new MarketplacePostDetail({
            id: null,
            marketplace_post_id: this.marketplacePost.id,
            type: type,
            error: '',
            value: '',
            position: 0,
            group_index: group.group,
            group_type: group.type,
            deleted: false,
            options: {},
            string_value: ''
        }));
        // reindex group
        group.details.forEach((group, index) => {
            group.position = index;
        })
    }

    /**
     * add image detail group
     *
     * @returns {void}
     */
    public addGroupDetailBefore(group, type): void {
        let nextIndex = group.details.length
        group.details.push(new MarketplacePostDetail({
            id: null,
            marketplace_post_id: this.marketplacePost.id,
            type: type,
            error: '',
            value: '',
            position: nextIndex,
            group_index: group.group,
            group_type: group.type,
            deleted: false,
            options: {},
            string_value: ''
        }));
    }

    /**
     * move participant detail up
     *
     * @returns {void}
     */
    public moveDetailUp(index): void {
        if (index > 0) {
            let active = { ...this.marketplacePost.groupedDetails[index] };
            let original = { ...this.marketplacePost.groupedDetails[index - 1] };
            this.marketplacePost.groupedDetails[index] = original;
            this.marketplacePost.groupedDetails[index - 1] = active;
        }
    }

    /**
     * move participant detail down
     *
     * @returns {void}
     */
    public moveDetailDown(index): void {
        if (index < (this.marketplacePost.groupedDetails.length - 1)) {
            let active = { ...this.marketplacePost.groupedDetails[index] };
            let original = { ...this.marketplacePost.groupedDetails[index + 1] };
            this.marketplacePost.groupedDetails[index] = original;
            this.marketplacePost.groupedDetails[index + 1] = active;
        }
    }

    /**
       * open gallery
       *
       * @return {Promise<void>}
       */
    public async openAlbums(detail = null): Promise<void> {
        this.plt.reloadable = false;

        if (await this.imagePicker.hasReadPermission()) {

            try {
                let originals = await this.fileManager();
                if (originals.length) {
                    let requests = await originals.map(async x => new UploadImage({
                        path: x,
                        guid: Helper.guid(),
                        thumb: x // eventually resize before upload via "await this.resize(x)"
                    }));

                    let images = await Promise.all(requests);
                    this.image = images[0];

                    setTimeout(() => {
                        this.uploadImage(detail);
                    }, 0);
                } else {
                    this.plt.reloadable = true;
                }
            } catch (exception) {
                this.plt.reloadable = true;
            }

        } else {

            let interval = setInterval(() => {
                this.imagePicker.hasReadPermission().then((boolHasPermissions) => {
                    if (boolHasPermissions) {
                        clearInterval(interval);
                        this.openAlbums(detail);
                    }
                });
            }, 1000);

            if (this.plt.is('ios')) {
                this.imagePicker.requestReadPermission();
            }

            if (this.plt.is('android')) {
                this.imagePicker.requestReadPermission();
            }

        }
    }

    /**
     * Opens the albums
     *
     * @return A collection of urls from the selected images
     */
    public fileManager(): Promise<Array<string>> {
        return this.imagePicker.getPictures({
            quality: 90,
            width: 2880,
            // height: 500,
            maximumImagesCount: 1,
        });
    }

    /**
     * Actually uploads an image
     */
    public async uploadImage(detail = null): Promise<void> {

        if (detail) {
            detail.uploading = true;
        } else {
            this.uploading = true;
        }
        this.plt.keepAwake();

        // Bind the progress function
        this.fileTransfer.onProgress(this.onProgress);

        let token = localStorage.getItem('token');

        // Prepare our upload options
        let options = {
            fileKey: 'file',
            fileName: this.image.path.split('/').pop(),
            mimeType: 'image/jpeg',
            chunkedMode: false,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            params: {}
        };

        try {
            let result = await this.fileTransfer.upload(
                encodeURI(this.image.path),
                encodeURI(environment.api + '/marketplace/' + this.marketplace.id + '/posts/upload?ngsw-bypass=true'),
                options,
                false
            );

            // set upload paths to detail value
            detail.value = JSON.parse(result.response).file_url;
            detail.options.filename = JSON.parse(result.response).filename;

            if (result.response) {
                this.plt.reloadable = true;
                if (detail) {
                    detail.uploading = false;
                } else {
                    this.uploading = false;
                }
                this.plt.allowSleepAgain();
                //this.showSuccess(this.translate.instant('MESSAGE_UPLOAD_SUCCESS'));
            }
        }
        catch (e) {
            this.overlayService.showError();
            this.plt.reloadable = true;
            if (detail) {
                detail.uploading = false;
            } else {
                this.uploading = false;
            }
            this.plt.allowSleepAgain();
        }
    }

    /**
     * Opens the camera and display the taken picture if available
     */
    public async openCamera(detail = null): Promise<void> {
        this.plt.reloadable = false;

        try {
            let image = await this.cameraManager();

            if (image) {
                this.image = new UploadImage({
                    path: image,
                    guid: Helper.guid(),
                    thumb: image // eventually resize before upload via "await this.resize(x)"
                });

                setTimeout(() => {
                    this.uploadImage(detail);
                }, 0);
            } else {
                this.plt.reloadable = true;
            }
        } catch (exception) {
            this.plt.reloadable = true;
        }
    }

    /**
     * Opens the camera so that the user can take a picture
     *
     * @return The url of the taken image
     */
    private cameraManager(): Promise<string> {
        return this.camera.getPicture({
            // We want to have the URL to the file
            destinationType: this.camera.DestinationType ? this.camera.DestinationType.FILE_URI : 1,
            // Source of the images is the camera
            sourceType: this.camera.PictureSourceType ? this.camera.PictureSourceType.CAMERA : 1,
            // Encoding type is JPEG
            encodingType: this.camera.EncodingType ? this.camera.EncodingType.JPEG : 0,
            // Give us the full quality of the image, lower it for better performance
            quality: 90,
            // Allow editing of the image after its taken
            allowEdit: false,
            // When a image is taken via the camera also save it to the native photo album
            saveToPhotoAlbum: false,
            // Correct the orrientation of the image
            correctOrientation: true,
            // Resize to save bandwidth and prevent rejection by the server
            targetWidth: 2880,
            // targetHeight: 1800
        });
    }

    /**
     * The on upload progress callback event
     *
     * @param progressEvent The progress event of the image upload
     */
    public onProgress = (progressEvent: ProgressEvent, detail?): void => {
        this.ngZone.run(() => {
            if (progressEvent.lengthComputable) {
                let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                if (progress > 100) progress = 100;
                if (progress < 0) progress = 0;
                if (detail) {
                    detail.progress = progress;
                } else {
                    this.progress = progress;
                }
            }
        });
    };

    /**
     * on image upload
     *
     * @param args
     */
    public onSending(args: any) {
        this.sending = true;

        const xhrRequest = args[1];

        xhrRequest.ontimeout = ((event) => {
            this.dropzone.directiveRef.dropzone().removeAllFiles(true); // remove not uploaded files
            this.overlayService.showError(this.translate.instant('REQUEST_TIMEOUT'));
        });

        xhrRequest.withCredentials = true;
        xhrRequest.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
    }
    /**
     * on upload finished
     *
     * @param args
     */
    public onUploadSuccess(args: any, detail?) {
        if (detail) {
            detail.value = args[1].file_url;
            detail.options.type = args[1].type;
            detail.options.filename = args[1].filename;
        }

        this.sending = false;
    }

    /**
     * on error during csv upload
     *
     * @param args
     */
    public onUploadError(args: any) {
        const message = args[1];
        if (message.indexOf('too large') !== -1 || message.indexOf('est trop grande') !== -1 || message.indexOf('ist zu gro') !== -1) {

            this.overlayService.showError(this.translate.instant('VALIDATION_IMAGE_TOO_LARGE', {
                maxSize: this.dropZoneConfigExtended.maxFilesize
            }));

        } else if (message.toLowerCase().indexOf('upload files of this type') !== -1) {

            this.overlayService.showError(this.translate.instant('VALIDATION_WRONG_TYPE', {
                fileTypes: this.dropZoneConfigExtended.acceptedFiles.split(',').join(', ')
            }));

        } else {
            // NOTE[jg]  maybe some general error?
            this.overlayService.showError(this.translate.instant('VALIDATION_IMAGE_OTHER'));
        }

        this.sending = false;
    }

    public togglePreview() {
        this.isPreview = !this.isPreview;
        if (this.isPreview) {
            this.groupedDetailPreview = this.marketplacePost.groupedDetails.filter((group) => {
                return !group.lang || group.lang == this.userLang;
            })
        }
    }

    public addReferenceSubject(string) {
        return this.translate.instant('MARKETPLACE_POST_REFERENCE_SUBJECT_PREFIX') + encodeURI(string);
    }

    /**
     * close modal window
     *
     * @param boolean modal
     *
     */
    public backClicked() {
        this.modalController.dismiss({ action: 'close' });
    }

    /**
     * close modal window
     *
     */
    public closeSaved() {
        this.sending = true;
        this.validate = true;
        this.validationErrors = {};

        // update
        if (this.marketplacePost.id) {

            // group details before savings for API
            let post = new MarketplacePost({ ...this.marketplacePost });
            post.groupedDetails = [...post.groupedDetails, ...post.groupedHeadlineDetails];

            this.marketplaceService.updatePostDeails(post).subscribe(
                (success) => {
                    this.marketplacePost = new MarketplacePost(success.post);
                    this.modalController.dismiss({ action: 'close', marketplacePost: this.marketplacePost });
                    this.sending = false;
                    this.validate = false;
                },
                (error) => {
                    this.handleError(error);
                    this.sending = false;
                }
            );
        } else {
            this.modalController.dismiss({ action: 'close', marketplacePost: this.marketplacePost });
        }
    }

    /**
     * rediret to chat with selected attendee
     *
     */
    public goToMessages(post: MarketplacePost) {
        // check if participant can start conversation
        this.participantService.canStartConversation(post.getRealParticipant().id).subscribe(
            (result) => {
                // participant can join conversation
                if (result) {
                    this.dismissModals();
                    // store linked items for next messaging
                    this.participantService.linkedItem = {
                        id: post.id,
                        marketplace_id: post.marketplace_id,
                        participant_id: post.getRealParticipant().id,
                        text: post.name,
                        type: 'marketplace-post',
                    };
                    this.router.navigate([this.plt.defaultLink + '/messages/direct', post.getRealParticipant().id], {
                        replaceUrl: true,
                        queryParams: {
                            linkedItem: JSON.stringify({
                                id: post.id,
                                marketplace_id: post.marketplace_id,
                                participant_id: post.getRealParticipant().id,
                                text: post.name,
                                type: 'marketplace-post',
                            })
                        }
                    });
                }
                // participant can not join conversation
                else {
                    this.overlayService.showError(this.translate.instant('CONVERSATION_LIMIT_REACHED'));
                }
            }
        );
    }

    private handleError(response) {
        const data = response.error;

        // NOTE[jg] now errors are not comming from backend
        if (data.fields) {
            for (const field in data.fields) {
                if (data.fields.hasOwnProperty(field)) {
                    this.validationErrors[field] = data.fields[field].join(' ');
                }
            }
        }

        this.overlayService.showError(data.message);
    }

    /**
     * add new contacts headline
     *
     * @returns {void}
     */
    public addContactsHeadline(): void {
        let headline = new MarketplaceHeadline();
        this.marketplacePost.contacts.push(headline);
        this.activeHeadline = headline;
    }

    /**
     * set active headline
     * @param {MarketplaceHeadline} headline
     */
    public setActiveHeadline(headline: MarketplaceHeadline) {
        if (this.searchbar) {
            this.searchbar.clearValue();
        }

        this.activeHeadline = headline;
    }

    /**
     * Add new participant to marketplace post contacts
     *
     * @param {Particiapnt} particiant marketplace participant
     */
    public addContact(participant) {
        if (!this.activeHeadline.participants.find((item) => { item.id == participant.id })) {
            this.activeHeadline.participants.push(participant)
        }

        // check resize of app and other items when bluring input
        this.autocompleteBlur();

        // reset value for autocomplet search
        this.searchbar.clearValue();
        // this.scrollBottom();
    }

    /**
     * Remove participant from contacts
     *
     * @param {Participant} participant marketplace participant
     * @param {MarketplaceHeadline} headline marketplace contacts headline
     */
    public removeContact(participant, headline) {
        headline.participants = headline.participants.filter((item) => {
            return item.id != participant.id
        });
    }

    /**
     * Remove headline from contacts
     *
     * @param {MarketplaceHeadline} headline marketplace contacts headline
     */
    public removeContactsHeadline(headline) {
        this.marketplacePost.contacts = this.marketplacePost.contacts.filter((item) => {
            return item != headline
        });

        if (this.activeHeadline == headline) {
            this.activeHeadline = null;
        }
    }

    /**
     * check app resize (keyboard opening) for input focus
     */
    public autocompleteFocus() {
        this.plt.checkResize();
        // we will hide on small screens all to make autocomplete input bigger
        // this.autocompleteOpen = true;
    }

    /**
     * check app resize (keyboard closing) for input blur
     */
    public autocompleteBlur(clear: boolean = false) {
        this.plt.checkResize(false);
        // this.autocompleteOpen = false;
        // close autocomplete list when triggering manually cancel for autocomplete
        if (clear) {
            // NOTE[jg] do this only for manual, not on ionBlur for autocomplete component
            // that was causing big performance problems
            this.searchbar.clearValue(true);
        }
    }

    public showAttendeeDetail(participant: Participant) {
        this.appEvents.publish('attendee:open:detail', participant);
    }

    public removeMyContact(headline, participant: Participant) {
        this.loading = true;

        let subscription = this.marketplaceService.deleteMyContact(headline, participant).subscribe(
            (response) => {
                headline.participants = headline.participants.filter((item) => {
                    return item.id != participant.id
                });

                this.loading = false;
            },
            (error) => {
                this.loading = false;
                const data = error.error;
                this.overlayService.showError(data.message);
            });
        this.plt.activeSubscriptions.push(subscription);
    }

    openLink(url: string, type: string) {

        if (isVideoLink(url)) {
            this.appEvents.publish('video:show', url);
        } else {
            if (!isExternalLink(url)) {
                // close current modal
                this.dismissModals();
                // open internal link
                let urlPath = new URL(url);
                this.router.navigateByUrl(this.plt.defaultLink + urlPath.pathname.replace(this.plt.defaultLink, '') + urlPath.search);
            } else {

                if (this.plt.is('cordova')) {
                    // cordova is not working with third options attribute
                    window.open(url, type == '_blank' ? '_system' : '');
                } else {
                    window.open(url, type == '_blank' ? '_system' : '', 'noreferrer');
                }
            }
        }
    }

    // function to close all modals
    public dismissModals() {
        this.modalController.getTop().then((modal) => {
            if (modal) {
                this.modalController.dismiss().then(() => {
                    this.dismissModals();
                });
            }
        });
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.participantService.autocompleteOptions = null;
    }
}
