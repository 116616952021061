<ion-header class="ion-no-shadow ion-no-border">
    <ion-toolbar>
        <ion-title>
            <ng-container *ngIf="step == 1">{{ 'CUSTOMIZE_YOUR_PROFILE' | translate }}</ng-container>
            <ng-container *ngIf="step == 2 && event.matching_status">{{ 'CUSTOMIZE_YOUR_PROFILE_KEYWORDS' | translate }}</ng-container>
            <ng-container *ngIf="step == wizardSteps">{{ 'PROFILE_EDIT_PREVIEW' | translate }}</ng-container>
            <ng-container *ngFor="let marketplace of marketplaces;let i = index">
                <ng-container *ngIf="step == ((wizardSteps+1) + i)">
                    {{ marketplace.name }}
                </ng-container>
            </ng-container>
        </ion-title>
    </ion-toolbar>
    <div class="pager">
        <div class="lines">
            <div class="line"
                 [ngClass]="{'finished': step > 0}">
            </div>
            <div *ngIf="event.matching_status"
                 class="line"
                 [ngClass]="{'finished': step > 1}">
            </div>
            <ng-container *ngFor="let marketplace of marketplaces;let i = index">
                <ng-container *ngIf="marketplace.containers.length > 0">
                    <div *ngIf="i < marketplaces.length"
                         class="line"
                         [ngClass]="{'finished': step > (wizardSteps + i)}">
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div class="dots">
            <span [ngClass]="{'active': step == 1, 'finished': step > 1}"
                  (click)="goToStep(1)">
                1
            </span>
            <span [ngClass]="{'active': step == 2, 'finished': step > 2}"
                  (click)="goToStep(2)">
                2
            </span>
            <span *ngIf="event.matching_status"
                  [ngClass]="{'active': step == 3, 'finished': step > 3}"
                  (click)="goToStep(3)">
                3
            </span>
            <ng-container *ngFor="let marketplace of marketplaces;let i = index">
                <ng-container *ngIf="marketplace.containers.length > 0">
                    <span [ngClass]="{'active': step == ((wizardSteps+1) + i), 'finished': step > ((wizardSteps+1) + i)}"
                          (click)="goToStep((wizardSteps+1) + i)">
                        {{ ((wizardSteps+1) + i) }}
                    </span>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ion-header>

<ion-slides #slider
            [pager]="false"
            (ionSlideTransitionEnd)="setStep()"
            [options]="slideOpts">
    <ion-slide>
        <profile-edit #profileEdit
                      (onSaved)="finishStep($event)"
                      [wizard]="true"
                      [skip]="nothingRequired">
        </profile-edit>
    </ion-slide>
    <ion-slide *ngIf="event.matching_status">
        <div class="content-box wizard">
            <div class="content-box-inner">
                <div class="content-data keywords">
                    <keyword-settings #keywordSettings
                                      [wizard]="true"
                                      view="manage-keywords">
                    </keyword-settings>
                </div>
                <div class="content-footer">

                    <ion-grid class="ion-no-padding">
                        <ion-row>
                            <ion-col size="12"
                                     class="ion-no-padding">

                                <ion-row *ngIf="!plt.sizeSm || user?.selected_participant?.keywordsRequired"
                                         class="ion-no-padding">
                                    <ion-col size="12"
                                             class="ion-no-padding ion-text-right">
                                        <ion-button *ngIf="!user?.selected_participant?.keywordsRequired"
                                                    class="clear"
                                                    fill="clear"
                                                    color="medium"
                                                    [routerDirection]="'root'"
                                                    (click)="finishStep()">
                                            {{ 'PROFILE_EDIT_SKIP' | translate }}
                                        </ion-button>
                                        <ion-button color="primary"
                                                    (click)="validateKeywords()">
                                            {{ 'BUTTON_SAVE' | translate }}
                                        </ion-button>
                                    </ion-col>
                                </ion-row>

                                <ion-row *ngIf="plt.sizeSm && !user?.selected_participant?.keywordsRequired"
                                         class="ion-no-padding action-buttons two">
                                    <ion-col size="6"
                                             class="ion-no-padding">
                                        <ion-button class="btn-close"
                                                    (click)="finishStep()">
                                            {{ 'PROFILE_EDIT_SKIP' | translate }}
                                        </ion-button>
                                    </ion-col>
                                    <ion-col size="6"
                                             class="ion-no-padding">
                                        <ion-button color="primary"
                                                    (click)="validateKeywords()">
                                            {{ 'BUTTON_SAVE' | translate }}
                                        </ion-button>
                                    </ion-col>
                                </ion-row>

                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </div>
        </div>
    </ion-slide>
    <ion-slide>
        <div class="content-box wizard">
            <div class="content-box-inner">
                <div class="content-data profile-preview ion-no-padding">
                    <attendee-detail #attendeeDetail
                                     *ngIf="user?.selected_participant"
                                     [attendee]="user.selected_participant"
                                     [wizard]="true"
                                     [hideHeader]="true">
                    </attendee-detail>
                </div>
                <div class="content-footer">

                    <ion-grid class="ion-no-padding">
                        <ion-row>
                            <ion-col size="12"
                                     class="ion-no-padding">
                                <ion-row class="ion-no-padding">
                                    <ion-col size="12"
                                             class="ion-no-padding ion-text-right">
                                        <ion-button color="primary"
                                                    (click)="finishStep()">
                                            <ng-container *ngIf="marketplaces.length > 0">
                                                {{ 'BUTTON_NEXT' | translate }}
                                            </ng-container>
                                            <ng-container *ngIf="marketplaces.length == 0">
                                                {{ 'CUSTOMIZE_YOUR_PROFILE_FINISH' | translate }}
                                            </ng-container>
                                        </ion-button>
                                    </ion-col>
                                </ion-row>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </div>
        </div>
    </ion-slide>
    <!-- MARKETPLACE KEYWORDS -->
    <ng-container *ngFor="let marketplace of marketplaces;let i = index">
        <ng-container *ngIf="marketplace.containers.length > 0">
            <ion-slide>
                <div class="content-box wizard">
                    <div class="content-box-inner">
                        <div class="content-data marketplace-keywords">
                            <!-- <h2 *ngIf="!plt.sizeSm">{{ marketplace.name }}</h2> -->
                            <p>{{ 'MARKETPLACE_POST_KEYWORDS_SELECT_HINT' | translate }}</p>
                            <marketplace-keywords #keywords
                                                  [collapsible]="false"
                                                  [allowKeywordSuggest]="false"
                                                  [marketplace]="marketplace"
                                                  [pivots]="marketplaceKeywords[marketplace.id]"
                                                  (onKeywordChanged)="updateKeywords(marketplace, $event)">
                            </marketplace-keywords>
                        </div>
                        <div class="content-footer">

                            <ion-grid class="ion-no-padding">
                                <ion-row>
                                    <ion-col size="12"
                                             class="ion-no-padding">
                                        <ion-row class="ion-no-padding">
                                            <ion-col size="12"
                                                     class="ion-no-padding ion-text-right">
                                                <ion-button color="primary"
                                                            (click)="applyKeywords(marketplace)">
                                                    <ng-container *ngIf="i !=  (marketplaces.length - 1) && marketplaces[i + 1] && marketplaces[i + 1]?.containers.length > 0">
                                                        {{ 'BUTTON_NEXT' | translate }}
                                                    </ng-container>
                                                    <ng-container *ngIf="i ==  (marketplaces.length - 1) || !(marketplaces[i + 1] && marketplaces[i + 1]?.containers.length > 0)">
                                                        {{ 'CUSTOMIZE_YOUR_PROFILE_FINISH' | translate }}
                                                    </ng-container>
                                                </ion-button>
                                            </ion-col>
                                        </ion-row>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </div>
                    </div>
                </div>
            </ion-slide>
        </ng-container>
    </ng-container>
</ion-slides>