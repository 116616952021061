import { Component, OnDestroy, OnInit, Output, EventEmitter, Input, ViewChild, NgZone } from '@angular/core';
import { Events } from '../../helpers/events';

// ionic
import { ModalController } from '@ionic/angular';

// ionic native
import { Camera } from '@ionic-native/camera/ngx';
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { FileTransfer, FileTransferObject } from "@ionic-native/file-transfer/ngx";


import { DropzoneComponent, DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { DropzoneService } from "../../services/dropzone.service";
import { Helper } from "../../services/helper";

// other libraries
import { TranslateService } from "@ngx-translate/core";

// rxjs
import { Subject } from "rxjs";

// components
import { MarketplaceAdDetailComponent } from "../marketplace-ad-detail/marketplace-ad-detail.component";
import { MarketplaceKeywordsComponent } from "../marketplace-keywords/marketplace-keywords.component";

// providers
import { MarketplaceService } from "../../services/marketplace.service";
import { OverlayService } from "../../services/overlay.service";
import { PlatformService } from "../../services/platform.service";
import { RouterExtService } from 'src/app/services/router-ext.service';

// models
import { Event } from "../../models/event";
import { Marketplace } from "../../models/marketplace";
import { MarketplacePost } from "../../models/marketplace-post";
import { UploadImage } from "../../models/upload-image";

// config not using...
import { environment } from "../../../environments/environment";
import { dropZoneSettings } from "../../settings/dropzone.settings";

// helpers
import { log } from '../../helpers/log';
import { Router } from '@angular/router';

@Component({
    selector: 'marketplace-add',
    templateUrl: './marketplace-add.component.html',
    styleUrls: ['./marketplace-add.component.scss'],
})
export class MarketplaceAddComponent implements OnInit, OnDestroy {

    /**
     * dropzone
     *
     * @type ViewChild
     */
    @ViewChild(DropzoneComponent) dropzone: DropzoneComponent;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * show form in modal
     *
     * @type boolean
     */
    @Input() modal: boolean = false;

    /**
     * marketplacePost
     *
     * @type {MarketplacePost}
     */
    @Input() marketplacePost: MarketplacePost = new MarketplacePost;

    /**
     * marketplace
     *
     * @type {Marketplace}
     */
    @Input() marketplace: Marketplace;

    @Input() event: Event;

    @Output() onMarketplaceSended: EventEmitter<MarketplacePost> = new EventEmitter();
    @Output() onCanceled: EventEmitter<MarketplacePost> = new EventEmitter();

    /**
     * marketplacePost
     *
     * @type {MarketplacePost}
     */
    private marketplacePostOriginal: MarketplacePost = new MarketplacePost;
    /**
     * validate state
     *
     * @type {boolean}
     */
    public validate: boolean = false;

    /**
     * Validation errors
     *
     * @type {object}
     */
    public validationErrors: object = {};

    /**
     * validation errors state
     *
     * @type {boolean}
     */
    public hasValidationErrors: boolean = false;

    /**
     * is image upload active
     *
     * @type {boolean}
     */
    public sending: boolean = false;

    /**
     * show link input
     *
     * @type {boolean}
     */
    public linkInputVisible: boolean = false;

    /**
     * dropzone config
     *
     * @type {DropzoneConfigInterface}
     */
    public dropZoneConfig: DropzoneConfigInterface = dropZoneSettings;

    /**
      * upload state
      *
      * @type {boolean}
      */
    public uploading: boolean = false;

    /**
      * The selected images
      *
      * @type UploadImage
      */
    public image: UploadImage = new UploadImage;

    /**
      * The selected images
      *
      * @type UploadImage
      */
    public froalaImage: UploadImage = new UploadImage;

    /**
      * Amount of progress made during an image upload
      *
      * @type number
      */
    public progress: number = 0;

    /**
      * Should we show input for price?
      *
      * @type boolean
      */
    public priceVisible: boolean = true;

    /**
      * The transfer object
      *
      * @type TransferObject
      */
    public fileTransfer: FileTransferObject = this.transfer.create();

    @Input() displayBigTextarea: boolean = false;

    @Input() showKeywords: boolean = true;

    // handlers for events
    eventChangeHandler;

    /**
     * which select type should be used.. alert/popover/action-sheet
     *
     * @type {string}
     */
    public selectType: string = 'popover';

    /**
     * default locale
     *
     * @type string
     */
    @Input() defaultLocale: string = 'de';

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;

    /**
      * submit state
      *
      * @type {boolean}
      */
    public submit: boolean = false;


    /**
     * constructor
     *
     * @param TranslateService
     * @param Camera
     * @param ImagePicker
     * @param FileTransfer
     * @param NgZone
     * @param Events
     * @param PlatformService
     * @param OverlayService
     * @param MarketplaceService
     * @param DropzoneService
     * @param ModalController
     */
    constructor(
        // public userService: UserService,
        private routerExtService: RouterExtService,
        private router: Router,
        public translate: TranslateService,
        public camera: Camera,
        public imagePicker: ImagePicker,
        public transfer: FileTransfer,
        public ngZone: NgZone,
        public appEvents: Events,
        public plt: PlatformService,
        public overlayService: OverlayService,
        public marketplaceService: MarketplaceService,
        public dropzoneService: DropzoneService,
        public modalController: ModalController
    ) {
        // get user lang
        this.userLang = this.translate.currentLang;

        // dropzone config
        this.dropZoneConfig = { ...dropzoneService.dropzoneConfig };
        this.dropZoneConfig.maxFilesize = 4;
        this.dropZoneConfig.acceptedFiles = 'image/jpg,image/jpeg,image/png,image/gif';
        this.dropZoneConfig.autoReset = 1000;
        this.dropZoneConfig.dictDefaultMessage = null;

        if (this.plt.sizeSm) {
            this.selectType = 'action-sheet';
        }
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() {
        this.dropZoneConfig.url = environment.api + '/marketplace/' + this.marketplace.id + '/posts/upload?ngsw-bypass=true';
        this.marketplacePostOriginal = new MarketplacePost(this.marketplacePost);

        this.eventChangeHandler = () => {
            this.marketplacePost = new MarketplacePost;
        }

        // attach hooks on event change
        this.appEvents.subscribe('event:changed', this.eventChangeHandler);

        // check if category has visible price input
        this.checkPriceVisibility(this.marketplacePost.marketplace_category_id);
    }

    public editMarketplace(marketplacePost: MarketplacePost) {
        this.marketplacePost = marketplacePost;
        this.marketplacePostOriginal = new MarketplacePost(this.marketplacePost);
    }

    /**
     * send new marketplacePost
     */
    public marketplacePostSave(valid) {
        // clear price if not visible
        if (!this.priceVisible) {
            delete this.marketplacePost.price;
            delete this.marketplacePost.coin_price;
        }

        if (valid) {
            this.submit = true;
            this.validationErrors = {};
            this.validate = true;
            this.marketplacePost.marketplace_id = this.marketplace.id;

            let post = new MarketplacePost({ ...this.marketplacePost });
            post.groupedDetails = [...post.groupedDetails, ...post.groupedHeadlineDetails];

            // update
            if (this.marketplacePost.id) {
                this.marketplaceService.updatePost(post).subscribe(
                    (success) => {
                        this.handleSuccess(success);
                    },
                    (error) => {
                        this.handleError(error);
                    }
                );
            }
            // create
            else {
                this.marketplaceService.createPost(post).subscribe(
                    (success) => {
                        this.handleSuccess(success);
                    },
                    (error) => {
                        this.handleError(error);
                    }
                );
            }
        }
    }

    private handleSuccess(response) {
        this.overlayService.showSuccess(response.message);
        this.marketplacePost = response.post ? new MarketplacePost(response.post) : new MarketplacePost;
        this.onMarketplaceSended.emit(this.marketplacePost);
        this.validate = false;
        this.displayBigTextarea = false;
        if (this.modal) {
            // close modal if used..
            this.closeSaved();
        }
        this.submit = false;
    }

    private handleError(response) {
        const data = response.error;

        if (data.fields) {
            for (const field in data.fields) {
                if (data.fields.hasOwnProperty(field)) {
                    this.hasValidationErrors = true;
                    this.validationErrors[field] = data.fields[field].join(' ');
                }
            }
        }

        this.submit = false;
        this.overlayService.showError(data.message);
    }

    /**
     * on image upload
     *
     * @param args
     */
    public onSending(args: any) {
        this.sending = true;

        const xhrRequest = args[1];

        xhrRequest.ontimeout = ((event) => {
            this.dropzone.directiveRef.dropzone().removeAllFiles(true); // remove not uploaded files
            this.overlayService.showError(this.translate.instant('REQUEST_TIMEOUT'));
        });

        xhrRequest.withCredentials = true;
        xhrRequest.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
    }

    /**
     * on upload finished
     *
     * @param args
     */
    public onUploadSuccess(args: any) {
        this.marketplacePost.image = args[1].file;
        this.marketplacePost.image_url = args[1].file_url;
        this.sending = false;
    }

    /**
     * on error during csv upload
     *
     * @param args
     */
    public onUploadError(args: any) {
        const message = args[1];
        if (message.indexOf('too large') !== -1 || message.indexOf('est trop grande') !== -1 || message.indexOf('ist zu gro') !== -1) {

            this.overlayService.showError(this.translate.instant('VALIDATION_IMAGE_TOO_LARGE', {
                maxSize: this.dropZoneConfig.maxFilesize
            }));

        } else if (message.toLowerCase().indexOf('upload files of this type') !== -1) {

            this.overlayService.showError(this.translate.instant('VALIDATION_WRONG_TYPE', {
                fileTypes: this.dropZoneConfig.acceptedFiles.split(',').join(', ')
            }));

        } else {
            this.overlayService.showError(this.translate.instant('VALIDATION_IMAGE_OTHER'));
        }
        this.sending = false;
    }

    /**
     * destroy image
     *
     * @return void
     */
    public deleteImage() {
        this.marketplacePost.image = null;
        this.marketplacePost.image_url = null;

        this.sending = false;
        // TODO
        if (!this.plt.is('cordova')) {
            // add timout to be sure that dropzone is visible
            setTimeout(() => {
                this.dropzone.directiveRef.reset();
            }, 250);
        }
    }

    /**
      * open gallery
      *
      * @return {Promise<void>}
      */
    public async openAlbums(file = false): Promise<void> {
        this.plt.reloadable = false;

        if (await this.imagePicker.hasReadPermission()) {

            try {
                let originals = await this.fileManager();
                if (originals.length) {
                    let requests = await originals.map(async x => new UploadImage({
                        path: x,
                        guid: Helper.guid(),
                        thumb: x // eventually resize before upload via "await this.resize(x)"
                    }));

                    let images = await Promise.all(requests);
                    this.image = images[0];

                    setTimeout(() => {
                        this.uploadImage(file);
                    }, 0);
                } else {
                    this.plt.reloadable = true;
                }
            } catch (exception) {
                this.plt.reloadable = true;
            }

        } else {

            let interval = setInterval(() => {
                this.imagePicker.hasReadPermission().then((boolHasPermissions) => {
                    if (boolHasPermissions) {
                        clearInterval(interval);
                        this.openAlbums(file);
                    }
                });
            }, 1000);

            if (this.plt.is('ios')) {
                this.imagePicker.requestReadPermission();
            }

            if (this.plt.is('android')) {
                this.imagePicker.requestReadPermission();
            }

        }
    }

    /**
     * Opens the albums
     *
     * @return A collection of urls from the selected images
     */
    public fileManager(): Promise<Array<string>> {
        return this.imagePicker.getPictures({
            quality: 90,
            width: 2880,
            // height: 900,
            maximumImagesCount: 1,
        });
    }

    /**
     * Actually uploads an image
     */
    public async uploadImage(file = false): Promise<void> {
        // if (file) {
        //     detail.uploading = true;
        // } else {
        //     this.uploading = true;
        // }
        this.uploading = true;

        this.plt.keepAwake();

        // Bind the progress function
        this.fileTransfer.onProgress(this.onProgress);

        let token = localStorage.getItem('token');

        // Prepare our upload options
        let options = {
            fileKey: 'file',
            fileName: this.image.path.split('/').pop(),
            mimeType: 'image/jpeg',
            chunkedMode: false,
            file: file,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            params: {}
        };

        try {
            let result = await this.fileTransfer.upload(
                encodeURI(this.image.path),
                encodeURI(environment.api + '/marketplace/' + this.marketplace.id + '/posts/upload?ngsw-bypass=true'),
                options,
                false
            );

            // set upload paths to participant
            this.marketplacePost.image = JSON.parse(result.response).file;
            this.marketplacePost.image_url = JSON.parse(result.response).file_url;

            if (result.response) {
                this.plt.reloadable = true;
                // if (file) {
                //     detail.uploading = false;
                // } else {
                //     this.uploading = false;
                // }
                this.uploading = false;
                this.plt.allowSleepAgain();
                //this.showSuccess(this.translate.instant('MESSAGE_UPLOAD_SUCCESS'));
            }
        }
        catch (e) {
            log('error', 'openAlbums', e);
            this.overlayService.showError();
            this.plt.reloadable = true;
            // if (file) {
            //     detail.uploading = false;
            // } else {
            //     this.uploading = false;
            // }
            this.uploading = false;
            this.plt.allowSleepAgain();
        }
    }

    /**
     * Opens the camera and display the taken picture if available
     */
    public async openCamera(file = false): Promise<void> {
        this.plt.reloadable = false;

        try {
            let image = await this.cameraManager();

            if (image) {
                this.image = new UploadImage({
                    path: image,
                    guid: Helper.guid(),
                    thumb: image // eventually resize before upload via "await this.resize(x)"
                });

                setTimeout(() => {
                    this.uploadImage(file);
                }, 0);
            } else {
                this.plt.reloadable = true;
            }
        } catch (exception) {
            this.plt.reloadable = true;
        }
    }

    /**
     * Opens the camera so that the user can take a picture
     *
     * @return The url of the taken image
     */
    private cameraManager(): Promise<string> {
        return this.camera.getPicture({
            // We want to have the URL to the file
            destinationType: this.camera.DestinationType ? this.camera.DestinationType.FILE_URI : 1,
            // Source of the images is the camera
            sourceType: this.camera.PictureSourceType ? this.camera.PictureSourceType.CAMERA : 1,
            // Encoding type is JPEG
            encodingType: this.camera.EncodingType ? this.camera.EncodingType.JPEG : 0,
            // Give us the full quality of the image, lower it for better performance
            quality: 90,
            // Allow editing of the image after its taken
            allowEdit: false,
            // When a image is taken via the camera also save it to the native photo album
            saveToPhotoAlbum: false,
            // Correct the orrientation of the image
            correctOrientation: true,
            // Resize to save bandwidth and prevent rejection by the server
            targetWidth: 2880,
            // targetHeight: 1800
        });
    }

    /**
     * The on upload progress callback event
     *
     * @param progressEvent The progress event of the image upload
     */
    public onProgress = (progressEvent: ProgressEvent, file?): void => {
        this.ngZone.run(() => {
            if (progressEvent.lengthComputable) {
                let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                if (progress > 100) progress = 100;
                if (progress < 0) progress = 0;
                // if (detail) {
                //     detail.progress = progress;
                // } else {
                //     this.progress = progress;
                // }
                this.progress = progress;
            }
        });
    };

    public showLinkInput() {
        this.linkInputVisible = true;
    }

    /**
     * open keyword select modal
     *
     * @return void
     */
    public async showKeywordsModal() {

        this.routerExtService.softNavigate();

        const modal = await this.modalController.create({
            component: MarketplaceKeywordsComponent,
            cssClass: 'keyword-select-modal small',
            componentProps: {
                marketplace: this.marketplace,
                pivots: [...this.marketplacePost.keywords],
                modal: true,
                collapsible: false
            }
        });

        modal.onWillDismiss().then((data) => {

            if (data.data?.keywords) {
                this.marketplacePost.keywords = data.data.keywords;
                this.marketplacePost.initKeywordsUnique();
            }

            if (data.data?.action == 'close' || data.role == 'backdrop') {
                this.routerExtService.softBack();
            }

        });

        return await modal.present();
    }

    /**
     * open design modal
     *
     * @return void
     */
    public async showDesignModal() {

        this.routerExtService.softNavigate();

        let editPost = new MarketplacePost({ ...this.marketplacePost });
        editPost.groupedDetails = [];
        editPost.groupedHeadlineDetails = [];

        if (this.marketplacePost.id) {
            editPost.initDetails();
        } else {
            editPost.groupedDetails = [...this.marketplacePost.groupedDetails];
            editPost.groupedHeadlineDetails = [...this.marketplacePost.groupedHeadlineDetails];
        }

        const modal = await this.modalController.create({
            component: MarketplaceAdDetailComponent,
            cssClass: 'marketplace-details-modal',
            componentProps: {
                editable: true,
                marketplace: this.marketplace,
                marketplacePost: editPost,
                categoryName: this.translateCategoryName()
            }
        });

        modal.onWillDismiss().then((data) => {
            if (data.data) {

                if (data.data.marketplacePost) {
                    // update marketplace model with details
                    this.marketplacePostOriginal.details = this.marketplacePost.details = data.data.marketplacePost.details;
                    this.marketplacePostOriginal.contacts = this.marketplacePost.contacts = data.data.marketplacePost.contacts;
                    
                    this.marketplacePostOriginal.groupedDetails = [...data.data.marketplacePost.groupedDetails];
                    this.marketplacePost.groupedDetails = [...data.data.marketplacePost.groupedDetails];

                    this.marketplacePostOriginal.groupedHeadlineDetails = [...data.data.marketplacePost.groupedHeadlineDetails];
                    this.marketplacePost.groupedHeadlineDetails = [...data.data.marketplacePost.groupedHeadlineDetails];
                }
            }

            if (data.data?.action == 'close' || data.role == 'backdrop') {
                let link = this.plt.defaultLink + this.routerExtService.currentUrl.split("?")[0].replace('/tabs', '');
                if (this.plt.tabMenu && !this.routerExtService.currentUrl.includes('/tabs')) {
                    this.router.navigateByUrl(link);
                } else {
                    this.routerExtService.softNavigate(link);
                }
            }
        });

        return await modal.present();
    }

    /**
     * close modal window
     *
     * @param boolean modal
     *
     */
    public backClicked(modal = true) {
        if (modal) {
            this.modalController.dismiss({ action: 'close', marketplacePost: this.marketplacePostOriginal });
        } else {
            this.onCanceled.emit(this.marketplacePostOriginal);
        }
    }

    /**
     * close modal window
     *
     */
    public closeSaved() {
        this.modalController.dismiss({ action: 'close', marketplacePost: this.marketplacePost });
    }

    /**
     * process category change
     *
     */
    public categorySelected($event) {
        // check if category has visible price input
        this.checkPriceVisibility($event.detail.value);
    }

    /**
     * process category change
     *
     * @param number categoryId
     *
     */
    public checkPriceVisibility(categoryId: number) {
        this.marketplace.categories.forEach((category) => {
            if (category.id == categoryId) {
                this.priceVisible = category.show_price;
            }
        });
    }

    public hideKeyboard($event) {
        // $event.stopPropagation()
        if ($event.preventDefault) {
            $event.preventDefault();
        }

        setTimeout(() => {
            if ($event.srcElement) {
                $event.srcElement.blur();
            }
        }, 100);
    }

    /**
     * show marketplacePost category name
     *
     * @return void
     */
    public translateCategoryName() {
        let categoryName;
        // get translations for category label
        if (this.marketplace && this.marketplace.categories) {
            this.marketplace.categories.forEach((category) => {
                if (this.marketplacePost && category.id == this.marketplacePost.marketplace_category_id) {
                    categoryName = category.translate(this.userLang, this.defaultLocale).name;
                }
            });
        }
        return categoryName;
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        if (this.eventChangeHandler) {
            this.appEvents.unsubscribe('event:changed', this.eventChangeHandler);
        }
        this.overlayService.closeModals();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
