import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ieTextareaFix]'
})
export class IeTextareaFixDirective {

    public elm: ElementRef;

    constructor(el: ElementRef) {
        this.elm = el;
    }

    @HostListener('ionInput') onIonChange() {
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        let textarea = this.elm.nativeElement.firstElementChild;

        if (isIEOrEdge) {
          const existingText = textarea.value;
          textarea.cursorPos = textarea.selectionStart;
          
          if (textarea.value.length === 0) {
            textarea.value = 'a';
            textarea.value = '';
            textarea.setSelectionRange(textarea.cursorPos, textarea.cursorPos);
          } else {
            textarea.value = '';
            textarea.value = existingText;
            textarea.setSelectionRange(textarea.cursorPos, textarea.cursorPos);
          }
        }
    }

}
