<div class="header">
    <div>
        <div class="filter">
            <ion-select interface="popover"
                        [interfaceOptions]="{cssClass:'not-overflow'}"
                        name="locale"
                        id="lang-select"
                        [(ngModel)]="locale"
                        (ionChange)="changeLang()">
                <ion-select-option *ngFor="let lang of plt.availableLangs"
                                   [value]="lang">
                    {{ lang | uppercase | translate }}
                </ion-select-option>
            </ion-select>
            <ion-select interface="popover"
                        [interfaceOptions]="{cssClass:'not-overflow'}"
                        name="source"
                        id="lang-source"
                        [(ngModel)]="source"
                        (ionChange)="changeSource()">
                <ion-select-option>
                </ion-select-option>
                <ion-select-option *ngFor="let source of trnsService.sources"
                                   [value]="source">
                    {{ source.name }}
                </ion-select-option>
            </ion-select>
        </div>
        <ion-searchbar *ngIf="!bigView"
                       [(ngModel)]="search"
                       (ionChange)="applyFilter()"
                       [placeholder]="'search'">
        </ion-searchbar>
        <ion-searchbar *ngIf="bigView"
                       [(ngModel)]="constantSearch"
                       (ionChange)="applyFilter()"
                       [placeholder]="'search by constant'">
        </ion-searchbar>
    </div>
    <div *ngIf="bigView"
         class="big-search">
        <ion-searchbar [(ngModel)]="search"
                       (ionChange)="applyFilter()"
                       [placeholder]="'search by ' + userLang  + ' version'">
        </ion-searchbar>
    </div>
    <div class="right-icons">
        <ion-icon name="close"
                  (click)="close()">
        </ion-icon>
        <ion-spinner *ngIf="filterLoading"></ion-spinner>
    </div>
</div>
<div class="grid">
    <div *ngIf="loading"
         class="loading">
        <ion-spinner></ion-spinner>
    </div>
    <ng-container *ngIf="!loading">

        <ng-container *ngIf="bigView">
            <ion-row>
                <ion-col></ion-col>
                <ion-col>{{ userLang | uppercase | translate }}</ion-col>
                <ng-container *ngFor="let lang of plt.availableLangs">
                    <ion-col *ngIf="lang != userLang">
                        {{ lang | uppercase | translate }}
                    </ion-col>
                </ng-container>
            </ion-row>
        </ng-container>

        <ng-container *ngFor="let translation of translations; trackBy: trackByFn">
            <ng-container *ngIf="(!search && !source && !constantSearch) || translation.match">
                <ion-row [ngClass]="{'big-view': bigView}">
                    <ion-col *ngIf="bigView">
                        {{translation.key}}
                    </ion-col>
                    <ion-col title="{{translation.key}}"
                             class="pointer"
                             (click)="edit(translation)">
                        <ng-container *ngIf="!translation.edit">
                            <ng-container *ngIf="!translation.text">
                                {{translation.key}}
                            </ng-container>
                            <ng-container *ngIf="translation.text">
                                {{translation.text}}
                            </ng-container>
                        </ng-container>
                        <ion-textarea *ngIf="translation.edit"
                                      [(ngModel)]="translation.text"
                                      (keyup.esc)=(cancelEdit($event,translation))
                                      (ionBlur)="update(translation)">
                        </ion-textarea>
                        <ion-button (click)="showHistory($event, translation)"
                                    color="primary"
                                    class="history"
                                    title="Show versions">
                            <ion-icon slot="icon-only"
                                      name="bookmark-outline">
                            </ion-icon>
                        </ion-button>
                        <ion-button (click)="autoTranslate(translation)"
                                    [disabled]="translation.loading"
                                    title="Deepl translation">
                            <ion-icon *ngIf="!translation.loading"
                                      slot="icon-only"
                                      name="language-outline">
                            </ion-icon>
                            <ion-spinner *ngIf="translation.loading"
                                         name="dots">
                            </ion-spinner>
                        </ion-button>
                    </ion-col>
                    <ng-container *ngFor="let lang of plt.availableLangs">
                        <ng-container *ngIf="lang != userLang">
                            <ng-container *ngIf="translation.other && translation.other[lang]">
                                <ion-col title="{{translation.other[lang].key}}"
                                         class="pointer big-view"
                                         (click)="edit(translation.other[lang])">
                                    <ng-container *ngIf="!translation.other[lang].edit">
                                        <ng-container *ngIf="!translation.other[lang].text">
                                            {{translation.other[lang].key}}
                                        </ng-container>
                                        <ng-container *ngIf="translation.other[lang].text">
                                            {{translation.other[lang].text}}
                                        </ng-container>
                                    </ng-container>
                                    <ion-textarea *ngIf="translation.other[lang].edit"
                                                  [(ngModel)]="translation.other[lang].text"
                                                  (keyup.esc)="cancelEdit($event, translation.other[lang])"
                                                  (ionBlur)="update(translation.other[lang])">
                                    </ion-textarea>
                                    <ion-button (click)="showHistory($event, translation.other[lang])"
                                                color="primary"
                                                class="history"
                                                title="Show versions">
                                        <ion-icon slot="icon-only"
                                                  name="bookmark-outline">
                                        </ion-icon>
                                    </ion-button>
                                    <ion-button (click)="autoTranslate(translation.other[lang])"
                                                [disabled]="translation.loading"
                                                title="Deepl translation">
                                        <ion-icon *ngIf="!translation.loading"
                                                  slot="icon-only"
                                                  name="language-outline">
                                        </ion-icon>
                                        <ion-spinner *ngIf="translation.loading"
                                                     name="dots">
                                        </ion-spinner>
                                    </ion-button>
                                </ion-col>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ion-row>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
<div class="footer">
    <ion-button (click)="add()"
                color="primary"
                title="Add translation">
        <ion-icon slot="icon-only"
                  name="add-outline">
        </ion-icon>
    </ion-button>
    <ion-button (click)="saveVersion()"
                color="primary"
                title="Save version">
        <ion-icon slot="icon-only"
                  name="save-outline">
        </ion-icon>
    </ion-button>
    <ion-button (click)="showVersions()"
                color="primary"
                title="Show versions">
        <ion-icon slot="icon-only"
                  name="bookmark-outline">
        </ion-icon>
    </ion-button>
    <ion-button (click)="loadData(true)"
                color="primary"
                title="Refresh translation from backend">
        <ion-icon slot="icon-only"
                  name="refresh-outline">
        </ion-icon>
    </ion-button>
    <ion-button *ngIf="bigView"
                (click)="download()"
                color="primary"
                title="Download translations">
        <ion-icon slot="icon-only"
                  name="download-outline">
        </ion-icon>
    </ion-button>
    <ion-button *ngIf="bigView"
                color="primary"
                title="Upload translations">
        <ion-icon *ngIf="!uploading"
                  slot="icon-only"
                  name="cloud-upload-outline">
        </ion-icon>
        <ion-spinner *ngIf="uploading"></ion-spinner>
        <dropzone [message]="''"
                  [config]="dropZoneConfig"
                  (success)="onUploadSuccess($event)"
                  (error)="onUploadError($event)"
                  (sending)="onSending($event)">
        </dropzone>
    </ion-button>
    <ion-button *ngIf="!bigView"
                (click)="toggleClickable()"
                [color]="!trnsService.clickable ? 'primary':'light'"
                title="Toggle clickable actions">
        <ion-icon *ngIf="!trnsService.clickable"
                  slot="icon-only"
                  name="lock-closed-outline">
        </ion-icon>
        <ion-icon *ngIf="trnsService.clickable"
                  slot="icon-only"
                  name="lock-open-outline">
        </ion-icon>
    </ion-button>
    <ion-button (click)="toggleView()"
                [color]="bigView ? 'primary':'light'"
                title="Toggle clickable actions">
        <ion-icon slot="icon-only"
                  name="expand-outline">
        </ion-icon>
    </ion-button>
</div>