<ng-template #defaultSelection
             let-attrs="attrs">
    <ion-chip class="{{ attrs.removeButtonClasses }}"
              [color]="attrs.removeButtonColor"
              [outline]="true">
        <ion-icon *ngIf="frontIcon"
                  [name]="frontIcon"
                  [slot]="'start'"
                  color="primary"></ion-icon>

        <ion-label>{{ attrs.label }}</ion-label>

        <ion-icon *ngIf="attrs.removeButtonIcon"
                  [name]="attrs.removeButtonIcon"
                  [slot]="attrs.removeButtonSlot">
        </ion-icon>
    </ion-chip>
</ng-template>

<div *ngIf="multi">
    <div *ngFor="let item of selected"
         class="selected-items"
         (click)="removeItem(item)">
        <ng-template [ngTemplateOutlet]="selectionTemplate || defaultSelection"
                     [ngTemplateOutletContext]="{
                            attrs: {
                              data:                item,
                              label:               getLabel(item),
                              removeButtonClasses: removeButtonClasses,
                              removeButtonColor:   removeButtonColor,
                              removeButtonIcon:    removeButtonIcon,
                              removeButtonSlot:    removeButtonSlot
                            }
                         }">
        </ng-template>
    </div>
</div>

<ion-input #inputElem
           [autocomplete]="enableBrowserAutoComplete ? 'on' : 'off'"
           [name]="name"
           (ionInput)="getItems($event)"
           (tap)="handleTap($event)"
           [(ngModel)]="keyword"
           (ngModelChange)="updateModel($event)"
           [placeholder]="autocompleteOptions.placeholder == null ? defaultOpts.placeholder : autocompleteOptions.placeholder"
           [type]="autocompleteOptions.type == null ? defaultOpts.type : autocompleteOptions.type"
           [clearOnEdit]="autocompleteOptions.clearOnEdit == null ? defaultOpts.clearOnEdit : autocompleteOptions.clearOnEdit"
           [clearInput]="autocompleteOptions.clearInput == null ? defaultOpts.clearInput : autocompleteOptions.clearInput"
           [color]="autocompleteOptions.color == null ? null : autocompleteOptions.color"
           [mode]="autocompleteOptions.mode == null ? defaultOpts.mode : autocompleteOptions.mode"
           [disabled]="disabled || (this.maxSelected !== null && this.selected.length >= this.maxSelected)"
           [ngClass]="{ 'hidden': !useIonInput, 'loading': isLoading }"
           [ngStyle]="styles.searchbar"
           (keydown.tab)="handleTabOut($event)"
           (keydown.shift.tab)="hideItemList()"
           (keyup.arrowDown)="highlightItem(-1)"
           (keyup.arrowUp)="highlightItem(1)"
           (keyup.enter)="handleSelectTap($event, suggestions[focusedOption])"
           (keyup.escape)="hideItemList()"
           (ionFocus)="onFocus($event)"
           (ionBlur)="onBlur($event)">
</ion-input>

<ion-searchbar #searchbarElem
               [autocomplete]="enableBrowserAutoComplete ? 'on' : 'off'"
               [name]="name"
               [animated]="autocompleteOptions.animated == null ? defaultOpts.animated : autocompleteOptions.animated"
               (ionInput)="getItems($event)"
               (tap)="handleTap($event)"
               [(ngModel)]="keyword"
               (ngModelChange)="updateModel($event)"
               [cancelButtonIcon]="autocompleteOptions.cancelButtonIcon == null ? defaultOpts.cancelButtonIcon : autocompleteOptions.cancelButtonIcon"
               [cancelButtonText]="autocompleteOptions.cancelButtonText == null ? defaultOpts.cancelButtonText : autocompleteOptions.cancelButtonText"
               [clearIcon]="autocompleteOptions.clearIcon == null ? defaultOpts.clearIcon : autocompleteOptions.clearIcon"
               [color]="autocompleteOptions.color == null ? null : autocompleteOptions.color"
               [showCancelButton]="autocompleteOptions.showCancelButton == null ?
                                        (defaultOpts.showCancelButton ? 'always' : 'never') :
                                        (autocompleteOptions.showCancelButton ? 'always' : 'never')"
               [debounce]="autocompleteOptions.debounce == null ? defaultOpts.debounce : autocompleteOptions.debounce"
               [placeholder]="autocompleteOptions.placeholder == null ? defaultOpts.placeholder : autocompleteOptions.placeholder"
               [autocorrect]="autocompleteOptions.autocorrect == null ? defaultOpts.autocorrect : autocompleteOptions.autocorrect"
               [mode]="autocompleteOptions.mode == null ? defaultOpts.mode : autocompleteOptions.mode"
               [searchIcon]="autocompleteOptions.searchIcon == null ? defaultOpts.searchIcon : autocompleteOptions.searchIcon"
               [spellcheck]="autocompleteOptions.spellcheck == null ? defaultOpts.spellcheck : autocompleteOptions.spellcheck"
               [type]="autocompleteOptions.type == null ? defaultOpts.type : autocompleteOptions.type"
               [ngClass]="{ 'hidden': useIonInput, 'loading': isLoading, 'disabled': disabled || (this.maxSelected !== null && this.selected.length >= this.maxSelected) }"
               [ngStyle]="styles.searchbar"
               (keydown.tab)="handleTabOut($event)"
               (keydown.shift.tab)="hideItemList()"
               (keyup.arrowDown)="highlightItem(-1)"
               (keyup.arrowUp)="highlightItem(1)"
               (keyup.enter)="handleSelectTap($event, suggestions[focusedOption])"
               (keyup.escape)="hideItemList()"
               (ionClear)="clickClear()"
               (ionFocus)="onFocus($event)"
               (ionBlur)="onBlur($event)">
</ion-searchbar>

<ng-template #defaultTemplate
             let-attrs="attrs">
    <span [innerHTML]='attrs.label'></span>
</ng-template>

<ng-template #defaultEmptyTemplate
             let-attrs="attrs"
             class="ion-text-center">
    {{ autocompleteOptions.noItems }}
</ng-template>

<ng-template [ngTemplateOutlet]="listTemplate || defaultList"
             [ngTemplateOutletContext]="{
                        attrs: {
                            data:            suggestions,
                            keyword:         keyword,
                            maxResults:      maxResults,
                            maxSelected:     maxSelected,
                            selected:        selected,
                            showSuggestions: showSuggestions
                          }
                        }">
</ng-template>

<ng-template #defaultList
             let-attrs="attrs">
    <ul *ngIf="!(disabled || (attrs.maxSelected !== null && attrs.selected.length >= attrs.maxSelected)) && attrs.data.length > 0 && attrs.showSuggestions"
        [ngStyle]="listStyles()">
        <li *ngFor="let suggestion of attrs.data| slice:0:attrs.maxResults; let index = index"
            [ngClass]="{ 'focus': focusedOption === index }"
            [ngStyle]="styles.listItem"
            (mouseenter)="focusedOption = index"
            (click)="handleSelectTap($event, suggestion)"
            (tap)="handleSelectTap($event, suggestion)">
            <ng-template [ngTemplateOutlet]="template || defaultTemplate"
                         [ngTemplateOutletContext]="{
                            attrs:{
                              data:               suggestion,
                              label:              getLabel(suggestion),
                              keyword:            keyword,
                              formValue:          getFormValue(suggestion),
                              labelAttribute:     getLabel(suggestion),
                              formValueAttribute: getFormValue(suggestion)
                            }
                         }">
            </ng-template>
        </li>
    </ul>

    <ul *ngIf="suggestions.length === 0 && showSuggestions"
        [ngStyle]="listStyles()">
        <li [ngStyle]="styles.listItem">
            <ng-template [ngTemplateOutlet]="emptyTemplate || defaultEmptyTemplate"
                         [ngTemplateOutletContext]="{
                        attrs:{
                          keyword: keyword
                        }
                     }">
            </ng-template>
        </li>
    </ul>
</ng-template>