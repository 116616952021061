import { Component, NgZone, OnInit } from '@angular/core';

// ionic
import { Platform } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

import { PlatformService } from '../../services/platform.service';

import QRReader from '../../helpers/qr';

import { log } from "../../helpers/log";

declare var QRScanner: any;

@Component({
    selector: 'app-qr-code-reader',
    templateUrl: './qr-code-reader.component.html',
    styleUrls: ['./qr-code-reader.component.scss'],
})
export class QrCodeReaderComponent implements OnInit {

    copiedText: string = '';

    qrScan: boolean = false;

    loading: boolean = true;

    constructor(
        private ngZone: NgZone,
        private modalController: ModalController,
        public platform: Platform,
        public plt: PlatformService
    ) {

    }

    startStreaming() {
        this.loading = true;
        if (!this.plt.is('cordova')) {
            QRReader.init(
                (allowed) => {
                    this.loading = false;
                    this.qrScan = allowed;
                    if (allowed) {
                        this.scan()
                    }
                });
        } else {
            this.scan();
        }
    }

    ngOnInit() {
        this.platform.ready().then(() => {
            this.startStreaming();
        });
    }

    //Scan
    scan() {
        if (this.plt.is('cordova')) {
            this.loading = false;
            QRScanner.prepare((err, status) => {
                if (err) {
                    // here we can handle errors and clean up any loose ends.
                    log('error', 'Scan error', err);
                } else {
                    this.qrScan = status.authorized;
                    if (status.authorized) {
                        // W00t, you have camera access and the scanner is initialized.
                        QRScanner.scan((err, text) => {
                            if (err) {
                                this.plt.hideApp = false;
                                // an error occurred, or the scan was canceled (error code `6`)
                                log('info', 'Scan error', err);
                                QRScanner.hide((status) => {
                                    log('info', 'Hide scan', status);
                                    this.ngZone.run(() => {
                                        this.plt.hideApp = false;
                                    });
                                    QRScanner.destroy(function (status) {
                                        log('info', 'Destroy scan', status);
                                    });
                                });
                            } else {
                                // The scan completed, display the contents of the QR code:
                                log('info', 'Scan');
                                // this.closeQr();
                                this.copiedText = text;
                                this.close();
                            }
                        });
                        QRScanner.show(() => {
                            this.ngZone.run(() => {
                                this.qrScan = true;
                                this.plt.hideApp = true;
                            });
                        }) //should feel very fast.

                    } else if (status.denied) {
                        // The video preview will remain black, and scanning is disabled. We can
                        // try to ask the user to change their mind, but we'll have to send them
                        // to their device settings with `QRScanner.openSettings()`.
                    } else {
                        // we didn't get permission, but we didn't get permanently denied. (On
                        // Android, a denial isn't permanent unless the user checks the "Don't
                        // ask again" box.) We can ask again at the next relevant opportunity.
                    }
                }
            }); // show the prompt
        } else {
            QRReader.scan(result => {
                this.copiedText = result;
                this.close();
            });
        }
    }

    /**
    * close QR code reader
    *
    * @return void
    */
    closeQr() {
        // NOTE[jg] cancel scan is not working from cordova-plugin-qrscanner-12 version 
        // QRScanner.cancelScan((status) => {
            // log('info', 'Cancel scan', status);
            QRScanner.hide((status) => {
                log('info', 'Hide scan', status);
                this.ngZone.run(() => {
                    this.plt.hideApp = false;
                });
                QRScanner.destroy(function (status) {
                    log('info', 'Destroy scan', status);
                });
            });
        // });
    }

    close() {

        // remove link part from qr codes
        this.copiedText = decodeURIComponent(this.copiedText.substr(this.copiedText.indexOf('access-key/') + 11));

        if (!this.plt.is('cordova')) {
            QRReader.destroy();
        } else {
            this.closeQr();
        }
        this.modalController.dismiss({
            action: 'close',
            text: this.copiedText
        });
    }

}
