import * as moment from "moment";
import { Participant } from "./participant";

export class CustomParticipant
{
    id: number;
    participant_id: number;
    participant: Participant;
    external_link: string;
    name: string;
    position: string;
    image: string;
    image_url: string;

    // version validation
    new_values: any;

    /**
     * constructor
     *
     * @param {CustomParticipant} init
     */
    public constructor(init?: CustomParticipant)
    {
        if (!!init)
        {
            Object.assign(this, init);

            if (init.participant) {
                this.participant = new Participant(init.participant);
            }
        }
    }

    public isEmpty() {
        // console.log('CustomParticipant::isEmpty()', this);
        return !this.name && !this.participant_id;
    }

    public getName() {
        if (this.participant) {
            return this.participant.getFullName();
        }
        return this.name;
    }

    public getPosition() {
        if (this.participant) {
            return this.participant.profession;
        }
        return this.position;
    }

    public getImage() {
        if (this.participant) {
            return this.participant.picture;
        }
        return this.image;
    }

    public getImageUrl() {
        if (this.participant) {
            this.participant.picture_url;
        }
        return this.image_url;
    }
}
