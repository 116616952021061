export class MarketplaceContainerTranslation {
    id: number;
    marketplace_container_id: number;
    locale: string;
    name: string;

    /**
     * constructor
     *
     * @param {MarketplaceContainerTranslation} init
     */
    public constructor(init?: MarketplaceContainerTranslation) {
        if (!!init) {
            Object.assign(this, init);
        }
    }

}
