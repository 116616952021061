import { version } from "./version";
import { config } from "./config";

export const environment = {
  production: true,
  name: 'production',
  version: version,
  debug: false,
  api: 'https://server.congreet.com/api/v2',
  redirectUri: 'http://localhost/callback',
  assets: config.assetsProd,
  pwa: config.prodPwa,
  deeplink: config.deeplink,
  appStoreLink: config.appStoreLink,
  playStoreLink: config.playStoreLink,
  benefitUrl: config.benefitUrl,
  availableLangs: config.availableLangs,
  liveTranslation: config.liveTranslationProd,
  cookieForAssets: true,
  hideSidebarPublicPages: config.hideSidebarPublicPages,
  publicPagesLang: config.publicPagesLang,
  hideLogout: config.hideLogout,
  hideSettingsPasswordReset: config.hideSettingsPasswordReset,
  atsAnonymous: config.atsAnonymous,
  noIframeRediret: config.noIframeRediret,
  showInstallPrompt: config.showInstallPrompt,
  showCredentialWallet: config.showCredentialWallet,
  hideRatingInMembers: config.hideRatingInMembers,
  sameSiteAssets: config.sameSiteAssets,
  useLicenceTotal: [655],
  google: {
      apiKeys: {
          android: 'AIzaSyArTn4ZZsimCyTU4U776Ww1mxGicQwUEG4',
          ios: 'AIzaSyDhrhGJ_ft5cAKP4_ST_QK7L5Lk1JvwsCc',
          web: 'AIzaSyBagu5q2R6i-IvZ8y6xDT3tfDJNwOeb9ow'
      },
      apiKey: 'AIzaSyArTn4ZZsimCyTU4U776Ww1mxGicQwUEG4'
  },
  linkedIn: {
      clientId: config.linkedIn.clientId
      // clientId: '864jq9ftuk7dbs' // for mobile aps
  },
  mobileAppId: config.mobileAppId,
  sentry: {
      enabled: false,
      dialog: false,
      dsn: ''
  },
  customDomains: [
    window.location.host,
    'app.skillsclub.com',
    // tb. and tqb. prefix will be probably used mostly by testing boards, so we will conver those in this case also
    'tqb.skillsclub.com',
    'tb.skillsclub.com',
    'pwa.congreet.com',
    '.coolgreet.de',
  ],
  customScheme: config.customScheme,
  wizard_skip_ttl: 1800
};
