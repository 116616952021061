import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, NgZone, Output } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

// ionic
import { AlertController } from "@ionic/angular";
import { ModalController, ActionSheetController } from '@ionic/angular';
import { Events } from '../../helpers/events';

// ionic native
import { Camera } from '@ionic-native/camera/ngx';
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { FileTransfer, FileTransferObject } from "@ionic-native/file-transfer/ngx";

import * as moment from "moment";

//import { ToastrConfig } from "ngx-toastr";
import { DropzoneComponent, DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { DropzoneService } from "../../services/dropzone.service";

// rxjs
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

// components
import { AttendeeDetailComponent } from "../../components/attendee-detail/attendee-detail.component";

// other services
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

// services
import { AuthenticationService } from '../../services/authentication.service';
//import { FroalaService } from "../../../../_services/froala.service";
import { UserService } from '../../services/user.service';
import { ParticipantService } from "../../services/participant.service";
import { EventService } from "../../services/event.service";
import { RouterExtService } from "../../services/router-ext.service";
import { PlatformService } from "../../services/platform.service";
import { OverlayService } from "../../services/overlay.service";
import { Helper } from "../../services/helper";

// models
import { User } from '../../models/user';
import { Participant } from '../../models/participant';
import { ParticipantDetail } from '../../models/participant-detail';
import { UploadImage } from "../../models/upload-image";
import { Group } from '../../models/group';
import { Event } from '../../models/event';

// config not using...
import { environment } from "../../../environments/environment";
import { dropZoneSettings } from "../../settings/dropzone.settings";
import { datepickerLangs } from "../../../assets/i18n/datepicker/langs";


@Component({
    selector: 'profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.scss'],
})
export class ProfileEditComponent implements OnInit, OnDestroy {

    /**
     * dropzone
     *
     * @type ViewChild
     */
    @ViewChild(DropzoneComponent) dropzone: DropzoneComponent;

    @Output() onChangeLoading: EventEmitter<boolean> = new EventEmitter();
    @Output() onSaved: EventEmitter<Participant> = new EventEmitter();

    /**
      * Is form used as part of profile wizard?
      *
      * @type boolean
      */
    @Input() wizard: boolean = false;

    /**
      * Is form used as part of profile wizard and can be skipped
      *
      * @type boolean
      */
    @Input() skip: boolean = false;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * subscription for event changes subject
     *
     * @type {Subscription}
     */
    private subscriptionEvent: Subscription;

    /**
     * loading state
     *
     * @type {boolean}
     */
    public submit: boolean = false;

    /**
     * is image upload active
     *
     * @type {boolean}
     */
    public sending: boolean = false;

    /**
     * initial loading state
     *
     * @type {boolean}
     */
    public loading: boolean = true;

    /**
     * available languages
     *
     * @type array
     */
    public langs: any = [];

    /**
     * selected language
     *
     * @type string
     */
    public selectedLang: string;

    /**
     * enable the possibility to delete the account
     *
     * @type {boolean}
     */
    public enableDeletion: boolean = false;

    /**
     * user
     *
     * @type {User}
     */
    public participant: Participant = new Participant;

    /**
     * edit participant
     *
     * @type {Participant}
     */
    public editParticipant: Participant = new Participant;

    /**
     * dropzone config
     *
     * @type {DropzoneConfigInterface}
     */
    public dropZoneConfig: DropzoneConfigInterface = dropZoneSettings;

    /**
     * dropzone config
     *
     * @type {DropzoneConfigInterface}
     */
    public dropZoneConfigExtended: DropzoneConfigInterface = dropZoneSettings;

    /**
     * the current user
     *
     * @type {User}
     */
    public user: User = new User;

    /**
     * current language
     *
     * @type string
     */
    public userLang: string;

    /**
     * validate state
     *
     * @type {boolean}
     */
    public validate: boolean = false;

    /**
     * Validation errors
     *
     * @type {object}
     */
    public validationErrors: object = {};

    /**
     * validation errors state
     *
     * @type {boolean}
     */
    public hasValidationErrors: boolean = false;

    /**
     * loading state
     *
     * @type {boolean}
     */
    public linkedInSubmit: boolean = false;

    /**
     * edit fields of participant
     *
     * @type {string[]}
     */
    protected editFields: string[] = [
        'public_email',
        'title',
        'profession',
        'company',
        'additional_company',
        'street',
        'streetnr',
        'zip',
        'city',
        'country',
        'phone',
        'xing_profile',
        'linkedin_profile',
        'description',
        'groupedDetails',
        'groupedDetailsDeleted',
        'picture',
        'picture_url',
        'wizard_status'
    ];

    /**
      * upload state
      *
      * @type {boolean}
      */
    public uploading: boolean = false;

    /**
      * The selected images
      *
      * @type UploadImage
      */
    public image: UploadImage = new UploadImage;

    /**
      * The selected images
      *
      * @type UploadImage
      */
    public froalaImage: UploadImage = new UploadImage;

    /**
      * Amount of progress made during an image upload
      *
      * @type number
      */
    public progress: number = 0;

    /**
      * The transfer object
      *
      * @type TransferObject
      */
    public fileTransfer: FileTransferObject = this.transfer.create();

    /**
     * datepicker language
     *
     * @type {Object}
     */
    public datepickerLangs = datepickerLangs;

    /**
     * Range for year drop down
     *
     * @type {string}
     */
    public yearRange: string = (new Date().getFullYear() - 60) + ':' + (new Date().getFullYear());

    /**
     * show group icon
     *
     * @type {Group}
     */
    public group: Group;

    /**
     * event
     *
     * @type {Event}
     */
    public event: Event;

    /**
     * which select type should be used.. alert/popover/action-sheet
     *
     * @type {string}
     */
    public selectType: string = 'popover';

    /**
     * constructor
     *
     * @param updates
     * @param camera
     * @param imagePicker
     * @param transfer
     * @param ngZone
     * @param route
     * @param userService
     * @param participantService
     * @param alertService
     * @param authService
     * @param router
     * @param appEvents
     * @param froalaService
     * @param dropzoneService
     */
    constructor(
        public plt: PlatformService,
        public actionSheetController: ActionSheetController,
        public overlayService: OverlayService,
        public translate: TranslateService,
        public camera: Camera,
        public imagePicker: ImagePicker,
        public transfer: FileTransfer,
        public ngZone: NgZone,
        public route: ActivatedRoute,
        public userService: UserService,
        public participantService: ParticipantService,
        public eventService: EventService,
        //public alertService: AlertService,
        public authService: AuthenticationService,
        public router: Router,
        public appEvents: Events,
        public alertCtrl: AlertController,
        public modalController: ModalController,
        public auth: AuthenticationService,
        //public froalaService: FroalaService,
        public dropzoneService: DropzoneService,
        public routerExtService: RouterExtService
    ) {
        // dropzone config
        this.dropZoneConfig = { ...dropzoneService.dropzoneConfig };
        this.dropZoneConfig.maxFilesize = 4;
        this.dropZoneConfig.acceptedFiles = 'image/jpg,image/jpeg,image/png,image/gif';
        this.dropZoneConfig.autoReset = 1000;

        // dropzone extended config
        this.dropZoneConfigExtended = { ...dropzoneService.dropzoneConfig };
        this.dropZoneConfigExtended.maxFilesize = 4;
        if (this.plt.is('cordova')) {
            this.dropZoneConfigExtended.acceptedFiles = 'application/pdf';
        } else {
            this.dropZoneConfigExtended.acceptedFiles = 'image/jpg,image/jpeg,image/png,application/pdf,image/gif';
        }
        this.dropZoneConfigExtended.autoReset = 1000;

        this.langs = this.translate.getLangs();
        this.userLang = this.translate.currentLang;
        this.selectedLang = this.translate.currentLang;

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.userLang = event.lang;
        });

        if (this.plt.sizeSm) {
            this.selectType = 'action-sheet';
        }
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() {
        this.userService.getCurrentUser()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                debounceTime(0)
            ).subscribe((user) => {
                if (user.id) {
                    this.user = user;
                    this.event = this.user.selected_participant.event;

                }
            });

        this.userService.getCurrentUser()
            .pipe(
                take(1),
                debounceTime(0)
            ).subscribe((user) => {
                if (user.id) {
                    this.user = user;
                    this.event = this.user.selected_participant.event;
                    this.refresh();

                }
            });

    }

    /**
      * set model values properly
      *
      * @return void
      */
    private initModel(model: any, $event?) {
        // must set to empty string, because froala cannot handle null correctly (during event switch)
        if (!model.description) {
            model.description = '';
        }

        // if there is no template yet, check for this profile by default
        if (!this.user.template) {
            model.set_global = true;
        }

        this.editParticipant = new Participant(model, this.user);

        this.dropZoneConfig.url = environment.api + '/participant/image/' + model.id + '?ngsw-bypass=true';
        this.dropZoneConfigExtended.url = environment.api + '/participant/upload/' + model.id + '?ngsw-bypass=true';
        //this.dropZoneConfig.method = 'put';

        if ($event) {
            $event.target.complete();
        } else {
            this.loading = false;
        }
    }

    /**
      * refresh list
      *
      * @return void
      */
    public refresh($event?) {
        if (this.user.id && this.user.selected_participant_id) {
            let subscription = this.participantService.getById(this.user.selected_participant_id)
                .subscribe((participant: Participant) => {
                    this.participant = new Participant(participant, this.user);
                    this.initModel(participant, $event);

                    // get attendees group from event
                    if (!this.group && this.event.use_groups && this.event.groups.length) {
                        if (this.participant.group) {
                            this.group = this.participant.group;
                        }
                        else {
                            this.group = this.event.groups.find(item => item.id == this.participant.group_id);
                        }
                    }

                    if (this.user.template && this.wizard) {
                        this.useTemplate();
                    }
                });
            this.plt.activeSubscriptions.push(subscription);
        }
    }

    /**
      * skip edit form, so only emit onSaved callback
      *
      * @return void
      */
    public skipEditation() {
        this.setParticipant(true, true);
    }

    /**
     * set user
     *
     * @return void
     */
    public setParticipant(valid: boolean = false, skipSaving: boolean = false) {
        this.validationErrors = {};

        // unset error messages for details and other formatting in details
        this.editParticipant.groupedDetails.forEach((group) => {
            group.details.forEach((detail) => {
                detail.error = '';
                // hotfix for proper date formating
                if (detail.type == 'from' || detail.type == 'to') {
                    if (detail.value) {
                        if (!(detail.value instanceof Date)) {
                            detail.value = moment(detail.value).toDate();
                        }

                        detail.value.setDate(2);
                    }
                }
            })
        });

        this.validate = true;

        if (!valid) {
            this.overlayService.showError(
                this.translate.instant('MESSAGE_ERROR') + ' '
                + this.translate.instant('VALIDATION_TRANSLATIONS_INCOMPLETE'));
        } else {
            this.submit = true;
            this.validationErrors = {};

            if (!skipSaving) {
                // we save changes only if it is not skip operation
                this.mergeEditParticipant();
            }

            // dont' set whole event info
            this.participant.event = null;
            // NOTE[jg] - correct missing attribute
            // this.participant.user = null;
            this.participant.blacklist = null;

            // we dont show details in wizard, so don't send it to backend
            if (this.wizard) {
                // remove grouped details to avoid rewriting details with empty array
                this.participant.groupedDetails = null;
                this.participant.groupedDetailsDeleted = null;
                this.participant.details = null;
            }

            // set finished step for wizard
            if (!this.participant.wizard_status) {
                this.editParticipant.wizard_status = 2;
                this.participant.wizard_status = 2;
            }

            // set request to API to store actual data
            this.participantService.update(this.participant).subscribe(
                (success) => {

                    this.user.selected_participant.updated_at = success.updated_at;
                    this.user.selected_participant.details = success.details;

                    // show succes message if profile was saved
                    if (!skipSaving) {
                        this.overlayService.showSuccess(success.message);
                    }

                    // reload user after updating participant
                    // this.appEvents.publish('user:current:participant');

                    // copy changes to current model
                    this.editFields.forEach((attribute) => {
                        this.user.selected_participant[attribute] = this.participant[attribute];
                    });

                    // copy full proper event details to be sure to have all details
                    this.userService.setCurrentUser(this.user);

                    // update data about users, ID from details
                    this.participant = new Participant(this.user.selected_participant, this.user, true);
                    this.editParticipant = new Participant(this.user.selected_participant, this.user, true);

                    this.sending = false;
                    this.submit = false;
                    this.validate = false;

                    if (!this.plt.is('cordova')) {
                        this.dropzone.directiveRef.reset();
                    }

                    this.onSaved.emit(this.participant);
                },
                (error) => {
                    const data = error.error;

                    if (data.fields) {
                        for (const field in data.fields) {
                            if (data.fields.hasOwnProperty(field)) {
                                this.hasValidationErrors = true;
                                this.validationErrors[field] = data.fields[field].join(' ');

                                let keys = field.split('.');
                                if (keys[0] == 'groupedDetails') {
                                    let errorMsg = data.fields[field].join(' ');

                                    if (keys[4] == 'options') {
                                        let errorMsgTranslated = errorMsg.replace(
                                            'Grouped details.' + keys[1] + '.details.' + keys[3] + '.options.name',
                                            this.translate.instant('PARTICIPANT_DETAIL_TYPE_' + this.editParticipant.groupedDetails[keys[1]].type + '_name')
                                        );
                                        this.editParticipant.groupedDetails[keys[1]].details[keys[3]].options.error = errorMsgTranslated;
                                    }
                                    else {
                                        let replaceText = this.translate.instant('PARTICIPANT_DETAIL_TYPE_' + this.editParticipant.groupedDetails[keys[1]].type);

                                        if (this.editParticipant.groupedDetails[keys[1]].details[keys[3]].type) {
                                            replaceText = this.translate.instant('PARTICIPANT_DETAIL_TYPE_' + this.editParticipant.groupedDetails[keys[1]].details[keys[3]].type);
                                        }

                                        let errorMsgTranslated = errorMsg.replace(
                                            'Grouped details.' + keys[1] + '.details.' + keys[3] + '.value',
                                            replaceText
                                        );
                                        this.editParticipant.groupedDetails[keys[1]].details[keys[3]].error = errorMsgTranslated;
                                    }
                                }
                            }
                        }
                    }

                    this.overlayService.showError(data.message);

                    this.sending = false;
                    this.submit = false;

                    if (!this.plt.is('cordova')) {
                        this.dropzone.directiveRef.reset();
                    }
                });
        }
    }

    /**
     * use values from template
     */
    public useTemplate() {
        var model = this.editParticipant;


        // copy details only if this is not a wizard
        if (!this.wizard) {
            model.details = this.user.template.details.filter(d => !d.options.menu);
            model.details.forEach((item) => {
                item.id = null;
                item.participant_id = this.editParticipant.id;
            });

            // add items to delete...
            model.groupedDetails.forEach((group) => {
                if (!group.menu) {
                    model.groupedDetailsDeleted.push(group);
                }
            });
        }

        model.groupedDetails = [];

        // check all attributes from template
        this.user.templateAttributes.forEach((field) => {
            let newValue = JSON.parse(JSON.stringify(this.user.template[field]));
            // && !model[field] - now we are oweriting all values
            //  except wizard where some value is already stored
            if (this.wizard && model[field]) {
                // do nothing
            } else {
                model[field] = newValue;
            }
        });

        if (this.wizard) {
            // we don't copy any details for wizard first step
            model.details = [];
        }

        this.initModel(model);
    }

    /**
     * add new participant detail group
     *
     * @returns {void}
     */
    public addDetail(detailType): void {
        let group = {
            type: detailType.name,
            line: true,
            menu: false,
            deleted: false,
            details: []
        };
        let nextIndex = this.editParticipant.groupedDetails.length;
        detailType.group.forEach((type, index) => {
            group.details.push(new ParticipantDetail({
                id: null,
                participant_id: this.editParticipant.id,
                type: type,
                error: '',
                value: '',
                position: index,
                group_index: nextIndex,
                group_type: detailType.name,
                deleted: false,
                options: {},
                string_value: ''
            }));
        });
        this.editParticipant.groupedDetails.push(group);
    }

    /**
     * delete participant detail group
     *
     * @returns {void}
     */
    public deleteGroup(index, group): void {
        this.editParticipant.groupedDetailsDeleted.push(group);
        // remove from actual groups
        this.editParticipant.groupedDetails.splice(index, 1);
    }

    /**
     * delete detail group
     *
     * @returns {void}
     */
    public deleteDetail(index, detail, groupIndex, group): void {
        if (detail.id) {
            this.editParticipant.groupedDetailsDeleted.push({
                details: [{
                    id: detail.id
                }]
            });
        }
        // remove from actual groups
        group.details.splice(index, 1);

        // check if group is havein any detail..
        if (group.details.length == 0) {
            this.editParticipant.groupedDetails.splice(groupIndex, 1);
        }
    }

    /**
     * add image detail group
     *
     * @returns {void}
     */
    public addGroupDetail(group, type): void {
        group.details.unshift(new ParticipantDetail({
            id: null,
            participant_id: this.editParticipant.id,
            type: type,
            error: '',
            value: '',
            position: 0,
            group_index: group.group,
            group_type: group.type,
            deleted: false,
            options: {},
            string_value: ''
        }));
        // reindex group
        group.details.forEach((group, index) => {
            group.position = index;
        })
    }

    /**
     * add image detail group
     *
     * @returns {void}
     */
    public addGroupDetailBefore(group, type): void {
        let nextIndex = group.details.length
        group.details.push(new ParticipantDetail({
            id: null,
            participant_id: this.editParticipant.id,
            type: type,
            error: '',
            value: '',
            position: nextIndex,
            group_index: group.group,
            group_type: group.type,
            deleted: false,
            options: {},
            string_value: ''
        }));
    }

    /**
     * move participant detail up
     *
     * @returns {void}
     */
    public moveDetailUp(index): void {
        if (index > 0) {
            let active = { ...this.editParticipant.groupedDetails[index] };
            let original = { ...this.editParticipant.groupedDetails[index - 1] };
            this.editParticipant.groupedDetails[index] = original;
            this.editParticipant.groupedDetails[index - 1] = active;
        }
    }

    /**
     * move participant detail down
     *
     * @returns {void}
     */
    public moveDetailDown(index): void {
        if (index < (this.editParticipant.groupedDetails.length - 1)) {
            let active = { ...this.editParticipant.groupedDetails[index] };
            let original = { ...this.editParticipant.groupedDetails[index + 1] };
            this.editParticipant.groupedDetails[index] = original;
            this.editParticipant.groupedDetails[index + 1] = active;
        }
    }

    /**
     * merge user edits into original user object
     *
     * @returns {void}
     */
    protected mergeEditParticipant(): void {
        this.editFields.forEach((field) => {
            // we need to also save removeing items.. not only creating and updating
            //if (this.editParticipant[field]) {
            this.participant[field] = JSON.parse(JSON.stringify(this.editParticipant[field]));
            //}
        });
    }

    /**
     * on image upload
     *
     * @param args
     */
    public onSending(args: any) {
        this.sending = true;

        const xhrRequest = args[1];

        // timeout event
        xhrRequest.ontimeout = ((event) => {
            this.dropzone.directiveRef.dropzone().removeAllFiles(true); // remove not uploaded files
            this.validate = true;
            this.validationErrors['picture'] = this.translate.instant('REQUEST_TIMEOUT');
            this.overlayService.showError(this.translate.instant('REQUEST_TIMEOUT'));
        });

        xhrRequest.withCredentials = true;
        xhrRequest.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
    }

    /**
     * on upload finished
     *
     * @param args
     */
    public onUploadSuccess(args: any, detail?) {
        if (detail) {
            detail.value = args[1].link;
            detail.options.type = args[1].type;
        } else {
            this.editParticipant.picture = args[1].picture;
            this.editParticipant.picture_url = args[1].picture_url;
        }

        this.validate = false;
        this.validationErrors['picture'] = false;
    }

    /**
     * on error during csv upload
     *
     * @param args
     */
    public onUploadError(args: any) {
        const message = args[1];
        if (message.indexOf('too large') !== -1 || message.indexOf('est trop grande') !== -1 || message.indexOf('ist zu gro') !== -1) {

            this.overlayService.showError(this.translate.instant('VALIDATION_IMAGE_TOO_LARGE', {
                maxSize: this.dropZoneConfig.maxFilesize
            }));

        } else if (message.toLowerCase().indexOf('upload files of this type') !== -1) {

            this.overlayService.showError(this.translate.instant('VALIDATION_WRONG_TYPE', {
                fileTypes: this.dropZoneConfig.acceptedFiles.split(',').join(', ')
            }));

        } else {
            this.overlayService.showError(this.translate.instant('VALIDATION_IMAGE_OTHER'));
        }
    }

    /**
     * destroy profile picture
     *
     * @return void
     */
    public deleteImage() {
        this.editParticipant.picture = null;
        this.editParticipant.picture_url = null;

        this.sending = false;
        // TODO
        if (!this.plt.is('cordova')) {
            this.dropzone.directiveRef.reset();
        }
    }

    public async confirmHideProfile(status: boolean = false) {
        this.overlayService.showConfirm(
            this.translate.instant('PROFILE_EDIT_HIDE_PROFILE'),
            this.translate.instant('PROFILE_EDIT_HIDE_PROFILE_HINT'),
            () => { this.hideProfile(status) },
            () => { },
            this.translate.instant('PROFILE_EDIT_HIDE_PROFILE_BUTTON')
        );
    }

    public hideProfile(status: boolean = false) {
        // set request to API to store actual data
        this.participantService.hide(this.participant.id, status).subscribe(
            (success) => {
                this.editParticipant.updated_at = success.updated_at;
                this.participant.updated_at = success.updated_at;

                this.participant.invisible = status;

                // show succes message if profile was saved
                this.overlayService.showSuccess(success.message);

                // reload user after updating participant
                this.appEvents.publish('user:current:participant');

                this.onSaved.emit(this.participant);
            },
            (error) => {
                const data = error.error

                this.overlayService.showError(data.message);
            });
    }

    public async openDestroyProfile() {

        let message = this.user.selected_participant.event.is_community ? this.translate.instant('PROFILE_EDIT_DELETE_PROFILE_HINT_COMMUNITY') : this.translate.instant('PROFILE_EDIT_DELETE_PROFILE_HINT');

        // add warning about removing credentials
        if (this.event.enable_credential_wallet) {
            message = this.translate.instant('PROFILE_EDIT_DELETE_PROFILE_HINT_COMMUNITY_CREDENTIAL_WALLET');
        }

        this.overlayService.showConfirm(
            this.user.selected_participant.event.is_community ? this.translate.instant('PROFILE_EDIT_DELETE_PROFILE_COMMUNITY') : this.translate.instant('PROFILE_EDIT_DELETE_PROFILE'),
            message,
            () => { this.destroyProfile() },
            () => { },
            this.translate.instant('PROFILE_EDIT_DELETE_PROFILE_PERMANENTLY')
        );
    }

    /**
     * delete profile
     */
    public destroyProfile() {
        this.loading = true;

        this.participantService.delete(this.participant.id).subscribe(
            () => {
                this.loading = false;
                this.appEvents.publish('logout');
                //this.alertService.success(success.message, true);
                // this.overlayService.showSuccess(success.message);
                // this.authService.logout();
                // this.router.navigate(['login']);
                // this.loading = false;
            },
            (error) => {
                //this.alertService.error(error.error.message, true);
                this.overlayService.showConnectionProblems(error);
                this.loading = false;
            });
    }

    public async openDestroyAccount() {

        let message = this.translate.instant('PROFILE_EDIT_DELETE_ACCOUNT_PARTICIPANT_HINT');

        if (this.user.is_organizer) {
            message = this.translate.instant('PROFILE_EDIT_DELETE_ACCOUNT_PARTICIPANT_WARNING_HINT');
        }

        if (this.plt.eventWithCredentialWallet) {
            message = message + '<br /><br /><span class="highlight">' + this.translate.instant('PROFILE_EDIT_DELETE_ACCOUNT_PARTICIPANT_HINT_CREDENTIAL_WALLET') + '</span>';
        }

        this.overlayService.showConfirm(
            this.translate.instant('PROFILE_EDIT_DELETE_ACCOUNT'),
            message,
            () => { this.destroyAccount() },
            () => { },
            this.translate.instant('PROFILE_EDIT_DELETE_ACCOUNT_PERMANENTLY')
        );
    }

    /**
     * delete account
     */
    public destroyAccount() {
        this.loading = true;

        this.userService.delete(this.user.id).subscribe(
            () => {
                this.loading = false;
                this.appEvents.publish('logout');
                //this.alertService.success(success.message, true);
                // this.overlayService.showSuccess(success.message);
                // this.authService.logout();
                // this.router.navigate(['login']);
                // this.loading = false;
            },
            (error) => {
                this.overlayService.showConnectionProblems(error);
                this.loading = false;
            });
    }

    /**
     * show deletion form
     *
     * @return void
     */
    public showDeletion() {
        this.enableDeletion = true;
    }

    public goToDetail() {
        // TODO
        //this.router.navigate(['/participant/dashboard/participants/detail/' + this.participant.id]);
        return false;
    }

    /**
     * go to participants detail page
     *
     * @param participant
     *
     * @return void
     */
    public async showAttendeeDetail() {

        this.routerExtService.softNavigate();

        const modal = await this.modalController.create({
            component: AttendeeDetailComponent,
            cssClass: 'attendee-modal',
            componentProps: {
                attendee: this.editParticipant,
                isPreview: true
            }
        });

        modal.onWillDismiss().then((data) => {
            if (data.data?.action == 'close' || data.role == 'backdrop') {
                this.routerExtService.softBack();
            }
        });

        return await modal.present();
    }

    /**
       * open gallery
       *
       * @return {Promise<void>}
       */
    public async openAlbums(detail = null): Promise<void> {
        this.plt.reloadable = false;

        if (await this.imagePicker.hasReadPermission()) {

            try {
                let originals = await this.fileManager();
                if (originals.length) {
                    let requests = await originals.map(async x => new UploadImage({
                        path: x,
                        guid: Helper.guid(),
                        thumb: x // eventually resize before upload via "await this.resize(x)"
                    }));

                    let images = await Promise.all(requests);
                    this.image = images[0];

                    setTimeout(() => {
                        this.uploadImage(detail);
                    }, 0);
                } else {
                    this.plt.reloadable = true;
                }
            } catch (exception) {
                this.plt.reloadable = true;
            }

        } else {

            let interval = setInterval(() => {
                this.imagePicker.hasReadPermission().then((boolHasPermissions) => {
                    if (boolHasPermissions) {
                        clearInterval(interval);
                        this.openAlbums(detail);
                    }
                });
            }, 1000);

            if (this.plt.is('ios')) {
                this.imagePicker.requestReadPermission();
            }

            if (this.plt.is('android')) {
                this.imagePicker.requestReadPermission();
            }

        }
    }

    /**
     * Opens the albums
     *
     * @return A collection of urls from the selected images
     */
    public fileManager(): Promise<Array<string>> {
        return this.imagePicker.getPictures({
            quality: 90,
            width: 2880,
            // height: 1024,
            maximumImagesCount: 1,
        });
    }

    /**
     * Actually uploads an image
     */
    public async uploadImage(detail = null): Promise<void> {

        if (detail) {
            detail.uploading = true;
        } else {
            this.uploading = true;
        }
        this.plt.keepAwake();

        // Bind the progress function
        this.fileTransfer.onProgress(this.onProgress);

        let token = localStorage.getItem('token');

        // Prepare our upload options
        let options = {
            fileKey: 'file',
            fileName: this.image.path.split('/').pop(),
            mimeType: 'image/jpeg',
            chunkedMode: false,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            params: {}
        };

        try {
            let result = await this.fileTransfer.upload(
                encodeURI(this.image.path),
                encodeURI(environment.api + '/participant/image/' + this.participant.id),
                options,
                false
            );

            if (detail) {
                // set upload paths to detail value
                detail.value = JSON.parse(result.response).picture_url;
            } else {
                // set upload paths to participant
                this.editParticipant.picture = JSON.parse(result.response).picture;
                this.editParticipant.picture_url = JSON.parse(result.response).picture_url;
            }

            if (result.response) {
                this.plt.reloadable = true;
                if (detail) {
                    detail.uploading = false;
                } else {
                    this.uploading = false;
                }
                this.plt.allowSleepAgain();
                //this.showSuccess(this.translate.instant('MESSAGE_UPLOAD_SUCCESS'));
            }
        }
        catch (e) {
            this.overlayService.showError();
            this.plt.reloadable = true;
            if (detail) {
                detail.uploading = false;
            } else {
                this.uploading = false;
            }
            this.plt.allowSleepAgain();
        }
    }

    /**
     * Opens the camera and display the taken picture if available
     */
    public async openCamera(detail = null): Promise<void> {
        this.plt.reloadable = false;

        try {
            let image = await this.cameraManager();

            if (image) {
                this.image = new UploadImage({
                    path: image,
                    guid: Helper.guid(),
                    thumb: image // eventually resize before upload via "await this.resize(x)"
                });

                setTimeout(() => {
                    this.uploadImage(detail);
                }, 0);
            } else {
                this.plt.reloadable = true;
            }
        } catch (exception) {
            this.plt.reloadable = true;
        }
    }

    /**
     * Opens the camera so that the user can take a picture
     *
     * @return The url of the taken image
     */
    private cameraManager(): Promise<string> {
        return this.camera.getPicture({
            // We want to have the URL to the file
            destinationType: this.camera.DestinationType ? this.camera.DestinationType.FILE_URI : 1,
            // Source of the images is the camera
            sourceType: this.camera.PictureSourceType ? this.camera.PictureSourceType.CAMERA : 1,
            // Encoding type is JPEG
            encodingType: this.camera.EncodingType ? this.camera.EncodingType.JPEG : 0,
            // Give us the full quality of the image, lower it for better performance
            quality: 90,
            // Allow editing of the image after its taken
            allowEdit: false,
            // When a image is taken via the camera also save it to the native photo album
            saveToPhotoAlbum: false,
            // Correct the orrientation of the image
            correctOrientation: true,
            // Resize to save bandwidth and prevent rejection by the server
            targetWidth: 2880,
            // targetHeight: 1800
        });
    }

    /**
     * The on upload progress callback event
     *
     * @param progressEvent The progress event of the image upload
     */
    public onProgress = (progressEvent: ProgressEvent, detail?): void => {
        this.ngZone.run(() => {
            if (progressEvent.lengthComputable) {
                let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                if (progress > 100) progress = 100;
                if (progress < 0) progress = 0;
                if (detail) {
                    detail.progress = progress;
                } else {
                    this.progress = progress;
                }
            }
        });
    };

    public navigate(link) {
        this.router.navigate([link]);
    }

    public leaveEvent() {
        if (
            (!this.event.use_groups && this.event.allow_skip_wizard)
            || (this.event.use_groups && this.event.can(this.editParticipant.group_id, 'allow_skip_wizard'))
        ) {
            // do nothing, or mabye pospone next show of wizard check?
            const item = {
                // ttl is in seconds, so we need to multiply it, to get it in milliseconds
                expiry: new Date().getTime() + (environment.wizard_skip_ttl * 1000),
            }
            sessionStorage.setItem('skip_wizard_' + this.event.id, JSON.stringify(item));

        } else {
            this.appEvents.publish('change:event', this.user.selected_participant.event_id);
        }

        this.appEvents.publish('close:wizard');
    }

    async presentActionSheet() {

        let buttons = [];
        if (this.skip) {
            buttons.push({
                text: this.translate.instant('PROFILE_EDIT_SKIP'),
                handler: () => {
                    this.skipEditation();
                }
            });
        }

        buttons.push({
            text: this.translate.instant('PROFILE_EDIT_LEAVE'),
            role: 'destructive',
            handler: () => {
                this.leaveEvent();
            }
        });

        buttons.push({
            text: this.user.selected_participant.event.is_community ? this.translate.instant('PROFILE_EDIT_DELETE_PROFILE_COMMUNITY') : this.translate.instant('PROFILE_EDIT_DELETE_PROFILE'),
            handler: () => {
                this.openDestroyProfile();
            }
        });

        buttons.push({
            text: this.translate.instant('PROFILE_EDIT_DELETE_ACCOUNT'),
            role: 'destructive',
            handler: () => {
                this.openDestroyAccount();
            }
        });

        buttons.push({
            text: this.translate.instant('BUTTON_CANCEL'),
            role: 'cancel',
            handler: () => {

            }
        });

        buttons.push();

        const actionSheet = await this.actionSheetController.create({
            header: '',
            buttons: buttons,
        });
        await actionSheet.present();
    }

    /**
     * on destroy
     */
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        if (this.hasValidationErrors) {
            this.appEvents.publish('user:current:participant');
        }
    }
}
