import { Component, EventEmitter, OnInit, OnDestroy, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// ionic native
import { Keyboard, KeyboardResizeMode } from '@ionic-native/keyboard/ngx';

// other libraries
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import * as moment from "moment";
import { DatexPipe } from "../../pipes/datex/datex";

// rxjs
import { Subject } from "rxjs";
import { take, takeUntil, debounceTime } from "rxjs/operators";

// components
import { AutoCompleteComponent } from "../../components/auto-complete/auto-complete.component";

// services
import { AppointmentService } from "../../services/appointment.service";
import { ConversationService } from "../../services/conversation.service";
import { EventService } from "../../services/event.service";
import { ParticipantService } from "../../services/participant.service";
import { PlatformService } from '../../services/platform.service';
import { OverlayService } from "../../services/overlay.service";
import { UserService } from "../../services/user.service";

// models
import { Appointment } from "../../models/appointment";
import { Conversation } from "../../models/conversation"
import { Event } from "../../models/event";
import { EventTimeSlots } from "../../models/event-timeslots";
import { Participant } from "../../models/participant";
import { ParticipantTimeslot } from "../../models/participant-timeslot";
import { User } from "../../models/user";
import { AppointmentParticipant } from "../../models/appointment-participant";

// setting
import { datepickerLangs } from "../../../assets/i18n/datepicker/langs";

@Component({
    selector: 'appointment-request',
    templateUrl: './appointment-request.component.html',
    styleUrls: ['./appointment-request.component.scss'],
})
export class AppointmentRequestComponent implements OnInit, OnDestroy {

    @ViewChild('autocomplete') searchbar: AutoCompleteComponent;

    /**
     * unsubscribe subject
     *
     * @type {Subject<void>}
     */
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    /**
     * user
     *
     * @type {User}
     */
    public user: User = new User;

    /**
     * selected language
     *
     * @type string
     */
    public userLang: string;


    /**
     * show loading
     *
     * @type {boolean}
     */
    public loading: boolean = true;

    /**
     * available languages
     *
     * @type array
     */
    public langs: any = [];

    /**
     * datepicker language
     *
     * @type {Object}
     */
    public datepickerLangs = datepickerLangs;

    /**
     * conversation ID
     *
     * @type {number}
     */
    public conversationId: number;

    /**
     * appointment
     *
     * @type {Appointment}
     */
    public appointment: Appointment = new Appointment;

    /**
     * Current date
     *
     * @type {Date}
     */
    public now: Date = new Date;

    /**
     * Range for year drop down
     *
     * @type {string}
     */
    public yearRange: string = new Date().getFullYear() + ':' + (new Date().getFullYear() + 1);

    /**
     * min year for ion-datetime, now we put timezone offset to 2 hours.. we should get offset from users device...
     *
     * @type {string}
     */
    public yearMin: string = new Date().toISOString();

    /**
     * max year for ion-datetime
     *
     * @type {string}
     */
    public yearMax: string = moment().add(180, 'days').toISOString();

    public starts_at: string = new Date().toISOString();

    /**
     * validate state
     *
     * @type {boolean}
     */
    public validate: boolean = false;

    /**
     * Validation errors
     *
     * @type {Object}
     */
    public validationErrors: any = {};

    /**
     * Event timeslots
     *
     * @type {EventTimeSlots}
     */
    public eventTimeslots: EventTimeSlots = new EventTimeSlots;

    /**
     * Participant timeslots
     *
     * @type {ParticipantTimeslot}
     */
    public participantTimeslot: ParticipantTimeslot = new ParticipantTimeslot;

    /**
     * timeslotDurationValue
     *
     * @type {number}
     */
    public timeslotDuration: number;

    /**
     * Event timeslots day selected timestamp
     *
     * @type {string}
     */
    public selectedDay: Date;

    /**
     * Event timeslots time selected
     *
     * @type {string}
     */
    public selectedTime: {
        start: Date,
        end: Date
    };

    /**
     * Array of available days for appointment form
     *
     * @type {Date[]}
     */
    public days: Date[] = [];

    /**
     * Array of actual timeslot by duration to show in appointment form
     *
     * @type {[]}
     */
    public timeslots = [];

    // validation status for slot searching
    public someFree = false;
    public smallerDurationFree = 0;
    public smallerDurationFreeDiffDay = 0;
    public diffDayFree = null;

    /**
     * which select type should be used.. alert/popover/action-sheet
     *
     * @type {string}
     */
    public selectType: string = 'popover';

    public customPickerOptions;

    public searchString: string = '';

    /**
     * is autocomplete list opened?
     *
     * @type {boolean}
     */
    public autocompleteOpen: boolean = false;

    /**
     * profile of attendee which is presented
     *
     * @type {Participant}
     */
    @Input() attendee: Participant;

    /**
     * is this component used in small version
     *
     * @type {boolean}
     */
    @Input() smallPreview: boolean = false;

    /**
     * class
     *
     * @type {string}
     */
    @Input() class: string = '';

    @Output() onAppointmentRequest: EventEmitter<any> = new EventEmitter();

    public editableLocation: boolean = true;

    constructor(
        public eventService: EventService,
        public plt: PlatformService,
        public conversationService: ConversationService,
        public appointmentService: AppointmentService,
        public participantService: ParticipantService,
        public overlayService: OverlayService,
        public translate: TranslateService,
        public userService: UserService,
        public router: Router,
        private keyboard: Keyboard,
    ) {

        // user language
        this.userLang = this.translate.currentLang;
        this.langs = this.translate.getLangs();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.userLang = event.lang;
        });

        let timezoneDate = new Date();
        timezoneDate.setHours(timezoneDate.getHours() - (new Date().getTimezoneOffset()/60));
        this.yearMin = timezoneDate.toISOString();
        this.starts_at = moment(this.appointment.date_starts_at).format('YYYY-MM-DDTHH:mmZ')

        this.customPickerOptions = {
            // backdropDismiss: false,
            // buttons: [{
            //     text: this.translate.instant('BUTTON_CANCEL'),
            //     role: 'cancel',
            //     handler: () => {
            //         //this.updateDatetimeValue(this.value);
            //         this.dateTimePicker.ionCancel.emit();
            //     }
            // }, {
            //     text: this.translate.instant('BUTTON_DONE'),
            //     handler: function(data) {
            //         this.dateTimePicker.updateDatetimeValue(data);
            //         const date = new Date(convertDataToISO(this.datetimeValue));
            //         this.datetimeValue.tzOffset = date.getTimezoneOffset() * -1;
            //         this.value = convertDataToISO(this.datetimeValue);
            //     }
            // }]
        }

        // TODO - create similar solution
        // this.appEvents.on('appointment:create', () => {
        //     this.appointment.send_email_confirmation = true;
        //     if (!this.showAppointment) {
        //         this.showAppointment = true;
        //
        //         // reset multiselects
        //         this.selectedDay = null;
        //         this.selectedTime = null;
        //         this.msgInput.nativeElement.focus();
        //     }
        // });
        if (this.plt.is('cordova')) {
            this.keyboard.setResizeMode(KeyboardResizeMode.Body);
        }
    }

    /**
     * on init
     *
     * @return void
     */
    ngOnInit() {


        this.participantService.autocompleteOptions = {
            onlyContactable: true,
            hideNotVisibleMessage: true
        };

        this.userService.getCurrentUser().pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(0)
        ).subscribe((user: User) => {
            if (user.id) {
                this.user = new User(user);
                this.user.selected_participant.event = new Event(this.user.selected_participant.event, this.user);

                this.participantService.eventId = this.user.selected_participant.event.id;

                this.appointmentService.getByParticipantId(+ this.attendee.id).subscribe((success) => {

                    this.conversationId = success.conversation_id;

                    // load user timeslots
                    if (this.user.selected_participant.event.use_timeslots) {
                        this.processTimeslots(success);
                    } else {
                        this.loading = false;
                    }

                    // @todo refactor with events
                    this.appointment.send_email_confirmation = true;
                });
            }
        });
        // TODO
        // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        //     this.userLang = event.lang;
        // });

        // reset invitations
        this.appointment.participants = [];
    }

    processTimeslots(conversationData) {
        this.participantService.getTimeslot(this.user.selected_participant.id).subscribe((data) => {
            // if this setting does not exits, we crate them from default event setting for timeslots
            if (!conversationData['participantTimeslot'] || !conversationData['participantTimeslot'].hasOwnProperty('id')) {
                // load timeslots from event if needed
                this.eventService.getEventTimeSetting(this.user.selected_participant.event.id).pipe(take(1)).subscribe((eventData) => {
                    // create and generate timeslots from event config
                    this.eventTimeslots = new EventTimeSlots(eventData.event);
                    // copy time slots from event which are default to participants timeslots
                    this.participantTimeslot.loadFromEvent(this.eventTimeslots);
                    // final updates for data processing
                    this.finalizeParticipantTimeslots(data, conversationData);
                });
            } else {
                // otherwise use saved setting for current user
                this.participantTimeslot = new ParticipantTimeslot(conversationData['participantTimeslot']);
                // final updates for data processing
                this.finalizeParticipantTimeslots(data, conversationData);

                // set custom place
                if (conversationData['participantTimeslot']['custom_places']) {
                    this.appointment.location = conversationData['participantTimeslot']['custom_places'];
                    this.editableLocation = false;
                }
                // set custom subject placeholder
                if (conversationData['participantTimeslot']['custom_subject']) {
                    this.appointment.description = conversationData['participantTimeslot']['custom_subject'];
                }
            }
        });
    }

    finalizeParticipantTimeslots(data, conversationData) {
        // there is array with days -> day is timestamp
        this.days = this.participantTimeslot.getDays(false);
        // store default timeslot duration
        this.timeslotDuration = this.participantTimeslot.timeslot_length;
        // mark slots with appointment as blocked for current user
        this.participantTimeslot.markAppointment(data['appointments']);
        // mark slots with appointment as blocked for other user form conversatoins
        this.participantTimeslot.markAppointment(conversationData['appointments']);
        // apply other user timeslot definition (blocked/free slot) if there is any
        if (conversationData['participantTimeslot']
            && conversationData['participantTimeslot']['timeslots']
            && conversationData['participantTimeslot']['timeslots']['items']) {
            this.participantTimeslot.markSlot(conversationData['participantTimeslot']['timeslots']['items']);
        } else {
            // if we already loaded config for event, use it
            if (this.eventTimeslots.event_id) {
                this.participantTimeslot.markSlot(this.eventTimeslots.eventTimeslots);
            } else {
                // load default setting for event and apply this for other side, if there is no stored setting for other participant
                this.eventService.getEventTimeSetting(this.user.selected_participant.event.id).pipe(take(1)).subscribe((eventData) => {
                    this.eventTimeslots = new EventTimeSlots(eventData.event);
                    this.participantTimeslot.markSlot(this.eventTimeslots.eventTimeslots);
                });
            }
        }
        // hide loading and scroll to bottom
        this.loading = false;
    }


    // DEPRECEATED - this is not used anywhere?
    /**
     * refresh list of available times for appointments
     *
     * @return void
     */
    refreshTimeslots() {
        this.appointmentService.getByParticipantId(this.attendee.id).subscribe((conversationData) => {
            this.processTimeslots(conversationData);
        })
    }

    /**
     * set appointment date
     *
     * @return void
     */
    public setAppointmentDate(): void {
        let momentDateStart = moment(this.appointment.date_starts_at);

        this.appointment.starts_at = momentDateStart.utc().format('YYYY-MM-DD HH:mm:ss');
    }

    /**
     * create appointment
     *
     * @return void
     */
    public sendAppointment(): void {
        this.validate = true;

        if (this.appointment.place) {
            this.appointment.location = this.appointment.place.title;
        }

        this.appointment.min_date = moment(this.now).utc().format('YYYY-MM-DD HH:mm:ss');

        if (this.user.selected_participant.event.use_timeslots && !this.selectedTime) {
            this.appointment.starts_at = null;
        }

        this.conversationService.sendAppointment(this.conversationId, this.appointment)
            .subscribe((conversation: Conversation) => {

                // mark slot as blocked if timesltos are enabled
                if (this.user.selected_participant.event.use_timeslots) {
                    this.participantTimeslot.markAppointment([this.appointment]);
                }

                this.appointment = new Appointment;
                this.validate = false;

                if (this.user.selected_participant.event.use_timeslots) {
                    this.timeslotDuration = this.participantTimeslot.timeslot_length;
                    this.selectDuration();
                }
                // exit component
                this.overlayService.showSuccess(this.translate.instant('APPOINTMENT_REQUEST_SAVED'));
                this.onAppointmentRequest.emit(conversation);

            }, error => {

                this.validationErrors = {};
                const data = error.error;

                if (data.fields) {
                    for (const field in data.fields) {
                        if (data.fields.hasOwnProperty(field)) {
                            this.validationErrors[field] = data.fields[field].join(' ');
                        }
                    }
                }
                // TODO
                this.overlayService.showError(data.message);
            });
    }


    /**
     * Changed expected duration for timeslot in appointment
     */
    public selectDuration() {
        this.selectedDay = null;
        this.selectedTime = null;
        this.appointment.starts_at = null
        this.appointment.date_starts_at = null;
        this.appointment.ends_at = null;
        this.timeslots = [];
    }

    /**
     * Changed expected day for timeslot in appointment
     */
    public selectDay() {
        this.selectedTime = null;
        this.appointment.starts_at = null;
        this.appointment.date_starts_at = null;
        this.appointment.ends_at = null;
        this.generateSlots();

        this.smallerDurationFree = 0;
        this.smallerDurationFreeDiffDay = 0;
        this.diffDayFree = null;

        // try to find better combination for user
        if (!this.someFree) {
            // try to find out smaller slot which can fit
            if (this.timeslotDuration != this.participantTimeslot.timeslot_length) {
                let step = this.timeslotDuration;
                while (this.smallerDurationFree == 0 && step > 0) {
                    this.testSlots(step, this.selectedDay);
                    // next step if needed
                    step = step - this.participantTimeslot.timeslot_length;
                }
            }

            // try different days if there is no free slot in this window
            if (this.selectedDay) {
                this.days.forEach(day => {
                    if (day.getTime() != this.selectedDay.getTime()) {
                        let step = this.timeslotDuration;
                        while (step > 0) {
                            this.testSlots(step, day);
                            // next step if needed
                            step = step - this.participantTimeslot.timeslot_length;
                        }
                    }
                });
            }

        }
    }

    /**
     * Changed expected time for timeslot in appointment
     */
    public selectTime() {
        if (this.selectedTime) {
            // this.selectedTime.start = moment(this.selectedTime.start).toDate();
            // this.selectedTime.end = moment(this.selectedTime.end).toDate();
            this.appointment.date_starts_at = this.selectedTime.start
            this.appointment.date_ends_at = this.selectedTime.end
            // timeslots are already stored in UTC, so not needed to transform them to UTC via .utc()
            this.appointment.starts_at = moment(this.appointment.date_starts_at).utc().format('YYYY-MM-DD HH:mm:ss');
            this.appointment.ends_at = moment(this.appointment.date_ends_at).utc().format('YYYY-MM-DD HH:mm:ss');
        }
    }

    /**
     * generate available time slots, by selected duration,day and time
     */
    public generateSlots() {
        this.timeslots = [];
        if (this.selectedDay) {
            this.someFree = false;
            let originalSlots = this.participantTimeslot.timeslots['items'][this.selectedDay.getTime()];

            // if there is no change for duration and default duration is used
            if (this.timeslotDuration == this.participantTimeslot.timeslot_length) {
                this.timeslots = originalSlots;
                // check if there is any free slot
                this.timeslots.forEach((slot) => {
                    if (slot.status == 'free' && !slot.appointment) {
                        this.someFree = true;
                    }
                });
            } else {
                // otherwise we need to join slots by selected duration
                let step = 1;
                let date = null;
                originalSlots.forEach(slot => {
                    if (!date) {
                        date = { ...slot };
                    }
                    if (slot.status != 'free' || slot.appointment) {
                        date.status = 'blocked';
                    }
                    // if we have founded expected slot size, we can push it to array of available timeslot
                    if (step == this.timeslotDuration / this.participantTimeslot.timeslot_length) {
                        // insert end
                        date.end = slot.end;
                        if (date.status == 'free') {
                            this.someFree = true;
                        }
                        // add to timeslot array for list of slots
                        this.timeslots.push({ ...date });
                        // reset slot and step
                        date = null;
                        step = 1;
                    } else {
                        step++;
                    }
                });
            }
        }
        // show only free timeslots
        this.timeslots = this.timeslots.filter((slot) => {
            return slot.status == 'free' && !slot.appointment;
        })
    }

    /**
     * test actual generated timeslot view to check if there are any available slot
     */
    public testSlots(duration, day) {
        if (this.selectedDay) {
            let originalSlots = this.participantTimeslot.timeslots['items'][day.getTime()];
            let step = 1;
            let date = null;
            originalSlots.forEach(slot => {
                if (!date) {
                    date = { ...slot };
                }
                // change whole slot status by one included slot (every whole generated slot is blocked when some included slot is blocked)
                if (slot.status != 'free' || slot.appointment) {
                    date.status = 'blocked';
                }
                // if we have founded expected slot size, we can push it to array of available timeslot
                if (step == duration / this.participantTimeslot.timeslot_length) {
                    // insert end
                    date.end = slot.end;
                    if (date.status == 'free') {

                        if (day.getTime() != this.selectedDay.getTime()) {
                            // seeking for highest available slot in another day
                            if (duration > this.smallerDurationFreeDiffDay) {
                                this.smallerDurationFreeDiffDay = duration;
                                this.diffDayFree = day;
                            }
                        } else {
                            // store that we have founded some available slot with smaller duration
                            this.smallerDurationFree = duration;
                        }
                    }
                    // reset slot and step
                    date = null;
                    step = 1;
                } else {
                    step++;
                }
            });
        }
    }

    /**
     * set proper date_start_at value for appointment from string start_at
     *
     * @return void
     */
    ionDatetimeChange(value) {
        this.appointment.starts_at = moment(value.detail.value, 'YYYY-MM-DDTHH:mmZ').utc().format('YYYY-MM-DD HH:mm:ss');
        this.appointment.date_starts_at = moment(value.detail.value, 'YYYY-MM-DDTHH:mmZ').toDate();
    }

    /**
     * trigger actions for inputfocus
     *
     */
    public inputFocus() {
        // scroll to last message
        this.scrollPageToBottom();
        // check page resizing after keyboard is opened
        this.plt.checkResize();
    }

    public scrollPageToBottom(): void {
        try {
            for (let i = 1; i <= 2; i++) {
                setTimeout(() => {
                    // scroll whole page to bottom, used for keyboard to focus textarea
                    let el = document.querySelector('.attendee-modal form');
                    if (el) {
                        el.scrollTo(0, document.querySelector('.attendee-modal form').scrollHeight);
                    } else {
                        // try to scroll form in attendee detail if modal is not available
                        let el = document.querySelector('attendee-detail form');
                        if (el) {
                            el.scrollTo(0, document.querySelector('attendee-detail form').scrollHeight);
                        }
                    }
                }, 250 * i);
            }
        } catch (err) {
            return;
        }
    }

    /**
     * check app resize (keyboard opening) for input focus
     */
    public autocompleteFocus() {
        this.plt.checkResize();
        // we will hide on small screens all to make autocomplete input bigger
        this.autocompleteOpen = true;
    }


    /**
     * check app resize (keyboard closing) for input blur
     */
    public autocompleteBlur(clear: boolean = false) {
        this.plt.checkResize(false);
        this.autocompleteOpen = false;
        // close autocomplete list when triggering manually cancel for autocomplete
        if (clear) {
            // NOTE[jg] do this only for manual, not on ionBlur for autocomplete component
            // that was causing big performance problems
            this.searchbar.clearValue(true);
        }
    }

    /**
     * Add new participant to appointment
     *
     * @param {Particiapnt} particiant event participant
     */
    public addParticipant(participant) {
        if (!this.appointment.participants.find((item) => { item.id == participant.id })) {
            this.appointment.participants.push(participant)
        }

        // check resize of app and other items when bluring input
        this.autocompleteBlur();

        // reset value for autocomplet searc
        this.searchbar.clearValue();
        this.scrollBottom();
    }

    /**
     * Remove participant from appointment
     *
     * @param {Participant} participant event participant
     */
    public removeParticipant(participant) {
        this.appointment.participants = this.appointment.participants.filter((item) => {
            return item.id != participant.id
        });
    }

    public scrollBottom() {
        setTimeout(() => {
            document.getElementById('invitation-box').scrollIntoView({ block: 'end' });
        }, 300);
    }

    /**
     * on destroy
     *
     * @return void
     */
    ngOnDestroy() {
        this.participantService.autocompleteOptions = null;

        if (this.plt.is('cordova')) {
            this.keyboard.setResizeMode(KeyboardResizeMode.Native);
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
