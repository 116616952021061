import { Component, ContentChild, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Input, Output } from '@angular/core';
import { OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-collapsible',
    templateUrl: './collapsible.component.html',
    styleUrls: ['./collapsible.component.scss'],
})
export class CollapsibleComponent implements OnInit, OnChanges {

    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

    @Input() icon: string = '';

    @Input() title: string = '';

    @Input() opened: boolean = false;

    @Input() count: number;

    @Output() boxOpened: EventEmitter<void> = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    public toggle() {
        this.opened = !this.opened;
        if (this.opened) {
            this.boxOpened.emit();
        }
    }

    public open() {
        this.opened = true;
        this.boxOpened.emit();
    }

    public close() {
        this.opened = false;
    }

    /**
     * Watch changes on Inputs and refresh component if needed
     *
     * @param participant
     */
    ngOnChanges(changes: SimpleChanges) {

        if (
            changes.hasOwnProperty('opened')
            && changes.opened.currentValue == false
        ) {
            this.close();
        }
    }

}
