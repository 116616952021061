<ion-content class="ion-padding">
    <div class="content-centered-column">
        <div class="content-centered-row">
            <form (ngSubmit)="reset()"
                  #f="ngForm"
                  novalidate>
                <ion-list class="form">
                    <ion-item class="ion-no-lines">
                        <h1>{{ 'LOST_PASSWORD_TITLE' | trns}}</h1>
                    </ion-item>
                    <ion-item class="ion-no-lines">
                        <p>{{ 'LOST_PASSWORD_TEXT' | trns }}&nbsp;&nbsp;</p>
                        <ion-label position="stacked">{{ 'EMAIL' | trns }}</ion-label>
                        <ion-input type="email"
                                   placeholder="{{ 'EMAIL_PLACEHOLDER' | translate }}"
                                   name="email"
                                   inputmode="email"
                                   (keyup.enter)="reset()"
                                   spellcheck="false"
                                   autocapitalize="off"
                                   #email="ngModel"
                                   [(ngModel)]="user.email"
                                   autofocus="true"
                                   pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                                   required>
                        </ion-input>
                    </ion-item>

                    <ion-button expand="block"
                                color="primary"
                                type="submit"
                                [disabled]="!f.form.valid">
                        {{ 'LOST_PASSWORD_BUTTON' | trns }}
                    </ion-button>
                    <ion-item class="ion-no-padding ion-no-lines">
                        <a class="link"
                           [routerLink]="'/'">
                            {{ 'LOST_PASSWORD_GOTO_LOGIN' | trns }}
                        </a>
                    </ion-item>

                </ion-list>
            </form>
        </div>
    </div>
</ion-content>