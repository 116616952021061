<div class="radial-progress">
    <svg *ngIf="matching && attendee?.matching_factor > 0"
         class="svg-progress"
         viewport="0 0 156 156"
         xmlns="http://www.w3.org/2000/svg">
        <circle id="bar"
                [attr.r]="radius"
                [attr.cx]="radius"
                [attr.cy]="radius"
                fill="transparent"
                [style.strokeDasharray]="circumference"
                [style.strokeDashoffset]="dashoffset">
        </circle>
    </svg>
    <div class="radial-inner">
        <ion-avatar *ngIf="attendee?.picture_url && picture && imageLoading"
                    slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <img *ngIf="picture && attendee?.picture_url"
             img-avatar
             [random]="attendee.id"
             [ngClass]="{'hidden': imageLoading}"
             (load)="loaded()"
             [src]="attendee.picture_url">
        <svg-icon *ngIf="!picture || !attendee?.picture_url"
                  [applyClass]="true"
                  [class]="'avatar-fill-' + order % 3 "
                  src="/assets/icons/ico-avatar-empty.svg">
        </svg-icon>
        <!-- <img *ngIf="!picture"
                  img-avatar
                  [random]="attendee.id"
                  [src]="''"> -->
    </div>
</div>